import Loading from "src/components/loading/InitialLoading";
import Pagination from "src/components/pagination";
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/table/Table";
import SubscriberActionsCell from "./SubsciberActionCells";
import ClickableTooltip from "src/components/clickable-tooltip/ClickableTooltip";
import Icon from "src/components/buttons/Icon";
import { Label } from "src/components/label/Label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/select/Select";
import SubscribersToolbar from "./SubscribersListToolbar";

const subscriberPageSize = 8;

const subscriber = {
  id: "550e8400-e29b-41d4-a716-4466",
  name: "Kyle Peterson",
  contanctInformation: {
    phone: "+1 203 5044 303",
    email: "test@gmail.com",
    address: "123 Test Ammon, ID 83475",
  },
  service: "QWKnet Enterprise",
  planInformation: {
    type: "Enterprise",
    bandwidth: "1000 UP / 1000 DOWN",
    linkSpeed: 0,
    price: "$0.00",
    datacap: "None",
    planDetails: "Contact Provider",
  },
  subscribedSince: new Date(),
};

export type Subscriber = typeof subscriber;

export default function SubscribersList() {
  const hash = window.location.hash.replace("#", "");
  const [lastPage, setLastPage] = useState<number>(1);
  const [selectedOperator, setSelectedOperator] = useState("City of Ammon");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>(
    hash !== "" ? hash : "*",
  );
  const [isLoading, setIsLoading] = useState(false);
  const [subscribers, setSubscribers] = useState<Subscriber[]>(
    new Array(30).fill(subscriber, 0, 30),
  );

  const [subscribersSearch, setSubscribersSearch] = useState<Subscriber[]>([]);

  const searchSubscriber = async (query: string, page: number = 1) => {
    setIsLoading(true);
    const searchFilteredResults = subscribers.filter((subscriber) => {
      if (subscriber.name.toLowerCase().includes(query.toLowerCase())) {
        return true;
      }
      return false;
    });
    const maxNumberOfPages = Math.ceil(
      searchFilteredResults.length / subscriberPageSize,
    );
    // perform client side pagination.
    const startIndex = (page - 1) * subscriberPageSize;
    const endIndex = startIndex + subscriberPageSize;
    const paginatedResults = searchFilteredResults.slice(startIndex, endIndex);

    setLastPage(maxNumberOfPages);
    setSubscribersSearch(paginatedResults);
    setIsLoading(false);
  };

  const formatDate = (date: Date) => {
    const formatter = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    return formatter.format(date);
  };

  const clearSearchSubscriber = () => {
    setSearchQuery("*");
    setSubscribersSearch([]);
  };

  const handleActionsCellClick = (
    event: React.MouseEvent<HTMLTableCellElement>,
  ) => {
    event.stopPropagation();
  };

  const handleSelectPage = (page: number) => {
    setCurrentPage(page);
    searchSubscriber(searchQuery, page);
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
  }, []);

  function renderSubscribersList() {
    if (isLoading) {
      return <Loading></Loading>;
    }

    if (
      subscribers.length > 0 &&
      subscribersSearch.length < 1 &&
      searchQuery === "*"
    ) {
      return (
        <div className="mt-10 p-10 text-center min-h-[calc(100vh-340px-2rem)]">
          <p className="font-bold mb-10">
            There are currently {subscribers.length} subscribers available for
            searching
          </p>
          <p>Please enter a search term to load results</p>
        </div>
      );
    }

    if (subscribersSearch.length < 1 && searchQuery !== "*") {
      return (
        <div className="mt-10 p-10 text-center min-h-[calc(100vh-340px-2rem)]">
          <p className="font-bold mb-10">No matching search results</p>
          <p>Try again using another search term</p>
        </div>
      );
    }

    return (
      <>
        <Table dataCy="subscribersTable">
          <TableHeader dataCy="subscribersTableHeader" className="sticky top-0">
            <TableRow dataCy="subscribersTableHeaderRow">
              <TableHead className="text-left">Name</TableHead>
              <TableHead>Service{" > "}Plan</TableHead>
              <TableHead>Subscribed since</TableHead>
              <TableHead className="text-center">Action</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody dataCy="subscribersTableBody">
            {subscribersSearch &&
              subscribersSearch.map((subscriber) => (
                <TableRow
                  key={subscriber.id}
                  className="font-medium"
                  dataCy={`${subscriber.id}Row`}
                >
                  <TableCell dataCy={`${subscriber.id}subscriberNameCell`}>
                    <div className="flex items-center gap-2">
                      {subscriber.name}
                      <ClickableTooltip
                        openOnHover={true}
                        header={
                          <p className="uppercase">Contact Information</p>
                        }
                        icon={
                          <Icon
                            size={30}
                            className="pt-[4px]"
                            name="exclamation"
                            data-cy={`contactInformationIcon-${subscriber.id}`}
                          />
                        }
                        content={
                          <ul className="flex flex-col py-2 font-base">
                            <li>
                              <span className="font-semibold">Phone</span>:{" "}
                              {subscriber.contanctInformation.phone}
                            </li>
                            <li>
                              <span className="font-semibold">Email</span>:{" "}
                              {subscriber.contanctInformation.email}
                            </li>
                            <li>
                              <span className="font-semibold">Address</span>:{" "}
                              {subscriber.contanctInformation.address}
                            </li>
                          </ul>
                        }
                        dataCy={`contactInformationTooltip-${subscriber.id}`}
                        className="w-[290px]"
                      />
                    </div>
                  </TableCell>
                  <TableCell dataCy={`${subscriber.id}RolesCell`}>
                    <div className="flex items-center gap-2">
                      <span>
                        {" "}
                        {subscriber.service}
                        {" > "}
                        {subscriber.planInformation.type}
                      </span>
                      <ClickableTooltip
                        openOnHover={true}
                        header={<p className="uppercase">Plan Information</p>}
                        icon={
                          <Icon
                            size={30}
                            className="pt-[4px]"
                            name="exclamation"
                            data-cy={`planInformationIcon-${subscriber.id}`}
                          />
                        }
                        content={
                          <ul className="flex flex-col py-2 font-base">
                            <li>
                              <span className="font-semibold">Bandwidth:</span>
                              {subscriber.planInformation.bandwidth}
                            </li>
                            <li>
                              <span className="font-semibold">Link Speed:</span>
                              {subscriber.planInformation.linkSpeed}
                            </li>
                            <li>
                              <span className="font-semibold">Price:</span>:{" "}
                              {subscriber.planInformation.price}
                            </li>
                            <li>
                              <span className="font-semibold">Data Cap:</span>:{" "}
                              {subscriber.planInformation.datacap}
                            </li>
                            <li>
                              <span className="font-semibold">
                                Plan Details:
                              </span>
                              : {subscriber.planInformation.planDetails}
                            </li>
                          </ul>
                        }
                        dataCy={`planInformationTooltip-${subscriber.id}`}
                        className="w-[290px]"
                      />
                    </div>
                  </TableCell>
                  <TableCell dataCy={`${subscriber.id}ContactCell`}>
                    {formatDate(subscriber.subscribedSince)}
                  </TableCell>
                  <TableCell
                    className="text-center"
                    onClick={handleActionsCellClick}
                    dataCy={`${subscriber.id}SubscriberActionsCell`}
                  >
                    <SubscriberActionsCell
                      removeSubscriber={() => {
                        setSubscribers(
                          subscribers.slice(0, subscribers.length - 1),
                        );
                      }}
                      subscriber={subscriber}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <div
          className={`flex flex-wrap items-center ${
            lastPage > 1 ? "justify-between" : "justify-start"
          } space-x-0 mt-4`}
        >
          {subscribersSearch && subscribers.length >= 1 && (
            <div className="text-xs font-normal font-secondary">
              Showing{" "}
              {lastPage > 1
                ? `${(currentPage - 1) * subscriberPageSize + 1} to ${
                    (currentPage - 1) * subscriberPageSize +
                      subscriberPageSize >
                    subscribers.length
                      ? subscribers.length
                      : (currentPage - 1) * subscriberPageSize +
                        subscriberPageSize
                  }`
                : `${subscribers?.length}`}{" "}
              of {subscribers?.length} entries
            </div>
          )}
          {lastPage > 1 && subscribersSearch.length >= 1 && (
            <div className="flex items-center">
              <Pagination
                pageCount={lastPage > 4 ? 4 : lastPage}
                currentPage={currentPage}
                lastPage={lastPage}
                onSelect={handleSelectPage}
              />
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <div className="flex flex-col gap-6">
      <div>
        <Label className="font-secondary text-xs uppercase" htmlFor="class">
          Operator
        </Label>
        <Select
          defaultValue={selectedOperator}
          onValueChange={setSelectedOperator}
          data-cy="subscribersOperatorSelect"
        >
          <SelectTrigger
            id="class"
            className="max-w-[305px]"
            data-cy="subscribersOperatorTrigger"
          >
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem
                data-cy="subscribersOperatorAmmon"
                value="City of Ammon"
              >
                City of Ammon
              </SelectItem>
              <SelectItem
                data-cy="subscribersOperatorSunnyValley"
                value="City of Boston"
              >
                City of Boston
              </SelectItem>
              <SelectItem
                data-cy="subscribersOperatorEntpnt"
                value="City of New york"
              >
                City of New york
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <SubscribersToolbar
        setSearchFilter={(query: string) => {
          setSearchQuery(query);
          searchSubscriber(query, currentPage);
        }}
        clearSearch={clearSearchSubscriber}
      ></SubscribersToolbar>
      {renderSubscribersList()}
    </div>
  );
}

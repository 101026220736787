import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/table/Table";

import { NetworkOperator } from "../list/NetworkOperatorsList";
import ScrollArea from "src/components/scroll-area/ScrollArea";
import { DialogClose } from "@radix-ui/react-dialog";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import AddNewContactDialog from "../manage-network-operator-contact/AddNewContactDialog";
import ManageNetworkOperatorContactDialog from "../manage-network-operator-contact/ManageNetworkOperatorContactDialog";
import { Contact } from "src/interfaces";

export default function ManageNetworkOperatorContacts({
  networkOperatorDetails,
}: {
  networkOperatorDetails: NetworkOperator;
}) {
  const contacts = networkOperatorDetails.Contacts as unknown as Contact[];

  const handleActionsCellClick = (
    event: React.MouseEvent<HTMLTableCellElement>,
  ) => {
    event.stopPropagation();
  };

  function renderTableBody() {
    if (contacts.length < 1) {
      return (
        <TableRow className="font-medium" dataCy={`noRecordsRow`}>
          <TableCell dataCy={`noRecordsTextCell`}>
            <p className="text-neutral-600 text-sm">No records found</p>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <>
        {contacts.map((contact) => (
          <TableRow
            key={contact.id}
            className="font-medium"
            dataCy={`${contact.id}Row`}
          >
            <TableCell
              dataCy={`${contact.id}manageNetworkOperatorsContactsFirstNameCell`}
            >
              {contact.first_name}
            </TableCell>
            <TableCell
              dataCy={`${contact.id}manageNetworkOperatorsContactsEmailCell`}
            >
              {contact.email}
            </TableCell>
            <TableCell
              dataCy={`${contact.id}manageNetworkOperatorsContactsTypeCell`}
            >
              {contact.type}
            </TableCell>
            <TableCell
              className="text-center"
              onClick={handleActionsCellClick}
              dataCy={`${contact.id}manageNetworkOperatorsContactsActionsCell`}
            >
              <ManageNetworkOperatorContactDialog contact={contact} />
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  }

  return (
    <>
      <ScrollArea className="max-h-[calc(85vh-theme(space.52))] my-4 p-0">
        <Table dataCy="manageNetworkOperatorsContactsTable">
          <TableHeader
            dataCy="manageNetworkOperatorsContactsTableHeader"
            className="sticky top-0"
          >
            <TableRow dataCy="manageNetworkOperatorsContactsTableHeaderRow">
              <TableHead className="text-left">Name</TableHead>
              <TableHead className="text-left">Email</TableHead>
              <TableHead className="text-left">Type</TableHead>
              <TableHead className="text-center">Action</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody dataCy="manageNetworkOperatorsContactsTableBody">
            {renderTableBody()}
          </TableBody>
        </Table>
      </ScrollArea>
      <AddNewContactDialog />
      <div className="flex items-center justify-between mt-2">
        <DialogClose asChild className="flex gap-2 ml-auto">
          <EpButton
            type="button"
            styling={EpButtonStyles.CANCEL}
            dataCy="cancelBtn"
            aria-label="cancel"
          >
            Cancel
          </EpButton>
        </DialogClose>
      </div>
    </>
  );
}

import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import { DialogClose } from "src/components/dialog/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/form/Form";
import { Input } from "src/components/input/Input";
import { Serviceplan } from "src/interfaces";

import { z } from "zod";
import PublishDetailsDialog from "../manage-network-operator-services/PublishNetworkOperatorServiceDialog";
import PlanFieldsGlobalValues from "./PlanFieldsGlobalValues";
import { useEffect, useState } from "react";
import LoadingAnimation from "src/components/spinners/LoadingAnimation";
import ScrollArea from "src/components/scroll-area/ScrollArea";

const ManageNetworkOperatorServicePlanFieldsFormSchema = z.object({
  linkSpeed: z.coerce.number(),
  down: z.coerce.number(),
  up: z.coerce.number(),
  price: z.coerce.number(),
});

type ManageNetworkOperatorServicePlanFieldsFormProps = {
  servicePlan: Serviceplan;
};

const capitalTocamelCaseMapping = {
  LINKSPEED: "linkSpeed",
  DOWN: "down",
  UP: "up",
  PRICE: "price",
} as const;

export default function ManageNetworkOperatorServicePlanFieldsForm({
  servicePlan,
}: ManageNetworkOperatorServicePlanFieldsFormProps) {
  const ManageNetworkOperatorServicePlanFieldsForm = useForm<
    z.infer<typeof ManageNetworkOperatorServicePlanFieldsFormSchema>
  >({
    resolver: zodResolver(ManageNetworkOperatorServicePlanFieldsFormSchema),
    mode: "onBlur",
    defaultValues: {
      down: servicePlan.fields.Down,
      linkSpeed: servicePlan.fields.LinkSpeed,
      price: servicePlan.fields.Price,
      up: servicePlan.fields.Up,
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [planFieldsGlobalValues, setPlanFieldsGlobalValuesState] = useState<
    Record<string, boolean>
  >({
    LINKSPEED: true,
    DOWN: true,
    UP: true,
    PRICE: true,
  });

  function handleManageDetailsSave(
    data: z.infer<typeof ManageNetworkOperatorServicePlanFieldsFormSchema>,
  ) {
    console.log({ data });
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1300);
  }

  function handleFormStateChange() {
    const defaultValues =
      ManageNetworkOperatorServicePlanFieldsForm.formState.defaultValues;
    const actualFormValues =
      ManageNetworkOperatorServicePlanFieldsForm.getValues();

    if (defaultValues) {
      const loopalbleKeys = Object.keys(capitalTocamelCaseMapping) as Array<
        keyof typeof capitalTocamelCaseMapping
      >;
      for (let i = 0; i < loopalbleKeys.length; i++) {
        const keyItem = loopalbleKeys[i];
        const camelCaseKey = capitalTocamelCaseMapping[keyItem];
        setPlanFieldsGlobalValuesState((originalState) => {
          return {
            ...originalState,
            [keyItem]:
              defaultValues[camelCaseKey] === actualFormValues[camelCaseKey],
          };
        });
      }
    }
  }

  function handleGlobalPlanCheckboxesValuesChange() {
    const loopalbleKeys = Object.keys(capitalTocamelCaseMapping) as Array<
      keyof typeof capitalTocamelCaseMapping
    >;
    const defaultValues = ManageNetworkOperatorServicePlanFieldsForm.formState
      .defaultValues as any;

    for (let i = 0; i < loopalbleKeys.length; i++) {
      const keyItem = loopalbleKeys[i];

      if (planFieldsGlobalValues[keyItem]) {
        const camelCaseKey = capitalTocamelCaseMapping[keyItem];
        ManageNetworkOperatorServicePlanFieldsForm.setValue(
          camelCaseKey,
          defaultValues[camelCaseKey],
        );
      }
    }
  }

  const { isValidating } = ManageNetworkOperatorServicePlanFieldsForm.formState;

  useEffect(() => {
    handleFormStateChange();
  }, [isValidating]);

  useEffect(() => {
    handleGlobalPlanCheckboxesValuesChange();
  }, [planFieldsGlobalValues]);

  return (
    <ScrollArea className="max-h-[calc(85vh-theme(space.52))] p-1">
      <Form {...ManageNetworkOperatorServicePlanFieldsForm}>
        <div className="grid grid-rows-1 grid-cols-2 mb-4 text-neutral-500 font-secondary text-xs">
          <span>CREATED: {servicePlan.created_at}</span>
          <span>UPDATED: {servicePlan.updated_at}</span>
        </div>
        <form
          onSubmit={ManageNetworkOperatorServicePlanFieldsForm.handleSubmit(
            handleManageDetailsSave,
          )}
        >
          <div className="grid grid-cols-2 gap-x-2 grid-rows-1">
            <FormField
              control={ManageNetworkOperatorServicePlanFieldsForm.control}
              name="linkSpeed"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>LINK SPEED</FormLabel>
                  <FormControl>
                    <Input
                      autoFocus
                      id="linkSpeed"
                      placeholder="0"
                      data-cy="linkSpeedInput"
                      type="number"
                      aria-label="Link Speed"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={ManageNetworkOperatorServicePlanFieldsForm.control}
              name="down"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>DOWN</FormLabel>
                  <FormControl>
                    <Input
                      id="down"
                      placeholder="Down"
                      data-cy="downInput"
                      type="number"
                      aria-label="Down"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="grid grid-cols-2 gap-x-2 grid-rows-1">
            <FormField
              control={ManageNetworkOperatorServicePlanFieldsForm.control}
              name="up"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>UP</FormLabel>
                  <FormControl>
                    <Input
                      id="up"
                      placeholder="0"
                      data-cy="upInput"
                      type="number"
                      aria-label="Up"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={ManageNetworkOperatorServicePlanFieldsForm.control}
              name="price"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>PRICE</FormLabel>
                  <FormControl>
                    <Input
                      id="price"
                      placeholder="Price"
                      data-cy="priceInput"
                      type="number"
                      aria-label="Price"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <PlanFieldsGlobalValues
            planFieldsGlobalValues={planFieldsGlobalValues}
            setPlanFieldsGlobalValuesState={setPlanFieldsGlobalValuesState}
          />
          <div className="flex gap-4 items-center justify-start mt-6">
            <EpButton
              aria-label="Save updated changes"
              dataCy="saveUpdatedChangesBtn"
              disabled={isLoading}
            >
              {isLoading && <LoadingAnimation />}
              {isLoading ? "Saving.." : "Save"}
            </EpButton>
            <span className="text-xs font-normal">
              <span className="font-medium mr-2">Last saved</span>{" "}
              {servicePlan.updated_at}
            </span>
          </div>
          <div className="flex items-center justify-between mt-8">
            <div className="flex justify-start gap-4 items-center border border-neutral-200 rounded-md p-2">
              <PublishDetailsDialog updatedAt={servicePlan.updated_at} />
              <span className="text-xs font-normal">
                <span className="font-medium mr-2">Last published</span>{" "}
                {servicePlan.updated_at}
              </span>
            </div>
            <DialogClose asChild className="flex gap-2 ml-auto">
              <EpButton
                type="button"
                styling={EpButtonStyles.CANCEL}
                dataCy="cancelBtn"
                aria-label="cancel"
              >
                Cancel
              </EpButton>
            </DialogClose>
          </div>
        </form>
      </Form>
    </ScrollArea>
  );
}

import { labelVariants } from "src/components/label/Label";
import { useSession } from "src/hooks/session/useSession";
import UpdatePasswordDialog from "./UpdatePasswordDialog";
import UpdateUsernameDialog from "./UpdateUsernameDialog";
import { cn } from "src/utils/cn";

export default function UserDetails() {
  const { useUser } = useSession();
  const { user, setUser } = useUser;

  return (
    <div>
      <h2 className="text-base font-base">Account Settings</h2>
      <div className="flex flex-col w-[500px] gap-8 py-4">
        <div className="flex flex-wrap items-center">
          <span
            className={cn(
              labelVariants(),
              "w-1/4 text-[#6D6D6D] font-secondary",
            )}
          >
            Username:
          </span>
          <span
            className={cn(labelVariants(), "w-1/4")}
            data-cy="usernameValue"
          >
            {user?.username}
          </span>
          <UpdateUsernameDialog user={user!} setUser={setUser} />
        </div>
        <div className="flex flex-wrap items-center">
          <span
            className={cn(
              labelVariants(),
              "w-1/4 text-[#6D6D6D] font-secondary",
            )}
          >
            Password:
          </span>
          <span
            className={cn(labelVariants(), "w-1/4")}
            data-cy="passwordValue"
          >
            **********
          </span>
          <UpdatePasswordDialog user={user} />
        </div>
      </div>
    </div>
  );
}

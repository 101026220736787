import { useState } from "react";
import IconButton from "../buttons/IconButton";
import { TableCell, TableRow } from "../table/Table";
import { ApproveServiceModal } from "./ApproveServiceModal";
import { ApprovalItem } from "./PendingApprovalTable";
import { formatDateToHumanReadableISOString } from "src/utils/date";

type PendingApprovalTableRowProps = {
  approvalItem: ApprovalItem;
  index: number;
};

export default function PendingApprovalTableRow({
  approvalItem,
  index,
}: PendingApprovalTableRowProps) {
  const [isApproveServiceModalOpen, setIsApproveServiceModalOpen] =
    useState(false);

  return (
    <TableRow dataCy={`pending-approval-table-row-${index}`}>
      <TableCell
        dataCy={`pending-approval-table-col-class-${index}`}
        className="font-medium"
      >
        {approvalItem.class}
      </TableCell>
      <TableCell dataCy={`pending-approval-table-col-provider-${index}`}>
        {approvalItem.provider}
      </TableCell>
      <TableCell dataCy={`pending-approval-table-col-name-${index}`}>
        {approvalItem.name}
      </TableCell>
      <TableCell dataCy={`pending-approval-table-col-id-${index}`}>
        {approvalItem.id}
      </TableCell>
      <TableCell dataCy={`pending-approval-table-col-created-${index}`}>
        {formatDateToHumanReadableISOString(approvalItem.createdAt)}
      </TableCell>
      <TableCell dataCy={`pending-approval-table-col-action-${index}`}>
        <IconButton
          onClick={() => setIsApproveServiceModalOpen(true)}
          name="view-file"
          aria-label="Open approval service modal"
          dataCy="approval-open-file"
        />
        {isApproveServiceModalOpen ? (
          <ApproveServiceModal
            approvalItem={approvalItem}
            setIsApproveServiceModalOpen={setIsApproveServiceModalOpen}
          />
        ) : null}
      </TableCell>
    </TableRow>
  );
}

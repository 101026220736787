import * as React from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../tooltip/Tooltip";
import { DataCyProps } from "../../interfaces";
import { cn } from "../../utils/cn";

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement> & {
    containerClassName?: string;
  } & DataCyProps
>(({ className, containerClassName, dataCy, ...props }, ref) => (
  <div
    className={cn(
      "w-full rounded-t-xl scrollbar-hide font-inter-500 overflow-hidden",
      containerClassName,
    )}
  >
    <table
      ref={ref}
      className={cn("w-full caption-bottom text-xs", className)}
      data-cy={dataCy}
      {...props}
    />
  </div>
));
Table.displayName = "Table";

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement> & {
    borderless?: boolean;
    sticky?: boolean;
  } & DataCyProps
>(({ className, borderless = false, dataCy, sticky = true, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn(
      borderless ? "" : "[&_tr]:border-b",
      `${sticky ? "sticky" : ""} top-0`,
      className,
    )}
    data-cy={dataCy}
    {...props}
  />
));
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement> & DataCyProps
>(({ className, dataCy, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn("[&_tr:last-child]:border-0", className)}
    data-cy={dataCy}
    {...props}
  />
));
TableBody.displayName = "TableBody";

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement> & DataCyProps
>(({ className, dataCy, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn("bg-primary font-medium", className)}
    data-cy={dataCy}
    {...props}
  />
));
TableFooter.displayName = "TableFooter";

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement> & {
    borderless?: boolean;
    hoverable?: boolean;
  } & DataCyProps
>(
  (
    {
      className,
      borderless = false,
      hoverable = true,
      dataCy,
      onClick,
      ...props
    },
    ref,
  ) => (
    <tr
      ref={ref}
      className={cn(
        `transition-colors ${
          hoverable ? "hover:bg-neutrals-light " : ""
        }even:bg-table-row-even data-[state=selected]:bg-muted`,
        borderless ? "" : "border-b shadow-row",
        className,
        onClick ? "cursor-pointer" : "",
      )}
      onClick={onClick}
      data-cy={dataCy}
      {...props}
    />
  ),
);
TableRow.displayName = "TableRow";

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement> & {
    borderless?: boolean;
  } & DataCyProps
>(({ className, borderless = false, dataCy, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      "h-8 px-4 text-left align-middle font-medium text-neutrals-form-gray-dark [&:has([role=checkbox])]:pr-0 bg-table-header-light-gray",
      borderless ? "" : "shadow-row",
      className,
    )}
    data-cy={dataCy}
    {...props}
  />
));
TableHead.displayName = "TableHead";

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement> & {
    tooltipText?: string;
    tooltipContentClassName?: string;
    tooltipHeader?: React.ReactNode;
  } & DataCyProps
>(
  (
    {
      className,
      dataCy,
      tooltipText,
      tooltipContentClassName,
      tooltipHeader,
      children,
      ...props
    },
    ref,
  ) => {
    const inner = (
      <div
        className={cn(
          "p-2 px-4 align-middle [&:has([role=checkbox])]:pr-0",
          className,
        )}
      >
        {children}
      </div>
    );
    return (
      <td ref={ref} data-cy={dataCy} {...props}>
        {tooltipText ? (
          <TooltipProvider delayDuration={500} skipDelayDuration={0}>
            <Tooltip>
              <TooltipTrigger asChild>{inner}</TooltipTrigger>
              <TooltipContent
                className={tooltipContentClassName}
                header={tooltipHeader}
              >
                <p>{tooltipText}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : (
          inner
        )}
      </td>
    );
  },
);
TableCell.displayName = "TableCell";

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement> & DataCyProps
>(({ className, dataCy, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn("mt-4 text-sm text-neutrals-form-gray-dark", className)}
    data-cy={dataCy}
    {...props}
  />
));
TableCaption.displayName = "TableCaption";

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
};

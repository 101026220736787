import PageContainer from "src/components/page-container/PageContainer";
import SubscriptionHistorySearchFilterer from "./search/SubscriptionHistorySearchFilterer";
import { useState } from "react";
import { SubscriptionHistory } from "src/interfaces";
import SubscriptionHistoryTable from "./list/SubscriptionHistoryTable";
import ScrollArea from "src/components/scroll-area/ScrollArea";

export default function SubscriptionHistoryPage() {
  const [filteredSubscriptionHistory, setFilteredSubscriptionHistory] =
    useState<SubscriptionHistory[]>([]);

  return (
    <div className="w-full">
      <span className="text-primary-font">Subscription History</span>
      <PageContainer className="mt-2">
        <ScrollArea className="w-full max-h-[calc(100vh-theme(space.52))]">
          <div className="flex flex-wrap justify-start space-x-0 w-full">
            <SubscriptionHistorySearchFilterer
              setFilteredSubscriptionHistory={setFilteredSubscriptionHistory}
            />
            {filteredSubscriptionHistory.length ? (
              <SubscriptionHistoryTable
                filteredSubscriptionHistory={filteredSubscriptionHistory}
              />
            ) : null}
          </div>
        </ScrollArea>
      </PageContainer>
    </div>
  );
}

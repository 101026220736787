import * as React from "react";
import * as SelectPrimitive from "@radix-ui/react-select";
import { ChevronDown } from "lucide-react";
import { cn } from "../../utils/cn";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../tooltip/Tooltip";
import CheckRoundedIcon from "../icons/CheckRoundedIcon";
import ScrollArea from "../scroll-area/ScrollArea";

const Select = SelectPrimitive.Root;
const SelectGroup = SelectPrimitive.Group;
const SelectValue = SelectPrimitive.Value;

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
    canClear?: boolean;
    onClear?: () => void;
    clearTooltip?: string;
  }
>(
  (
    { className, children, canClear = false, onClear, clearTooltip, ...props },
    ref,
  ) => {
    const handleClearClick = () => {
      onClear && onClear();
    };

    const select = (
      <SelectPrimitive.Trigger
        ref={ref}
        className={cn(
          "flex h-8 w-full items-center justify-between rounded-md",
          "border border-neutrals-gray bg-background px-3 py-2 text-xs font-secondary ring-offset-background",
          "placeholder:text-muted-foreground",
          "focus:outline-none focus-visible:ring-2 focus-visible:input focus-visible:ring-offset-1 focus-visible:border-[#2E9BFF]",
          "disabled:cursor-not-allowed disabled:border-input disabled:bg-[#F0F0F0]",
          "aria-expanded:border-[#2E9BFF] aria-expanded:ring-2 aria-expanded:ring-offset-1",
          className,
        )}
        {...props}
      >
        {children}
        <SelectPrimitive.Icon asChild>
          <ChevronDown className="h-4 w-4 opacity-50" />
        </SelectPrimitive.Icon>
      </SelectPrimitive.Trigger>
    );

    const selectWithClear = (
      <div className="max-h-[30px]">
        {select}
        <TooltipProvider delayDuration={500} skipDelayDuration={0}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div
                onClick={handleClearClick}
                className="cursor-pointer w-4 relative left-[88%] bottom-[24px]"
                data-cy="clearSelectValueButton"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                >
                  <path
                    d="M12.1084 2.54712C12.0429 2.48149 11.9651 2.42943 11.8795 2.3939C11.7938 2.35838 11.702 2.34009 11.6093 2.34009C11.5166 2.34009 11.4248 2.35838 11.3391 2.3939C11.2535 2.42943 11.1757 2.48149 11.1102 2.54712L7.64845 6.0018L4.18669 2.54004C4.12115 2.4745 4.04334 2.42251 3.9577 2.38704C3.87207 2.35157 3.78029 2.33331 3.6876 2.33331C3.59491 2.33331 3.50313 2.35157 3.41749 2.38704C3.33186 2.42251 3.25405 2.4745 3.18851 2.54004C3.12297 2.60558 3.07098 2.68339 3.03551 2.76903C3.00004 2.85466 2.98178 2.94644 2.98178 3.03913C2.98178 3.13182 3.00004 3.2236 3.03551 3.30924C3.07098 3.39487 3.12297 3.47268 3.18851 3.53822L6.65027 6.99998L3.18851 10.4617C3.12297 10.5273 3.07098 10.6051 3.03551 10.6907C3.00004 10.7764 2.98178 10.8681 2.98178 10.9608C2.98178 11.0535 3.00004 11.1453 3.03551 11.2309C3.07098 11.3166 3.12297 11.3944 3.18851 11.4599C3.25405 11.5255 3.33186 11.5774 3.41749 11.6129C3.50313 11.6484 3.59491 11.6666 3.6876 11.6666C3.78029 11.6666 3.87207 11.6484 3.9577 11.6129C4.04334 11.5774 4.12115 11.5255 4.18669 11.4599L7.64845 7.99816L11.1102 11.4599C11.1757 11.5255 11.2536 11.5774 11.3392 11.6129C11.4248 11.6484 11.5166 11.6666 11.6093 11.6666C11.702 11.6666 11.7938 11.6484 11.8794 11.6129C11.965 11.5774 12.0428 11.5255 12.1084 11.4599C12.1739 11.3944 12.2259 11.3166 12.2614 11.2309C12.2969 11.1453 12.3151 11.0535 12.3151 10.9608C12.3151 10.8681 12.2969 10.7764 12.2614 10.6907C12.2259 10.6051 12.1739 10.5273 12.1084 10.4617L8.64662 6.99998L12.1084 3.53822C12.3774 3.26921 12.3774 2.81613 12.1084 2.54712Z"
                    fill="#A9A9A9"
                  />
                </svg>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p className="capitalize" data-cy="clearSelectValueTooltip">
                {clearTooltip ? clearTooltip : "Clear value"}
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    );

    return canClear ? selectWithClear : select;
  },
);
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = "popper", ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        "relative z-50 min-w-[8rem] rounded-md border bg-popover text-popover-foreground shadow-small",
        "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
        position === "popper" &&
          "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
        className,
      )}
      position={position}
      {...props}
    >
      <ScrollArea className="max-h-60 px-0">
        <SelectPrimitive.Viewport
          className={cn(
            "p-0",
            position === "popper" &&
              "w-full min-w-[var(--radix-select-trigger-width)]",
          )}
        >
          {children}
        </SelectPrimitive.Viewport>
      </ScrollArea>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn("py-2 px-3 text-sm font-secondary", className)}
    {...props}
  />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      "relative flex w-full cursor-default select-none items-center py-2 px-3 text-xs font-secondary outline-none focus:bg-table-header-light-gray focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      className,
    )}
    {...props}
  >
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    <span className="absolute right-3 flex items-center justify-center text-bold">
      <SelectPrimitive.ItemIndicator>
        <CheckRoundedIcon />
      </SelectPrimitive.ItemIndicator>
    </span>
  </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn("-mx-1 my-1 h-px bg-muted", className)}
    {...props}
  />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectSeparator,
};

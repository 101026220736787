import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";

import { useState } from "react";
import NetworkOperatorContactForm from "./NetworkOperatorContactForm";
import { Contact, Location } from "src/interfaces";
import NetworkOperatorLocationForm from "./NetworkOperatorLocationForm";
import IconButton from "src/components/buttons/IconButton";
import TabMenu from "src/components/tabMenu";
import { TabConfig } from "src/components/tabMenu/TabMenu";

type ManageNetworkOperatorContactDialog = {
  contact: Contact;
};

export default function ManageNetworkOperatorContactDialog({
  contact,
}: ManageNetworkOperatorContactDialog) {
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [contactDetails, _] = useState<Contact>(contact);

  const locationDetails: Location = contactDetails.Location
    ? contactDetails.Location
    : {
        address1: "",
        city: "",
        country: "",
        id: "",
        state: "",
        uri: "",
        zip: "",
        address2: "",
        status: "",
      };

  const manageNetworkOperatorContactsTabsConfig: TabConfig[] = [
    {
      key: "contact",
      title: "Contact",
      titleAttribute: "Manage Contact Details",
      component: (
        <NetworkOperatorContactForm
          actionType="update"
          contactDetails={contactDetails}
          handleNext={() => {}}
        />
      ),
      dataCy: "manageContactTab",
    },
    {
      key: "location",
      title: "Location",
      titleAttribute: "Manage Location Details",
      component: (
        <NetworkOperatorLocationForm
          actionType="update"
          handleBack={() => {}}
          handleCloseDialog={() => setIsDialogOpened(false)}
          locationDetails={locationDetails}
        />
      ),
      dataCy: "manageLocationTab",
    },
  ];

  return (
    <Dialog
      open={isDialogOpened}
      onOpenChange={(value) => setIsDialogOpened(value)}
    >
      <DialogTrigger asChild>
        <IconButton
          aria-label="Manage contacts details"
          dataCy="manageNetworkOperatorContactsDialogBtn"
          tooltipText="Manage contacts details"
          name="view-file"
        />
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-4xl font-base"
        dataCy="manageNetworkOperatorContactsDialogCnt"
      >
        <DialogHeader
          dataCy="manageNetworkOperatorContactsDialogHeader"
          borderless
        >
          <DialogTitle dataCy="manageNetworkOperatorContactsDialogTitle">
            Manage {contactDetails.first_name}
          </DialogTitle>
        </DialogHeader>
        <TabMenu
          config={manageNetworkOperatorContactsTabsConfig}
          dataCy="manageNetworkOperatorContactsTabMenu"
        ></TabMenu>
      </DialogContent>
    </Dialog>
  );
}

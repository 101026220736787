import IconButton from "src/components/buttons/IconButton";
import GlobalServiceDetailsDialog from "../actions/ManageGlobalServiceDialog";
import RemoveGlobalService from "../actions/RemoveGlobalServiceDialog";
import { GlobalServicePlan } from "../manage-global-service/ManageDetailsPlans";
import { toast } from "src/components/toast/useToast";

type GlobalServicesActionsCellProps = {
  globalService: GlobalServicePlan;
  removeGlobalService: (globalServices: GlobalServicePlan) => void;
};

export default function GlobalServicesActionsCell({
  globalService,
  removeGlobalService,
}: GlobalServicesActionsCellProps) {
  async function handleCopy() {
    try {
      await navigator.clipboard.writeText(globalService.id);
      toast({
        title: "Success",
        description: (
          <>
            Copied to clipboard!
            <br />
          </>
        ),
      });
    } catch (error) {
      toast({
        title: "Error",
        description: (
          <>
            Failed to Copy to clipboard!
            <br />
          </>
        ),
      });
    }
  }
  return (
    <div className="flex justify-center gap-x-2">
      <GlobalServiceDetailsDialog globalService={globalService} />
      <IconButton
        name="copy"
        dataCy="copyIdActionBtn"
        tooltipText={`Copy ID to clipboard`}
        ariaLabel={`Copy ID to clipboard`}
        onClick={handleCopy}
      />
      <RemoveGlobalService
        removeGlobalService={removeGlobalService}
        globalService={globalService}
      />
    </div>
  );
}

import { SVGProps } from "react";

const ManageIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const defaultFill = "#6D6D6D";
  const fill = props.fill || defaultFill;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill={fill}
    >
      <path
        d="M22.8166 5.68782C22.7203 5.29981 22.2348 5.16833 21.952 5.45106L18.7569 8.64621L15.8402 8.16023L15.3542 5.24352L18.5494 2.04837C18.8338 1.76392 18.6967 1.27923 18.3062 1.18212C16.2703 0.677671 14.0286 1.22122 12.4375 2.81192C10.7342 4.5152 10.2654 6.98418 10.9744 9.13691L1.80534 18.306C0.731553 19.3798 0.731553 21.1209 1.80534 22.1947C2.87913 23.2684 4.62021 23.2684 5.694 22.1947L14.8553 13.0333C17.0089 13.7513 19.4732 13.2774 21.1876 11.5629C22.7805 9.97007 23.3232 7.72496 22.8166 5.68782ZM3.7501 21.2811C3.18077 21.2811 2.71885 20.8192 2.71885 20.2499C2.71885 19.6801 3.18077 19.2186 3.7501 19.2186C4.31943 19.2186 4.78135 19.6801 4.78135 20.2499C4.78135 20.8192 4.31943 21.2811 3.7501 21.2811Z"
        fill="#434343"
      />
    </svg>
  );
};

export default ManageIcon;

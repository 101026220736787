import { WebhookHistory } from "src/interfaces";
import WebhookHistoryToolbar from "./WebhookHistoryToolbar";
import { useState } from "react";
import Loading from "src/components/loading/Loading";

import { usePagination } from "@table-library/react-table-library/pagination";

import Pagination from "src/components/pagination";
import {
  ReportsTable,
  ReportsTableHeader,
  ReportsTableRow,
  ReportsTableBody,
  ReportsTableCell,
  ReportsTableHeaderCell,
  ReportsTableHeaderRow,
  themeConfig,
} from "src/components/reports-table/ReportsTable";
import EpButton from "src/components/buttons/EpButton";
import EyeIcon from "src/components/icons/EyeIcon";
import WebhookHistoryExpandedRow from "./WebhookHistoryExpandedRow";
import EyeClosedIcon from "src/components/icons/EyeClosedIcon";
import React from "react";

type WebhookHistoryTableProps = {
  filteredWebhookHistory: WebhookHistory[];
};

const webhookHistoryPageSize = 8;

export const initialColumns = [
  {
    title: "URI",
    isHidden: false,
    width: "35%",
    get: (item: WebhookHistory) => {
      return `${item.method} ${item.url}`;
    },
  },
  {
    title: "Type",
    get: (item: WebhookHistory) => {
      return item.type;
    },
    width: "15%",
    isHidden: false,
  },
  {
    title: "Created",
    get: (item: WebhookHistory) => {
      return item.created_at;
    },
    width: "25%",
    isHidden: false,
  },
  {
    title: "Details",
    get: () => {
      return null;
    },
    width: "25%",
    isHidden: false,
  },
];

export type WebhookHistoryCol = (typeof initialColumns)[number];

export default function WebhookHistoryTable({
  filteredWebhookHistory,
}: WebhookHistoryTableProps) {
  const hash = window.location.hash.replace("#", "");
  const [searchQuery, setSearchQuery] = useState(hash !== "" ? hash : "*");
  const [webhookHistorySearch, setWebhookHistorySearch] = useState<
    WebhookHistory[]
  >(filteredWebhookHistory);
  const [isLoading, setIsLoading] = useState(false);
  const [lastPage, setLastPage] = useState<number>(
    Math.ceil(filteredWebhookHistory.length / webhookHistoryPageSize),
  );

  const [columns, setColumns] = useState(initialColumns);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const pagination = usePagination<WebhookHistory>(
    { nodes: webhookHistorySearch },
    {
      state: {
        page: 0,
        size: webhookHistoryPageSize,
      },
    },
  );

  const currentPage = pagination.state.page + 1;

  const clearSearchwebhookHistory = () => {
    setSearchQuery("*");
    setWebhookHistorySearch(filteredWebhookHistory);
  };

  const searchWebhookHistory = async (query: string, page: number = 1) => {
    if (!query) {
      setWebhookHistorySearch(filteredWebhookHistory);
      setLastPage(
        Math.ceil(filteredWebhookHistory.length / webhookHistoryPageSize),
      );
      return;
    }
    setIsLoading(true);
    const searchFilteredResults = filteredWebhookHistory.filter(
      (webhookHistory) => {
        if (webhookHistory.url.toLowerCase().includes(query.toLowerCase())) {
          return true;
        }
        return false;
      },
    );
    const maxNumberOfPages = Math.floor(
      searchFilteredResults.length / webhookHistoryPageSize,
    );
    // perform client side pagination.
    const startIndex = (page - 1) * webhookHistoryPageSize;
    const endIndex = startIndex + webhookHistoryPageSize;
    const paginatedResults = searchFilteredResults.slice(startIndex, endIndex);

    setLastPage(maxNumberOfPages);
    setWebhookHistorySearch(paginatedResults);
    setIsLoading(false);
  };

  const handleSelectPage = (page: number) => {
    pagination.fns.onSetPage(page - 1);
  };

  function handleRowExpand(rowIndex: number) {
    if (expandedRows.includes(rowIndex)) {
      setExpandedRows(expandedRows.filter((index) => index !== rowIndex));
    } else {
      setExpandedRows([...expandedRows, rowIndex]);
    }
  }

  function getTableColumnsWidths() {
    let widthTemplateString = "";

    columns.forEach((col) => {
      if (!col.isHidden) {
        widthTemplateString += col.width + " ";
      }
    });

    return widthTemplateString.trim();
  }

  function renderWebhookHistorysList() {
    if (isLoading) {
      return <Loading></Loading>;
    }

    if (webhookHistorySearch.length < 1 && searchQuery !== "*") {
      return (
        <div className="mt-10 p-10 text-center min-h-[calc(100vh-480px-2rem)]">
          <p className="font-bold mb-10">No matching search results</p>
          <p>Try again using another search term</p>
        </div>
      );
    }

    return (
      <div className="mt-4">
        <ReportsTable
          data={{ nodes: webhookHistorySearch }}
          layout={{ horizontalScroll: true, fixedHeader: true, custom: true }}
          theme={{
            Table: `
            --data-table-library_grid-template-columns: ${getTableColumnsWidths()} !important;
          `,
            BaseCell: `
            &:last-of-type {
              text-align: center;
          
             & > div{
              display: flex;
              justify-content:center;
              padding:4px
             }
          
            }
          `,
            ...themeConfig,
          }}
          pagination={pagination}
          data-cy="webhookHistoryTable"
        >
          {(tableList: WebhookHistory[]) => {
            return (
              <React.Fragment>
                <ReportsTableHeader
                  data-cy="webhookHistoryTableHeader"
                  className="sticky top-0"
                >
                  <ReportsTableHeaderRow data-cy="webhookHistoryTableHeaderRow">
                    {columns.map((col, index) => {
                      const pinLeft = index === 0;
                      return (
                        <ReportsTableHeaderCell
                          className="text-center"
                          key={col.title + index}
                          pinLeft={pinLeft}
                          hide={col.isHidden}
                        >
                          {col.title}
                        </ReportsTableHeaderCell>
                      );
                    })}
                  </ReportsTableHeaderRow>
                </ReportsTableHeader>
                <ReportsTableBody data-cy="webhookHistoryTableBody">
                  {tableList.map((webhookHistory, rowIndex) => {
                    const isRowExapanded = expandedRows.includes(rowIndex);
                    const pinLeft = rowIndex === 0;

                    return (
                      <>
                        <ReportsTableRow
                          key={webhookHistory.id}
                          className="font-medium"
                          data-cy={`${webhookHistory.id}-Row`}
                        >
                          {columns.map((col, index) => {
                            return (
                              <ReportsTableCell
                                key={index}
                                pinLeft={pinLeft}
                                hide={col.isHidden}
                                data-cy={`${webhookHistory.id}webhookHistory${col.title}Cell`}
                              >
                                {col.title === "Details" ? (
                                  <EpButton
                                    data-cy={`${webhookHistory.id}webhookHistory${col.title}ToggleBtn`}
                                    onClick={() => handleRowExpand(rowIndex)}
                                  >
                                    {isRowExapanded ? (
                                      <>
                                        <EyeClosedIcon fill="black" /> Hide
                                        Details
                                      </>
                                    ) : (
                                      <>
                                        <EyeIcon /> Show Details
                                      </>
                                    )}
                                  </EpButton>
                                ) : (
                                  col.get(webhookHistory)
                                )}
                              </ReportsTableCell>
                            );
                          })}
                        </ReportsTableRow>
                        {isRowExapanded ? (
                          <WebhookHistoryExpandedRow
                            webhookHistory={webhookHistory}
                          />
                        ) : null}
                      </>
                    );
                  })}
                </ReportsTableBody>
              </React.Fragment>
            );
          }}
        </ReportsTable>
        <div
          className={`flex flex-wrap items-center ${
            lastPage > 1 ? "justify-between" : "justify-start"
          } space-x-0 mt-4`}
        >
          {webhookHistorySearch.length >= 1 && (
            <div className="text-xs font-normal font-secondary">
              Showing{" "}
              {lastPage > 1
                ? `${(currentPage - 1) * webhookHistoryPageSize + 1} to ${
                    (currentPage - 1) * webhookHistoryPageSize +
                      webhookHistoryPageSize >
                    webhookHistorySearch.length
                      ? webhookHistorySearch.length
                      : (currentPage - 1) * webhookHistoryPageSize +
                        webhookHistoryPageSize
                  }`
                : `${webhookHistorySearch?.length}`}{" "}
              of {webhookHistorySearch?.length} entries
            </div>
          )}
          {lastPage > 1 && webhookHistorySearch.length >= 1 && (
            <div className="flex items-center">
              <Pagination
                pageCount={lastPage > 4 ? 4 : lastPage}
                currentPage={currentPage}
                lastPage={lastPage}
                onSelect={handleSelectPage}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="font-primary text-black w-full">
      <p className="font-medium my-6">Webhook History Search Results</p>
      <div className="bg-[#4485D140] mb-6 border border-[#2E9BFF] p-2 rounded-md font-semibold text-xs">
        {filteredWebhookHistory.length} webhook(s) matched the provided search.
      </div>
      <WebhookHistoryToolbar
        clearSearch={clearSearchwebhookHistory}
        setSearchFilter={(query: string) => {
          setSearchQuery(query);
          searchWebhookHistory(query, currentPage);
        }}
        setColumns={setColumns}
        columns={columns}
        data={filteredWebhookHistory}
      />
      {renderWebhookHistorysList()}
    </div>
  );
}

import { useState } from "react";
import IconButton from "src/components/buttons/IconButton";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";
import TabMenu, { TabConfig } from "src/components/tabMenu/TabMenu";
import { GlobalServicePlan } from "../manage-global-service/ManageDetailsPlans";
import ManageGlobalServicePlanDetails from "../manage-global-service-plan/ManageServicePlanForm";
import ManageServicePlanFieldsForm from "../manage-global-service-plan/ManageServicePlanFieldsForm";

type GlobalServicePlanDetailsDialogProps = {
  servicePlan: GlobalServicePlan;
};

export default function GlobalServicePlanDetailsDialog({
  servicePlan,
}: GlobalServicePlanDetailsDialogProps) {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const globalServicePlanDetailsTabsConfig: TabConfig[] = [
    {
      key: "details",
      title: "Details",
      titleAttribute: "Manage Plan Details",
      component: (
        <ManageGlobalServicePlanDetails
          operationType="update"
          globalServicePlan={servicePlan}
        />
      ),
      dataCy: "managePlanDetailsTab",
    },
    {
      key: "fields",
      title: "Fields",
      titleAttribute: "Manage Plan Fields",
      component: (
        <ManageServicePlanFieldsForm
          operationType="update"
          globalServicePlan={servicePlan}
        />
      ),
      dataCy: "managePlanFieldsTab",
    },
  ];

  return (
    <Dialog
      open={isDialogOpened}
      onOpenChange={(value) => setIsDialogOpened(value)}
    >
      <DialogTrigger asChild>
        <IconButton
          name="view-file"
          dataCy="globalServicePlanDetailsActionBtn"
          tooltipText={`View Plan Details`}
          ariaLabel={`View Plan Details`}
        />
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-4xl font-base"
        dataCy="globalServicePlanDetailsDialogCnt"
      >
        <DialogHeader dataCy="globalServicePlanDetailsDialogHeader" borderless>
          <DialogTitle dataCy="globalServicePlanDetailsDialogTitle">
            {servicePlan.provider} - {servicePlan.name}
          </DialogTitle>
        </DialogHeader>
        <TabMenu
          config={globalServicePlanDetailsTabsConfig}
          dataCy="globalServicePlanDetailsTabMenu"
        ></TabMenu>
      </DialogContent>
    </Dialog>
  );
}

import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "../../utils/cn";

const badgeVariants = cva(
  "inline-flex items-center justify-center text-center px-auto rounded-full border py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        paid: "w-24 text-[#488B5B] bg-[#84E88E]",
        overdue: "w-24 text-[#EC0000] bg-[#FFD2D2]",
        pending: "w-24 text-[#FF7A00] bg-[#FCE8B5]",
        view: "w-24 text-[#2E9BFF] bg-[#B2D8FB]",
        good: "w-[68px] text-[#55857E] border-[#55857E] rounded-md",
        error: "w-[68px] text-[#EC0000] border-[#EC0000] rounded-md",
        active: "w-[68px] bg-[#488B5B] border-[#488B5B] text-white rounded-md",
        "in-active":
          "w-[68px] bg-[#6D6D6D] border-[#6D6D6D] text-white rounded-md",
      },
    },
    defaultVariants: {
      variant: "view",
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };

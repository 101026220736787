const ProviderIcon = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg
      width="81"
      height="78"
      viewBox="0 0 81 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_93_4147)">
        <path
          d="M80.1414 28.6207C77.2483 21.0201 67.7971 18.5076 61.2805 22.6677L52.5502 15.7048C54.9099 8.34448 49.3973 0.248102 40.8268 0.00927189C32.0004 -0.236694 25.9086 8.01789 28.3685 15.6949L19.632 22.6626C12.9162 18.3753 3.22451 21.2211 0.608176 29.1008C-1.8087 36.38 3.28678 43.5146 10.4312 44.7494L13.8114 56.4006C7.77552 61.3928 7.92203 70.9955 14.9917 75.6093C21.9955 80.18 31.2506 76.8896 33.9727 69.8083H46.927C49.7477 77.2291 59.575 80.3348 66.495 75.2029C73.1599 70.2603 72.828 60.8652 66.7695 56.1484L70.2591 44.7875C77.7498 43.6407 82.9679 36.0465 80.1414 28.6206V28.6207ZM46.4024 63.1293H34.5061C33.1772 56.8544 26.7745 52.6004 20.207 53.568L17.4427 44.039C24.1555 41.4444 27.1104 33.8562 24.1633 27.7302L32.1728 21.3424C36.8452 25.2469 44.0225 25.2952 48.7392 21.3476L56.7557 27.7413C53.8163 33.8502 56.7676 41.3083 63.2646 43.9564L60.3286 53.5149C53.828 52.7351 47.7074 57.0362 46.4024 63.1293Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_93_4147">
          <rect width="80.9209" height="77.7374" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProviderIcon;

import Icon from "src/components/buttons/Icon";
import ClickableTooltip from "src/components/clickable-tooltip/ClickableTooltip";
import { Search } from "src/components/input/Input";
import LoadNetworkOperatorDialog from "../actions/LoadNetworkOperatorDialog";
import { NetworkOperator } from "./NetworkOperatorsList";

interface NetworkOperatorToolbarProps {
  setSearchFilter: (searchFilter: string) => void;
  clearSearch: () => void;
  addOperatorToNetwork: (operator: NetworkOperator) => void;
}

export default function NetworkOperatorListToolbar({
  clearSearch,
  setSearchFilter,
  addOperatorToNetwork,
}: NetworkOperatorToolbarProps) {
  const handleSearch = (value: string) => {
    if (value !== "") {
      setSearchFilter(value);
    } else {
      clearSearch();
    }
  };

  return (
    <div
      className="flex justify-between items-center"
      data-cy="networkOperatorToolbarSection"
    >
      <div className="flex items-center max-w-[540px] gap-2">
        <Search
          placeholder="Search"
          className="search min-w-[305px]"
          ctaCallback={handleSearch}
          closeCallback={clearSearch}
          dataCy="searchOperatorsInput"
          aria-label="Search"
        ></Search>
        <ClickableTooltip
          header={<p>Search Terms</p>}
          icon={
            <Icon
              size={30}
              className="pt-[4px]"
              name="exclamation"
              data-cy="searchInfoIcon"
              aria-label="Search Info"
            />
          }
          content={
            "Search for a network operators by providing a operators name"
          }
          dataCy="searchOperatorsTooltip"
          aria-label="search operators tooltip"
          className="w-[290px]"
        />
      </div>
      <LoadNetworkOperatorDialog addOperatorToNetwork={addOperatorToNetwork} />
    </div>
  );
}

import PageContainer from "src/components/page-container/PageContainer";
import TabMenu, { TabConfig } from "src/components/tabMenu/TabMenu";

import UserDetails from "./details/UserDetails";
import UserContactForm from "./contact/UserContactForm";
import UserLocationForm from "./location/UserLocationForm";
import UserPortalManagementForm from "./portal-management/PortalManagementForm";

export default function UserProfile() {
  const userProfileTabsConfig: TabConfig[] = [
    {
      key: "details",
      title: "Account",
      titleAttribute: "Account Details",
      component: <UserDetails />,
      dataCy: "accountDetailsTab",
    },
    {
      key: "contact",
      title: "Contact",
      titleAttribute: "Contact Details",
      component: <UserContactForm />,
      dataCy: "contactDetailsTab",
    },
    {
      key: "location",
      title: "Location",
      titleAttribute: "Location Details",
      component: <UserLocationForm />,
      dataCy: "locationDetailsTab",
    },
    {
      key: "portal-management",
      title: "Portal Management",
      titleAttribute: "Portal Management Settings",
      component: <UserPortalManagementForm />,
      dataCy: "accountDetailsTab",
    },
  ];

  return (
    <PageContainer className="gap-6">
      <TabMenu
        config={userProfileTabsConfig}
        dataCy="userProfileTabsMenu"
      ></TabMenu>
    </PageContainer>
  );
}

import React, { ReactNode, createContext } from "react";
import { UserHookType, useUser } from "../user/useUser";

interface SessionContextType {
  useUser: UserHookType;
}

export const SessionProvider: React.FC<SessionProviderProps> = ({
  children,
}) => {
  const useUserInContext = useUser();

  const value = {
    useUser: useUserInContext,
  };

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

export const SessionContext = createContext<SessionContextType | undefined>(
  undefined,
);

interface SessionProviderProps {
  children: ReactNode;
}

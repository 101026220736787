import { useState } from "react";

import { EpButtonStyles } from "src/components/buttons/constants";

import EpButton from "src/components/buttons/EpButton";
import { PortInterface } from "src/interfaces";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "src/components/dialog/Dialog";
import LoadingAnimation from "src/components/spinners/LoadingAnimation";
import WarningIcon from "src/components/icons/WarningIcon";

interface UnsubscriberLanDetailsConfirmationDialogProps {
  isDialogOpened: boolean;
  setIsDialogOpened: (value: boolean) => void;
  setCurrentPort: (port: PortInterface | undefined) => void;
}

export default function UnsubscriberLanDetailsConfirmationDialog({
  setCurrentPort,
  isDialogOpened,
  setIsDialogOpened,
}: UnsubscriberLanDetailsConfirmationDialogProps) {
  const [isLoading, setIsLoading] = useState(false);

  function handleUnSubscribe() {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setIsDialogOpened(false);
      setCurrentPort(undefined);
    }, 1200);
  }

  function closeDialog() {
    setIsDialogOpened(false);
    setCurrentPort(undefined);
  }

  return (
    <Dialog open={isDialogOpened} onOpenChange={closeDialog}>
      <DialogContent
        className="sm:max-w-xl font-base"
        dataCy="unsubscriberConfirmationDialogCnt"
      >
        <DialogHeader dataCy="unsubscriberConfirmationDialogHeader">
          <DialogTitle dataCy="unsubscriberConfirmationDialogTitle">
            Unsubscribe?
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-3 text-[#434343]">
          <p className="text-sm flex items-center gap-2">
            <WarningIcon fill="#EC0000" />
            This action will unsubscribe the user from the service.
          </p>
          <p className="text-sm text-neutral-600">
            Are you sure you want to continue?
          </p>
        </div>
        <DialogFooter>
          <EpButton
            styling={EpButtonStyles.DELETE}
            dataCy="unsubscriberBtn"
            className="flex gap-1"
            type="submit"
            onClick={handleUnSubscribe}
            aria-label="Unsubscribe"
            disabled={isLoading}
          >
            {isLoading && <LoadingAnimation />}
            {isLoading ? "Processing" : "Unsubscribe"}
          </EpButton>
          <DialogClose asChild className="flex gap-2">
            <EpButton
              type="button"
              styling={EpButtonStyles.CANCEL}
              dataCy="cancelBtn"
              aria-label="cancel"
            >
              Cancel
            </EpButton>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

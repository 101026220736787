import PageContainer from "src/components/page-container/PageContainer";
import SubscribersList from "./list/SubscribersList";

export default function Subscribers() {
  return (
    <PageContainer>
      <SubscribersList />
    </PageContainer>
  );
}

import { Webhook } from "src/interfaces";
import RemoveWebhookDialog from "./RemoveWebhookDialog";
import ManageWebhookDetailsDialog from "./ManageWebhookDetailsDialog";

type WebhookActionsCellProps = {
  webhook: Webhook;
  removeWebhook: (webhooks: Webhook) => void;
};

export default function WebhookTableActionsCell({
  webhook,
  removeWebhook,
}: WebhookActionsCellProps) {
  return (
    <div className="flex justify-center gap-x-2">
      <ManageWebhookDetailsDialog webhook={webhook} />
      <RemoveWebhookDialog removeWebhook={removeWebhook} webhook={webhook} />
    </div>
  );
}

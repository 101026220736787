import { SVGProps } from "react";

const SpyglassIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Spyglass">
        <path
          d="M5.54962 1.22089C4.59127 1.22089 3.65444 1.50507 2.85759 2.0375C2.06075 2.56993 1.43969 3.3267 1.07295 4.2121C0.706201 5.09751 0.610244 6.07178 0.797209 7.01171C0.984174 7.95165 1.44566 8.81504 2.12332 9.4927C2.80098 10.1704 3.66437 10.6318 4.60431 10.8188C5.54424 11.0058 6.51852 10.9098 7.40392 10.5431C8.28932 10.1763 9.04608 9.55527 9.57852 8.75843C10.1109 7.96158 10.3951 7.02475 10.3951 6.0664C10.3951 4.78132 9.88452 3.54889 8.97582 2.6402C8.06713 1.7315 6.8347 1.22097 5.54962 1.22089Z"
          stroke="currentColor"
          strokeWidth="0.987055"
          strokeMiterlimit="10"
        />
        <g id="Vector_2">
          <path
            d="M9.16455 9.68152L12.5486 13.0656L9.16455 9.68152Z"
            fill="currentColor"
          />
          <path
            d="M9.16455 9.68152L12.5486 13.0656"
            stroke="currentColor"
            strokeWidth="0.987055"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default SpyglassIcon;

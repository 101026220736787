import { useLocation, useNavigate } from "react-router-dom";
import { useSession } from "../../hooks/session/useSession";
import { protectedPages } from "../../routes";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/popover/Popover";
import UserProfileIcon from "../../components/icons/UserProfileIcon";
import UserIcon from "../../components/icons/UserIcon";
import LogoutIcon from "../../components/icons/LogoutIcon";

export default function Sidebar() {
  const { useUser } = useSession();
  const { user } = useUser;
  const location = useLocation();
  const navigate = useNavigate();
  const pathnames = location.pathname.split("/");
  const rootMenuItemName = pathnames[1];
  const option = protectedPages[rootMenuItemName];
  const Icon = option?.icon || null;
  const optionClasses =
    "hover:bg-[#F9F9F9] cursor-pointer flex gap-4 items-center h-8 pl-2";

  if (!rootMenuItemName || !option) {
    return <></>;
  }
  const handleLogout = () => {
    useUser.logout();
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <header className="flex top-0 justify-between items-center px-10 pt-10 pb-6 text-neutrals-form-gray-dark bg-main-content flex-wrap rounded-tl-5xl">
      <div className="flex items-center text-neutrals-form-gray-dark">
        {Icon && (
          <Icon
            className="mr-2 fill-neutrals-form-gray-dark"
            height={20}
            width={22}
            style={{ height: "1.5rem", width: "1.5rem" }}
          />
        )}
        <h1 className="text-medium font-semibold text-2xl flex items-center">
          {option.title}
        </h1>
      </div>
      <div className="flex">
        <div className="mr-9 text-xs flex items-center font-medium">
          {user ? user.username : "Support"}
        </div>

        <Popover>
          <PopoverTrigger
            className="flex gap-2 items-center"
            data-cy="userOptionsBtn"
          >
            <UserProfileIcon />
            <span className="sr-only">Account Menu</span>
          </PopoverTrigger>
          <PopoverContent className="flex flex-col w-[200px]">
            <div>
              <ul
                className="text-sm font-semibold text-[#253331] py-4 px-2"
                data-cy="userOptionsList"
              >
                <li
                  onClick={() => handleNavigate("/profile")}
                  className={optionClasses}
                  data-cy="userProfile"
                >
                  <UserIcon fill="#253331" /> User Profile
                </li>
                <li
                  onClick={handleLogout}
                  className={optionClasses}
                  data-cy="userLogout"
                >
                  <LogoutIcon fill="#253331" /> Log Out
                </li>
              </ul>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </header>
  );
}

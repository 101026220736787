import { useMemo, useState } from "react";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";

import { User } from "src/interfaces/User";
import NewUsernameForm from "./NewUserForm";
import CurrentUsernameForm from "./CurrentUsernameForm";

interface UpdateUsernameDialogProps {
  user: User;
  setUser: (user: User) => void;
}

export default function UpdateUsernameDialog({
  user,
  setUser,
}: UpdateUsernameDialogProps) {
  const [isCurrentUsernameValid, setIsCurrentNameValid] = useState(false);

  const handleCloseDialog = () => {
    setIsDialogOpened(!isDialogOpened);
  };

  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);

  useMemo(() => {
    setIsCurrentNameValid(false);
  }, [isDialogOpened]);

  return (
    <Dialog open={isDialogOpened} onOpenChange={handleCloseDialog}>
      <DialogTrigger>
        <EpButton
          styling={EpButtonStyles.SECONDARY}
          dataCy="updateUsernameBtn"
          tooltipText="Update username"
        >
          Update Username
        </EpButton>
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-[425px]"
        dataCy="updateUsernameDialogCnt"
      >
        <DialogHeader dataCy="updateUsernameDialogHeader" borderless>
          <DialogTitle dataCy="updateUsernameDialogTitle">
            Update Username
          </DialogTitle>
        </DialogHeader>
        {isCurrentUsernameValid ? (
          <NewUsernameForm
            user={user}
            setUser={setUser}
            handleCloseDialog={handleCloseDialog}
          />
        ) : (
          <CurrentUsernameForm
            setIsCurrentNameValid={setIsCurrentNameValid}
            user={user}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

import { useState } from "react";
import EpButton from "src/components/buttons/EpButton";
import {
  EpButtonStyles,
  EpButtonSizes,
} from "src/components/buttons/constants";
import EyeClosedIcon from "src/components/icons/EyeClosedIcon";
import LayoutIcon from "src/components/icons/LayoutIcon";

import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "src/components/popover/Popover";
import ScrollArea from "src/components/scroll-area/ScrollArea";
import { cn } from "src/utils/cn";
import {
  SubscriptionHistoryCol,
  initialColumns,
} from "./SubscriptionHistoryTable";
import ArrageWithTreeDotsIcon from "src/components/icons/ArrageWithTreeDotsIcon";

type SubscriptionTableSortingPopoverProps = {
  updateColumns: (items: SubscriptionHistoryCol[]) => void;
};

export function SubscriptionTableSortingPopover({
  updateColumns,
}: SubscriptionTableSortingPopoverProps) {
  const [cols, setCols] = useState(initialColumns);

  const hiddenFieldsCount = cols.filter((col) => col.isHidden).length;

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    const { id } = e.currentTarget;
    const idx = id;
    e.dataTransfer.setData("colIdx", idx);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) =>
    e.preventDefault();

  const handleOnDrop = (e: React.DragEvent<HTMLDivElement>) => {
    const { id } = e.currentTarget;

    const droppedColIdx = Number(id);
    const draggedColIdx = Number(e.dataTransfer.getData("colIdx"));

    let tempCols = [...cols];

    tempCols[draggedColIdx] = cols[droppedColIdx];
    tempCols[droppedColIdx] = cols[draggedColIdx];

    setCols(tempCols);
  };

  function handleSaveChanges() {
    updateColumns(cols);
  }

  function handleHideAll() {
    setCols(
      cols.map((col) => {
        return {
          ...col,
          isHidden: true,
        };
      }),
    );
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <EpButton
          dataCy="customiseTableColumnsActionBtn"
          aria-label="Customise table columns"
          styling={EpButtonStyles.OUTLINE}
          sizing={EpButtonSizes.LARGE}
        >
          <LayoutIcon aria-hidden="true" className="fill-primary mr-1" />
          Customize Columns
        </EpButton>
      </PopoverTrigger>
      <PopoverContent
        align="end"
        className="w-[378px] p-0 border border-[#0000001F] rounded-md shadow-none"
      >
        <div className="p-3 border-b border-[#0000001F] bg-table-header-light-gray rounded-t-md">
          <span className="font-primary text-primary text-base font-semibold">
            Customise Columns
          </span>
        </div>
        <div className="flex justify-between px-3 py-4 text-sm font-secondary text-[#6D6D6D]">
          <span
            onClick={handleHideAll}
            data-cy="hideAllColsActionBtn"
            aria-label="Hide all columns"
            className="underline underline-offset-2 cursor-pointer"
          >
            Hide all
          </span>
          <span className="flex items-center gap-1">
            <EyeClosedIcon aria-hidden="true" fill="#6D6D6D" />
            {hiddenFieldsCount} hidden fields
          </span>
        </div>
        <ScrollArea className="max-h-[20vh] px-1">
          <div className="flex flex-col gap-4 w-full">
            {cols.map((col, index) => {
              const isChecked = !col.isHidden;
              return (
                <div
                  id={index.toString()}
                  key={col.title}
                  data-cy={`${col.title}-row`}
                  draggable
                  onDragStart={handleDragStart}
                  onDragOver={handleDragOver}
                  onDrop={handleOnDrop}
                  className="bg-white flex items-center justify-between active:bg-[#ECEBEB] rounded-sm px-3 py-1 border border-[#D0D5DD] font-secondary text-sm font-normal text-[#6D6D6D]"
                >
                  <div className="flex items-center gap-2">
                    <div className="flex justify-center w-full relative">
                      <input
                        type="checkbox"
                        className={cn(
                          "peer appearance-none flex border h-4 w-4 rounded",
                          isChecked ? "bg-primary-color-50" : "bg-[#FFF]",
                          isChecked ? "border-primary" : "border-[#A9A9A9]",
                          "focus:ring-2 focus:ring-offset-1 focus:input focus:input focus:border-[#2E9BFF]",
                          "focus-visible:outline-none focus-visible:ring-2 focus-visible:input focus-visible:ring-offset-1 focus-visible:border-[#2E9BFF]",
                          "disabled:cursor-not-allowed disabled:bg-gray-200",
                        )}
                        id={col.title}
                        name={col.title}
                        checked={isChecked}
                        aria-label={col.title}
                        data-cy={`${col.title}-checkbox`}
                        onChange={() => {
                          setCols(
                            cols.map((c, idx) => {
                              if (idx === index) {
                                return {
                                  ...c,
                                  isHidden: !col.isHidden,
                                };
                              }
                              return c;
                            }),
                          );
                        }}
                      />
                      <svg
                        className="absolute w-3 h-3 mt-0.5 hidden peer-checked:block pointer-events-none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#253331"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </div>
                    <label htmlFor={col.title}>{col.title}</label>
                  </div>
                  <ArrageWithTreeDotsIcon />
                </div>
              );
            })}
          </div>
        </ScrollArea>
        <div className="flex px-4 justify-end py-2 gap-4">
          <PopoverClose asChild>
            <EpButton
              dataCy="cancelActionButton"
              aria-label="Close columns customization popover"
              styling={EpButtonStyles.CANCEL}
              sizing={EpButtonSizes.LARGE}
            >
              Cancel
            </EpButton>
          </PopoverClose>
          <PopoverClose asChild>
            <EpButton
              dataCy="saveBtn"
              aria-label="save columns customizations"
              onClick={handleSaveChanges}
              sizing={EpButtonSizes.LARGE}
            >
              Save
            </EpButton>
          </PopoverClose>
        </div>
      </PopoverContent>
    </Popover>
  );
}

export const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, "0");
};

export const formatDate = (date: Date) => {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join("-");
};

export function formatDateToHumanReadableISOString(date: Date) {
  const isoString = date.toISOString();

  const year = isoString.slice(0, 4);
  const month = isoString.slice(5, 7);
  const day = isoString.slice(8, 10);
  const time = isoString.slice(11, 19);

  return `${year}-${month}-${day} ${time}`;
}

import { SVGProps } from "react";

const CloseIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const defaultFill = "#C4C4C4";
  const fill = props.fill || defaultFill;
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Close">
        <path
          d="M18.5961 5.70998C18.5036 5.61728 18.3937 5.54373 18.2727 5.49355C18.1518 5.44337 18.0221 5.41754 17.8911 5.41754C17.7602 5.41754 17.6305 5.44337 17.5095 5.49355C17.3885 5.54373 17.2786 5.61728 17.1861 5.70998L12.2961 10.59L7.40612 5.69998C7.31354 5.6074 7.20363 5.53396 7.08267 5.48385C6.9617 5.43375 6.83205 5.40796 6.70112 5.40796C6.57019 5.40796 6.44054 5.43375 6.31958 5.48385C6.19861 5.53396 6.0887 5.6074 5.99612 5.69998C5.90354 5.79256 5.8301 5.90247 5.77999 6.02344C5.72989 6.1444 5.7041 6.27405 5.7041 6.40498C5.7041 6.53591 5.72989 6.66556 5.77999 6.78652C5.8301 6.90749 5.90354 7.0174 5.99612 7.10998L10.8861 12L5.99612 16.89C5.90354 16.9826 5.8301 17.0925 5.77999 17.2134C5.72989 17.3344 5.7041 17.464 5.7041 17.595C5.7041 17.7259 5.72989 17.8556 5.77999 17.9765C5.8301 18.0975 5.90354 18.2074 5.99612 18.3C6.0887 18.3926 6.19861 18.466 6.31958 18.5161C6.44054 18.5662 6.57019 18.592 6.70112 18.592C6.83205 18.592 6.9617 18.5662 7.08267 18.5161C7.20363 18.466 7.31354 18.3926 7.40612 18.3L12.2961 13.41L17.1861 18.3C17.2787 18.3926 17.3886 18.466 17.5096 18.5161C17.6305 18.5662 17.7602 18.592 17.8911 18.592C18.0221 18.592 18.1517 18.5662 18.2727 18.5161C18.3936 18.466 18.5035 18.3926 18.5961 18.3C18.6887 18.2074 18.7621 18.0975 18.8122 17.9765C18.8624 17.8556 18.8881 17.7259 18.8881 17.595C18.8881 17.464 18.8624 17.3344 18.8122 17.2134C18.7621 17.0925 18.6887 16.9826 18.5961 16.89L13.7061 12L18.5961 7.10998C18.9761 6.72998 18.9761 6.08998 18.5961 5.70998Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default CloseIcon;

import { SVGProps } from "react";

const WarningIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const defaultFill = "#434343";
  const fill = props.fill || defaultFill;
  return (
    <svg
      width={props.width || 24}
      height={props.height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill={fill}
      data-cy="warningIcon"
    >
      <path
        d="M4.47012 21.0001H19.5301C21.0701 21.0001 22.0301 19.3301 21.2601 18.0001L13.7301 4.99005C12.9601 3.66005 11.0401 3.66005 10.2701 4.99005L2.74012 18.0001C1.97012 19.3301 2.93012 21.0001 4.47012 21.0001ZM12.0001 14.0001C11.4501 14.0001 11.0001 13.5501 11.0001 13.0001V11.0001C11.0001 10.4501 11.4501 10.0001 12.0001 10.0001C12.5501 10.0001 13.0001 10.4501 13.0001 11.0001V13.0001C13.0001 13.5501 12.5501 14.0001 12.0001 14.0001ZM13.0001 17.0001C13.0001 17.5523 12.5524 18.0001 12.0001 18.0001C11.4478 18.0001 11.0001 17.5523 11.0001 17.0001C11.0001 16.4478 11.4478 16.0001 12.0001 16.0001C12.5524 16.0001 13.0001 16.4478 13.0001 17.0001Z"
        fill={fill}
      />
    </svg>
  );
};

export default WarningIcon;

import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import { DialogClose } from "src/components/dialog/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/form/Form";
import { Input } from "src/components/input/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/select/Select";
import { z } from "zod";
import PublishWebhookDetailsDialog from "./PublishWebhookDetailsDialog";
import { Webhook } from "src/interfaces";
import { useState } from "react";
import LoadingAnimation from "src/components/spinners/LoadingAnimation";
import { toast } from "src/components/toast/useToast";

const SERVICES_OPTIONS = ["ENTPNT", "QWKnet", "EXAMPLE SERVICE 124"];

const SERVICE_PLANS_OPTIONS = ["All Service Plans", "Plan 2024", "Plan 2025"];

const WEBHOOK_TYPES = [
  "PLAN_CHANGE",
  "PRE_PLAN_CHANGE",
  "PRE_SUBSCRIBE",
  "SUBSCRIBE",
  "UNSUBSCRIBE",
];

const WEBHOOK_METHODS = [
  "AUTH",
  "CONNECT",
  "DELETE",
  "GET",
  "PATCH",
  "POST",
  "PURGE",
  "PUT",
  "VIEW",
];

const WEBHOOK_RETURN_TYPES = [
  "HTML",
  "JSON",
  "OBJECT",
  "STRING",
  "TEXT",
  "XML",
];

const STATUS = [
  "Active",
  "Inactive",
  "Locked",
  "Paused",
  "Pending",
  "Provision",
  "Repaired",
  "Replaced",
  "RMA",
  "Standby",
  "Suspended",
];

const ManageWebhookDetailsFormSchema = z.object({
  service: z.string().optional(),
  service_plan: z.string().optional(),
  method: z.string().min(1, {
    message: "Please select a method",
  }),
  type: z.string().min(1, {
    message: "Please select a type",
  }),
  return_type: z.string().min(1, {
    message: "Please select a return type",
  }),
  status: z.string().min(1, {
    message: "Please select a status",
  }),
  username: z.string().optional(),
  password: z.string().optional(),
  url: z.string().url({
    message: "Please enter a valid URL",
  }),
});

export default function ManageWebhookDetailsForm({
  webhook,
  operationType,
  handleCloseDialog,
}: {
  webhook?: Webhook;
  operationType: "create" | "update";
  handleCloseDialog: () => void;
}) {
  const defaultValues: z.infer<typeof ManageWebhookDetailsFormSchema> = webhook
    ? {
        ...webhook,
        service: webhook.Service?.name,
        service_plan: webhook.Serviceplan?.name,
      }
    : {
        method: "",
        return_type: "",
        status: "",
        type: "",
        url: "",
        password: "",
        service: "",
        service_plan: "",
        username: "",
      };

  const ManageWebhookDetailsForm = useForm<
    z.infer<typeof ManageWebhookDetailsFormSchema>
  >({
    resolver: zodResolver(ManageWebhookDetailsFormSchema),
    mode: "onBlur",
    defaultValues,
  });

  const [isLoading, setIsLoading] = useState(false);

  function handleManageWebhookDetailsSave(
    data: z.infer<typeof ManageWebhookDetailsFormSchema>,
  ) {
    console.log({ data });
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      handleCloseDialog();
      if (operationType === "create") {
        toast({
          title: "Success",
          description: `Webhook created successfully!`,
        });
      } else {
        toast({
          title: "Success",
          description: `Webhook details updated successfully!`,
        });
      }
    }, 1500);
  }

  return (
    <Form {...ManageWebhookDetailsForm}>
      {operationType === "update" ? (
        <div className="grid grid-rows-1 grid-cols-3 mb-4 text-neutral-500 font-secondary text-xs">
          <span>CREATED: {webhook?.created_at}</span>
          <span>UPDATED: {webhook?.updated_at}</span>
          <span>
            PUBLISHED:{" "}
            {webhook?.published_at ? (
              webhook?.published_at
            ) : (
              <span className="font-bold text-black uppercase">
                Data has not been published
              </span>
            )}
          </span>
        </div>
      ) : null}
      <form
        onSubmit={ManageWebhookDetailsForm.handleSubmit(
          handleManageWebhookDetailsSave,
        )}
      >
        <div className="grid grid-cols-2 gap-2 grid-rows-1">
          <FormField
            control={ManageWebhookDetailsForm.control}
            name="service"
            render={({ field }) => (
              <FormItem className="w-full  mb-4">
                <FormLabel>SERVICES (OPTIONAL)</FormLabel>
                <Select
                  onValueChange={(value) => {
                    ManageWebhookDetailsForm.setValue(field.name, value);
                    ManageWebhookDetailsForm.trigger(field.name);
                  }}
                  value={field.value}
                >
                  <FormControl>
                    <SelectTrigger
                      aria-label="Select Service"
                      data-cy="services-select"
                    >
                      <SelectValue>{field.value}</SelectValue>
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {SERVICES_OPTIONS.map((stat) => (
                      <SelectItem
                        key={stat}
                        value={stat}
                        data-cy={`services-option-${stat}`}
                      >
                        {stat}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={ManageWebhookDetailsForm.control}
            name="service_plan"
            render={({ field }) => (
              <FormItem className="w-full  mb-4">
                <FormLabel>SERVICE PLANS (OPTIONAL)</FormLabel>
                <Select
                  onValueChange={(value) => {
                    ManageWebhookDetailsForm.setValue(field.name, value);
                    ManageWebhookDetailsForm.trigger(field.name);
                  }}
                  value={field.value}
                >
                  <FormControl>
                    <SelectTrigger
                      aria-label="Select Service Plans"
                      data-cy="service-plans-select"
                    >
                      <SelectValue>{field.value}</SelectValue>
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {SERVICE_PLANS_OPTIONS.map((stat) => (
                      <SelectItem
                        key={stat}
                        value={stat}
                        data-cy={`service-plan-option-${stat}`}
                      >
                        {stat}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="grid grid-cols-2 grid-rows-1 gap-2">
          <FormField
            control={ManageWebhookDetailsForm.control}
            name="method"
            render={(field) => (
              <FormItem className="w-full  mb-4">
                <FormLabel>METHOD</FormLabel>
                <Select
                  onValueChange={(value) => {
                    ManageWebhookDetailsForm.setValue(field.field.name, value);
                    ManageWebhookDetailsForm.trigger(field.field.name);
                  }}
                  value={field.field.value}
                >
                  <FormControl>
                    <SelectTrigger
                      aria-label="Webhook Method Type"
                      data-cy="webhook-method-type-select"
                      onBlur={() =>
                        ManageWebhookDetailsForm.trigger(field.field.name)
                      }
                    >
                      <SelectValue>{field.field.value}</SelectValue>
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {WEBHOOK_METHODS.map((type) => (
                      <SelectItem
                        key={type}
                        value={type}
                        data-cy={`webhook-method-type-${type}`}
                      >
                        {type}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={ManageWebhookDetailsForm.control}
            name="type"
            render={(field) => (
              <FormItem className="w-full  mb-4">
                <FormLabel>WEBHOOK TYPE</FormLabel>
                <Select
                  onValueChange={(value) => {
                    ManageWebhookDetailsForm.setValue(field.field.name, value);
                    ManageWebhookDetailsForm.trigger(field.field.name);
                  }}
                  value={field.field.value}
                >
                  <FormControl>
                    <SelectTrigger
                      aria-label="Webhook Type"
                      data-cy="webhook-type-select"
                      onBlur={() =>
                        ManageWebhookDetailsForm.trigger(field.field.name)
                      }
                    >
                      <SelectValue>{field.field.value}</SelectValue>
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {WEBHOOK_TYPES.map((type) => (
                      <SelectItem
                        key={type}
                        value={type}
                        data-cy={`webhook-type-${type}`}
                      >
                        {type}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="grid grid-cols-2 gap-2 grid-rows-1">
          <FormField
            control={ManageWebhookDetailsForm.control}
            name="return_type"
            render={(field) => (
              <FormItem className="w-full mb-4">
                <FormLabel>RETURN TYPE</FormLabel>
                <Select
                  onValueChange={(value) => {
                    ManageWebhookDetailsForm.setValue(field.field.name, value);
                    ManageWebhookDetailsForm.trigger(field.field.name);
                  }}
                  value={field.field.value}
                >
                  <FormControl>
                    <SelectTrigger
                      aria-label="Webhook return type"
                      data-cy="webhook-return-type-select"
                      onBlur={() =>
                        ManageWebhookDetailsForm.trigger(field.field.name)
                      }
                    >
                      <SelectValue>{field.field.value}</SelectValue>
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {WEBHOOK_RETURN_TYPES.map((type) => (
                      <SelectItem
                        key={type}
                        value={type}
                        data-cy={`webhook-return-type-${type}`}
                      >
                        {type}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={ManageWebhookDetailsForm.control}
            name="status"
            render={(field) => (
              <FormItem className="w-full  mb-4">
                <FormLabel>STATUS</FormLabel>
                <Select
                  onValueChange={(value) => {
                    ManageWebhookDetailsForm.setValue(field.field.name, value);
                    ManageWebhookDetailsForm.trigger(field.field.name);
                  }}
                  value={field.field.value}
                >
                  <FormControl>
                    <SelectTrigger
                      aria-label="Webhook status"
                      data-cy="webhook-status-select"
                      onBlur={() =>
                        ManageWebhookDetailsForm.trigger(field.field.name)
                      }
                    >
                      <SelectValue>{field.field.value}</SelectValue>
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {STATUS.map((stat) => (
                      <SelectItem
                        key={stat}
                        value={stat}
                        data-cy={`webhook-status-${stat}`}
                      >
                        {stat}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="grid grid-cols-2 gap-2 grid-rows-1">
          <FormField
            control={ManageWebhookDetailsForm.control}
            name="username"
            render={(field) => (
              <FormItem className="w-full  mb-4">
                <FormLabel>USER NAME (OPTIONAL)</FormLabel>
                <FormControl>
                  <Input
                    id="username"
                    placeholder="User Name"
                    data-cy="usernameInput"
                    type="text"
                    aria-label="User name"
                    autoComplete="username"
                    {...field.field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={ManageWebhookDetailsForm.control}
            name="password"
            render={(field) => (
              <FormItem className="w-full  mb-4">
                <FormLabel>PASSWORD (OPTIONAL)</FormLabel>
                <FormControl>
                  <Input
                    id="password"
                    placeholder="Password"
                    data-cy="passwordInput"
                    type="password"
                    aria-label="Password"
                    autoComplete="current-password"
                    {...field.field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={ManageWebhookDetailsForm.control}
          name="url"
          render={(field) => (
            <FormItem className="w-full  mb-4">
              <FormLabel>URL</FormLabel>
              <FormControl>
                <Input
                  id="url"
                  placeholder="http://ammon2.qwk.net/send_email.php"
                  data-cy="urlInput"
                  type="text"
                  aria-label="URL"
                  {...field.field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {operationType === "update" ? (
          <div className="flex gap-4 items-center justify-start mt-6">
            <EpButton
              aria-label="Save webhook details values"
              dataCy="saveWebhookDetails"
            >
              {isLoading && <LoadingAnimation />}
              {isLoading ? "Saving" : "Save"}
            </EpButton>
            <span className="text-xs font-normal">
              <span className="font-medium mr-2">Last saved</span>{" "}
              {webhook?.updated_at}
            </span>
          </div>
        ) : null}
        <div className="flex items-center justify-between mt-8 pb-1 pr-1">
          {operationType === "update" ? (
            <div className="flex justify-start gap-4 items-center border border-neutral-200 rounded-md p-2">
              <PublishWebhookDetailsDialog updatedAt={webhook?.updated_at!} />
              <span className="text-xs font-normal">
                <span className="font-medium mr-2">Last published</span>{" "}
                {webhook?.updated_at}
              </span>
            </div>
          ) : (
            <EpButton
              aria-label="Create Webhook"
              dataCy="createWebhookBtn"
              disabled={isLoading}
            >
              {isLoading && <LoadingAnimation />}
              {isLoading ? "Processing" : "Create"}
            </EpButton>
          )}
          <DialogClose asChild className="flex gap-2 ml-auto">
            <EpButton
              type="button"
              styling={EpButtonStyles.CANCEL}
              dataCy="cancelBtn"
              aria-label="cancel"
            >
              Cancel
            </EpButton>
          </DialogClose>
        </div>
      </form>
    </Form>
  );
}

import * as React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { cn } from "../../utils/cn";
import { DataCyProps } from "../../interfaces";

const TooltipProvider = TooltipPrimitive.Provider;
const Tooltip = TooltipPrimitive.Root;
const TooltipTrigger = TooltipPrimitive.Trigger;

interface PopoverProps
  extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> {
  header?: React.ReactNode; // Allow a React component or any valid React children
}
const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  PopoverProps & DataCyProps
>(
  (
    {
      className,
      header = undefined,
      children,
      sideOffset = 4,
      dataCy,
      ...props
    },
    ref,
  ) => (
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        "z-20 overflow-hidden rounded-md text-xs bg-[#F0F0F0] mx-10 text-popover-foreground shadow-tooltip animate-in fade-in-0 zoom-in-95",
        "data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
        "drop-shadow-tooltip",
        "font-secondary",
        // The following line draws an arrow on the bottom of the tooltip, but I can get a border around it.
        // I also don't know how to put it on the side where the trigger is.
        // The code might be helpful when we take another stab at adding the arrow.
        // "after:content-[''] after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:-translate-y-[1px] after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-white after:drop-shadow-md",
        className,
      )}
      style={{
        boxShadow:
          "0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
      }}
      data-cy={dataCy}
      {...props}
    >
      {header && (
        <div className="px-3 py-1.5 z-20 font-semibold text-xs">{header}</div>
      )}
      <div className="px-3 py-1.5 bg-popover z-20">{children}</div>
    </TooltipPrimitive.Content>
  ),
);
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };

import * as React from "react";
import { useFormField } from "../form/Form";
import { cn } from "../../utils/cn";
import EyeClosedIcon from "../icons/EyeClosedIcon";
import EyeIcon from "../icons/EyeIcon";
import ArrangeIcon from "../icons/ArrangeIcon";
import CloseIcon from "../icons/CloseIcon";
import SpyglassIcon from "../icons/SpyGlassIcon";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  isDraggable?: boolean;
  closeCallback?: () => void;
  ctaCallback?: (enteredText: string) => void;
  dataCy?: string;
  rootClassNames?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { className, type, isDraggable = false, rootClassNames, dataCy, ...props },
    ref,
  ) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
    const inputType = type === "password" && showPassword ? "text" : type;
    const { formItemId } = useFormField();
    let input = (
      <div className="relative w-full">
        <input
          type={inputType}
          className={cn(
            "flex h-8 w-full rounded-sm border border-input bg-background px-3 py-2 ring-offset-background font-secondary text-xs",
            "file:border-0 file:bg-transparent file:text-sm file:font-medium",
            "placeholder:text-muted-foreground",
            "focus-visible:outline-none focus-visible:ring-2 focus-visible:input focus-visible:ring-offset-1 focus-visible:border-[#2E9BFF]",
            "disabled:cursor-not-allowed disabled:bg-neutral-200 disabled:border disabled:border-neutral-300 disabled:text-neutral-600",
            className,
            props.readOnly ? "bg-[#F0F0F0] border-[#C4C4C4]" : "",
          )}
          id={formItemId}
          ref={ref}
          data-cy={dataCy}
          {...props}
        />
        {type === "password" && (
          <button
            type="button"
            className="absolute right-3 top-1/2 -translate-y-1/2"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <EyeClosedIcon /> : <EyeIcon />}
          </button>
        )}
      </div>
    );
    if (type === "checkbox") {
      const borderColor = props.checked ? "#253331" : "#A9A9A9";
      const backgroundColor = props.checked ? "#D7F1DA" : "#FFFFFF";

      input = (
        <div
          className={cn("flex justify-center w-full relative", rootClassNames)}
        >
          <input
            type="checkbox"
            className={cn(
              "peer appearance-none flex border h-4 w-4 rounded",
              `bg-[${backgroundColor}]`,
              `border-[${borderColor}]`,
              "focus:ring-2 focus:ring-offset-1 focus:input focus:input focus:border-[#2E9BFF]",
              "focus-visible:outline-none focus-visible:ring-2 focus-visible:input focus-visible:ring-offset-1 focus-visible:border-[#2E9BFF]",
              "disabled:cursor-not-allowed disabled:bg-gray-200",
              className,
            )}
            data-cy={dataCy}
            id={formItemId}
            ref={ref}
            {...props}
          />
          <svg
            className="absolute w-3 h-3 mt-0.5 hidden peer-checked:block pointer-events-none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
        </div>
      );
    }

    if (type === "radio") {
      const borderColor = props.checked ? "#253331" : "#A9A9A9";
      const backgroundColor = props.checked ? "#D7F1DA" : "#FFFFFF";

      input = (
        <div className="flex justify-center w-full relative">
          <input
            type="radio"
            className={cn(
              "peer appearance-none flex border h-4 w-4 rounded-full",
              `bg-[${backgroundColor}]`,
              `border-[${borderColor}]`,
              "focus:ring-2 focus:ring-offset-1 focus:input focus:input focus:border-[#2E9BFF]",
              "focus-visible:outline-none focus-visible:ring-2 focus-visible:input focus-visible:ring-offset-1 focus-visible:border-[#2E9BFF]",
              "disabled:cursor-not-allowed disabled:bg-gray-200",
              className,
            )}
            id={formItemId}
            data-cy={dataCy}
            ref={ref}
            {...props}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="absolute w-8 h-8 -top-2 hidden peer-checked:block pointer-events-none"
          >
            <circle cx="12.1" cy="12.1" r="1" />
          </svg>
        </div>
      );
    }
    if (isDraggable) {
      input = (
        <div className="flex items-center bg-table-header-light-gray rounded-l pl-0.5">
          <ArrangeIcon />
          {input}
        </div>
      );
    }
    return input;
  },
);
Input.displayName = "Input";

const Search = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      ctaCallback = () => {},
      closeCallback = () => {},
      dataCy,
      ...props
    },
    ref,
  ) => {
    const [enteredText, setEnteredText] = React.useState("");
    const [focused, setFocused] = React.useState(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setEnteredText(event.target.value);
    };

    const clearData = () => {
      setEnteredText("");
      closeCallback();
    };

    return (
      <div className="relative">
        <input
          type="text"
          placeholder="Search..."
          className={cn(
            "flex h-8 w-full rounded-full border border-[#A9A9A9] placeholder:italic bg-background pl-3 py-2 pr-[80px] text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50",
            className,
            "placeholder:text-secondary-font",
            "focus-visible:outline-none focus-visible:ring-2 focus-visible:border-[#2E9BFF] focus-visible:input focus-visible:ring-offset-2",
          )}
          value={enteredText}
          ref={ref}
          onChange={handleInputChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              ctaCallback(enteredText);
            }
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          {...props}
          data-cy={dataCy}
        />
        {enteredText && (
          <button
            type="button"
            className="absolute right-[50px] top-1/2 -translate-y-1/2 text-[#A9A9A9]"
            onClick={clearData}
            aria-label="Clear Search"
            data-cy="clearSearchBtn"
          >
            <CloseIcon />
          </button>
        )}
        <div dir="rtl">
          <button
            type="button"
            className={cn(
              "absolute right-0 top-0.5 m-0 px-[14px] h-full -translate-y-[2px] rounded-s-full border text-[#A9A9A9]",
              "hover:bg-[#E8E8E8] hover:text-[#6D6D6D] active:bg-[#D2D2D2] active:text-[#6D6D6D]",
              focused ? "border-[#2E9BFF]" : "border-[#A9A9A9]",
            )}
            onClick={() => ctaCallback(enteredText)}
            aria-label="Search"
            data-cy="searchBtn"
          >
            <SpyglassIcon />
          </button>
        </div>
      </div>
    );
  },
);
Search.displayName = "Search";

export { Input, Search };

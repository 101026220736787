import { SVGProps } from "react";
import { cn } from "src/utils/cn";

const CheckIcon: React.FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...props
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className, "mt-1")}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.546 5.11101C21.8272 5.3923 21.9852 5.77376 21.9852 6.17151C21.9852 6.56925 21.8272 6.95071 21.546 7.23201L10.303 18.475C10.1544 18.6236 9.97802 18.7415 9.78387 18.8219C9.58973 18.9024 9.38164 18.9438 9.1715 18.9438C8.96135 18.9438 8.75326 18.9024 8.55912 18.8219C8.36497 18.7415 8.18857 18.6236 8.04 18.475L2.454 12.89C2.31073 12.7516 2.19646 12.5861 2.11784 12.4031C2.03923 12.2201 1.99785 12.0233 1.99612 11.8241C1.99439 11.6249 2.03234 11.4274 2.10776 11.2431C2.18318 11.0587 2.29456 10.8913 2.4354 10.7504C2.57624 10.6096 2.74372 10.4982 2.92806 10.4228C3.11241 10.3474 3.30993 10.3094 3.5091 10.3111C3.70827 10.3129 3.9051 10.3542 4.0881 10.4329C4.27111 10.5115 4.43663 10.6257 4.575 10.769L9.171 15.365L19.424 5.11101C19.5633 4.97162 19.7287 4.86104 19.9107 4.7856C20.0928 4.71016 20.2879 4.67133 20.485 4.67133C20.6821 4.67133 20.8772 4.71016 21.0592 4.7856C21.2413 4.86104 21.4067 4.97162 21.546 5.11101Z"
        fill="inherit"
      />
    </svg>
  );
};

export default CheckIcon;

import {
  Provider,
  Service,
  Serviceplan,
  SubscriptionHistory,
} from "src/interfaces";

const subscriptionHistory: SubscriptionHistory = {
  account_id: "a3d72348-d021-11ee-996c-4f33504e4b50",
  port_interface_id: "f040b572-dd79-11ee-9f8d-57315a475147",
  serviceplan_id: "cba3a9cc-cc6c-11ee-935e-52364357434a",
  user_id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
  created_at: "2024-03-20 14:57:14",
  updated_at: "2024-03-20 14:57:16",
  id: "f041587e-dd79-11ee-957a-3450524b3042",
  canceled_at: "2024-03-20 14:57:14",
  canceled_by: "Test@test.com",
  expected_expire: "2024-03-20 14:57:14",
  expired_at: "2024-03-20 14:57:14",
  local_guid: "f041587e-dd79-11ee-957a-3450524b3042",
  provider_id: "7aa49c76-85a3-11ee-b78b-34543646364c",
  service_id: "c3fc38a6-cc6c-11ee-b3a0-41304c54534f",
  Provider: {
    id: "7aa49c76-85a3-11ee-b78b-34543646364c",
    auto_approve: [],
    description: "Provider Account for DEV system",
    has_portal: true,
    image: "",
    name: "QA Dev Provider",
    parent_id: "02ced310-5a6d-11eb-bd67-35323941484f",
    status: "ACTIVE",
    type: "SERVICE",
    created_at: new Date(),
    updated_at: new Date(),
    level: "1",
    Contacts: [
      {
        id: "b356d3e8-92f2-11ee-a6fa-475a54303751",
        email: "Testeringss@nopes.test",
        first_name: "Contact",
        last_name: "Testering",
        location_id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
        phone: "8002223333",
        status: "ACTIVE",
        type: "SHIPPING",
        created_at: "2023-12-04 15:15:58",
        updated_at: "2024-03-12 14:06:33",
        Location: {
          id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
          address1: "1000 Drive",
          address2: "",
          city: "Ammon",
          country: "US",
          elevation: "",
          lat: "",
          lon: "",
          state: "ID",
          status: "ACTIVE",
          uri: "",
          zip: "00000",
          created_at: "2023-12-04 15:15:58",
          updated_at: "2023-12-04 15:15:58",
        },
      },
    ],
  } as unknown as Provider,
  Service: {
    id: "c3fc38a6-cc6c-11ee-b3a0-41304c54534f",
    access_code: "X4QOBS4G",
    account_types: '["RESIDENTIAL"]',
    approval_values: {
      account_types: {
        value: '["RESIDENTIAL",]',
        status: "PUBLISHED",
        published_values: ['["RESIDENTIAL",]'],
      },
    },
    description: "This is the Service 2024 in this Automated town.",
    display_type: "PUBLIC",
    image: null,
    ip_range: "",
    json: [],
    marketing_url: "",
    name: "Service 2024",
    network_operator_label: "123",
    provider_id: "7aa49c76-85a3-11ee-b78b-34543646364c",
    servicetype_id: "5f207676-7a21-11ec-9c1e-374742515a4d",
    standby_settings: {
      enabled: "1",
      on_initially: "0",
    },
    status: "ACTIVE",
    subscription_redirect_url: "",
    support_url: "",
    virtual_system_id: null,
    walled_garden_label: "123",
    created_at: "2024-02-15 18:43:21",
    updated_at: "2024-04-05 17:15:27",
    Provider: {
      id: "7aa49c76-85a3-11ee-b78b-34543646364c",
      auto_approve: [],
      description: "Provider Account for DEV system",
      has_portal: true,
      image: null,
      name: "QA Dev Provider",
      parent_id: "02ced310-5a6d-11eb-bd67-35323941484f",
      status: "ACTIVE",
      type: "SERVICE",
      created_at: "2023-11-20 12:26:07",
      updated_at: "2024-04-05 17:15:31",
      level: "1",
      Contacts: [
        {
          id: "b356d3e8-92f2-11ee-a6fa-475a54303751",
          email: "Testeringss@nopes.test",
          first_name: "Contact",
          last_name: "Testering",
          location_id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
          phone: "8002223333",
          status: "ACTIVE",
          type: "SHIPPING",
          created_at: "2023-12-04 15:15:58",
          updated_at: "2024-03-12 14:06:33",
          Location: {
            id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
            address1: "1000 Drive",
            address2: "",
            city: "Ammon",
            country: "US",
            elevation: null,
            lat: null,
            lon: null,
            state: "ID",
            status: "ACTIVE",
            uri: "",
            zip: "00000",
            created_at: "2023-12-04 15:15:58",
            updated_at: "2023-12-04 15:15:58",
          },
        },
      ],
    },
  } as unknown as Service,
  Serviceplan: {
    id: "cba3a9cc-cc6c-11ee-935e-52364357434a",
    approval_values: [],
    description: "This is the 2024 basic service plan edited",
    expire_time: "0",
    image: "",
    name: "Basic 2024",
    ratings_sum: "0",
    service_id: "c3fc38a6-cc6c-11ee-b3a0-41304c54534f",
    status: "ACTIVE",
    total_reviews: "0",
    created_at: "2024-02-15 18:43:34",
    updated_at: "2024-04-05 17:13:50",
    fields: {
      Name: "Basic 2024",
      LinkSpeed: "10",
      Down: "150",
      Up: "1",
      Price: "19.99",
    },
    Service: {
      id: "c3fc38a6-cc6c-11ee-b3a0-41304c54534f",
      access_code: "X4QOBS4G",
      account_types: '["RESIDENTIAL"]',
      approval_values: {
        account_types: {
          value: '["RESIDENTIAL",]',
          status: "PUBLISHED",
          published_values: ['["RESIDENTIAL",]'],
        },
      },
      description: "This is the Service 2024 in this Automated town.",
      display_type: "PUBLIC",
      image: null,
      ip_range: "",
      json: [],
      marketing_url: "",
      name: "Service 2024",
      network_operator_label: "123",
      provider_id: "7aa49c76-85a3-11ee-b78b-34543646364c",
      servicetype_id: "5f207676-7a21-11ec-9c1e-374742515a4d",
      standby_settings: {
        enabled: "1",
        on_initially: "0",
      },
      status: "ACTIVE",
      subscription_redirect_url: "",
      support_url: "",
      virtual_system_id: null,
      walled_garden_label: "123",
      created_at: "2024-02-15 18:43:21",
      updated_at: "2024-04-05 17:15:27",
      Provider: {
        id: "7aa49c76-85a3-11ee-b78b-34543646364c",
        auto_approve: [],
        description: "Provider Account for DEV system",
        has_portal: true,
        image: null,
        name: "QA Dev Provider",
        parent_id: "02ced310-5a6d-11eb-bd67-35323941484f",
        status: "ACTIVE",
        type: "SERVICE",
        created_at: "2023-11-20 12:26:07",
        updated_at: "2024-04-05 17:15:31",
        level: "1",
        Contacts: [
          {
            id: "b356d3e8-92f2-11ee-a6fa-475a54303751",
            email: "Testeringss@nopes.test",
            first_name: "Contact",
            last_name: "Testering",
            location_id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
            phone: "8002223333",
            status: "ACTIVE",
            type: "SHIPPING",
            created_at: "2023-12-04 15:15:58",
            updated_at: "2024-03-12 14:06:33",
            Location: {
              id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
              address1: "1000 Drive",
              address2: "",
              city: "Ammon",
              country: "US",
              elevation: "",
              lat: "",
              lon: "",
              state: "ID",
              status: "ACTIVE",
              uri: "",
              zip: "00000",
              created_at: "2023-12-04 15:15:58",
              updated_at: "2023-12-04 15:15:58",
            },
          },
        ],
      },
    },
  } as unknown as Serviceplan,
  Portinterface: {
    id: "f041587e-dd79-11ee-957a-3450524b3042",
    description: "",
    device_id: "f0395a48-dd79-11ee-a68d-35343031464e",
    display: false,
    display_name: "Management2",
    mac_address_text: "",
    name: "Management2",
    number: "7",
    network_protocol: "",
    patch_id: "",
    speed: "1000",
    state: "CLOSED",
    status: "ACTIVE",
    type: "VIRT",
    created_at: "2024-03-08 11:30:28",
    updated_at: "2024-03-08 11:30:29",
    Subscriptions: [],
  },
};

export default subscriptionHistory;

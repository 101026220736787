import { SVGProps } from "react";

const CSVIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6393 13.364H2.66663V2.66932H7.3193V5.98932H10.6393V6.66665H12.0066V5.34265L10.64 3.97465L9.3333 2.66932L8.00663 1.34265H2.6733C2.32067 1.34389 1.98294 1.48497 1.73421 1.73494C1.48549 1.98492 1.3461 2.32335 1.34663 2.67598L1.33997 13.3427C1.33944 13.6953 1.47883 14.0337 1.72755 14.2837C1.97627 14.5337 2.314 14.6747 2.66663 14.676H12.0066V13.3427L10.6393 13.364ZM14.1733 12.0093L15.34 8.00932H14.34L13.6733 10.296L13.0066 8.00932H12.0066L13.1733 12.0093H14.1733Z"
        fill="inherit"
      />
      <path
        d="M6.67338 8.00928H4.67338C4.49673 8.0098 4.32747 8.08021 4.20256 8.20512C4.07765 8.33003 4.00724 8.4993 4.00671 8.67594V11.3426C4.00724 11.5193 4.07765 11.6885 4.20256 11.8134C4.32747 11.9383 4.49673 12.0087 4.67338 12.0093H6.67338C6.85003 12.0087 7.01929 11.9383 7.1442 11.8134C7.26911 11.6885 7.33952 11.5193 7.34005 11.3426V10.6759H6.34005V11.0093H5.00671V9.00928H6.34005V9.34261H7.34005V8.67594C7.33952 8.4993 7.26911 8.33003 7.1442 8.20512C7.01929 8.08021 6.85003 8.0098 6.67338 8.00928ZM11.34 9.01328V8.00928H8.67338C8.49657 8.00928 8.327 8.07952 8.20198 8.20454C8.07695 8.32956 8.00671 8.49913 8.00671 8.67594V9.84728C8.00671 10.0241 8.07695 10.1937 8.20198 10.3187C8.327 10.4437 8.49657 10.5139 8.67338 10.5139H10.3467V11.0093H8.00671V12.0139H10.6734C10.8502 12.0139 11.0198 11.9437 11.1448 11.8187C11.2698 11.6937 11.34 11.5241 11.34 11.3473V10.1759C11.34 9.99913 11.2698 9.82956 11.1448 9.70454C11.0198 9.57952 10.8502 9.50928 10.6734 9.50928H9.00005V9.01394L11.34 9.01328Z"
        fill="inherit"
      />
    </svg>
  );
};

export default CSVIcon;

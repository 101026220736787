import { FC, HTMLProps } from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../tooltip/Tooltip";
import DOMPurify from "dompurify";

interface IconProps extends HTMLProps<SVGSVGElement> {
  name: string;
  className?: string;
  size?: number;
  fill?: string;
  tooltipText?: string;
  tooltipContentClassName?: string;
  tooltipHeader?: React.ReactNode;
}

const Icon: FC<IconProps> = ({
  name,
  className,
  size = 24,
  fill,
  tooltipText = undefined,
  tooltipContentClassName = undefined,
  tooltipHeader = undefined,
  ...restProps
}) => {
  const fillColor = fill || "gray";

  const icon = (
    <svg
      className={`icon ${className ? className : ""}`}
      {...restProps}
      fill={fillColor}
      width={size + 1}
      height={size}
      viewBox={`0 0 24 25`}
    >
      <use href={`/Icons.svg#${name}`} />
    </svg>
  );

  return tooltipText ? (
    <TooltipProvider delayDuration={500} skipDelayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>{icon}</TooltipTrigger>
        <TooltipContent
          className={`${
            tooltipContentClassName ? tooltipContentClassName : ""
          } max-w-[400px]`}
          header={tooltipHeader}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(tooltipText),
            }}
          />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ) : (
    icon
  );
};

export default Icon;

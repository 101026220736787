import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";

import ScrollArea from "src/components/scroll-area/ScrollArea";
import ManageWebhookDetailsForm from "../manage-webhook/ManageWebhookDetailsForm";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonSizes } from "src/components/buttons/constants";

export default function CreateWebhookDialog() {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  return (
    <Dialog
      open={isDialogOpened}
      onOpenChange={(value) => setIsDialogOpened(value)}
    >
      <DialogTrigger asChild>
        <EpButton
          sizing={EpButtonSizes.LARGE}
          aria-label="Add Webhook"
          dataCy="addWebhookActionBtn"
          className="ml-auto"
        >
          Add Webhook
        </EpButton>
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-4xl font-base"
        dataCy="createWebhookDialogCnt"
      >
        <DialogHeader dataCy="createWebhookDialogHeader" borderless>
          <DialogTitle dataCy="createWebhookDialogTitle">
            Add Webhook
          </DialogTitle>
        </DialogHeader>
        <ScrollArea className="max-h-[calc(90vh-theme(space.52))] p-1">
          <ManageWebhookDetailsForm
            handleCloseDialog={() => setIsDialogOpened(false)}
            operationType="create"
          />
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}

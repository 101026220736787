import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import { DialogClose } from "src/components/dialog/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/form/Form";
import { Input } from "src/components/input/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/select/Select";
import { Textarea } from "src/components/textarea/Textarea";
import { formatDate, formatDateToHumanReadableISOString } from "src/utils/date";
import { z } from "zod";
import { GlobalServicePlan } from "../manage-global-service/ManageDetailsPlans";
import PublishDetailsDialog from "../actions/PublishDetailsDialog";
import ScrollArea from "src/components/scroll-area/ScrollArea";

const STATUS = [
  "Active",
  "Inactive",
  "Locked",
  "Paused",
  "Pending",
  "Provision",
  "Repaired",
  "Replaced",
  "RMA",
  "Standby",
  "Suspended",
];

const ManageServicePlanDetailsFormSchema = z.object({
  name: z.string().min(1, {
    message: "Please enter a valid name",
  }),
  status: z.string().nonempty({ message: "Required" }),
  id: z.string().readonly(),
  description: z.string().min(4, {
    message: "Please enter a valid description",
  }),
  expireTime: z.coerce.number().optional(),
});

type ManageGlobalServicePlanDetailsProps = {
  globalServicePlan: GlobalServicePlan;
  operationType: "create" | "update";
};

export default function ManageGlobalServicePlanDetails({
  globalServicePlan,
  operationType,
}: ManageGlobalServicePlanDetailsProps) {
  const ManageGlobalServicePlanDetailsForm = useForm<
    z.infer<typeof ManageServicePlanDetailsFormSchema>
  >({
    resolver: zodResolver(ManageServicePlanDetailsFormSchema),
    mode: "onBlur",
    defaultValues: {
      ...globalServicePlan,
    },
  });

  function handleManageDetailsSave(
    data: z.infer<typeof ManageServicePlanDetailsFormSchema>,
  ) {
    if (operationType === "create") {
      //proceed with create API
    } else {
      // proceed with update API
    }
    console.log({ data });
  }

  return (
    <ScrollArea className="max-h-[calc(90vh-theme(space.52))]">
      <Form {...ManageGlobalServicePlanDetailsForm}>
        {operationType === "update" ? (
          <div className="grid grid-rows-1 grid-cols-2 text-neutral-500 font-secondary text-xs">
            <span>CREATED: {formatDate(globalServicePlan.createdAt)}</span>
            <span>UPDATED: {formatDate(globalServicePlan.updatedAt)}</span>
          </div>
        ) : null}
        <form
          onSubmit={ManageGlobalServicePlanDetailsForm.handleSubmit(
            handleManageDetailsSave,
          )}
        >
          <div className="grid grid-cols-2 grid-rows-1">
            <FormField
              control={ManageGlobalServicePlanDetailsForm.control}
              name="name"
              render={(field) => (
                <FormItem className="w-full pr-2 mb-4">
                  <FormLabel>NAME</FormLabel>
                  <FormControl>
                    <Input
                      autoFocus
                      id="name"
                      placeholder="Name"
                      data-cy="nameInput"
                      type="text"
                      aria-label="Name"
                      {...field.field}
                      onBlur={() =>
                        ManageGlobalServicePlanDetailsForm.trigger(
                          field.field.name,
                        )
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={ManageGlobalServicePlanDetailsForm.control}
              name="id"
              render={(field) => (
                <FormItem className="w-full pr-1 mb-4">
                  <FormLabel>ID</FormLabel>
                  <FormControl>
                    <Input
                      readOnly
                      id="id"
                      placeholder="ID"
                      data-cy="idInput"
                      type="text"
                      aria-label="ID"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={ManageGlobalServicePlanDetailsForm.control}
            name="description"
            render={({ field }) => (
              <FormItem className="pr-1">
                <FormLabel>DESCRIPTION *</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Description"
                    data-cy="descriptionInput"
                    aria-label="Service Description"
                    className={"h-[50px] text-neutral-700"}
                    defaultValue={field.value || ""}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="grid grid-cols-2 grid-rows-1 mt-4">
            <FormField
              control={ManageGlobalServicePlanDetailsForm.control}
              name="status"
              render={(field) => (
                <FormItem className="w-full pr-2 mb-4">
                  <FormLabel>STATUS</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      ManageGlobalServicePlanDetailsForm.setValue(
                        field.field.name,
                        value,
                      );
                      ManageGlobalServicePlanDetailsForm.trigger(
                        field.field.name,
                      );
                    }}
                    defaultValue={globalServicePlan?.status}
                  >
                    <FormControl>
                      <SelectTrigger
                        aria-label="Service status"
                        data-cy="service-status-select"
                        onBlur={() =>
                          ManageGlobalServicePlanDetailsForm.trigger(
                            field.field.name,
                          )
                        }
                      >
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {STATUS.map((stat) => (
                        <SelectItem
                          key={stat}
                          value={stat}
                          data-cy={`service-status-${stat}`}
                        >
                          {stat}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={ManageGlobalServicePlanDetailsForm.control}
              name="expireTime"
              render={(field) => (
                <FormItem className="w-full pr-1 mb-4">
                  <FormLabel>EXPIRE TIME (OPTIONAL)</FormLabel>
                  <FormControl>
                    <Input
                      id="expireTime"
                      placeholder="Expire Time"
                      data-cy="expireTimeInput"
                      type="number"
                      aria-label="Expire Time"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <span className="text-xs font-base">IMAGE</span>
          <p className="text-xs text-neutrals-form-gray-dark mt-2">
            To set an image, send the image to the Network Operator so they can
            upload it to their system.
          </p>
          {operationType === "update" ? (
            <div className="flex gap-4 items-center justify-start mt-6">
              <EpButton
                aria-label="Save updated service plan"
                dataCy="saveUpdatedServicePlanBtn"
              >
                Save
              </EpButton>
              <span className="text-xs font-normal">
                <span className="font-medium mr-2">Last saved</span>{" "}
                {formatDateToHumanReadableISOString(
                  globalServicePlan.updatedAt,
                )}
              </span>
            </div>
          ) : null}
          <div className="flex items-center justify-between mt-8 pb-1 pr-1">
            {operationType === "update" ? (
              <div className="flex justify-start gap-4 items-center border border-neutral-200 rounded-md p-2">
                <PublishDetailsDialog
                  updatedAt={formatDateToHumanReadableISOString(
                    globalServicePlan.updatedAt,
                  )}
                />
                <span className="text-xs font-normal">
                  <span className="font-medium mr-2">Last published</span>{" "}
                  {formatDateToHumanReadableISOString(
                    globalServicePlan.updatedAt,
                  )}
                </span>
              </div>
            ) : (
              <EpButton
                aria-label="Save new service plan"
                dataCy="saveNewServicePlanBtn"
              >
                Save
              </EpButton>
            )}
            <DialogClose asChild className="flex gap-2 ml-auto">
              <EpButton
                type="button"
                styling={EpButtonStyles.CANCEL}
                dataCy="cancelBtn"
                aria-label="cancel"
              >
                Cancel
              </EpButton>
            </DialogClose>
          </div>
        </form>
      </Form>
    </ScrollArea>
  );
}

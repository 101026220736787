import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import EpButton from "src/components/buttons/EpButton";
import IconButton from "src/components/buttons/IconButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import { DialogClose } from "src/components/dialog/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/form/Form";
import { Input } from "src/components/input/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/select/Select";
import { Textarea } from "src/components/textarea/Textarea";
import { formatDate, formatDateToHumanReadableISOString } from "src/utils/date";
import { z } from "zod";
import PublishDetailsDialog from "../actions/PublishDetailsDialog";
import { GlobalServicePlan } from "./ManageDetailsPlans";
import MultiSelectFormField from "src/components/multi-select/MultiSelect";
import ScrollArea from "src/components/scroll-area/ScrollArea";

const STATUS = [
  "Active",
  "Inactive",
  "Locked",
  "Paused",
  "Pending",
  "Provision",
  "Repaired",
  "Replaced",
  "RMA",
  "Standby",
  "Suspended",
];

const TYPES = [
  "Residential",
  "Billing",
  "Sales",
  "Support",
  "Service",
  "Shipping",
  "Howzart",
  "Minimal",
  "Maximal",
];

const STAND_BY_OPTIONS = ["Initially On", "Initially Off"];

const DISPLAY_TYPES = ["PUBLIC", "PRIVATE"];

const ManageDetailsFormSchema = z.object({
  planName: z.string().min(1, {
    message: "Please enter a valid name",
  }),
  status: z.string().nonempty({ message: "Required" }),
  id: z.string().readonly().optional(),
  description: z.string().min(4, {
    message: "Please enter a valid description",
  }),
  displayType: z.string().nonempty({ message: "Required" }),
  accessCode: z.string().readonly(),
  accountTypes: z.array(z.string()).optional(),
  ipRange: z.string().optional(),
  marketingURL: z.string().optional(),
  subscriptionRedirectURL: z.string().optional(),
  supportURL: z.string().optional(),
  standByEnabled: z.string().nonempty({ message: "Required" }),
  standByInitiallyOn: z.string().nonempty({ message: "Required" }),
});

export default function ManageDetailsForm({
  serviceDetails,
  operationType,
}: {
  serviceDetails: GlobalServicePlan;
  operationType: "create" | "update";
}) {
  const ManageGlobalServiceDetailsForm = useForm<
    z.infer<typeof ManageDetailsFormSchema>
  >({
    resolver: zodResolver(ManageDetailsFormSchema),
    mode: "onBlur",
    defaultValues: {
      ...serviceDetails,
    },
  });

  function handleManageDetailsSave(
    data: z.infer<typeof ManageDetailsFormSchema>,
  ) {
    console.log({ data });
  }

  return (
    <ScrollArea className="max-h-[calc(90vh-theme(space.52))] px-2">
      <Form {...ManageGlobalServiceDetailsForm}>
        {operationType === "update" ? (
          <div className="grid grid-rows-1 grid-cols-2 mb-4 text-neutral-500 font-secondary text-xs">
            <span>CREATED: {formatDate(serviceDetails.createdAt)}</span>
            <span>UPDATED: {formatDate(serviceDetails.updatedAt)}</span>
          </div>
        ) : null}
        <form
          onSubmit={ManageGlobalServiceDetailsForm.handleSubmit(
            handleManageDetailsSave,
          )}
        >
          <div className="grid grid-cols-2 grid-rows-1">
            <div className="grid grid-cols-2 grid-rows-1">
              <FormField
                control={ManageGlobalServiceDetailsForm.control}
                name="planName"
                render={(field) => (
                  <FormItem className="w-full pr-2 mb-4">
                    <FormLabel>NAME</FormLabel>
                    <FormControl>
                      <Input
                        autoFocus
                        id="planName"
                        placeholder="Name"
                        data-cy="planNameInput"
                        type="text"
                        aria-label="Name"
                        {...field.field}
                        onBlur={() =>
                          ManageGlobalServiceDetailsForm.trigger(
                            field.field.name,
                          )
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={ManageGlobalServiceDetailsForm.control}
                name="status"
                render={(field) => (
                  <FormItem className="w-full pr-2 mb-4">
                    <FormLabel>STATUS</FormLabel>
                    <Select
                      onValueChange={(value) =>
                        ManageGlobalServiceDetailsForm.setValue(
                          field.field.name,
                          value,
                        )
                      }
                      defaultValue={serviceDetails?.status}
                    >
                      <FormControl>
                        <SelectTrigger
                          aria-label="Service status"
                          data-cy="service-status-select"
                          onBlur={() => {
                            ManageGlobalServiceDetailsForm.trigger(
                              field.field.name,
                            );
                          }}
                        >
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {STATUS.map((stat) => (
                          <SelectItem
                            key={stat}
                            value={stat}
                            data-cy={`service-status-${stat}`}
                          >
                            {stat}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={ManageGlobalServiceDetailsForm.control}
              name="id"
              render={(field) => (
                <FormItem className="w-full pr-1 mb-4">
                  <FormLabel>ID</FormLabel>
                  <FormControl>
                    <Input
                      readOnly
                      id="id"
                      placeholder="ID"
                      data-cy="idInput"
                      type="text"
                      aria-label="ID"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={ManageGlobalServiceDetailsForm.control}
            name="description"
            render={({ field }) => (
              <FormItem className="pr-1">
                <FormLabel>DESCRIPTION *</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Description"
                    data-cy="descriptionInput"
                    aria-label="Service Description"
                    className={"h-[90px] text-neutral-700"}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="grid grid-cols-2 grid-rows-1 mt-4">
            <FormField
              control={ManageGlobalServiceDetailsForm.control}
              name="displayType"
              render={(field) => (
                <FormItem className="w-full pr-2 mb-4">
                  <FormLabel>DISPLAY TYPE</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      ManageGlobalServiceDetailsForm.setValue(
                        field.field.name,
                        value,
                      );
                    }}
                    value={field.field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        aria-label="Display Type"
                        data-cy="service-display-type-select"
                        onBlur={() => {
                          ManageGlobalServiceDetailsForm.trigger(
                            field.field.name,
                          );
                        }}
                      >
                        <SelectValue>{field.field.value}</SelectValue>
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {DISPLAY_TYPES.map((type) => (
                        <SelectItem
                          key={type}
                          value={type}
                          data-cy={`service-display-type-${type}`}
                        >
                          {type}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex items-center pr-1">
              <FormField
                control={ManageGlobalServiceDetailsForm.control}
                name="accessCode"
                render={(field) => (
                  <FormItem className="w-full pr-2 mb-4">
                    <FormLabel>ACCESS CODE</FormLabel>
                    <FormControl>
                      <Input
                        readOnly
                        id="accessCode"
                        placeholder="Access Code"
                        data-cy="accessCodeInput"
                        type="text"
                        aria-label="Access Code"
                        {...field.field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <IconButton
                aria-label="Refresh access code"
                dataCy="refreshAccessCodeBtn"
                tooltipText="Generate New Access Code"
                type="button"
                name="refresh"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 grid-rows-1">
            <FormField
              control={ManageGlobalServiceDetailsForm.control}
              name="accountTypes"
              render={(field) => (
                <FormItem className="w-full pr-2">
                  <FormLabel>ACCOUNT TYPES (OPTIONAL)</FormLabel>
                  <FormControl>
                    <MultiSelectFormField
                      options={TYPES.map((t) => ({
                        label: t,
                        value: t,
                      }))}
                      onValueChange={field.field.onChange}
                      defaultValue={serviceDetails?.accountTypes}
                      placeholder="Select Account Types"
                      data-cy="service-account-type-multi-select"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={ManageGlobalServiceDetailsForm.control}
              name="ipRange"
              render={(field) => (
                <FormItem className="w-full pr-1 mb-4">
                  <FormLabel>IP / RANGE (OPTIONAL)</FormLabel>
                  <FormControl>
                    <Input
                      id="ipRange"
                      placeholder="IPV4 / IPV6 Range"
                      data-cy="ipRangeInput"
                      type="text"
                      aria-label="IPV4 / IPV6 Range"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="grid grid-cols-2 grid-rows-1">
            <FormField
              control={ManageGlobalServiceDetailsForm.control}
              name="marketingURL"
              render={(field) => (
                <FormItem className="w-full pr-2 mb-4">
                  <FormLabel>MARKETING URL (OPTIONAL)</FormLabel>
                  <FormControl>
                    <Input
                      id="marketing_url"
                      placeholder="Marketing URL"
                      data-cy="marketingURLInput"
                      type="text"
                      aria-label="Marketing URL"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={ManageGlobalServiceDetailsForm.control}
              name="subscriptionRedirectURL"
              render={(field) => (
                <FormItem className="w-full pr-1 mb-4">
                  <FormLabel>SUBSCRIPTION REDIRECT URL (OPTIONAL)</FormLabel>
                  <FormControl>
                    <Input
                      id="subscriptionRedirectURL"
                      placeholder="Subscription Redirect URL"
                      data-cy="subscriptionRedirectURLInput"
                      type="text"
                      aria-label="Subscription Redirect URL"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="grid grid-cols-2 grid-rows-1">
            <FormField
              control={ManageGlobalServiceDetailsForm.control}
              name="supportURL"
              render={(field) => (
                <FormItem className="w-full pr-2 mb-4">
                  <FormLabel>SUPPORT URL (OPTIONAL)</FormLabel>
                  <Select
                    onValueChange={(value) =>
                      ManageGlobalServiceDetailsForm.setValue(
                        field.field.name,
                        value,
                      )
                    }
                    defaultValue={serviceDetails?.supportURL}
                  >
                    <FormControl>
                      <SelectTrigger
                        aria-label="Support URL"
                        data-cy="service-support-url-select"
                        onBlur={() => {
                          ManageGlobalServiceDetailsForm.trigger(
                            field.field.name,
                          );
                        }}
                      >
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {TYPES.map((type) => (
                        <SelectItem
                          key={type}
                          value={type}
                          data-cy={`service-support-url-${type}`}
                        >
                          {type}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="grid grid-cols-2 grid-rows-1">
              <FormField
                control={ManageGlobalServiceDetailsForm.control}
                name="standByEnabled"
                render={(field) => (
                  <FormItem className="w-full pr-2 mb-4">
                    <FormLabel>STAND BY ENABLED</FormLabel>
                    <FormControl>
                      <Input
                        id="standByEnabled"
                        placeholder="Stand By Enabled"
                        data-cy="standByEnabledInput"
                        type="text"
                        aria-label="Stand By Enabled"
                        {...field.field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={ManageGlobalServiceDetailsForm.control}
                name="standByInitiallyOn"
                render={(field) => (
                  <FormItem className="w-full pr-1 mb-4">
                    <FormLabel>STAND BY - INITIALLY ON</FormLabel>
                    <Select
                      onValueChange={(value) =>
                        ManageGlobalServiceDetailsForm.setValue(
                          field.field.name,
                          value,
                        )
                      }
                      defaultValue={serviceDetails?.standByInitiallyOn}
                    >
                      <FormControl>
                        <SelectTrigger
                          aria-label="STAND BY INITIALLY ON"
                          data-cy="service-stand-by-initial-select"
                          onBlur={() => {
                            ManageGlobalServiceDetailsForm.trigger(
                              field.field.name,
                            );
                          }}
                        >
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {STAND_BY_OPTIONS.map((type) => (
                          <SelectItem
                            key={type}
                            value={type}
                            data-cy={`service-stand-by-initial-${type}`}
                          >
                            {type}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <span className="text-xs font-base">IMAGE</span>
          <p className="text-xs text-neutrals-form-gray-dark Text font-base mt-2">
            To set an image, send the image to the Network Operator so they can
            upload it to their system.
          </p>
          {operationType === "update" ? (
            <div className="flex gap-4 items-center justify-start mt-6">
              <EpButton
                aria-label="Save Updated Values"
                dataCy="saveUpdatedValuesBtn"
              >
                Save
              </EpButton>
              <span className="text-xs font-normal">
                <span className="font-medium mr-2">Last saved</span>{" "}
                {formatDateToHumanReadableISOString(serviceDetails.updatedAt)}
              </span>
            </div>
          ) : null}
          <div className="flex items-center justify-between mt-8 pb-1 pr-1">
            {operationType === "update" ? (
              <div className="flex justify-start gap-4 items-center border border-neutral-200 rounded-md p-2">
                <PublishDetailsDialog
                  updatedAt={formatDateToHumanReadableISOString(
                    serviceDetails.updatedAt,
                  )}
                />
                <span className="text-xs font-normal">
                  <span className="font-medium mr-2">Last published</span>{" "}
                  {formatDateToHumanReadableISOString(serviceDetails.updatedAt)}
                </span>
              </div>
            ) : (
              <EpButton aria-label="Create service" dataCy="createServiceBtn">
                Create
              </EpButton>
            )}
            <DialogClose asChild className="flex gap-2 ml-auto">
              <EpButton
                type="button"
                styling={EpButtonStyles.CANCEL}
                dataCy="cancelBtn"
                aria-label="cancel"
              >
                Cancel
              </EpButton>
            </DialogClose>
          </div>
        </form>
      </Form>
    </ScrollArea>
  );
}

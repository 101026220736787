import { jsPDF } from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";

import EpButton from "src/components/buttons/EpButton";
import Icon from "src/components/buttons/Icon";
import {
  EpButtonSizes,
  EpButtonStyles,
} from "src/components/buttons/constants";
import ClickableTooltip from "src/components/clickable-tooltip/ClickableTooltip";
import CSVIcon from "src/components/icons/CSVIcon";
import PDFIcon from "src/components/icons/PDFIcon";

import { Search } from "src/components/input/Input";
import { SubscriptionTableSortingPopover } from "./SubscriptionTableSortingPopover";
import { SubscriptionHistoryCol } from "./SubscriptionHistoryTable";
import { SubscriptionHistory } from "src/interfaces";

import { downloadAsCsvFile } from "src/utils/table-to-csv";

type SubscriptionHistoryToolbarProps = {
  setSearchFilter: (searchFilter: string) => void;
  clearSearch: () => void;
  columns: SubscriptionHistoryCol[];
  data: SubscriptionHistory[];
  setColumns: (items: SubscriptionHistoryCol[]) => void;
};

const DOWNLOADED_FILENAME = "subscription-history";

export default function SubscriptionHistoryToolbar({
  setSearchFilter,
  clearSearch,
  setColumns,
  data,
  columns,
}: SubscriptionHistoryToolbarProps) {
  const handleSearch = (value: string) => {
    setSearchFilter(value);
  };

  const handleDownloadCsv = () => {
    const cols = columns.map((col) => {
      return {
        title: col.title,
        get: col.get,
      };
    });

    downloadAsCsvFile<SubscriptionHistory>(cols, data, DOWNLOADED_FILENAME);
  };

  const handleDownloadPdf = async () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const headRow = columns.map((col) => col.title);
    const bodyRows = data.map((item) => {
      return columns.map((col) => {
        return col.get(item);
      });
    });

    const doc = new jsPDF(orientation, unit, size);
    autoTable(doc, {
      head: [headRow],
      body: bodyRows as RowInput[],
    });

    doc.save(`${DOWNLOADED_FILENAME}.pdf`);
  };

  return (
    <div
      className="flex justify-between items-center"
      data-cy="subscriptionHistoryToolbarSection"
    >
      <div className="flex items-center max-w-[540px] gap-2">
        <Search
          placeholder="Search"
          className="search min-w-[305px]"
          ctaCallback={handleSearch}
          closeCallback={clearSearch}
          dataCy="searchSubscriptionHistoryInput"
        ></Search>
        <ClickableTooltip
          header={<p>Search Terms</p>}
          icon={
            <Icon
              size={30}
              className="pt-[4px]"
              name="exclamation"
              data-cy="searchInfoIcon"
            />
          }
          content={
            "Search for a subscription history by providing a subscription history name"
          }
          dataCy="searchSubscriptionHistoryTooltip"
          className="w-[290px]"
        />
      </div>
      <div className="flex gap-4 pr-2">
        <EpButton
          dataCy="downloadTableAsCSVActionBtn"
          aria-label="Export table data as csv"
          onClick={handleDownloadCsv}
          styling={EpButtonStyles.OUTLINE}
          sizing={EpButtonSizes.LARGE}
        >
          <CSVIcon aria-hidden="true" className="mr-1" />
          Export CSV
        </EpButton>
        <EpButton
          dataCy="downloadTableAsPDFActionBtn"
          aria-label="Export table data as pdf"
          onClick={handleDownloadPdf}
          styling={EpButtonStyles.OUTLINE}
          sizing={EpButtonSizes.LARGE}
        >
          <PDFIcon aria-hidden="true" className="fill-primary mr-0.5" />
          Download PDF
        </EpButton>
        <SubscriptionTableSortingPopover updateColumns={setColumns} />
      </div>
    </div>
  );
}

import PageContainer from "src/components/page-container/PageContainer";
import WebhookHistorySearchFilterer from "./search/WebhookHistorySearchFilterer";
import { useState } from "react";
import { WebhookHistory } from "src/interfaces";
import WebhookHistoryTable from "./list/WebhookHistoryTable";
import ScrollArea from "src/components/scroll-area/ScrollArea";

export default function WebhookHistoryPage() {
  const [filteredWebhookHistory, setFilteredWebhookHistory] = useState<
    WebhookHistory[]
  >([]);

  return (
    <div className="w-full">
      <span className="text-primary-font">Webhook History</span>
      <PageContainer className="mt-2">
        <ScrollArea className="w-full max-h-[calc(100vh-theme(space.52))]">
          <div className="flex flex-wrap justify-start space-x-0 w-full">
            <WebhookHistorySearchFilterer
              setFilteredWebhookHistory={setFilteredWebhookHistory}
            />
            {filteredWebhookHistory.length ? (
              <WebhookHistoryTable
                filteredWebhookHistory={filteredWebhookHistory}
              />
            ) : null}
          </div>
        </ScrollArea>
      </PageContainer>
    </div>
  );
}

import { MouseEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PageConfig } from "../../routes";
import {
  NavigationMenuItem,
  NavigationMenuLink,
} from "../../components/navigation-menu/NavigationMenu";

export default function MainMenuItem({
  menuKey,
  option,
}: {
  menuKey: string;
  option: PageConfig;
}) {
  const location = useLocation();
  const selected = !!(option.path && location.pathname.startsWith(option.path));
  const highlight = selected
    ? "bg-white font-bold rounded-l-md text-active-menu"
    : "";
  const Icon = option.icon;
  const navigate = useNavigate();
  const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(option.path);
  };

  return (
    <NavigationMenuItem className={highlight}>
      <NavigationMenuLink
        key={menuKey}
        onClick={(e: MouseEvent<HTMLAnchorElement>) => onClick(e)}
        href={`#${option.path}`}
      >
        {Icon && (
          <Icon
            className="mr-1 flex-shrink-0"
            fill={selected ? "#55857E" : "inherit"}
            width="24"
            height="24"
            viewBox={"0 0 24 24"}
          />
        )}
        <div className={`mt-1 ${selected ? "text-primary-color" : ""}`}>
          {option.label}
        </div>
      </NavigationMenuLink>
    </NavigationMenuItem>
  );
}

import { Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";

const AuthedLayout = () => {
  return (
    <div className="flex h-screen bg-gradient-to-b from-bg-top to-bg-bottom">
      <Sidebar />
      <main
        id="mainContent"
        className="bg-main-content w-full bg-white rounded-tl-5xl flex-grow"
      >
        <Header />
        <div className="flex w-full pl-9 pr-6 text-neutrals-medium pb-8">
          <Outlet />
        </div>
      </main>
    </div>
  );
};
export default AuthedLayout;

import { useState } from "react";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";

import WarningIcon from "src/components/icons/WarningIcon";
import LoadingAnimation from "src/components/spinners/LoadingAnimation";

export default function PublishDetailsDialog({
  updatedAt,
}: {
  updatedAt: string;
}) {
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function handlePublish() {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setIsDialogOpened(false);
    }, 1200);
  }

  return (
    <Dialog
      open={isDialogOpened}
      onOpenChange={(value) => setIsDialogOpened(value)}
    >
      <DialogTrigger asChild>
        <EpButton
          aria-label="Publish details"
          dataCy="publishDetailsActionBtn"
          styling={EpButtonStyles.SECONDARY}
        >
          Publish
        </EpButton>
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-[425px] font-base"
        dataCy="publishDetailsDialogCnt"
      >
        <DialogHeader dataCy="publishDetailsDialogHeader" borderless>
          <DialogTitle dataCy="publishDetailsDialogTitle">
            Publish Details
          </DialogTitle>
        </DialogHeader>
        <p className="text-sm flex items-center gap-2 text-[#434343] -mt-4">
          <WarningIcon aria-hidden="true" fill="#FF7A00" />
          You are about to publish saved version {updatedAt}
        </p>
        <DialogFooter>
          <EpButton
            dataCy="confirmPublishDetailsBtn"
            aria-label="Confirm Publish details"
            className="flex gap-1"
            onClick={handlePublish}
            disabled={isLoading}
          >
            {isLoading && <LoadingAnimation />}
            {isLoading ? "Publishing" : "Publish"}
          </EpButton>
          <DialogClose asChild className="flex gap-2">
            <EpButton
              type="button"
              styling={EpButtonStyles.CANCEL}
              dataCy="cancelBtn"
              aria-label="Cancel"
            >
              Cancel
            </EpButton>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

import { RowConfig } from "./constants";

interface FlowRowsProps {
  config: RowConfig;
}

const FlowRows: React.FC<FlowRowsProps> = ({ config }) => {
  return (
    <>
      <tr>
        <td rowSpan={2} className="whitespace-pre pr-4 pt-1 align-top">
          <div className="text-xs font-semibold">{config.leftLabel}</div>
          <div>{config.leftSublabel}</div>
        </td>
        <td colSpan={5} className="w-full">
          <div className="arrow-container">
            <div
              className={`arrowbody-${config.arrowDirection} arrowbody-${config.arrowColor}`}
            ></div>
            <div
              className={`arrowhead-${config.arrowDirection} arrowhead-${config.arrowColor}`}
            ></div>
          </div>
        </td>
        <td rowSpan={2} className="whitespace-pre pl-4 pt-1 align-top">
          <div className="text-xs font-semibold">{config.rightLabel}</div>
          <div>{config.rightSublabel}</div>
        </td>
      </tr>
      <tr className="w-full flex -translate-y-[0.325rem]">
        <td className="w-full text-center">{config.lab}</td>
        <td className="w-full text-center">PRI: {config.pri}</td>
        <td className="w-full text-center">BYTES: {config.bytes}</td>
        <td className="w-full text-center">PACKETS: {config.packets}</td>
        <td className="w-full text-center">
          V: {config.v} / F: {config.f}
        </td>
      </tr>
    </>
  );
};

export default FlowRows;

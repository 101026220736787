import { useState } from "react";
import EpButton from "../buttons/EpButton";
import { EpButtonStyles } from "../buttons/constants";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../dialog/Dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../table/Table";
import { Textarea } from "../textarea/Textarea";
import { Loader2 } from "lucide-react";
import { useToast } from "../toast/useToast";
import { ApprovalItem } from "./PendingApprovalTable";
import ScrollArea from "../scroll-area/ScrollArea";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Form,
} from "../form/Form";
import { Input } from "../input/Input";
import { formatDateToHumanReadableISOString } from "src/utils/date";

type ApproveServiceModalProps = {
  approvalItem: ApprovalItem;
  setIsApproveServiceModalOpen: (value: boolean) => void;
};

const ApproveServiceFormScheme = z.object({
  comments: z.string(),
  approvalNeedItems: z.array(
    z.object({
      id: z.string().readonly(),
      field: z.string().readonly(),
      currentValue: z.string().readonly(),
      pendingValue: z.string().readonly(),
      lastPublishedValue: z.string().readonly(),
      status: z.enum(["approved", "deny", "none"]),
    }),
  ),
});

export function ApproveServiceModal({
  approvalItem,
  setIsApproveServiceModalOpen,
}: ApproveServiceModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const form = useForm<z.infer<typeof ApproveServiceFormScheme>>({
    resolver: zodResolver(ApproveServiceFormScheme),
    defaultValues: {
      comments: "",
      approvalNeedItems: [...approvalItem.approvalNeedItems],
    },
  });

  const { fields, update } = useFieldArray({
    control: form.control,
    name: "approvalNeedItems",
  });

  const updatedApprovalNeedItems = form.watch("approvalNeedItems");
  const comments = form.watch("comments");

  function handleApproveServiceModalSubmit() {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      // TODO: pass this data to api and submit to backend.
      console.log({ updatedApprovalNeedItems, comments });
      toast({
        title: "Success",
        description: `${approvalItem.name} was approved`,
      });
      setIsApproveServiceModalOpen(false);
    }, 2000);
  }

  return (
    <Dialog
      defaultOpen
      onOpenChange={(value) => setIsApproveServiceModalOpen(value)}
    >
      <DialogContent className="sm:max-w-3xl">
        <DialogHeader borderless>
          <DialogTitle className="font-normal capitalize">
            Approve {approvalItem.class}
          </DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 pb-4">
          <h4 className="mb-3">{approvalItem.name}</h4>
          <div className="grid grid-rows-1 grid-cols-2 font-secondary text-xs">
            <span>
              CREATED:{" "}
              {formatDateToHumanReadableISOString(approvalItem.createdAt)}
            </span>
            <span>
              UPDATED:{" "}
              {formatDateToHumanReadableISOString(approvalItem.updatedAt)}
            </span>
          </div>
          <Form {...form}>
            <form>
              <div className="mt-4">
                <ScrollArea className="max-h-[calc(65vh-theme(space.52))]">
                  <Table dataCy="approvalServiceModalTable">
                    <TableHeader dataCy="approvalServiceModalTableHeader">
                      <TableRow>
                        <TableHead>Field</TableHead>
                        <TableHead>Current Value</TableHead>
                        <TableHead>Pending Value</TableHead>
                        <TableHead className="w-40">
                          Last Published Value
                        </TableHead>
                        <TableHead>Approve</TableHead>
                        <TableHead>Deny</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody dataCy="approvalServiceModalTableBody">
                      {approvalItem.approvalNeedItems.map(
                        (approvalNeedItem, index) => {
                          const formItem = fields[index];
                          return (
                            <TableRow
                              dataCy={`approval-service-modal-table-row-${index}`}
                            >
                              <TableCell
                                dataCy={`approval-service-modal-table-col-field-${index}`}
                                className="font-medium"
                              >
                                {approvalNeedItem.field}
                              </TableCell>
                              <TableCell
                                dataCy={`approval-service-modal-table-col-current-value-${index}`}
                              >
                                {approvalNeedItem.currentValue}
                              </TableCell>
                              <TableCell
                                dataCy={`approval-service-modal-table-pending-value-${index}`}
                              >
                                {approvalNeedItem.pendingValue}
                              </TableCell>
                              <TableCell
                                dataCy={`approval-service-modal-table-col-last-published-value-${index}`}
                              >
                                {approvalNeedItem.lastPublishedValue}
                              </TableCell>
                              <TableCell
                                dataCy={`approval-service-modal-table-col-approve-${index}`}
                              >
                                <Input
                                  dataCy={`approval-service-modal-table-col-radio-approve-${index}`}
                                  id={`approval-service-modal-table-col-radio-approve-${index}`}
                                  aria-label="approve"
                                  type="radio"
                                  checked={formItem.status === "approved"}
                                  value="approve"
                                  onChange={() => {
                                    update(index, {
                                      ...formItem,
                                      status: "approved",
                                    });
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                dataCy={`approval-service-modal-table-col-deny-${index}`}
                              >
                                <Input
                                  type="radio"
                                  dataCy={`approval-service-modal-table-col-radio-deny-${index}`}
                                  id={`approval-service-modal-table-col-radio-deny-${index}`}
                                  aria-label="deny"
                                  checked={formItem.status === "deny"}
                                  value="deny"
                                  onChange={() => {
                                    update(index, {
                                      ...formItem,
                                      status: "deny",
                                    });
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        },
                      )}
                    </TableBody>
                  </Table>
                </ScrollArea>
              </div>
              <FormField
                control={form.control}
                name="comments"
                render={({ field }) => (
                  <FormItem className="mt-10">
                    <FormLabel htmlFor="comments">COMMENTS</FormLabel>
                    <FormControl>
                      <Textarea
                        id="comments"
                        placeholder="Brief comment to be sent to provider. For example, why a field was denied. "
                        data-cy="commentTextarea"
                        aria-label="Comments"
                        rows={5}
                        defaultValue={field.value || ""}
                        onChange={(e) => {
                          form.setValue(field.name, e.currentTarget.value);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>
        <DialogFooter>
          <EpButton
            dataCy="modalSubmit"
            onClick={handleApproveServiceModalSubmit}
            disabled={isLoading}
            aria-label="submit"
          >
            {isLoading ? (
              <>
                <Loader2 className="animate-spin h-4 w-4" />
                Submitting
              </>
            ) : (
              "Submit"
            )}
          </EpButton>
          <EpButton
            dataCy="modalCancel"
            onClick={() => setIsApproveServiceModalOpen(false)}
            styling={EpButtonStyles.CANCEL}
            aria-label="cancle"
          >
            Cancel
          </EpButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

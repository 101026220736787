import { Input } from "src/components/input/Input";

export default function PlanDetailsGlobalValues({
  planDetailsGlobalValues,
  setPlanDetailsGlobalValues,
}: {
  planDetailsGlobalValues: Record<string, boolean>;
  setPlanDetailsGlobalValues: (values: Record<string, boolean>) => void;
}) {
  const values = ["NAME", "STATUS", "DESCRIPITON", "EXPIRE TIME"];

  return (
    <div className="flex flex-col gap-3 mt-4">
      <span className="text-xs font-base mb-1 font-inter">
        GLOBAL PLAN VALUES
      </span>
      <div
        data-cy="planDetailsGlobalValuesSection"
        className="grid grid-cols-4 gap-2"
      >
        {values.map((value) => {
          return (
            <div key={value} className="flex justify-start gap-2 items-center">
              <Input
                type="checkbox"
                checked={planDetailsGlobalValues[value]}
                aria-label={value.toLowerCase()}
                dataCy={`${value}-checkbox`}
                id={value}
                onChange={(e) => {
                  setPlanDetailsGlobalValues({
                    ...planDetailsGlobalValues,
                    [value]: e.currentTarget.checked,
                  });
                }}
              />
              <label
                className="text-1.5xs font-semibold font-inter"
                htmlFor={value}
              >
                {value}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

import { Device, User } from ".";

export interface Account {
  id: string;
  account_number: string;
  name: string;
  status: AccountStatus;
  type: string;
  notes: string;
  created_at?: string;
  updated_at?: string;
  Devices?: Device[];
  Users?: User[];
}

export enum AccountStatus {
  Active = "ACTIVE",
  Pending = "PENDING",
  Inactive = "INACTIVE",
}

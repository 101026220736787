import { zodResolver } from "@hookform/resolvers/zod";
import { isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  Form,
} from "src/components/form/Form";
import { Input } from "src/components/input/Input";
import LoadingAnimation from "src/components/spinners/LoadingAnimation";
import { User } from "src/interfaces/User";
import { z } from "zod";

interface UpdatePasswordDialogProps {
  user: User | null;
}

const UpdatePasswordFormSchema = z
  .object({
    currentPassword: z.string().min(8, {
      message: "Password is required and must contain 8 or more characters.",
    }),
    newPassword: z.string().min(8, {
      message:
        "New Password is required and must contain 8 or more characters.",
    }),
    confirmNewPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmNewPassword, {
    message: "Password doesn’t match",
    path: ["confirmNewPassword"],
  });

export default function UpdatePasswordDialog({
  user,
}: UpdatePasswordDialogProps) {
  const form = useForm<z.infer<typeof UpdatePasswordFormSchema>>({
    resolver: zodResolver(UpdatePasswordFormSchema),
    mode: "onBlur",
  });
  const { watch, formState } = form;

  const handleUpdatePassword = async () => {
    form.trigger();

    const errors = formState.errors;
    const currentPassword = watch("currentPassword");
    const newPassword = watch("newPassword");
    const confirmNewPassword = watch("confirmNewPassword");

    if (
      currentPassword &&
      newPassword &&
      confirmNewPassword &&
      isEmpty(errors) &&
      newPassword === confirmNewPassword &&
      user
    ) {
      setIsProcessing(true);
      setTimeout(() => {
        setIsProcessing(false);
      }, 1200);
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpened(!isDialogOpened);
  };

  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  useMemo(() => form.reset(), [isDialogOpened]);

  return (
    <Dialog open={isDialogOpened} onOpenChange={handleCloseDialog}>
      <DialogTrigger>
        <EpButton
          styling={EpButtonStyles.SECONDARY}
          dataCy="updatePasswordBtn"
          tooltipText="Update Password"
        >
          Update Password
        </EpButton>
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-[425px]"
        dataCy="updatePasswordDialogCnt"
      >
        <DialogHeader dataCy="updatePasswordDialogHeader" borderless>
          <DialogTitle dataCy="updatePasswordDialogTitle">
            Update Password
          </DialogTitle>
        </DialogHeader>
        <p className="text-sm text-[#434343]">
          Please enter your current password, followed by your new password and
          a confirmation of the new password.
        </p>
        <div className="flex flex-col w-full">
          <Form {...form}>
            <form className="flex flex-col w-full">
              <FormField
                control={form.control}
                name="currentPassword"
                render={(field) => (
                  <FormItem className="w-full pr-2 mb-4">
                    <FormLabel>CURRENT PASSWORD</FormLabel>{" "}
                    <FormControl>
                      <Input
                        id="currentPassword"
                        placeholder="Current Password"
                        data-cy="currentPasswordInput"
                        type="password"
                        aria-label="Current Password"
                        {...field.field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="newPassword"
                render={(field) => (
                  <FormItem className="w-full pr-2 mb-4">
                    <FormLabel>NEW PASSWORD</FormLabel>{" "}
                    <FormControl>
                      <Input
                        id="newPassword"
                        placeholder="New Password"
                        data-cy="newPasswordInput"
                        type="password"
                        aria-label="New Password"
                        {...field.field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="confirmNewPassword"
                render={(field) => (
                  <FormItem className="w-full pr-2 mb-4">
                    <FormLabel>CONFIRM NEW PASSWORD</FormLabel>{" "}
                    <FormControl>
                      <Input
                        id="confirmNewPassword"
                        placeholder="Confirm New Password"
                        data-cy="confirmNewPasswordInput"
                        type="password"
                        aria-label="Confirm New Password"
                        {...field.field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>

        <DialogFooter>
          <EpButton
            styling={EpButtonStyles.PRIMARY}
            dataCy="updatePasswordBtn"
            onClick={handleUpdatePassword}
            className="flex gap-1"
            aria-label="Update Password"
            disabled={isProcessing}
          >
            {isProcessing && <LoadingAnimation />}
            {isProcessing ? "Saving" : "Save"}
          </EpButton>
          <DialogClose asChild className="flex gap-2">
            <EpButton
              aria-label="Cancel"
              styling={EpButtonStyles.CANCEL}
              dataCy="cancelBtn"
            >
              Cancel
            </EpButton>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

import { SVGProps } from "react";

const UserIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const defaultFill = "#434343";
  const fill = props.fill || defaultFill;
  const viewBox = `0 0 ${props.width ? props.width : 21} ${
    props.height ? props.height : 20
  }`;

  return (
    <svg
      width={props.width || 21}
      height={props.height || 20}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill={fill}
      data-cy="userIcon"
    >
      <path d="M21.9731 19.6875C20.6878 17.4316 18.6807 15.6738 16.275 14.6972C17.4713 13.7999 18.355 12.549 18.8009 11.1216C19.2468 9.69424 19.2322 8.16274 18.7593 6.74407C18.2865 5.32539 17.3792 4.09147 16.166 3.21709C14.9529 2.3427 13.4954 1.87219 12 1.87219C10.5046 1.87219 9.04706 2.3427 7.83392 3.21709C6.62078 4.09147 5.7135 5.32539 5.24061 6.74407C4.76772 8.16274 4.75319 9.69424 5.19907 11.1216C5.64496 12.549 6.52865 13.7999 7.72498 14.6972C5.31928 15.6738 3.31221 17.4316 2.02686 19.6875C1.94721 19.8156 1.89413 19.9583 1.87076 20.1073C1.8474 20.2563 1.85423 20.4085 1.89084 20.5548C1.92746 20.7011 1.99312 20.8385 2.08392 20.9589C2.17472 21.0794 2.2888 21.1803 2.41938 21.2557C2.54996 21.3312 2.69438 21.3796 2.84405 21.3981C2.99373 21.4166 3.14559 21.4049 3.29062 21.3635C3.43566 21.3222 3.57089 21.2521 3.6883 21.1574C3.8057 21.0628 3.90288 20.9455 3.97404 20.8125C5.67279 17.8763 8.67279 16.125 12 16.125C15.3272 16.125 18.3272 17.8772 20.0259 20.8125C20.1802 21.0605 20.4248 21.2388 20.7081 21.3097C20.9914 21.3806 21.2912 21.3386 21.544 21.1925C21.7969 21.0464 21.9831 20.8077 22.0631 20.5268C22.1432 20.246 22.1109 19.945 21.9731 19.6875ZM7.12498 9C7.12498 8.03582 7.41089 7.09329 7.94657 6.2916C8.48224 5.48991 9.24361 4.86507 10.1344 4.49609C11.0252 4.12711 12.0054 4.03057 12.951 4.21867C13.8967 4.40678 14.7653 4.87107 15.4471 5.55286C16.1289 6.23464 16.5932 7.10328 16.7813 8.04894C16.9694 8.99459 16.8729 9.97479 16.5039 10.8656C16.1349 11.7564 15.5101 12.5177 14.7084 13.0534C13.9067 13.5891 12.9642 13.875 12 13.875C10.7075 13.8735 9.4684 13.3594 8.55448 12.4455C7.64056 11.5316 7.12647 10.2925 7.12498 9Z" />
    </svg>
  );
};

export default UserIcon;

import { useState } from "react";
import IconButton from "src/components/buttons/IconButton";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";
import TabMenu, { TabConfig } from "src/components/tabMenu/TabMenu";
import ManageDetailsForm from "../manage-global-service/ManageDetailsForm";
import ManageDetailsPlans, {
  GlobalServicePlan,
} from "../manage-global-service/ManageDetailsPlans";

type ManageGlobalServiceDialogProps = {
  globalService: GlobalServicePlan;
};

export default function ManageGlobalServiceDialog({
  globalService,
}: ManageGlobalServiceDialogProps) {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const manageGlobalServiceTabsConfig: TabConfig[] = [
    {
      key: "details",
      title: "Details",
      titleAttribute: "Manage Details",
      component: (
        <ManageDetailsForm
          operationType="update"
          serviceDetails={globalService}
        />
      ),
      dataCy: "manageDetailsTab",
    },
    {
      key: "plans",
      title: "Plans",
      titleAttribute: "Manage Plans",
      component: <ManageDetailsPlans />,
      dataCy: "managePlansTab",
    },
  ];

  return (
    <Dialog
      open={isDialogOpened}
      onOpenChange={(value) => setIsDialogOpened(value)}
    >
      <DialogTrigger asChild>
        <IconButton
          name="view-file"
          dataCy="manageGlobalServiceActionBtn"
          tooltipText={`View Service Details`}
          ariaLabel={`View Service Details`}
        />
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-4xl font-base"
        dataCy="manageGlobalServiceDialogCnt"
      >
        <DialogHeader dataCy="manageGlobalServiceDialogHeader" borderless>
          <DialogTitle dataCy="manageGlobalServiceDialogTitle">
            Manage {globalService.provider}
          </DialogTitle>
        </DialogHeader>
        <TabMenu
          config={manageGlobalServiceTabsConfig}
          dataCy="manageGlobalServiceTabMenu"
        ></TabMenu>
      </DialogContent>
    </Dialog>
  );
}

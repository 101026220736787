import Loading from "src/components/loading/InitialLoading";
import Pagination from "src/components/pagination";
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/table/Table";
import GlobalServicesActionsCell from "./GlobalServicesActionCells";
import GlobalServicesToolbar from "./GlobalServicesToolbar";
import { Dot } from "src/components/dot/Dot";
import { GlobalServicePlan } from "../manage-global-service/ManageDetailsPlans";

const globalServicesPageSize = 10;

const globalService = {
  id: "550e8400-e29b-41d4-a716-4466",
  planName: "Basic 5 - 15mbs/15mbs",
  domain: "PUBLIC",
  type: "Residential",
  status: "ACTIVE",
  provider: "QWKnet",
  description: "15mbs/15mbs",
  expireTime: 0,
  linkSpeed: 0,
  down: 15,
  up: 15,
  price: 5.88,
  displayType: "PUBLIC",
  accessCode: "9OIXVEQD",
  accountTypes: ["Residential"],
  createdAt: new Date(),
  updatedAt: new Date(),
  name: "QWKnet",
  ipRange: "",
  marketingURL: "http://www.qwk.net/residential-fiber/ammon/",
  subscriptionRedirectURL:
    "http://ammon.qwk.net/Ammon.Subscription.API/Welcome.aspx",
  supportURL: "https://customers.qwk.net/dp",
  standByEnabled: "Yes",
  standByInitiallyOn: "Intially On",
};

export default function GlobalServicesList() {
  const hash = window.location.hash.replace("#", "");
  const [lastPage, setLastPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>(
    hash !== "" ? hash : "*",
  );
  const [isLoading, setIsLoading] = useState(false);
  const [globalServices, setGlobalServices] = useState<GlobalServicePlan[]>(
    new Array(30).fill(globalService, 0, 30).map((globalService, index) => {
      return {
        ...globalService,
        id: globalService.id + index,
      };
    }),
  );

  const [globalServicesSearch, setGlobalServicesSearch] = useState<
    GlobalServicePlan[]
  >([]);

  const searchGlobalServices = async (query: string, page: number = 1) => {
    setIsLoading(true);
    const searchFilteredResults = globalServices.filter((globalServices) => {
      if (globalServices.provider.toLowerCase().includes(query.toLowerCase())) {
        return true;
      }
      return false;
    });
    const maxNumberOfPages = Math.ceil(
      searchFilteredResults.length / globalServicesPageSize,
    );
    // perform client side pagination.
    const startIndex = (page - 1) * globalServicesPageSize;
    const endIndex = startIndex + globalServicesPageSize;
    const paginatedResults = searchFilteredResults.slice(startIndex, endIndex);

    setLastPage(maxNumberOfPages);
    setGlobalServicesSearch(paginatedResults);
    setIsLoading(false);
  };

  const clearSearchglobalServices = () => {
    setSearchQuery("*");
    setGlobalServicesSearch([]);
  };

  const handleActionsCellClick = (
    event: React.MouseEvent<HTMLTableCellElement>,
  ) => {
    event.stopPropagation();
  };

  const handleSelectPage = (page: number) => {
    setCurrentPage(page);
    searchGlobalServices(searchQuery, page);
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
  }, []);

  function renderglobalServicessList() {
    if (isLoading) {
      return <Loading></Loading>;
    }

    if (
      globalServices.length > 0 &&
      globalServicesSearch.length < 1 &&
      searchQuery === "*"
    ) {
      return (
        <div className="mt-10 p-10 text-center min-h-[calc(100vh-280px-2rem)]">
          <p className="font-bold mb-10">
            There are currently {globalServices.length} global services
            available for searching
          </p>
          <p>Please enter a search term to load results</p>
        </div>
      );
    }

    if (globalServicesSearch.length < 1 && searchQuery !== "*") {
      return (
        <div className="mt-10 p-10 text-center min-h-[calc(100vh-280px-2rem)]">
          <p className="font-bold mb-10">No matching search results</p>
          <p>Try again using another search term</p>
        </div>
      );
    }

    return (
      <div className="mt-4">
        <Table dataCy="globalServicesTable">
          <TableHeader
            dataCy="globalServicesTableHeader"
            className="sticky top-0"
          >
            <TableRow dataCy="globalServicesTableHeaderRow">
              <TableHead className="text-left">Provider</TableHead>
              <TableHead>Domain</TableHead>
              <TableHead>Type</TableHead>
              <TableHead className="text-center w-[90px]">Action</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody dataCy="globalServicesTableBody">
            {globalServicesSearch &&
              globalServicesSearch.map((globalService) => (
                <TableRow
                  key={globalService.id}
                  className="font-medium"
                  dataCy={`${globalService.id}Row`}
                >
                  <TableCell
                    dataCy={`${globalService.id}globalServiceProviderCell`}
                  >
                    <div className="flex items-center gap-2">
                      {globalService.status === "ACTIVE" ? (
                        <Dot className="h-2 w-2" variant={"active"} />
                      ) : (
                        <Dot className="h-2 w-2" variant={"inactive"} />
                      )}
                      {globalService.provider}
                    </div>
                  </TableCell>
                  <TableCell
                    className="uppercase"
                    dataCy={`${globalService.id}DomainCell`}
                  >
                    {globalService.domain}
                  </TableCell>
                  <TableCell dataCy={`${globalService.id}TypeCell`}>
                    {globalService.type}
                  </TableCell>
                  <TableCell
                    className="text-center"
                    onClick={handleActionsCellClick}
                    dataCy={`${globalService.id}globalServiceActionsCell`}
                  >
                    <GlobalServicesActionsCell
                      removeGlobalService={() => {
                        setGlobalServices(
                          globalServices.slice(0, globalServices.length - 1),
                        );
                      }}
                      globalService={globalService}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <div
          className={`flex flex-wrap items-center ${
            lastPage > 1 ? "justify-between" : "justify-start"
          } space-x-0 mt-4`}
        >
          {globalServicesSearch && globalServices.length >= 1 && (
            <div className="text-xs font-normal font-secondary">
              Showing{" "}
              {lastPage > 1
                ? `${(currentPage - 1) * globalServicesPageSize + 1} to ${
                    (currentPage - 1) * globalServicesPageSize +
                      globalServicesPageSize >
                    globalServices.length
                      ? globalServices.length
                      : (currentPage - 1) * globalServicesPageSize +
                        globalServicesPageSize
                  }`
                : `${globalServices?.length}`}{" "}
              of {globalServices?.length} entries
            </div>
          )}
          {lastPage > 1 && globalServicesSearch.length >= 1 && (
            <div className="flex items-center">
              <Pagination
                pageCount={lastPage > 4 ? 4 : lastPage}
                currentPage={currentPage}
                lastPage={lastPage}
                onSelect={handleSelectPage}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6">
      <GlobalServicesToolbar
        setSearchFilter={(query: string) => {
          setSearchQuery(query);
          searchGlobalServices(query, currentPage);
        }}
        clearSearch={clearSearchglobalServices}
      ></GlobalServicesToolbar>
      {renderglobalServicessList()}
    </div>
  );
}

import { SVGProps } from "react";

const GlobalServicesIcon = (props: SVGProps<SVGSVGElement>) => {
  const defaultFill = "#6D6D6D";
  const fill = props.fill || defaultFill;
  const viewBox = `0 0 ${props.width ? props.width : 21} ${
    props.height ? props.height : 20
  }`;
  return (
    <svg
      width={props.width || 21}
      height={props.height || 20}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill={fill}
      data-testid="icon"
    >
      <path
        d="M13 10.5V14.5L16 16.5L16.75 15.25L14.5 13.75V10.5H13ZM20 10.39V10C20 4.5 15.5 0 10 0C4.47 0 0 4.5 0 10C0 15.5 4.5 20 10 20C10.13 20 10.24 20 10.37 19.97C11.43 20.62 12.67 21 14 21C17.86 21 21 17.86 21 14C21 12.68 20.62 11.44 20 10.39ZM17.76 8.11L17.59 8H17.74C17.75 8.03 17.75 8.07 17.76 8.11ZM16.92 6H13.97C13.657 4.76146 13.1936 3.5659 12.59 2.44C14.43 3.07 15.96 4.34 16.92 6ZM10 2.03C10.83 3.23 11.5 4.57 11.91 6H8.09C8.5 4.57 9.17 3.23 10 2.03ZM7.66 8H10.41C9.14624 8.75456 8.15249 9.88828 7.57 11.24C7.53 10.83 7.5 10.42 7.5 10C7.5 9.32 7.56 8.65 7.66 8ZM7.4 2.44C6.8 3.55 6.35 4.75 6 6H3.08C4.03886 4.32721 5.5748 3.06149 7.4 2.44ZM2.26 12C2.1 11.36 2 10.69 2 10C2 9.31 2.1 8.64 2.26 8H5.64C5.56 8.66 5.5 9.32 5.5 10C5.5 10.68 5.56 11.34 5.64 12H2.26ZM3.08 14H6C6.35 15.25 6.8 16.45 7.4 17.56C5.57862 16.9317 4.04485 15.6677 3.08 14ZM14 19C11.24 19 9 16.76 9 14C9 11.24 11.24 9 14 9C16.76 9 19 11.24 19 14C19 16.76 16.76 19 14 19Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default GlobalServicesIcon;

import BackButton from "src/components/pagination/BackButton";
import PageButton from "src/components/pagination/PageButton";
import NextButton from "src/components/pagination/NextButton";

interface PaginationProps {
  pageCount: number;
  currentPage: number;
  lastPage: number;
  onSelect: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  pageCount,
  currentPage,
  lastPage,
  onSelect = () => null,
}) => {
  const guessFirstPage = currentPage - Math.floor((pageCount - 1) / 2);
  const firstPageDisplayed = Math.min(
    lastPage - pageCount + 1,
    Math.max(guessFirstPage, 1),
  );

  const slots = Array.from({ length: pageCount }, (_, key) => key);
  slots.pop();

  return (
    <div className="flex flex-wrap justify-start space-x-0">
      <BackButton
        disabled={currentPage === 1}
        onClick={() => onSelect(currentPage - 1)}
      >
        Previous
      </BackButton>
      {slots.map((slot) => {
        const key = slot + firstPageDisplayed;
        return (
          <PageButton
            key={`s-${key}`}
            selected={key === currentPage}
            onClick={() => onSelect(key)}
            dataCy={`page${slot}Button`}
            disabled={key === currentPage}
            aria-label={
              key === currentPage
                ? `Current Page, Page ${key}`
                : `Go To, Page ${key}`
            }
          >
            {key}
          </PageButton>
        );
      })}
      <PageButton
        key={`s-${lastPage}`}
        selected={lastPage === currentPage}
        onClick={() => onSelect(lastPage)}
        dataCy={`page${lastPage}Button`}
        disabled={lastPage === currentPage}
        aria-label={
          lastPage === currentPage
            ? `Current Page, Page ${lastPage}`
            : `Go To, Page ${lastPage}`
        }
      >
        {lastPage}
      </PageButton>
      <NextButton
        disabled={currentPage === lastPage}
        onClick={() => onSelect(currentPage + 1)}
      >
        Next
      </NextButton>
    </div>
  );
};

export default Pagination;

import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import LoginInput from "../../components/login/LoginInput";
import LoginButton from "../../components/login/LoginButton";
import { useSession } from "../../hooks/session/useSession";
import { FC } from "react";

const FormSchema = z.object({
  username: z.string().min(1, {
    message: "Enter your user name",
  }),
  password: z.string().min(1, {
    message: "Enter your password",
  }),
});

const Login: FC = () => {
  const { useUser } = useSession();
  const methods = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });
  const errors = methods.formState.errors;
  const bottomErrors = [[errors["password"]?.message, useUser.error]];

  const onSubmit: SubmitHandler<z.infer<typeof FormSchema>> = (data) => {
    useUser.loginNow(data.username, data.password);
  };

  return (
    <div className="flex-col space-y-2 h-full justify-center items-center">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <h2 className="text-primary-color-1000 text-4xl font-semibold mb-4 lg:mb-8 ">
            Login
          </h2>
          <div className="flex flex-col gap-1">
            <label
              htmlFor="username"
              className="text-neutrals-form-gray-dark text-base font-medium"
            >
              User Name
            </label>
            <LoginInput
              name="username"
              id="username"
              error={errors["username"]?.message}
            />
          </div>

          <div className="flex flex-col gap-1 pt-4">
            <label
              htmlFor="password"
              className="text-neutrals-form-gray-dark text-base font-medium"
            >
              Password
            </label>
            <LoginInput
              type="password"
              name="password"
              id="password"
              error={
                bottomErrors.some((error) => error)
                  ? errors["password"]?.message || (useUser.error as string)
                  : undefined
              }
            />
          </div>
          <div className="py-6 lg:py-10 ">
            <LoginButton>Login</LoginButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Login;

import * as ScrollAreaPrimitives from "@radix-ui/react-scroll-area";
import { FC, useEffect, useState } from "react";
import "./scroll-area-styles.css";
import { cn } from "../../utils/cn";

interface ScrollAreaProps {
  className: string;
  children: React.ReactNode;
}

const ScrollArea: FC<ScrollAreaProps> = ({ className, children, ...props }) => {
  const [scrollAreaViewportClasses, setScrollAreaViewportClasses] =
    useState<string>();

  useEffect(() => {
    setScrollAreaViewportClasses(cn("ScrollAreaViewport", className));
  }, [className]);

  return (
    <ScrollAreaPrimitives.Root className="ScrollAreaRoot">
      <ScrollAreaPrimitives.Viewport
        className={scrollAreaViewportClasses}
        {...props}
      >
        {children}
      </ScrollAreaPrimitives.Viewport>
      <ScrollAreaPrimitives.Scrollbar
        className="ScrollAreaScrollbar"
        orientation="vertical"
      >
        <ScrollAreaPrimitives.Thumb className="ScrollAreaThumb" />
      </ScrollAreaPrimitives.Scrollbar>
      <ScrollAreaPrimitives.Scrollbar
        className="ScrollAreaScrollbar"
        orientation="horizontal"
      >
        <ScrollAreaPrimitives.Thumb className="ScrollAreaThumb" />
      </ScrollAreaPrimitives.Scrollbar>
      <ScrollAreaPrimitives.Corner className="ScrollAreaCorner" />
    </ScrollAreaPrimitives.Root>
  );
};

export default ScrollArea;

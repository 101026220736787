import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import { DialogClose, DialogFooter } from "src/components/dialog/Dialog";
import {
  FormField,
  FormItem,
  FormControl,
  FormMessage,
  Form,
} from "src/components/form/Form";
import { Input } from "src/components/input/Input";
import LoadingAnimation from "src/components/spinners/LoadingAnimation";
import { toast } from "src/components/toast/useToast";
import { User } from "src/interfaces/User";
import { z } from "zod";

const NewUsernameFormScheme = z.object({
  newUsername: z.string().min(3),
});
interface NewUsernameFormProps {
  user: User;
  setUser: (user: User) => void;
  handleCloseDialog: () => void;
}

export default function NewUsernameForm({
  user,
  setUser,
  handleCloseDialog,
}: NewUsernameFormProps) {
  const newUsernameForm = useForm<z.infer<typeof NewUsernameFormScheme>>({
    resolver: zodResolver(NewUsernameFormScheme),
    mode: "onBlur",
    defaultValues: {
      newUsername: "",
    },
  });

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const handleUpdateUsername = (
    data: z.infer<typeof NewUsernameFormScheme>,
  ) => {
    setIsProcessing(true);
    setTimeout(() => {
      setUser({
        ...user,
        username: data.newUsername,
      });

      setIsProcessing(false);
      toast({
        title: "Success",
        description: (
          <>
            Username updated successfully!
            <br />
          </>
        ),
      });
      handleCloseDialog();
    }, 1200);
  };

  return (
    <>
      <p className="text-sm text-[#434343] -mt-4">
        Please enter your <span className="font-semibold">new </span>
        username.
      </p>
      <div className="flex flex-col w-full">
        <Form {...newUsernameForm}>
          <form
            onSubmit={newUsernameForm.handleSubmit(handleUpdateUsername)}
            className="flex flex-col w-full"
          >
            <FormField
              control={newUsernameForm.control}
              name="newUsername"
              render={(field) => (
                <FormItem className="w-full pr-2 mb-4">
                  <FormControl>
                    <Input
                      autoFocus
                      id="newUsername"
                      placeholder="New Username"
                      data-cy="newUsernameInput"
                      type="text"
                      aria-label="New Username"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
      </div>
      <DialogFooter>
        <DialogClose asChild className="flex gap-2">
          <EpButton
            styling={EpButtonStyles.CANCEL}
            aria-label="Cancel"
            dataCy="cancelBtn"
          >
            Cancel
          </EpButton>
        </DialogClose>
        <EpButton
          styling={EpButtonStyles.PRIMARY}
          dataCy="updateUsernameBtn"
          onClick={newUsernameForm.handleSubmit(handleUpdateUsername)}
          className="flex gap-1"
          aria-label="Update username"
          disabled={isProcessing}
        >
          {isProcessing && <LoadingAnimation />}
          {isProcessing ? "Saving" : "Save"}
        </EpButton>
      </DialogFooter>
    </>
  );
}

import IconButton from "src/components/buttons/IconButton";
import { Subscriber } from "./SubscribersList";
import RemoveSubscriberDialog from "../actions/RemoveSubscriberDialog";
import { toast } from "src/components/toast/useToast";
import SubscriberDetailsDialog from "../actions/SubscriberDetailsDialog";

type SubscriberActionsCellProps = {
  subscriber: Subscriber;
  removeSubscriber: (subscribers: Subscriber) => void;
};

export default function SubscriberActionsCell({
  subscriber,
  removeSubscriber,
}: SubscriberActionsCellProps) {
  function handleRefresh() {
    toast({
      title: "Success",
      description: (
        <>
          Refreshed data successfully
          <br />
        </>
      ),
    });
  }
  return (
    <div className="flex justify-center gap-x-2">
      <SubscriberDetailsDialog subscriber={subscriber} />
      <IconButton
        name="refresh"
        dataCy="refreshDataBtn"
        tooltipText={`Refresh`}
        ariaLabel={`refresh`}
        onClick={handleRefresh}
      />
      <RemoveSubscriberDialog
        removeSubscriber={removeSubscriber}
        subscriber={subscriber}
      />
    </div>
  );
}

import { SVGProps } from "react";

const EyeIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.17092 7.78008L2.06321 8L2.17092 8.21992C2.75091 9.40414 3.6515 10.4019 4.77033 11.0997C5.88916 11.7976 7.18133 12.1675 8.49996 12.1675C9.81858 12.1675 11.1108 11.7976 12.2296 11.0997C13.3484 10.4019 14.249 9.40414 14.829 8.21992L14.9367 8L14.829 7.78008C14.249 6.59585 13.3484 5.59811 12.2296 4.90026C11.1108 4.20241 9.81858 3.83245 8.49996 3.83245C7.18133 3.83245 5.88916 4.20241 4.77033 4.90026C3.6515 5.59811 2.75091 6.59585 2.17092 7.78008ZM8.49996 6.5C8.89778 6.5 9.27931 6.65804 9.56062 6.93934L9.91417 6.58579L9.56062 6.93934C9.84192 7.22064 9.99996 7.60218 9.99996 8C9.99996 8.39783 9.84192 8.77936 9.56062 9.06066C9.27931 9.34196 8.89778 9.5 8.49996 9.5C8.10213 9.5 7.7206 9.34196 7.4393 9.06066C7.15799 8.77936 6.99996 8.39783 6.99996 8C6.99996 7.60218 7.15799 7.22064 7.4393 6.93934C7.7206 6.65804 8.10213 6.5 8.49996 6.5ZM8.49996 3.5C11.5503 3.5 14.1674 5.35446 15.293 8C14.1674 10.6455 11.5503 12.5 8.49996 12.5C5.44963 12.5 2.83254 10.6455 1.70691 8C2.83254 5.35446 5.44963 3.5 8.49996 3.5Z"
        fill="#253331"
        stroke="#253331"
      />
    </svg>
  );
};

export default EyeIcon;

import { useLocation } from "react-router-dom";
import SubMenuItem from "./SubMenuItem";
import { PageConfig, menuHierarchy, protectedPages } from "../../routes";
import {
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuTrigger,
} from "../../components/navigation-menu/NavigationMenu";

export default function TreeMenuItem({
  menuKey,
  option,
}: {
  menuKey: string;
  option: PageConfig;
}) {
  const location = useLocation();
  const inPath = option.path.split("/")[1] === location.pathname.split("/")[1];
  const highlight = inPath
    ? "bg-white font-bold rounded-l-md text-active-menu"
    : "";
  const Icon = option.icon;
  const selectedOption: PageConfig | undefined = menuHierarchy[menuKey]
    .map((childKey) => protectedPages[childKey])
    .find(
      (childOption) => childOption && childOption.path === location.pathname,
    );

  return (
    <>
      <NavigationMenuItem className={highlight}>
        <NavigationMenuTrigger>
          {Icon && (
            <Icon
              className="mr-1 flex-shrink-0"
              fill={highlight ? "#55857E" : ""}
            />
          )}
          <span className="pt-0.5">{option.title}</span>
        </NavigationMenuTrigger>
        <NavigationMenuContent>
          <ul>
            {menuHierarchy[menuKey].map((childKey) => {
              return (
                <SubMenuItem key={childKey} option={protectedPages[childKey]} />
              );
            })}
          </ul>
        </NavigationMenuContent>
      </NavigationMenuItem>
      {inPath && selectedOption && (
        <div
          className={`bg-white h-auto leading-3 flex text-[#55857E] items-center text-1.5xs font-bold rounded-l-md text-active-menu ml-4 my-1 py-1 pl-2`}
        >
          {selectedOption.label}
        </div>
      )}
    </>
  );
}

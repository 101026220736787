type Columns<T> = {
  title: string;
  get: (item: T) => string | null | undefined;
}[];

export const downloadAsCsvFile = <T>(
  columns: Columns<T>,
  data: T[],
  filename: string,
) => {
  const csvData = makeCsvData<T>(columns, data);
  const csvFile = new Blob([csvData], { type: "text/csv" });
  const downloadLink = document.createElement("a");

  downloadLink.style.display = "none";
  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

const makeCsvData = <T>(columns: Columns<T>, data: T[]) => {
  return data.reduce(
    (csvString, rowItem) => {
      return (
        csvString +
        columns.map(({ get }) => escapeCsvCell(get(rowItem) ?? "")).join(",") +
        "\r\n"
      );
    },
    columns.map(({ title }) => escapeCsvCell(title)).join(",") + "\r\n",
  );
};

const escapeCsvCell = (cell: string | JSX.Element) => {
  if (typeof cell !== "string") {
    return "";
  }

  if (cell == null) {
    return "";
  }
  const sc = cell.toString().trim();
  if (sc === "" || sc === '""') {
    return sc;
  }

  if (
    sc.includes('"') ||
    sc.includes(",") ||
    sc.includes("\n") ||
    sc.includes("\r")
  ) {
    return '"' + sc.replace(/"/g, '""') + '"';
  }
  return sc;
};

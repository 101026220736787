import { useState, useEffect } from "react";
import { Badge } from "src/components/badge/Badge";
import IconButton from "src/components/buttons/IconButton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/table/Table";
import { Device, PortInterface } from "src/interfaces";
import SubscriberLanDetailsDialog from "../actions/SubscriberLanDetailsDialog";
import TestServiceNetworkDialog from "../actions/TestServiceNetworkDialog";

type InfoTableProps = {
  ports: PortInterface[];
  currentDevice: Device;
};

const accountDetails = {
  id: "a3d72348-d021-11ee-996c-4f33504e4b50",
  account_number: "admin",
  name: "admin",
  notes: "",
  status: "ACTIVE",
  type: "RESIDENTIAL",
  created_at: "2024-02-20 11:55:39",
  updated_at: "2024-03-08 11:35:36",
};

export default function InfoTable({ ports, currentDevice }: InfoTableProps) {
  const [lanItems, setLanItems] = useState<PortInterface[]>([]);
  const [currentPort, setCurrentPort] = useState<PortInterface | undefined>(
    undefined,
  );

  useEffect(() => {
    const aux: PortInterface[] = [];
    for (const port of ports) {
      if (port.type === "LAN") {
        aux.push(port);
      }
    }
    aux.sort((a, b) => a.display_name.localeCompare(b.display_name));
    setLanItems(aux);
  }, [ports]);

  return (
    <div className="mt-6">
      <Table dataCy="deviceInfoTable">
        <TableHeader dataCy="deviceInfoTableHeader">
          <TableRow>
            <TableHead borderless>Port</TableHead>
            <TableHead borderless>Account Name</TableHead>
            <TableHead borderless>Account Type</TableHead>
            <TableHead borderless>Last Updated</TableHead>
            <TableHead borderless>Provider</TableHead>
            <TableHead borderless>State</TableHead>
            <TableHead borderless>Test</TableHead>
            <TableHead borderless>Action</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody dataCy="deviceInfoTableBody">
          {lanItems.map((lanItem, index) => {
            return (
              <TableRow
                dataCy={`deviceInfoTableRow-${lanItem?.id}`}
                key={lanItem.id}
                hoverable={false}
              >
                <TableCell dataCy={`deviceInfoTablePortCell-${lanItem?.id}`}>
                  {lanItem.display_name}
                </TableCell>
                <TableCell
                  dataCy={`deviceInfoTableAccountNameCell-${lanItem?.id}`}
                >
                  {accountDetails.name}
                </TableCell>
                <TableCell
                  dataCy={`deviceInfoTableAccountTypeCell-${lanItem?.id}`}
                >
                  {accountDetails.type}
                </TableCell>
                <TableCell
                  dataCy={`deviceInfoTableLastUpdatedCell-${lanItem?.id}`}
                >
                  {lanItem.updated_at}
                </TableCell>
                <TableCell
                  dataCy={`deviceInfoTableProviderCell-${lanItem?.id}`}
                >
                  {lanItem.name}
                </TableCell>
                <TableCell dataCy={`deviceInfoTableStateCell-${lanItem?.id}`}>
                  {lanItem.status === "ACTIVE" ? (
                    <Badge
                      className="rounded-full px-10 uppercase"
                      variant="active"
                      data-cy={`deviceInfoTableStateCell-${lanItem?.id}-badge`}
                    >
                      Active
                    </Badge>
                  ) : (
                    <Badge
                      className="rounded-full px-10 uppercase"
                      variant="in-active"
                      data-cy={`deviceInfoTableStateCell-${lanItem?.id}-badge`}
                    >
                      InActive
                    </Badge>
                  )}
                </TableCell>
                <TableCell
                  dataCy={`deviceInfoTableAccountTestCell-${lanItem?.id}`}
                >
                  <TestServiceNetworkDialog
                    currentDevice={currentDevice}
                    currentSubscription={lanItems[index].Subscriptions[0]}
                    currentPort={lanItems[index]}
                  />
                </TableCell>
                <TableCell
                  className="text-center"
                  dataCy={`deviceInfoTableActionCell-${lanItem?.id}`}
                >
                  <IconButton
                    onClick={() => setCurrentPort(lanItems[index])}
                    aria-label="edit"
                    dataCy={`editLan-${lanItem?.id}`}
                    name="edit"
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {currentPort !== undefined ? (
        <SubscriberLanDetailsDialog
          currentDevice={currentDevice}
          currentPort={currentPort}
          setCurrentPort={setCurrentPort}
        />
      ) : null}
    </div>
  );
}

import { useState } from "react";
import IconButton from "src/components/buttons/IconButton";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";

import ScrollArea from "src/components/scroll-area/ScrollArea";
import { Webhook } from "src/interfaces";
import ManageWebhookDetailsForm from "../manage-webhook/ManageWebhookDetailsForm";

type ManageWebhookDetailsDialogProps = {
  webhook: Webhook;
};

export default function ManageWebhookDetailsDialog({
  webhook,
}: ManageWebhookDetailsDialogProps) {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  return (
    <Dialog
      open={isDialogOpened}
      onOpenChange={(value) => setIsDialogOpened(value)}
    >
      <DialogTrigger asChild>
        <IconButton
          name="view-file"
          dataCy="manageWebhookActionBtn"
          tooltipText={`View Webhook Details`}
          ariaLabel={`View Webhook Details`}
        />
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-4xl font-base"
        dataCy="manageWebhookDialogCnt"
      >
        <DialogHeader dataCy="manageWebhookDialogHeader" borderless>
          <DialogTitle dataCy="manageWebhookDialogTitle">
            Manage {webhook.url}
          </DialogTitle>
        </DialogHeader>
        <ScrollArea className="max-h-[calc(90vh-theme(space.52))] p-1">
          <ManageWebhookDetailsForm
            handleCloseDialog={() => setIsDialogOpened(false)}
            operationType="update"
            webhook={webhook}
          />
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}

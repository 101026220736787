import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";

import {
  FormField,
  FormItem,
  FormControl,
  FormMessage,
  Form,
  FormLabel,
} from "src/components/form/Form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/select/Select";
import LoadingAnimation from "src/components/spinners/LoadingAnimation";
import { toast } from "src/components/toast/useToast";
import { useSession } from "src/hooks/session/useSession";
import { z } from "zod";

const detailsEnums = {
  management: [
    {
      key: "ACCOUNT_LIST",
      label: "Account List",
      value: "/dashboard#/account/list",
    },
    {
      key: "DEVICE_LIST",
      label: "Device List",
      value: "/dashboard#/device/list",
    },
    {
      key: "PROVIDER_LIST",
      label: "Provider List",
      value: "/dashboard#/provider/list",
    },
    {
      key: "ACCOUNT_REPORT",
      label: "Account Report",
      value: "/dashboard#/reports/accounts",
    },
    {
      key: "SUBSCRIBER_LIST",
      label: "Subscriber List",
      value: "/dashboard#/subscriber/list",
    },
    {
      key: "USER_LIST",
      label: "User List",
      value: "/dashboard#/user/list",
    },
  ],
  subscriber: [
    {
      key: "ACCOUNT_DASHBOARD",
      label: "Account Dashboard",
      value: "/dashboard#/account/status",
    },
  ],
  roles: [
    { key: "ADMIN", label: "Administrator" },
    { key: "SERVICE", label: "Service Provider" },
    { key: "AUDIT", label: "Audit" },
    { key: "CSR", label: "Customer Service Representative" },
    { key: "FIELD_INSTALL", label: "Field Installer" },
    { key: "FIELD_TECH", label: "Field Technician" },
    { key: "OFFICE", label: "Office" },
    { key: "PSR", label: "Provider Service Representative" },
    { key: "PROVISION", label: "Provisioning" },
    { key: "SUBSCRIBER", label: "Subscriber" },
    { key: "SYS_SUPPORT", label: "System Support" },
    { key: "VBG", label: "VBG-Radius Access" },
  ],
};

const UserPortalManagementFormScheme = z.object({
  default_url_management_portal: z.string().optional(),
});

export default function UserPortalManagementForm() {
  const { useUser } = useSession();
  const { user, setUser } = useUser;

  const UserPortalManagementForm = useForm<
    z.infer<typeof UserPortalManagementFormScheme>
  >({
    resolver: zodResolver(UserPortalManagementFormScheme),
    mode: "onBlur",
    defaultValues: {
      default_url_management_portal: user?.default_url_management_portal,
    },
  });

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const handleUpdateLocationInformation = (
    data: z.infer<typeof UserPortalManagementFormScheme>,
  ) => {
    setIsProcessing(true);

    if (user) {
      setTimeout(() => {
        setIsProcessing(false);
        setUser({
          ...user,
          default_url_management_portal: data.default_url_management_portal!,
        });
        toast({
          title: "Success",
          description: (
            <>
              Portal details updated successfully!
              <br />
            </>
          ),
        });
      }, 1200);
    }
  };

  return (
    <div>
      <p className="text-base font-medium text-black">
        Select your preferred default screen to view upon login for a
        personalized dashboard experience.
      </p>
      <Form {...UserPortalManagementForm}>
        <form
          onSubmit={UserPortalManagementForm.handleSubmit(
            handleUpdateLocationInformation,
          )}
          className="flex flex-col gap-4 mb-20"
        >
          <FormField
            control={UserPortalManagementForm.control}
            name="default_url_management_portal"
            render={(field) => (
              <FormItem className="w-1/2 pr-2 inline-block mt-3.5">
                <FormLabel>DEFAULT MANAGEMENT URL</FormLabel>
                <Select
                  value={field.field.value}
                  onValueChange={(value) => {
                    UserPortalManagementForm.setValue(field.field.name, value);
                    UserPortalManagementForm.trigger(field.field.name);
                  }}
                >
                  <FormControl>
                    <SelectTrigger
                      data-cy="urlManagementPortalInput"
                      aria-label="Default Management URL"
                      onBlur={() =>
                        UserPortalManagementForm.trigger(field.field.name)
                      }
                    >
                      <SelectValue placeholder="Select a Default URL" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {detailsEnums?.management?.map(
                      (url: { key: string; value: string; label: string }) => (
                        <SelectItem
                          value={url.value}
                          key={url.key}
                          data-cy={`managementUrlOption${url.value}`}
                        >
                          {url.label}
                        </SelectItem>
                      ),
                    )}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <p className="text-1.5xs -mt-2">
            Select your preferred default screen to view upon login for a
            personalized dashboard experience.
          </p>
        </form>
      </Form>
      <EpButton
        styling={EpButtonStyles.PRIMARY}
        dataCy="updatePortalManagementDetailsBtn"
        aria-label="Update portal management details"
        onClick={UserPortalManagementForm.handleSubmit(
          handleUpdateLocationInformation,
        )}
        className="flex gap-1 mt-2"
        disabled={isProcessing}
      >
        {isProcessing && <LoadingAnimation />}
        {isProcessing ? "Saving" : "Save"}
      </EpButton>
    </div>
  );
}

import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import LoadingAnimation from "src/components/spinners/LoadingAnimation";
import { z } from "zod";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";
import {
  FormField,
  FormItem,
  FormControl,
  FormMessage,
  Form,
} from "src/components/form/Form";
import { Input } from "src/components/input/Input";
import IconButton from "src/components/buttons/IconButton";
import WarningIcon from "src/components/icons/WarningIcon";
import { toast } from "src/components/toast/useToast";
import { Webhook } from "src/interfaces/Webhook";

interface RemoveWebhookDialogProps {
  webhook: Webhook;
  removeWebhook: (webhook: Webhook) => void;
}

const RemoveWebhookFormSchema = z
  .object({
    enteredWebhookUrl: z.string(),
    webhookUrl: z.string(),
  })
  .refine((data) => data.webhookUrl === data.enteredWebhookUrl, {
    message: "Entered webhook url doesn’t match",
    path: ["enteredWebhookUrl"],
  });

export default function RemoveWebhookDialog({
  webhook,
  removeWebhook,
}: RemoveWebhookDialogProps) {
  const form = useForm<z.infer<typeof RemoveWebhookFormSchema>>({
    resolver: zodResolver(RemoveWebhookFormSchema),
    mode: "onBlur",
    defaultValues: {
      webhookUrl: webhook.url,
      enteredWebhookUrl: "",
    },
  });

  const handleCloseDialog = () => {
    setIsDialogOpened(!isDialogOpened);
  };

  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  function handleRemoveWebhook(data: z.infer<typeof RemoveWebhookFormSchema>) {
    setIsLoading(true);
    removeWebhook(webhook);
    setTimeout(() => {
      toast({
        title: "Success",
        description: (
          <>
            {data.enteredWebhookUrl} Removed
            <br />
          </>
        ),
      });
      setIsLoading(false);
      handleCloseDialog();
    }, 1500);
  }

  useMemo(() => form.reset(), [isDialogOpened]);

  return (
    <Dialog open={isDialogOpened} onOpenChange={handleCloseDialog}>
      <DialogTrigger asChild>
        <IconButton
          name="remove"
          dataCy="removeWebhookActionBtn"
          tooltipText={`Remove webhook`}
          ariaLabel={`Remove webhook`}
        />
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-[425px] font-base"
        dataCy="removeWebhookDialogCnt"
      >
        <DialogHeader dataCy="removeWebhookDialogHeader" borderless>
          <DialogTitle dataCy="removeWebhookDialogTitle">
            Remove Webhook
          </DialogTitle>
        </DialogHeader>
        <p className="text-sm flex items-center gap-2 text-[#434343] -mt-4">
          <WarningIcon fill="#EC0000" />
          Are you sure you want to remove this webhook?
        </p>
        <p className="text-sm text-red-600 font-semibold">
          Enter <span className="text-black">{webhook.url}</span> below to
          remove the account.
        </p>
        <div className="flex flex-col w-full">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleRemoveWebhook)}
              className="flex flex-col w-full"
            >
              <FormField
                control={form.control}
                name="enteredWebhookUrl"
                render={(field) => (
                  <FormItem className="w-full pr-2 h-10">
                    <FormControl>
                      <Input
                        id="enteredWebhookUrl"
                        placeholder="Enter Webhook URL"
                        data-cy="enteredWebhookUrlInput"
                        type="text"
                        aria-label="Webhook URL"
                        {...field.field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>

        <DialogFooter>
          <EpButton
            styling={EpButtonStyles.DELETE}
            dataCy="removeWebhookBtn"
            aria-label="Remove Webhook"
            className="flex gap-1"
            type="submit"
            onClick={form.handleSubmit(handleRemoveWebhook)}
            disabled={isLoading}
          >
            {isLoading && <LoadingAnimation />}
            {isLoading ? "Processing" : "Remove Webhook"}
          </EpButton>
          <DialogClose asChild className="flex gap-2">
            <EpButton
              type="button"
              styling={EpButtonStyles.CANCEL}
              dataCy="cancelBtn"
              aria-label="Cancel"
            >
              Cancel
            </EpButton>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

import PageContainer from "src/components/page-container/PageContainer";

import SearchFilterer from "src/components/approval/SearchFilterer";
import { useState } from "react";
import { PendingApprovalTable } from "src/components/approval/PendingApprovalTable";

export default function Approval() {
  const [selectedClass, setSelectedClass] = useState<string | undefined>(
    undefined,
  );
  const [selectedProvider, setSelectedProvider] = useState<string | undefined>(
    undefined,
  );

  return (
    <div className="w-full">
      <h2 className="text-primary-color-1000 text-base font-primary">
        Pending Approval
      </h2>
      <PageContainer className="mt-3">
        <SearchFilterer
          setSelectedClass={setSelectedClass}
          setSelectedProvider={setSelectedProvider}
        />
        {selectedClass === undefined && selectedProvider === undefined ? (
          <div className="mt-10 p-10 text-center">
            <p className="font-bold mb-10">
              There are currently 10 approval available for searching
            </p>
            <p>Please enter a search term to load results</p>
          </div>
        ) : (
          <PendingApprovalTable
            selectedClass={selectedClass}
            selectedProvider={selectedProvider}
          />
        )}
      </PageContainer>
    </div>
  );
}

import { Service, Serviceplan } from "src/interfaces";

export const networkOperator = {
  id: "550e8400-e29b-41d4-a716-4466",
  name: "City of Ammon",
  status: "ACTIVE",
  location: "Ammon, ID 834858 United States",
  createdAt: new Date(),
  updatedAt: new Date(),
  type: "Transport",
  autoApprove: "Service, Service Plan",
  description:
    "This is the best provider for the system - The Network Operator.",
  Contacts: [
    {
      id: "93b64126-0f90-11ee-9109-343045423248",
      email: "adaptivetest@gmail.com",
      first_name: "Test",
      last_name: "Test",
      location_id: "93b65c10-0f90-11ee-8e4a-495450543557",
      phone: "(121)-212-1212",
      status: "ACTIVE",
      type: "BILLING",
      created_at: "2023-06-20 11:33:32",
      updated_at: "2023-06-20 11:33:32",
      Location: {
        id: "93b65c10-0f90-11ee-8e4a-495450543557",
        address1: "100 Main St",
        address2: "",
        city: "Atl",
        country: "US",
        elevation: "",
        lat: "",
        lon: "",
        state: "GA",
        status: "ACTIVE",
        uri: "",
        zip: "12345",
        created_at: "2023-06-20 11:33:32",
        updated_at: "2023-06-20 11:33:32",
      },
    },
  ],
  Services: [
    {
      id: "c3fc38a6-cc6c-11ee-b3a0-41304c54534f",
      access_code: "X4QOBS4G",
      account_types: ["RESIDENTIAL"],
      approval_values: {
        status: "None",
        values: [
          "Operator change 1 example",
          "Operator change 2 example",
          "Operator change 3 example",
        ],
        account_types: {
          value: ["RESIDENTIAL"],
          status: "PUBLISHED",
          published_values: ["RESIDENTIAL"],
        },
      },
      description: "This is the Service 2024 in this Automated town.",
      display_type: "PUBLIC",
      image: "",
      ip_range: "0.0.0.0 - 128.0.0.1",
      standByInitiallyOn: "",
      standByEnabled: "",
      marketing_url: "",
      name: "Service 2024",
      network_operator_label: "123",
      provider_id: "7aa49c76-85a3-11ee-b78b-34543646364c",
      servicetype_id: "5f207676-7a21-11ec-9c1e-374742515a4d",
      standby_settings: {
        enabled: "1",
        on_initially: "0",
      },
      ServicType: {
        id: "5f207676-7a21-11ec-9c1e-374742515a4d",
        color: "red",
        description: "Service type descriptiom",
        display: true,
        fields_template_json: "",
        image: "",
        name: "Service Type",
        port_type: "",
        status: "ACTIVE",
      },
      status: "ACTIVE",
      subscription_redirect_url: "",
      support_url: "",
      virtual_system_id: "",
      walled_garden_label: "123",
      created_at: "2024-02-15 18:43:21",
      updated_at: "2024-04-05 17:15:27",
      Provider: {
        id: "7aa49c76-85a3-11ee-b78b-34543646364c",
        auto_approve: [],
        description: "Provider Account for DEV system",
        has_portal: true,
        image: "",
        name: "QA Dev Provider",
        parent_id: "02ced310-5a6d-11eb-bd67-35323941484f",
        status: "ACTIVE",
        type: "SERVICE",
        created_at: "2023-11-20 12:26:07",
        updated_at: "2024-04-05 17:15:31",
        level: "1",
        Contacts: [
          {
            id: "35f1795e-a404-11ed-a815-5138434e3238",
            email: "dherrick@entpnt.com",
            first_name: "Devyn",
            last_name: "Herrick",
            location_id: "35f18bb0-a404-11ed-95de-344437433133",
            phone: "2087168185",
            status: "ACTIVE",
            type: "SUPPORT",
            created_at: "2023-02-03 13:49:11",
            updated_at: "2023-02-03 13:49:11",
          },
          {
            id: "b356d3e8-92f2-11ee-a6fa-475a54303751",
            email: "Testeringss@nopes.test",
            first_name: "Contact",
            last_name: "Testering",
            location_id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
            phone: "8002223333",
            status: "ACTIVE",
            type: "SUPPORT",
            created_at: "2023-12-04 15:15:58",
            updated_at: "2024-03-12 14:06:33",
            Location: {
              id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
              address1: "1000 Drive",
              address2: "",
              city: "Ammon",
              country: "US",
              elevation: "",
              lat: "",
              lon: "",
              state: "ID",
              status: "ACTIVE",
              uri: "",
              zip: "00000",
              created_at: "2023-12-04 15:15:58",
              updated_at: "2023-12-04 15:15:58",
            },
          },
          {
            id: "b356d3e8-92f2-11ee-a6fa-475a54303751",
            email: "Testeringss@nopes.test",
            first_name: "Contact",
            last_name: "Testering",
            location_id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
            phone: "8002223333",
            status: "ACTIVE",
            type: "SHIPPING",
            created_at: "2023-12-04 15:15:58",
            updated_at: "2024-03-12 14:06:33",
            Location: {
              id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
              address1: "1000 Drive",
              address2: "",
              city: "Ammon",
              country: "US",
              elevation: "",
              lat: "",
              lon: "",
              state: "ID",
              status: "ACTIVE",
              uri: "",
              zip: "00000",
              created_at: "2023-12-04 15:15:58",
              updated_at: "2023-12-04 15:15:58",
            },
          },
        ],
      },
    },
  ] as unknown as Service[],
  Plans: [
    {
      id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
      description: "",
      expire_time: "0",
      image: "",
      name: "Basic 5 - 15mbs/15mbs",
      ratings_sum: "",
      service_id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
      status: "ACTIVE",
      total_reviews: "3",
      created_at: "2023-07-10 12:42:08",
      updated_at: "2022-11-30 13:39:41 ",
      fields: {
        LinkSpeed: 0,
        Down: 15,
        Up: 15,
        Price: 5.88,
      },
      approval_values: {
        description: {
          pending_approval_values: ["Details"],
          published_values: ["https://entpnt.com", "https://entpnt.ac.us/"],
        },
      },
      Service: {
        id: "c3fc38a6-cc6c-11ee-b3a0-41304c54534f",
        access_code: "X4QOBS4G",
        account_types: ["RESIDENTIAL"],
        approval_values: {
          status: "None",
          values: [
            "Operator change 1 example",
            "Operator change 2 example",
            "Operator change 3 example",
          ],
          account_types: {
            value: ["RESIDENTIAL"],
            status: "PUBLISHED",
            published_values: ["RESIDENTIAL"],
          },
        },
        description: "This is the Service 2024 in this Automated town.",
        display_type: "PUBLIC",
        image: "",
        ip_range: "0.0.0.0 - 128.0.0.1",
        standByInitiallyOn: "",
        standByEnabled: "",
        marketing_url: "",
        name: "Service 2024",
        network_operator_label: "123",
        provider_id: "7aa49c76-85a3-11ee-b78b-34543646364c",
        servicetype_id: "5f207676-7a21-11ec-9c1e-374742515a4d",
        standby_settings: {
          enabled: "1",
          on_initially: "0",
        },
        ServicType: {
          id: "5f207676-7a21-11ec-9c1e-374742515a4d",
          color: "red",
          description: "Service type descriptiom",
          display: true,
          fields_template_json: "",
          image: "",
          name: "Service Type",
          port_type: "",
          status: "ACTIVE",
        },
        status: "ACTIVE",
        subscription_redirect_url: "",
        support_url: "",
        virtual_system_id: "",
        walled_garden_label: "123",
        created_at: "2024-02-15 18:43:21",
        updated_at: "2024-04-05 17:15:27",
        Provider: {
          id: "7aa49c76-85a3-11ee-b78b-34543646364c",
          auto_approve: [],
          description: "Provider Account for DEV system",
          has_portal: true,
          image: "",
          name: "QA Dev Provider",
          parent_id: "02ced310-5a6d-11eb-bd67-35323941484f",
          status: "ACTIVE",
          type: "SERVICE",
          created_at: "2023-11-20 12:26:07",
          updated_at: "2024-04-05 17:15:31",
          level: "1",
          Contacts: [
            {
              id: "35f1795e-a404-11ed-a815-5138434e3238",
              email: "dherrick@entpnt.com",
              first_name: "Devyn",
              last_name: "Herrick",
              location_id: "35f18bb0-a404-11ed-95de-344437433133",
              phone: "2087168185",
              status: "ACTIVE",
              type: "SUPPORT",
              created_at: "2023-02-03 13:49:11",
              updated_at: "2023-02-03 13:49:11",
            },
            {
              id: "b356d3e8-92f2-11ee-a6fa-475a54303751",
              email: "Testeringss@nopes.test",
              first_name: "Contact",
              last_name: "Testering",
              location_id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
              phone: "8002223333",
              status: "ACTIVE",
              type: "SUPPORT",
              created_at: "2023-12-04 15:15:58",
              updated_at: "2024-03-12 14:06:33",
              Location: {
                id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
                address1: "1000 Drive",
                address2: "",
                city: "Ammon",
                country: "US",
                elevation: "",
                lat: "",
                lon: "",
                state: "ID",
                status: "ACTIVE",
                uri: "",
                zip: "00000",
                created_at: "2023-12-04 15:15:58",
                updated_at: "2023-12-04 15:15:58",
              },
            },
            {
              id: "b356d3e8-92f2-11ee-a6fa-475a54303751",
              email: "Testeringss@nopes.test",
              first_name: "Contact",
              last_name: "Testering",
              location_id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
              phone: "8002223333",
              status: "ACTIVE",
              type: "SHIPPING",
              created_at: "2023-12-04 15:15:58",
              updated_at: "2024-03-12 14:06:33",
              Location: {
                id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
                address1: "1000 Drive",
                address2: "",
                city: "Ammon",
                country: "US",
                elevation: "",
                lat: "",
                lon: "",
                state: "ID",
                status: "ACTIVE",
                uri: "",
                zip: "00000",
                created_at: "2023-12-04 15:15:58",
                updated_at: "2023-12-04 15:15:58",
              },
            },
          ],
        },
      },
    },
  ] as unknown as Serviceplan[],
};

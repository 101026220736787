import { Input } from "src/components/input/Input";

export default function GlobalPlanValues({
  globalPlanValuesState,
  setGlobalPlanValuesState,
}: {
  globalPlanValuesState: Record<string, boolean>;
  setGlobalPlanValuesState: (values: Record<string, boolean>) => void;
}) {
  const values = [
    "NAME",
    "STATUS",
    "DESCRIPITON",
    "DISPLAY TYPE",
    "ACCESS CODE",
    "ACCOUNT TYPES",
    "IP RANGE",
    "MARKETING URL",
    "SUBSCRIPTION REDIRECT URL",
    "SUPPORT URL",
    "STAND BY ENABLED",
    "STAND BY - INITAILLY ON",
  ];

  return (
    <div className="flex flex-col gap-3 mt-4">
      <span className="text-xs font-base mb-1 font-inter">
        GLOBAL PLAN VALUES
      </span>
      <div data-cy="globalPlanValuesSection" className="grid grid-cols-4 gap-2">
        {values.map((value) => {
          return (
            <div key={value} className="flex justify-start gap-2 items-center">
              <Input
                type="checkbox"
                checked={globalPlanValuesState[value]}
                aria-label={value.toLowerCase()}
                dataCy={`${value}-checkbox`}
                id={value}
                onChange={(e) => {
                  setGlobalPlanValuesState({
                    ...globalPlanValuesState,
                    [value]: e.currentTarget.checked,
                  });
                }}
              />
              <label
                className="text-1.5xs font-semibold font-inter"
                htmlFor={value}
              >
                {value}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

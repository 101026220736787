import { SVGProps } from "react";

const PDFIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.56775 4.08329C2.56775 3.30191 3.18495 2.66663 3.94409 2.66663H7.38495V5.49996C7.38495 5.89176 7.69248 6.20829 8.07313 6.20829H10.8258V9.39579H6.3527C5.59356 9.39579 4.97635 10.0311 4.97635 10.8125V14H3.94409C3.18495 14 2.56775 13.3647 2.56775 12.5833V4.08329ZM10.8258 5.49996H8.07313V2.66663L10.8258 5.49996ZM6.3527 10.4583H7.04087C7.70538 10.4583 8.24517 11.0139 8.24517 11.6979C8.24517 12.3819 7.70538 12.9375 7.04087 12.9375H6.69678V13.6458C6.69678 13.8406 6.54194 14 6.3527 14C6.16345 14 6.00861 13.8406 6.00861 13.6458V10.8125C6.00861 10.6177 6.16345 10.4583 6.3527 10.4583ZM7.04087 12.2291C7.32689 12.2291 7.557 11.9923 7.557 11.6979C7.557 11.4035 7.32689 11.1666 7.04087 11.1666H6.69678V12.2291H7.04087ZM9.10538 10.4583H9.79356C10.3634 10.4583 10.8258 10.9342 10.8258 11.5208V12.9375C10.8258 13.524 10.3634 14 9.79356 14H9.10538C8.91614 14 8.7613 13.8406 8.7613 13.6458V10.8125C8.7613 10.6177 8.91614 10.4583 9.10538 10.4583ZM9.79356 13.2916C9.9828 13.2916 10.1376 13.1323 10.1376 12.9375V11.5208C10.1376 11.326 9.9828 11.1666 9.79356 11.1666H9.44947V13.2916H9.79356ZM11.514 10.8125C11.514 10.6177 11.6688 10.4583 11.8581 10.4583H12.8903C13.0796 10.4583 13.2344 10.6177 13.2344 10.8125C13.2344 11.0073 13.0796 11.1666 12.8903 11.1666H12.2022V11.875H12.8903C13.0796 11.875 13.2344 12.0343 13.2344 12.2291C13.2344 12.4239 13.0796 12.5833 12.8903 12.5833H12.2022V13.6458C12.2022 13.8406 12.0473 14 11.8581 14C11.6688 14 11.514 13.8406 11.514 13.6458V10.8125Z"
        fill="inherit"
      />
    </svg>
  );
};

export default PDFIcon;

export default function Footer() {
  return (
    <footer className="flex flex-col text-2xs font-semibold bottom-0 text-black p-2 w-full z-10 text-center">
      Powered By
      <a
        href="https://entpnt.com/"
        className="text-neutrals-form-gray-dark ml-0.5"
      >
        EntryPoint Networks
      </a>
    </footer>
  );
}

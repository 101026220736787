import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/form/Form";
import { Input } from "src/components/input/Input";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/select/Select";
import { WebhookHistory } from "src/interfaces";
import { formatDate } from "src/utils/date";
import { z } from "zod";
import webhookHistory from "../__mock__";

const WebhookHistorySearchFiltererSchema = z.object({
  provider: z.string().optional(),
  service: z.string().optional(),
  service_plan: z.string().optional(),
  start_date: z.string().optional(),
  end_date: z.string().optional(),
});

type WebhookHistorySearchFiltererProps = {
  setFilteredWebhookHistory: (value: WebhookHistory[]) => void;
};

export default function WebhookHistorySearchFilterer({
  setFilteredWebhookHistory,
}: WebhookHistorySearchFiltererProps) {
  const form = useForm<z.infer<typeof WebhookHistorySearchFiltererSchema>>({
    resolver: zodResolver(WebhookHistorySearchFiltererSchema),
    defaultValues: {
      start_date: formatDate(
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7),
      ),
      end_date: formatDate(new Date()),
      provider: "",
      service_plan: "",
      service: "",
    },
  });

  const webhookHistoryList = new Array(20)
    .fill(webhookHistory, 0, 20)
    .map((item, idx) => {
      return { ...item, id: String(idx + 1) };
    }) as WebhookHistory[];

  function handleShortCut(days: number) {
    const differenceInMilleSeconds =
      new Date().getTime() - 1000 * 60 * 60 * 24 * days;
    form.setValue("start_date", formatDate(new Date(differenceInMilleSeconds)));
  }

  function handleSearch(
    data: z.infer<typeof WebhookHistorySearchFiltererSchema>,
  ) {
    console.log({ data });
    setFilteredWebhookHistory(webhookHistoryList);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSearch)} className="w-full">
        <div className="flex flex-col gap-2 bg-neutrals-hover-color w-full p-3 rounded-md">
          <div className="flex gap-4">
            <FormField
              control={form.control}
              name="provider"
              render={(field) => (
                <FormItem>
                  <FormLabel>PROVIDERS</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      form.trigger();
                      form.setValue(field.field.name, value);
                    }}
                    value={field.field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        id="providers_selector"
                        data-cy="filterProviders"
                        className="w-[300px]"
                      >
                        <SelectValue placeholder="Select Provider" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem
                          data-cy="allProviders"
                          value="All Providers"
                        >
                          All Providers
                        </SelectItem>
                        <SelectItem
                          data-cy="devLabProovider"
                          value="Dev Lab Provider"
                        >
                          Dev Lab Provider
                        </SelectItem>
                        <SelectItem data-cy="ent" value="Ent">
                          Ent
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="service"
              render={(field) => (
                <FormItem>
                  <FormLabel>SERVICES</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      form.trigger();
                      form.setValue(field.field.name, value);
                    }}
                    disabled={!form.getValues("provider")}
                    value={field.field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        id="service_selector"
                        data-cy="filterService"
                        className="w-[300px]"
                      >
                        <SelectValue placeholder="Select Service" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem data-cy="allServices" value="All Services">
                          All Approvals
                        </SelectItem>
                        <SelectItem
                          data-cy="exampleService1"
                          value="Example Service 1"
                        >
                          Example Service 1
                        </SelectItem>
                        <SelectItem
                          data-cy="exampleService2"
                          value="Example Service 2"
                        >
                          Example Service 2
                        </SelectItem>
                        <SelectItem
                          data-cy="exampleService3"
                          value="Example Service 3"
                        >
                          Example Service 3
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="service_plan"
              render={(field) => (
                <FormItem>
                  <FormLabel>SERVICE PLANS</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      form.trigger();
                      form.setValue(field.field.name, value);
                    }}
                    disabled={
                      !form.getValues("provider") || !form.getValues("service")
                    }
                    value={field.field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        id="service_plan_selector"
                        data-cy="filterServicePlan"
                        className="w-[300px]"
                      >
                        <SelectValue placeholder="Select Service Plan" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem
                          data-cy="allServicePlans"
                          value="All Service Plans"
                        >
                          All Service Plans
                        </SelectItem>
                        <SelectItem
                          data-cy="exampleServicePlan1"
                          value="Example Service Plan 1"
                        >
                          Example Service Plan 1
                        </SelectItem>
                        <SelectItem
                          data-cy="exampleServicePlan2"
                          value="Example Service Plan 2"
                        >
                          Example Service Plan 2
                        </SelectItem>
                        <SelectItem
                          data-cy="exampleService3"
                          value="Example Service Plan 3"
                        >
                          Example Service Plan 3
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="flex items-start">
            <div className="flex gap-10">
              <FormField
                control={form.control}
                name="start_date"
                render={(field) => (
                  <FormItem className="w-1/3 pr-2 mb-4 inline-block">
                    <FormLabel>START DATE</FormLabel>
                    <FormControl>
                      <Input
                        id="start_date"
                        data-cy="filter-start-date"
                        type="date"
                        className="w-fit"
                        {...field.field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="end_date"
                render={(field) => (
                  <FormItem className="w-1/3 pr-2 mb-4 inline-block">
                    <FormLabel>END DATE</FormLabel>
                    <FormControl>
                      <Input
                        id="end_date"
                        data-cy="filter-end-date"
                        type="date"
                        className="w-fit"
                        {...field.field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex flex-col">
              <span className="text-[0.625rem] font-secondary">SHORTCUTS</span>
              <div className="flex max-w-[200px] flex-wrap gap-1">
                <EpButton
                  aria-label="Today"
                  dataCy="todayDateShortcutBtn"
                  styling={EpButtonStyles.SECONDARY}
                  type="button"
                  onClick={() => handleShortCut(0)}
                >
                  Today
                </EpButton>
                <EpButton
                  aria-label="7 Days"
                  dataCy="7daysShortcutBtn"
                  styling={EpButtonStyles.SECONDARY}
                  type="button"
                  onClick={() => handleShortCut(7)}
                >
                  7 days
                </EpButton>
                <EpButton
                  aria-label="30 Days"
                  dataCy="30daysShortcutBtn"
                  styling={EpButtonStyles.SECONDARY}
                  type="button"
                  onClick={() => handleShortCut(30)}
                >
                  30 Days
                </EpButton>
                <EpButton
                  aria-label="60 Days"
                  dataCy="60daysShortcutBtn"
                  styling={EpButtonStyles.SECONDARY}
                  type="button"
                  onClick={() => handleShortCut(60)}
                >
                  60 Days
                </EpButton>
                <EpButton
                  aria-label="Prev Month"
                  dataCy="prevMonthShortcutBtn"
                  styling={EpButtonStyles.SECONDARY}
                  type="button"
                  onClick={() => handleShortCut(30)}
                >
                  Prev Month
                </EpButton>
              </div>
            </div>
          </div>
          <EpButton
            dataCy="searchButton"
            type="submit"
            className="w-fit ml-auto"
            aria-label="Search"
          >
            Search
          </EpButton>
        </div>
      </form>
    </Form>
  );
}

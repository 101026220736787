import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import { DialogClose } from "src/components/dialog/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/form/Form";
import { Input } from "src/components/input/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/select/Select";
import { Textarea } from "src/components/textarea/Textarea";
import { formatDateToHumanReadableISOString } from "src/utils/date";
import { z } from "zod";
import { NetworkOperator } from "../list/NetworkOperatorsList";
import { toast } from "src/components/toast/useToast";
import ScrollArea from "src/components/scroll-area/ScrollArea";

const STATUS = [
  "Active",
  "Inactive",
  "Locked",
  "Paused",
  "Pending",
  "Provision",
  "Repaired",
  "Replaced",
  "RMA",
  "Standby",
  "Suspended",
];

const TYPES = [
  "Residential",
  "Billing",
  "Sales",
  "Support",
  "Service",
  "Shipping",
  "Howzart",
  "Minimal",
  "Maximal",
  "Effort",
];

const AUTO_APPROVE = [
  "Service",
  "Service Plan",
  "Residential",
  "Billing",
  "Sales",
  "Support",
  "Shipping",
  "Howzart",
];

const ManageNetworkOperatorDetailsFormSchema = z.object({
  name: z.string().min(1, {
    message: "Please enter a valid name",
  }),
  status: z.string().nonempty({ message: "Required" }),
  id: z.string().readonly(),
  description: z.string().min(4, {
    message: "Please enter a valid description",
  }),
  type: z.string().nonempty({ message: "Required" }),
  autoApprove: z.string().nonempty({ message: "Required" }),
});

export default function ManageNetworkOperatorDetailsForm({
  networkOperatorDetails,
}: {
  networkOperatorDetails: NetworkOperator;
}) {
  const ManageGlobalServiceDetailsForm = useForm<
    z.infer<typeof ManageNetworkOperatorDetailsFormSchema>
  >({
    resolver: zodResolver(ManageNetworkOperatorDetailsFormSchema),
    mode: "onBlur",
    defaultValues: {
      ...networkOperatorDetails,
    },
  });

  function handleManageNetworkOperatorDetailsSave(
    data: z.infer<typeof ManageNetworkOperatorDetailsFormSchema>,
  ) {
    console.log({ data });
    toast({
      title: "Success",
      description: (
        <>
          Saved successfully
          <br />
        </>
      ),
    });
  }

  return (
    <ScrollArea className="max-h-[calc(80vh-theme(space.52))] p-1">
      <Form {...ManageGlobalServiceDetailsForm}>
        <div className="grid grid-rows-1 grid-cols-2 my-4 text-neutral-500 font-secondary text-xs">
          <span>
            CREATED:{" "}
            {formatDateToHumanReadableISOString(
              networkOperatorDetails.createdAt,
            )}
          </span>
          <span>
            UPDATED:{" "}
            {formatDateToHumanReadableISOString(
              networkOperatorDetails.updatedAt,
            )}
          </span>
        </div>
        <form
          onSubmit={ManageGlobalServiceDetailsForm.handleSubmit(
            handleManageNetworkOperatorDetailsSave,
          )}
        >
          <div className="grid grid-cols-2 gap-2 grid-rows-1">
            <FormField
              control={ManageGlobalServiceDetailsForm.control}
              name="name"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>NAME</FormLabel>
                  <FormControl>
                    <Input
                      autoFocus
                      id="name"
                      placeholder="Name"
                      data-cy="nameInput"
                      type="text"
                      aria-label="Name"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={ManageGlobalServiceDetailsForm.control}
              name="id"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>ID</FormLabel>
                  <FormControl>
                    <Input
                      readOnly
                      id="id"
                      placeholder="ID"
                      data-cy="idInput"
                      type="text"
                      aria-label="ID"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="grid grid-cols-3 gap-2 grid-rows-1">
            <FormField
              control={ManageGlobalServiceDetailsForm.control}
              name="status"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>STATUS</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      ManageGlobalServiceDetailsForm.setValue(
                        field.field.name,
                        value,
                      );
                      ManageGlobalServiceDetailsForm.trigger(field.field.name);
                    }}
                    value={field.field?.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        aria-label="Service status"
                        data-cy="operator-status-select"
                        onBlur={() =>
                          ManageGlobalServiceDetailsForm.trigger(
                            field.field.name,
                          )
                        }
                      >
                        <SelectValue>{field.field?.value}</SelectValue>
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {STATUS.map((stat) => (
                        <SelectItem
                          key={stat}
                          value={stat}
                          data-cy={`operator-status-${stat}`}
                        >
                          {stat}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={ManageGlobalServiceDetailsForm.control}
              name="type"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>TYPE</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      ManageGlobalServiceDetailsForm.setValue(
                        field.field.name,
                        value,
                      );
                      ManageGlobalServiceDetailsForm.trigger(field.field.name);
                    }}
                    value={field.field?.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        aria-label="Type"
                        data-cy="operator-type-select"
                        onBlur={() =>
                          ManageGlobalServiceDetailsForm.trigger(
                            field.field.name,
                          )
                        }
                      >
                        <SelectValue>{field.field?.value}</SelectValue>
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {TYPES.map((type) => (
                        <SelectItem
                          key={type}
                          value={type}
                          data-cy={`operator-type-${type}`}
                        >
                          {type}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={ManageGlobalServiceDetailsForm.control}
              name="autoApprove"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>AUTO APPROVE</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      ManageGlobalServiceDetailsForm.setValue(
                        field.field.name,
                        value,
                      );
                      ManageGlobalServiceDetailsForm.trigger(field.field.name);
                    }}
                    value={field.field?.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        aria-label="Auto Approve"
                        data-cy="network-operator-auto-approve-select"
                        onBlur={() =>
                          ManageGlobalServiceDetailsForm.trigger(
                            field.field.name,
                          )
                        }
                      >
                        <SelectValue>{field.field?.value}</SelectValue>
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {AUTO_APPROVE.map((type) => (
                        <SelectItem
                          key={type}
                          value={type}
                          data-cy={`network-operator-auto-approve-type-${type}`}
                        >
                          {type}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={ManageGlobalServiceDetailsForm.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>DESCRIPTION *</FormLabel>
                <FormControl>
                  <Textarea
                    required
                    placeholder="Description"
                    data-cy="descriptionInput"
                    aria-label="Operator Description"
                    className={"h-[90px] text-neutral-700"}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <span className="text-xs font-base block mt-5">IMAGE</span>
          <p className="text-xs text-neutrals-form-gray-dark mt-2">
            To set an image, send the image to the Network Operator so they can
            upload it to their system.
          </p>
          <div className="flex items-center justify-between mt-8">
            <EpButton
              aria-label="Save Operator details"
              dataCy="saveOperatorDetailsBtn"
            >
              Save
            </EpButton>
            <DialogClose asChild className="flex gap-2 ml-auto">
              <EpButton
                type="button"
                styling={EpButtonStyles.CANCEL}
                dataCy="cancelBtn"
                aria-label="cancel"
              >
                Cancel
              </EpButton>
            </DialogClose>
          </div>
        </form>
      </Form>
    </ScrollArea>
  );
}

import * as React from "react";
import {
  Table,
  Header,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell,
  Footer,
  FooterRow,
  FooterCell,
} from "@table-library/react-table-library/table";
import * as ReportsTableTypes from "@table-library/react-table-library/types/table";
import { Theme, useTheme } from "@table-library/react-table-library/theme";

export const themeConfig: Theme = {
  Header: `
    color: #6D6D6D;
    `,
  HeaderRow: `
    background-color: #F0F0F0;
    `,
  HeaderCell: `
    display: flex;
    padding: 14px;
    align-items: center;
    font-family: Inter;
    font-weight: 600;
    line-height: 20px;
    font-size: 16px;
    color: #434343;
    &:first-of-type{
      border-top-left-radius: 4px;
    }
    &:last-child{
      border-top-right-radius: 4px;
    }
    min-height: 50px;
    `,
  Row: `
    font-size: 12px;
    font-family: Inter;

    &:nth-of-type(odd) {
        background-color: #FFFFFF;
    }
    
    &:nth-of-type(even) {
        background-color: #FAFAFA;
    }
    &:last-child {
      td{
        border-bottom: 1px solid #0000001F;
      }
    }    
    `,
  Cell: `
    padding: 14px 16px;
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #434343;
    border-top: 1px solid #0000001F;
    min-height: 50px;
   `,
  Footer: ``,
  FooterRow: ``,
  FooterCell: ``,
};

const theme = useTheme(themeConfig);

const ReportsTable = React.forwardRef<
  ReportsTableTypes.TableProps<ReportsTableTypes.TableNode>,
  ReportsTableTypes.TableProps<ReportsTableTypes.TableNode>
>(({ ...props }, ref) => {
  return <Table ref={ref} theme={theme} {...props} />;
});
ReportsTable.displayName = "ReportsTable";

// Header
const ReportsTableHeader = React.forwardRef<
  ReportsTableTypes.HeaderProps,
  ReportsTableTypes.HeaderProps
>(({ children, ...props }, ref) => (
  <Header ref={ref} {...props}>
    {children}
  </Header>
));
ReportsTableHeader.displayName = "ReportsTableHeader";

const ReportsTableHeaderRow = React.forwardRef<
  ReportsTableTypes.HeaderRowProps,
  ReportsTableTypes.HeaderRowProps
>(({ children, ...props }, ref) => (
  <HeaderRow ref={ref} {...props}>
    {children}
  </HeaderRow>
));
ReportsTableHeaderRow.displayName = "ReportsTableHeaderRow";

const ReportsTableHeaderCell = React.forwardRef<
  ReportsTableTypes.HeaderRowProps,
  ReportsTableTypes.HeaderRowProps
>(({ children, ...props }, ref) => (
  <HeaderCell ref={ref} {...props}>
    {children}
  </HeaderCell>
));
ReportsTableHeaderCell.displayName = "ReportsTableHeaderCell";

// Body
const ReportsTableBody = React.forwardRef<
  ReportsTableTypes.BodyProps,
  ReportsTableTypes.BodyProps
>(({ children, ...props }, ref) => (
  <Body ref={ref} {...props}>
    {children}
  </Body>
));
ReportsTableBody.displayName = "ReportsTableBody";

const ReportsTableRow = React.forwardRef<
  ReportsTableTypes.RowProps<ReportsTableTypes.TableNode>,
  ReportsTableTypes.RowProps<ReportsTableTypes.TableNode>
>((props, ref) => <Row ref={ref} {...props} />);
ReportsTableRow.displayName = "ReportsTableRow";

const ReportsTableCell = React.forwardRef<
  ReportsTableTypes.CellProps,
  ReportsTableTypes.CellProps
>((props, ref) => {
  return (
    <Cell ref={ref} {...props}>
      {props.children}
    </Cell>
  );
});
ReportsTableCell.displayName = "ReportsTableCell";

// Footer
const ReportsTableFooter = React.forwardRef<
  ReportsTableTypes.HeaderProps,
  ReportsTableTypes.HeaderProps
>((props, ref) => <Footer ref={ref} {...props} />);
ReportsTableFooter.displayName = "ReportsTableFooter";

const ReportsTableFooterRow = React.forwardRef<
  ReportsTableTypes.HeaderRowProps,
  ReportsTableTypes.HeaderRowProps
>((props, ref) => <FooterRow ref={ref} {...props} />);
ReportsTableFooterRow.displayName = "ReportsTableFooterRow";

const ReportsTableFooterCell = React.forwardRef<
  ReportsTableTypes.HeaderCellProps,
  ReportsTableTypes.HeaderCellProps
>((props, ref) => (
  <FooterCell ref={ref} {...props}>
    {props.children}
  </FooterCell>
));
ReportsTableFooterCell.displayName = "ReportsTableFooterCell";

export {
  ReportsTable,
  ReportsTableHeader,
  ReportsTableHeaderRow,
  ReportsTableHeaderCell,
  ReportsTableBody,
  ReportsTableRow,
  ReportsTableCell,
  ReportsTableFooter,
  ReportsTableFooterRow,
  ReportsTableFooterCell,
};

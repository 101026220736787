import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/table/Table";

import ScrollArea from "src/components/scroll-area/ScrollArea";
import { DialogClose } from "@radix-ui/react-dialog";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import { Service } from "src/interfaces";
import { Dot } from "src/components/dot/Dot";
import ClickableTooltip from "src/components/clickable-tooltip/ClickableTooltip";
import ManageNetworkOperatorActions from "../manage-network-operator-services/ManageNetworkOperatorActions";

export default function ManageNetworkOperatorServices({
  services,
}: {
  services: Service[];
}) {
  const handleActionsCellClick = (
    event: React.MouseEvent<HTMLTableCellElement>,
  ) => {
    event.stopPropagation();
  };

  function renderTableBody() {
    if (services.length < 1) {
      return (
        <TableRow className="font-medium" dataCy={`noRecordsRow`}>
          <TableCell dataCy={`noRecordsTextCell`}>
            <p className="text-neutral-600 text-sm">No records found</p>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <>
        {services.map((service) => (
          <TableRow
            key={service.id}
            className="font-medium"
            dataCy={`${service.id}networkOperatorServicesRow`}
          >
            <TableCell dataCy={`${service.id}networkOperatorServicesNameCell`}>
              <div className="flex items-center gap-1">
                {service.status === "ACTIVE" ? (
                  <Dot className="h-2 w-2" variant={"active"} />
                ) : (
                  <Dot className="h-2 w-2" variant={"inactive"} />
                )}
                {service.name}
              </div>
            </TableCell>
            <TableCell
              dataCy={`${service.id}networkOperatorServicesServiceTypeCell`}
            >
              {service.ServicType?.name}
            </TableCell>
            <TableCell
              dataCy={`${service.id}networkOperatorServicesDisplayTypeCell`}
            >
              {service.display_type}
            </TableCell>
            <TableCell
              dataCy={`${service.id}networkOperatorServicesAccountTypesCell`}
            >
              {service.account_types[0]}
            </TableCell>
            <TableCell
              dataCy={`${service.id}networkOperatorServicesPendingApprovalsCell`}
            >
              <ClickableTooltip
                header={<p>Has Approval Values</p>}
                icon={
                  <span className="hover:underline hover:text-blue-700">
                    {service.approval_values.status}
                  </span>
                }
                content={
                  <div className="py-2">
                    <span className="font-bold">Operator changes</span>
                    <ul
                      data-cy={`${service.id}networkOperatorServicesPendingApprovalsToolTipContent`}
                      className="flex flex-col py-1 font-base"
                    >
                      {service.approval_values.values.map((value: string) => {
                        return (
                          <li
                            key={value}
                            className="ml-2"
                            data-cy={`${service.id}-${value}-networkOperatorServicesPendingApprovalsToolTipContentItem`}
                          >
                            &bull; <span className="font-medium">{value}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                }
                dataCy={`contactInformationTooltip-${service.id}`}
                className="w-[290px]"
              />
            </TableCell>
            <TableCell
              className="text-center"
              onClick={handleActionsCellClick}
              dataCy={`${service.id}networkOperatorServicesActionsCell`}
            >
              <ManageNetworkOperatorActions service={service} />
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  }

  return (
    <>
      <ScrollArea className="max-h-[calc(85vh-theme(space.52))] my-4 p-0">
        <Table dataCy="networkOperatorServicesTable">
          <TableHeader
            dataCy="networkOperatorServicesTableHeader"
            className="sticky top-0"
          >
            <TableRow dataCy="networkOperatorServicesTableHeaderRow">
              <TableHead className="text-left">Name</TableHead>
              <TableHead className="text-left">Service Type</TableHead>
              <TableHead className="text-left">Display Type</TableHead>
              <TableHead className="text-left">Account Type</TableHead>
              <TableHead className="text-left">Pending Approvals</TableHead>
              <TableHead className="text-center w-[90px]">Action</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody dataCy="networkOperatorServicesTableBody">
            {renderTableBody()}
          </TableBody>
        </Table>
      </ScrollArea>
      <div className="flex items-center justify-between mt-6">
        <EpButton type="button" dataCy="saveBtn" aria-label="Save">
          Save
        </EpButton>
        <DialogClose asChild className="flex gap-2 ml-auto">
          <EpButton
            type="button"
            styling={EpButtonStyles.CANCEL}
            dataCy="cancelBtn"
            aria-label="cancel"
          >
            Cancel
          </EpButton>
        </DialogClose>
      </div>
    </>
  );
}

import IconButton from "src/components/buttons/IconButton";
import { toast } from "src/components/toast/useToast";

import RemoveNetworkOperatorDialog from "../actions/RemoveNetworkOperatorDialog";
import { NetworkOperator } from "./NetworkOperatorsList";
import ManageNetworkOperatorDialog from "../actions/ManageNetworkOperatorDialog";

interface NetworkOperatorActionDialogProps {
  operator: NetworkOperator;
  removeNetworkOperator: (operator: NetworkOperator) => void;
}

export default function NetworkOperatorActionCells({
  operator,
  removeNetworkOperator,
}: NetworkOperatorActionDialogProps) {
  async function handleCopy() {
    try {
      await navigator.clipboard.writeText(operator.id);
      toast({
        title: "Success",
        description: (
          <>
            Copied successfully!
            <br />
          </>
        ),
      });
    } catch (error) {
      toast({
        title: "Error",
        description: (
          <>
            Failed to copy, Please try again!
            <br />
          </>
        ),
      });
    }
  }
  return (
    <div className="flex justify-end gap-x-2">
      <ManageNetworkOperatorDialog networkOperator={operator} />
      <IconButton
        name="copy"
        dataCy="copyOperatorNameBtn"
        tooltipText={`Copy network operator ID`}
        ariaLabel={`Copy network operator ID`}
        onClick={handleCopy}
      />
      <RemoveNetworkOperatorDialog
        removeNetworkOperator={removeNetworkOperator}
        operator={operator}
      />
    </div>
  );
}

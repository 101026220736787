import { zodResolver } from "@hookform/resolvers/zod";
import { ChevronRight } from "lucide-react";
import { useForm } from "react-hook-form";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import { DialogClose, DialogFooter } from "src/components/dialog/Dialog";
import {
  FormField,
  FormItem,
  FormControl,
  FormMessage,
  Form,
} from "src/components/form/Form";
import { Input } from "src/components/input/Input";
import { User } from "src/interfaces/User";
import { z } from "zod";

const CurrentUsernameFormScheme = z
  .object({
    currentUsername: z.string(),
    enteredCurrentUsername: z.string(),
  })
  .refine((data) => data.enteredCurrentUsername === data.currentUsername, {
    message: "The username you have entered is incorrect.",
    path: ["enteredCurrentUsername"],
  });

interface CurrentUsernameFormProps {
  user: User | null;
  setIsCurrentNameValid: (value: boolean) => void;
}

export default function CurrentUsernameForm({
  user,
  setIsCurrentNameValid,
}: CurrentUsernameFormProps) {
  const currentUserForm = useForm<z.infer<typeof CurrentUsernameFormScheme>>({
    resolver: zodResolver(CurrentUsernameFormScheme),
    mode: "onBlur",
    defaultValues: {
      currentUsername: user?.username,
      enteredCurrentUsername: "",
    },
  });

  const handleValidateEnteredUserName = () => {
    setIsCurrentNameValid(true);
  };

  return (
    <>
      <p className="text-sm text-[#434343] -mt-4">
        Please enter your current username.
      </p>
      <div className="flex flex-col w-full">
        <Form {...currentUserForm}>
          <form
            onSubmit={currentUserForm.handleSubmit(
              handleValidateEnteredUserName,
            )}
            className="flex flex-col w-full"
          >
            <FormField
              control={currentUserForm.control}
              name="enteredCurrentUsername"
              render={(field) => (
                <FormItem className="w-full pr-2 mb-4">
                  <FormControl>
                    <Input
                      id="enteredCurrentUsername"
                      placeholder="Current Username"
                      data-cy="currentUsernameInput"
                      type="text"
                      aria-label="Current Username"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
      </div>
      <DialogFooter>
        <DialogClose asChild className="flex gap-2">
          <EpButton
            type="button"
            styling={EpButtonStyles.CANCEL}
            dataCy="cancelBtn"
            aria-label="cancel"
          >
            Cancel
          </EpButton>
        </DialogClose>

        <EpButton
          styling={EpButtonStyles.PRIMARY}
          dataCy="validateUsernameBtn"
          aria-label="Next"
          onClick={currentUserForm.handleSubmit(handleValidateEnteredUserName)}
          className="flex gap-1"
          type="submit"
        >
          Next <ChevronRight />
        </EpButton>
      </DialogFooter>
    </>
  );
}

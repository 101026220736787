import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/table/Table";
import WebhooksTableRow from "./WebhooksTableRow";
import { Webhook } from "src/interfaces";
import webhook from "../__mock__";
import { useEffect, useState } from "react";
import Pagination from "src/components/pagination";
import Loading from "src/components/loading/InitialLoading";
import ScrollArea from "src/components/scroll-area/ScrollArea";

type WebhooksTableProps = {
  selectedService: string | undefined;
  selectedServicePlan: string | undefined;
};

const webhookPageSize = 8;

export function WebhooksTable({
  selectedService,
  selectedServicePlan,
}: WebhooksTableProps) {
  const mockWebhooksData: Array<Webhook> = new Array(40)
    .fill(webhook, 0, 40)
    .map((webhook, index) => {
      return {
        ...webhook,
        id: webhook.id + index,
      };
    })
    .filter(() => {
      if (selectedService || selectedServicePlan) {
        return true;
      }
      return false;
    });

  const [lastPage, setLastPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [webhooks, setWebhooks] = useState<Webhook[]>(mockWebhooksData);

  const handlePageChange = async (page: number = 1) => {
    setIsLoading(true);

    const maxNumberOfPages = Math.ceil(
      mockWebhooksData.length / webhookPageSize,
    );
    // perform client side pagination.
    const startIndex = (page - 1) * webhookPageSize;
    const endIndex = startIndex + webhookPageSize;
    const paginatedResults = mockWebhooksData.slice(startIndex, endIndex);

    setLastPage(maxNumberOfPages);
    setWebhooks(paginatedResults);
    setIsLoading(false);
  };

  const handleSelectPage = (page: number) => {
    setCurrentPage(page);
    handlePageChange(page);
  };

  useEffect(() => {
    handlePageChange();
  }, []);

  function removeWebhook(webhook: Webhook) {
    console.log({ webhook });
  }

  if (isLoading) {
    return <Loading></Loading>;
  }

  if (webhooks.length < 1) {
    return (
      <div className="mt-10 p-10 text-center">
        <p className="font-bold mb-10">No matching search results</p>
        <p>Try again using another search term</p>
      </div>
    );
  }

  return (
    <div className="mt-10">
      <ScrollArea className="max-h-[calc(80vh-theme(space.52))]">
        <Table dataCy="webhooksTable">
          <TableHeader dataCy="webhooksTableHeader">
            <TableRow>
              <TableHead>Service {">"} Plan</TableHead>
              <TableHead>URL</TableHead>
              <TableHead>Method</TableHead>
              <TableHead>Type</TableHead>
              <TableHead>Created</TableHead>
              <TableHead>Published</TableHead>
              <TableHead className="text-center w-[90px]">Action</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody dataCy="webhooksTableBody">
            {webhooks.map((webhookItem) => (
              <WebhooksTableRow
                key={webhookItem.id}
                webhooksItem={webhookItem}
                removeWebhook={removeWebhook}
              />
            ))}
          </TableBody>
        </Table>
      </ScrollArea>
      <div
        className={`flex flex-wrap items-center ${
          lastPage > 1 ? "justify-between" : "justify-start"
        } space-x-0 mt-4`}
      >
        {webhooks.length >= 1 && (
          <div className="text-xs font-normal font-secondary">
            Showing{" "}
            {lastPage > 1
              ? `${(currentPage - 1) * webhookPageSize + 1} to ${
                  (currentPage - 1) * webhookPageSize + webhookPageSize >
                  mockWebhooksData.length
                    ? mockWebhooksData.length
                    : (currentPage - 1) * webhookPageSize + webhookPageSize
                }`
              : `${mockWebhooksData?.length}`}{" "}
            of {mockWebhooksData?.length} entries
          </div>
        )}
        {lastPage > 1 && webhooks.length >= 1 && (
          <div className="flex items-center">
            <Pagination
              pageCount={lastPage > 4 ? 4 : lastPage}
              currentPage={currentPage}
              lastPage={lastPage}
              onSelect={handleSelectPage}
            />
          </div>
        )}
      </div>
    </div>
  );
}

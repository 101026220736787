import IconButton from "src/components/buttons/IconButton";
import GlobalServicePlanDetailsDialog from "../actions/GlobalServicePlanDetailsDialog";
import { GlobalServicePlan } from "./ManageDetailsPlans";
import { toast } from "src/components/toast/useToast";

type ManageDetailsPlanActionCellProps = {
  servicePlan: GlobalServicePlan;
};

export default function ManageDetailsPlanActionCell({
  servicePlan,
}: ManageDetailsPlanActionCellProps) {
  async function handleCopy() {
    try {
      await navigator.clipboard.writeText(servicePlan.id);
      toast({
        title: "Success",
        description: (
          <>
            Copied to clipboard!
            <br />
          </>
        ),
      });
    } catch (error) {
      toast({
        title: "Error",
        description: (
          <>
            Failed to Copy to clipboard!
            <br />
          </>
        ),
      });
    }
  }
  return (
    <div className="flex justify-center gap-x-2">
      <GlobalServicePlanDetailsDialog servicePlan={servicePlan} />
      <IconButton
        name="copy"
        dataCy="copyIdActionBtn"
        tooltipText={`Copy ID to clipboard`}
        ariaLabel={`Copy ID to clipboard`}
        onClick={handleCopy}
      />
    </div>
  );
}

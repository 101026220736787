import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "../table/Table";
import PendingApprovalTableRow from "./PendingApprovalTableRow";

type ApprovalStatus = "approved" | "deny" | "none";

const approvalNeedItem = {
  id: "2309405-4509569304-949eb",
  field: "Description",
  status: "none" as ApprovalStatus,
  currentValue: "This is the Basic Service in this basic town",
  pendingValue: "This is the Basic Service in this Basic town [edited]",
  lastPublishedValue: undefined,
};

const data = {
  class: "Service",
  provider: "Dev Lab Provider",
  name: "Test",
  createdAt: new Date(),
  updatedAt: new Date(),
  id: "2309405-4509569304-949e",
  approvalNeedItems: new Array(10).fill(approvalNeedItem, 0, 10) as Array<
    typeof approvalNeedItem
  >,
};

export type ApprovalItem = typeof data;

type PendingApprovalTableProps = {
  selectedClass: string | undefined;
  selectedProvider: string | undefined;
};

export function PendingApprovalTable({
  selectedClass,
  selectedProvider,
}: PendingApprovalTableProps) {
  // mock data
  const pendingApprovalsList: Array<ApprovalItem> = new Array(10)
    .fill(data, 0, 10)
    .filter((approvalItem: ApprovalItem) => {
      if (selectedClass && approvalItem.class === selectedClass) {
        return true;
      }
      if (selectedProvider && approvalItem.provider === selectedProvider) {
        return true;
      }
      return false;
    });

  if (pendingApprovalsList.length < 1) {
    return (
      <div className="mt-10 p-10 text-center">
        <p className="font-bold mb-10">No matching search results</p>
        <p>Try again using another search term</p>
      </div>
    );
  }

  return (
    <div className="mt-10">
      <Table dataCy="approvalsPendingTable">
        <TableHeader dataCy="approvalsPendingTableHeader">
          <TableRow>
            <TableHead>Class</TableHead>
            <TableHead>Provider</TableHead>
            <TableHead>Name</TableHead>
            <TableHead>ID</TableHead>
            <TableHead>Created</TableHead>
            <TableHead>Action</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody dataCy="approvalsPendingTableBody">
          {pendingApprovalsList.map((approvalItem, index) => (
            <PendingApprovalTableRow
              key={approvalItem.id}
              index={index}
              approvalItem={approvalItem}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

import IconButton from "src/components/buttons/IconButton";
import { toast } from "src/components/toast/useToast";

import { Serviceplan } from "src/interfaces";
import ManageNetworkOperatorPlansDialog from "./ManageNetworkOperatorPlansDialog";

interface ManageNetworkOperatorPlansActionsProps {
  service: Serviceplan;
}

export default function ManageManageNetworkOperatorPlansActions({
  service,
}: ManageNetworkOperatorPlansActionsProps) {
  async function handleCopy() {
    try {
      await navigator.clipboard.writeText(service.id);
      toast({
        title: "Success",
        description: (
          <>
            Copied successfully!
            <br />
          </>
        ),
      });
    } catch (error) {
      toast({
        title: "Error",
        description: (
          <>
            Failed to copy, Please try again!
            <br />
          </>
        ),
      });
    }
  }
  return (
    <div className="flex justify-end items-center gap-x-2">
      <ManageNetworkOperatorPlansDialog servicePlan={service} />
      <IconButton
        name="copy"
        dataCy={`copyServiceIDBtn-${service.id}`}
        tooltipText={`Copy service ID`}
        ariaLabel={`Copy services ID`}
        onClick={handleCopy}
      />
    </div>
  );
}

import { ReactNode } from "react";

interface InputProps {
  children: ReactNode;
}

export default function LoginButton({ children }: InputProps) {
  return (
    <button
      type="submit"
      className="border rounded-2xl h-14 w-full md:w-96 bg-primary-color-50 text-primary-color-1000 text-xl font-bold hover:shadow-lg hover:bg-primary-color-light active:shadow-none"
    >
      {children}
    </button>
  );
}

import { SVGProps } from "react";

const CheckRoundedIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const defaultFill = "#434343";
  const fill = props.fill || defaultFill;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="CheckRounded">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.747 3.81435C13.9228 3.99016 14.0215 4.22857 14.0215 4.47716C14.0215 4.72575 13.9228 4.96417 13.747 5.13998L6.72015 12.1669C6.62729 12.2597 6.51704 12.3334 6.3957 12.3837C6.27436 12.434 6.14431 12.4598 6.01297 12.4598C5.88162 12.4598 5.75157 12.434 5.63023 12.3837C5.50889 12.3334 5.39864 12.2597 5.30578 12.1669L1.81453 8.67623C1.72499 8.58974 1.65357 8.4863 1.60443 8.37192C1.5553 8.25754 1.52944 8.13452 1.52836 8.01004C1.52727 7.88556 1.55099 7.76211 1.59813 7.64689C1.64527 7.53168 1.71488 7.427 1.80291 7.33898C1.89093 7.25096 1.99561 7.18134 2.11082 7.13421C2.22604 7.08707 2.34949 7.06335 2.47397 7.06443C2.59845 7.06551 2.72147 7.09137 2.83584 7.14051C2.95022 7.18964 3.05367 7.26106 3.14015 7.3506L6.01265 10.2231L12.4208 3.81435C12.5078 3.72723 12.6112 3.65812 12.725 3.61097C12.8388 3.56382 12.9607 3.53955 13.0839 3.53955C13.2071 3.53955 13.329 3.56382 13.4428 3.61097C13.5566 3.65812 13.66 3.72723 13.747 3.81435Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default CheckRoundedIcon;

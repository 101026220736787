import PageContainer from "src/components/page-container/PageContainer";

import { useState } from "react";
import WebhooksFilterer from "./search/WebhooksFilterer";
import { WebhooksTable } from "./list/WebhooksTable";

export default function Webhooks() {
  const [selectedService, setSelectedService] = useState<string | undefined>(
    undefined,
  );
  const [selectedServicePlan, setSelectedServicePlan] = useState<
    string | undefined
  >(undefined);

  return (
    <PageContainer>
      <WebhooksFilterer
        setSelectedService={setSelectedService}
        setSelectedServicePlan={setSelectedServicePlan}
      />
      {selectedService === undefined && selectedServicePlan === undefined ? (
        <div className="mt-10 p-10 text-center">
          <p className="font-bold mb-10">
            There are currently 10 webhooks available for searching
          </p>
          <p>Please enter a search term to load results</p>
        </div>
      ) : (
        <WebhooksTable
          selectedService={selectedService}
          selectedServicePlan={selectedServicePlan}
        />
      )}
    </PageContainer>
  );
}

import { TableRow, TableCell } from "src/components/table/Table";
import { Webhook } from "src/interfaces";
import { Dot } from "src/components/dot/Dot";
import WebhookTableActionsCell from "../actions/WebhookTableActions";

type WebhooksTableRowProps = {
  webhooksItem: Webhook;
  removeWebhook: (webhook: Webhook) => void;
};

export default function WebhooksTableRow({
  webhooksItem,
  removeWebhook,
}: WebhooksTableRowProps) {
  return (
    <TableRow dataCy={`webhooks-table-row-${webhooksItem.id}`}>
      <TableCell
        dataCy={`webhooks-table-col-service-plan-${webhooksItem.id}`}
        className="font-medium"
      >
        <div className="flex items-center gap-1">
          {webhooksItem.status === "Active" ? (
            <Dot className="h-2 w-2" variant={"active"} />
          ) : (
            <Dot className="h-2 w-2" variant={"inactive"} />
          )}
          {webhooksItem?.Serviceplan?.name}
        </div>
      </TableCell>
      <TableCell dataCy={`webhooks-table-col-url-${webhooksItem.id}`}>
        {webhooksItem.url}
      </TableCell>
      <TableCell dataCy={`webhooks-table-col-method-${webhooksItem.id}`}>
        {webhooksItem.method}
      </TableCell>
      <TableCell dataCy={`webhooks-table-col-type-${webhooksItem.id}`}>
        {webhooksItem.type}
      </TableCell>
      <TableCell dataCy={`webhooks-table-col-created-at-${webhooksItem.id}`}>
        {webhooksItem.created_at}
      </TableCell>
      <TableCell dataCy={`webhooks-table-col-published-at-${webhooksItem.id}`}>
        {webhooksItem.published_at}
      </TableCell>
      <TableCell dataCy={`webhooks-table-col-action-${webhooksItem.id}`}>
        <div className="flex gap-2">
          <WebhookTableActionsCell
            removeWebhook={removeWebhook}
            webhook={webhooksItem}
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

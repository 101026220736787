import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import EpButton from "src/components/buttons/EpButton";
import IconButton from "src/components/buttons/IconButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import { DialogClose } from "src/components/dialog/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/form/Form";
import { Input } from "src/components/input/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/select/Select";
import { Textarea } from "src/components/textarea/Textarea";
import { z } from "zod";
import MultiSelectFormField from "src/components/multi-select/MultiSelect";
import PublishDetailsDialog from "./PublishNetworkOperatorServiceDialog";
import { Service } from "src/interfaces";
import GlobalPlanValues from "./GlobalPlanValues";
import { useEffect, useState } from "react";
import * as _ from "lodash";
import LoadingAnimation from "src/components/spinners/LoadingAnimation";
import ClickableTooltip from "src/components/clickable-tooltip/ClickableTooltip";
import Icon from "src/components/buttons/Icon";

const STATUS = [
  "Active",
  "Inactive",
  "Locked",
  "Paused",
  "Pending",
  "Suspended",
  "Provision",
  "Repaired",
  "Replaced",
  "RMA",
  "Standby",
];

const TYPES = [
  "Residential",
  "Billing",
  "Sales",
  "Support",
  "Service",
  "Shipping",
  "Minimal",
  "Maximal",
  "Effort",
  "Cricket",
  "Fever",
  "Thread",
];

const STAND_BY_OPTIONS = ["Initially On", "Initially Off"];

const STAND_BY_ENABLED_OPTIONS = ["Yes", "No"];

const DISPLAY_TYPES = ["PUBLIC", "PRIVATE"];

const ManageNetworkOperatorServiceFormSchema = z.object({
  name: z.string().min(1, {
    message: "Please enter a valid name",
  }),
  status: z.string().nonempty({ message: "Required" }),
  id: z.string().readonly(),
  description: z.string().min(4, {
    message: "Please enter a valid description",
  }),
  display_type: z.string().optional(),
  access_code: z.string().readonly().optional(),
  account_types: z.array(z.string()).optional(),
  ip_range: z.string(),
  marketing_url: z.string().optional(),
  subscription_redirect_url: z.string().optional(),
  support_url: z.string().optional(),
  standByEnabled: z.string().optional(),
  standByInitiallyOn: z.string().optional(),
});

const capitalTocamelCaseMapping = {
  NAME: "name",
  STATUS: "status",
  DESCRIPITON: "description",
  "DISPLAY TYPE": "display_type",
  "ACCESS CODE": "access_code",
  "ACCOUNT TYPES": "account_types",
  "IP RANGE": "ip_range",
  "MARKETING URL": "marketing_url",
  "SUBSCRIPTION REDIRECT URL": "subscription_redirect_url",
  "SUPPORT URL": "support_url",
  "STAND BY ENABLED": "standByEnabled",
  "STAND BY - INITAILLY ON": "standByInitiallyOn",
} as const;

export default function ManageNetworkOperatorServiceForm({
  serviceDetails,
}: {
  serviceDetails: Service;
}) {
  const ManageNetworkOperatorServiceFormInstance = useForm<
    z.infer<typeof ManageNetworkOperatorServiceFormSchema>
  >({
    resolver: zodResolver(ManageNetworkOperatorServiceFormSchema),
    mode: "onBlur",
    defaultValues: {
      ...serviceDetails,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const [globalPlanValuesState, setGlobalPlanValuesState] = useState<
    Record<string, boolean>
  >({
    NAME: true,
    STATUS: true,
    DESCRIPITON: true,
    "DISPLAY TYPE": true,
    "ACCESS CODE": true,
    "ACCOUNT TYPES": true,
    "IP RANGE": true,
    "MARKETING URL": true,
    "SUBSCRIPTION REDIRECT URL": true,
    "SUPPORT URL": true,
    "STAND BY ENABLED": true,
    "STAND BY - INITAILLY ON": true,
  });

  function handleManageDetailsSave(
    data: z.infer<typeof ManageNetworkOperatorServiceFormSchema>,
  ) {
    console.log({ data });
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1300);
  }

  function handleFormStateChange() {
    const defaultValues =
      ManageNetworkOperatorServiceFormInstance.formState.defaultValues;
    const actualFormValues =
      ManageNetworkOperatorServiceFormInstance.getValues();
    if (defaultValues) {
      const loopalbleKeys = Object.keys(capitalTocamelCaseMapping) as Array<
        keyof typeof capitalTocamelCaseMapping
      >;
      for (let i = 0; i < loopalbleKeys.length; i++) {
        const keyItem = loopalbleKeys[i];
        const camelCaseKey = capitalTocamelCaseMapping[keyItem];
        if (keyItem === "ACCOUNT TYPES") {
          setGlobalPlanValuesState((originalState) => {
            return {
              ...originalState,
              [keyItem]: _.isEqual(
                _.sortBy(defaultValues["account_types"]),
                _.sortBy(actualFormValues["account_types"]),
              ),
            };
          });
        } else {
          setGlobalPlanValuesState((originalState) => {
            return {
              ...originalState,
              [keyItem]:
                defaultValues[camelCaseKey] === actualFormValues[camelCaseKey],
            };
          });
        }
      }
    }
  }

  function handleGlobalPlanCheckboxesValuesChange() {
    const loopalbleKeys = Object.keys(capitalTocamelCaseMapping) as Array<
      keyof typeof capitalTocamelCaseMapping
    >;
    const defaultValues = ManageNetworkOperatorServiceFormInstance.formState
      .defaultValues as any;

    for (let i = 0; i < loopalbleKeys.length; i++) {
      const keyItem = loopalbleKeys[i];

      if (globalPlanValuesState[keyItem]) {
        const camelCaseKey = capitalTocamelCaseMapping[keyItem];
        ManageNetworkOperatorServiceFormInstance.setValue(
          camelCaseKey,
          defaultValues[camelCaseKey],
        );
      }
    }
  }

  const status = ManageNetworkOperatorServiceFormInstance.watch("status");
  const display_type =
    ManageNetworkOperatorServiceFormInstance.watch("display_type");
  const ip_range = ManageNetworkOperatorServiceFormInstance.watch("ip_range");
  const support_url =
    ManageNetworkOperatorServiceFormInstance.watch("support_url");
  const standByInitiallyOn =
    ManageNetworkOperatorServiceFormInstance.watch("standByInitiallyOn");
  const isValidating =
    ManageNetworkOperatorServiceFormInstance.formState.isValidating;

  useEffect(() => {
    handleFormStateChange();
  }, [
    status,
    display_type,
    ip_range,
    support_url,
    standByInitiallyOn,
    isValidating,
  ]);

  useEffect(() => {
    handleGlobalPlanCheckboxesValuesChange();
  }, [globalPlanValuesState]);

  return (
    <Form {...ManageNetworkOperatorServiceFormInstance}>
      <div className="grid grid-rows-1 grid-cols-2 mb-4 text-neutral-500 font-secondary text-xs">
        <span>CREATED: {serviceDetails.created_at}</span>
        <span>UPDATED: {serviceDetails.updated_at}</span>
      </div>
      <form
        onSubmit={ManageNetworkOperatorServiceFormInstance.handleSubmit(
          handleManageDetailsSave,
        )}
      >
        <div className="grid grid-cols-2 gap-x-2 grid-rows-1">
          <div className="grid grid-cols-2 gap-2 grid-rows-1">
            <FormField
              control={ManageNetworkOperatorServiceFormInstance.control}
              name="name"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>NAME *</FormLabel>
                  <FormControl>
                    <Input
                      autoFocus
                      id="name"
                      placeholder="Name"
                      data-cy="nameInput"
                      type="text"
                      aria-label="Name"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={ManageNetworkOperatorServiceFormInstance.control}
              name="status"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>STATUS *</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      ManageNetworkOperatorServiceFormInstance.trigger();
                      ManageNetworkOperatorServiceFormInstance.setValue(
                        field.field.name,
                        value,
                      );
                    }}
                    defaultValue={field.field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        aria-label="Service status"
                        data-cy="service-status-select"
                        onBlur={() =>
                          ManageNetworkOperatorServiceFormInstance.trigger(
                            field.field.name,
                          )
                        }
                      >
                        <SelectValue>{field.field.value}</SelectValue>
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {STATUS.map((stat) => (
                        <SelectItem
                          key={stat}
                          value={stat}
                          data-cy={`service-status-${stat}`}
                        >
                          {stat}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={ManageNetworkOperatorServiceFormInstance.control}
            name="id"
            render={(field) => (
              <FormItem className="w-full mb-4">
                <FormLabel>ID</FormLabel>
                <FormControl>
                  <Input
                    readOnly
                    id="id"
                    placeholder="ID"
                    data-cy="idInput"
                    type="text"
                    aria-label="ID"
                    {...field.field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={ManageNetworkOperatorServiceFormInstance.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>DESCRIPTION *</FormLabel>
              <FormControl>
                <Textarea
                  required
                  placeholder="Description"
                  data-cy="descriptionInput"
                  aria-label="Service Description"
                  className={"h-[90px] text-neutral-700"}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="grid grid-cols-2 gap-x-2 grid-rows-1 mt-4">
          <FormField
            control={ManageNetworkOperatorServiceFormInstance.control}
            name="display_type"
            render={(field) => (
              <FormItem className="w-full mb-4">
                <FormLabel>DISPLAY TYPE</FormLabel>
                <Select
                  onValueChange={(value) => {
                    ManageNetworkOperatorServiceFormInstance.setValue(
                      field.field.name,
                      value,
                    );
                    ManageNetworkOperatorServiceFormInstance.trigger(
                      field.field.name,
                    );
                  }}
                  defaultValue={field.field.value}
                >
                  <FormControl>
                    <SelectTrigger
                      aria-label="Display Type"
                      data-cy="service-display-type-select"
                      onBlur={() =>
                        ManageNetworkOperatorServiceFormInstance.trigger(
                          field.field.name,
                        )
                      }
                    >
                      <SelectValue>{field.field.value}</SelectValue>
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {DISPLAY_TYPES.map((type) => (
                      <SelectItem
                        key={type}
                        value={type}
                        data-cy={`service-display-type-${type}`}
                      >
                        {type}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex gap-2 items-center">
            <FormField
              control={ManageNetworkOperatorServiceFormInstance.control}
              name="access_code"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>ACCESS CODE</FormLabel>
                  <FormControl>
                    <Input
                      readOnly
                      id="access_code"
                      placeholder="Access Code"
                      data-cy="accessCodeInput"
                      type="text"
                      aria-label="Access Code"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <IconButton
              aria-label="Refresh access code"
              tooltipText="Refresh"
              dataCy="refreshAccessCode"
              type="button"
              name="refresh"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-x-2 grid-rows-1">
          <FormField
            control={ManageNetworkOperatorServiceFormInstance.control}
            name="account_types"
            render={(field) => (
              <FormItem className="w-full mb-4">
                <FormLabel>ACCOUNT TYPES</FormLabel>
                <FormControl>
                  <MultiSelectFormField
                    id="account_types"
                    options={TYPES.map((t) => ({
                      label: t,
                      value: t,
                    }))}
                    onValueChange={field.field.onChange}
                    value={field.field.value}
                    placeholder="Select Account Types"
                    data-cy="service-account-type-multi-select"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={ManageNetworkOperatorServiceFormInstance.control}
            name="ip_range"
            render={(field) => (
              <FormItem className="w-full mb-4">
                <FormLabel>IP / RANGE</FormLabel>
                <FormControl>
                  <Input
                    id="ip_range"
                    placeholder="IPV4 / IPV6 Range"
                    data-cy="ipRangeInput"
                    type="text"
                    aria-label="IPV4 / IPV6 Range"
                    {...field.field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="grid grid-cols-2 gap-x-2 grid-rows-1">
          <FormField
            control={ManageNetworkOperatorServiceFormInstance.control}
            name="marketing_url"
            render={(field) => (
              <FormItem className="w-full mb-4">
                <FormLabel className="flex items-center">
                  MARKETING URL{" "}
                  <ClickableTooltip
                    header={<p>Status: Published</p>}
                    icon={
                      <Icon
                        size={17}
                        className="pt-[3px]"
                        name="exclamation"
                        data-cy="marketingURLStatusInfoIcon"
                      />
                    }
                    content={
                      <>
                        <p>Actual value: {serviceDetails.marketing_url}</p>
                        <span>Published values:</span>
                        <ul>
                          <li>
                            {ManageNetworkOperatorServiceFormInstance.getValues(
                              "marketing_url",
                            )}
                          </li>
                        </ul>
                      </>
                    }
                    dataCy="marketingURLStatusInfoTooltip"
                    className="w-[290px]"
                  />
                </FormLabel>
                <FormControl>
                  <Input
                    id="marketing_url"
                    placeholder="Marketing URL"
                    data-cy="marketingUrlInput"
                    type="text"
                    aria-label="Marketing URL"
                    {...field.field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={ManageNetworkOperatorServiceFormInstance.control}
            name="subscription_redirect_url"
            render={(field) => (
              <FormItem className="w-full mb-4">
                <FormLabel>SUBSCRIPTION REDIRECT URL</FormLabel>
                <FormControl>
                  <Input
                    id="subscription_redirect_url"
                    placeholder="Subscription Redirect URL"
                    data-cy="subscriptionRedirectUrlInput"
                    type="text"
                    aria-label="Subscription Redirect URL"
                    {...field.field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="grid grid-cols-2 gap-x-2 grid-rows-1">
          <FormField
            control={ManageNetworkOperatorServiceFormInstance.control}
            name="support_url"
            render={(field) => (
              <FormItem className="w-full mb-4">
                <FormLabel>SUPPORT URL</FormLabel>
                <FormControl>
                  <Input
                    id="support_url"
                    placeholder="Support URL"
                    data-cy="supportURLInput"
                    type="text"
                    aria-label="Support URL"
                    {...field.field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="grid grid-cols-2 gap-x-2 grid-rows-1">
            <FormField
              control={ManageNetworkOperatorServiceFormInstance.control}
              name="standByEnabled"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>STAND BY ENABLED</FormLabel>
                  <FormControl>
                    <Select
                      onValueChange={(value) => {
                        ManageNetworkOperatorServiceFormInstance.setValue(
                          field.field.name,
                          value,
                        );
                        ManageNetworkOperatorServiceFormInstance.trigger(
                          field.field.name,
                        );
                      }}
                      value={field.field.value}
                    >
                      <FormControl>
                        <SelectTrigger
                          aria-label="Stand By Enabled"
                          data-cy="service-stand-enabled-select"
                          onBlur={() =>
                            ManageNetworkOperatorServiceFormInstance.trigger(
                              field.field.name,
                            )
                          }
                        >
                          <SelectValue>{field.field.value}</SelectValue>
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {STAND_BY_ENABLED_OPTIONS.map((type) => (
                          <SelectItem
                            key={type}
                            value={type}
                            data-cy={`service-stand-by-enabled-${type}`}
                          >
                            {type}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={ManageNetworkOperatorServiceFormInstance.control}
              name="standByInitiallyOn"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>
                    STAND BY{" "}
                    {field.field.value ? `- ${field.field.value}` : null}
                  </FormLabel>
                  <Select
                    onValueChange={(value) => {
                      ManageNetworkOperatorServiceFormInstance.setValue(
                        field.field.name,
                        value,
                      );
                      ManageNetworkOperatorServiceFormInstance.trigger(
                        field.field.name,
                      );
                    }}
                    value={field.field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        aria-label="STAND BY INITIALLY"
                        data-cy="service-stand-by-initial-select"
                        onBlur={() =>
                          ManageNetworkOperatorServiceFormInstance.trigger(
                            field.field.name,
                          )
                        }
                      >
                        <SelectValue>{field.field.value}</SelectValue>
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {STAND_BY_OPTIONS.map((type) => (
                        <SelectItem
                          key={type}
                          value={type}
                          data-cy={`service-stand-by-initial-${type}`}
                        >
                          {type}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <span className="text-xs font-base font-inter">IMAGE</span>
        <p className="text-xs text-neutrals-form-gray-dark mt-2 font-inter">
          To set an image, send the image to the Network Operator so they can
          upload it to their system.
        </p>
        <GlobalPlanValues
          globalPlanValuesState={globalPlanValuesState}
          setGlobalPlanValuesState={setGlobalPlanValuesState}
        />
        <div className="flex gap-4 items-center justify-start mt-6">
          <EpButton
            aria-label="Save Updated Values"
            dataCy="saveUpdatedValuesBtn"
            disabled={isLoading}
          >
            {isLoading && <LoadingAnimation />}
            {isLoading ? "Saving..." : "Save"}
          </EpButton>
          <span className="text-xs font-normal">
            <span className="font-medium mr-2">Last saved</span>{" "}
            {serviceDetails.updated_at}
          </span>
        </div>
        <div className="flex items-center justify-between mt-8">
          <div className="flex justify-start gap-4 items-center border border-neutral-200 rounded-md p-2">
            <PublishDetailsDialog updatedAt={serviceDetails.updated_at} />
            <span className="text-xs font-normal">
              <span className="font-medium mr-2">Last published</span>{" "}
              {serviceDetails.updated_at}
            </span>
          </div>

          <DialogClose asChild className="flex gap-2 ml-auto">
            <EpButton
              type="button"
              styling={EpButtonStyles.CANCEL}
              dataCy="cancelBtn"
              aria-label="cancel"
            >
              Cancel
            </EpButton>
          </DialogClose>
        </div>
      </form>
    </Form>
  );
}

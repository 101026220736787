import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import LoadingAnimation from "src/components/spinners/LoadingAnimation";
import { z } from "zod";
import { Subscriber } from "../list/SubscribersList";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";
import {
  FormField,
  FormItem,
  FormControl,
  FormMessage,
  Form,
} from "src/components/form/Form";
import { Input } from "src/components/input/Input";
import IconButton from "src/components/buttons/IconButton";
import WarningIcon from "src/components/icons/WarningIcon";
import { toast } from "src/components/toast/useToast";

interface RemoveSubscriberDialogProps {
  subscriber: Subscriber;
  removeSubscriber: (subscriber: Subscriber) => void;
}

const RemoveSubscriberFormSchema = z
  .object({
    enteredSubscriberName: z.string(),
    subscriberName: z.string(),
  })
  .refine((data) => data.subscriberName === data.enteredSubscriberName, {
    message: "Entered subscriber name doesn’t match",
    path: ["enteredSubscriberName"],
  });

export default function RemoveSubscriber({
  subscriber,
  removeSubscriber,
}: RemoveSubscriberDialogProps) {
  const form = useForm<z.infer<typeof RemoveSubscriberFormSchema>>({
    resolver: zodResolver(RemoveSubscriberFormSchema),
    mode: "onBlur",
    defaultValues: {
      subscriberName: subscriber.name,
    },
  });

  const handleCloseDialog = () => {
    setIsDialogOpened(!isDialogOpened);
  };

  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  function handleRemoveSubscriber(
    data: z.infer<typeof RemoveSubscriberFormSchema>,
  ) {
    setIsLoading(true);
    removeSubscriber(subscriber);
    setTimeout(() => {
      toast({
        title: "Success",
        description: (
          <>
            {data.enteredSubscriberName} Removed
            <br />
          </>
        ),
      });
      setIsLoading(false);
      handleCloseDialog();
    }, 1500);
  }

  useMemo(() => form.reset(), [isDialogOpened]);

  return (
    <Dialog open={isDialogOpened} onOpenChange={handleCloseDialog}>
      <DialogTrigger>
        <IconButton
          name="remove"
          dataCy="removeSubscriberActionBtn"
          tooltipText={`Remove subscriber`}
          ariaLabel={`Remove subscriber`}
        />
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-[425px] font-base"
        dataCy="removeSubscriberDialogCnt"
      >
        <DialogHeader dataCy="removeSubscriberDialogHeader" borderless>
          <DialogTitle dataCy="removeSubscriberDialogTitle">
            Remove Subscriber
          </DialogTitle>
        </DialogHeader>
        <p className="text-sm flex items-center gap-2 text-[#434343] -mt-4">
          <WarningIcon fill="#EC0000" />
          Are you sure you want to remove {subscriber.name}?
        </p>
        <p className="text-sm text-red-600 font-semibold">
          Enter <span className="text-black">{subscriber.name}</span> below to
          remove the account.
        </p>
        <div className="flex flex-col w-full">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleRemoveSubscriber)}
              className="flex flex-col w-full"
            >
              <FormField
                control={form.control}
                name="enteredSubscriberName"
                render={(field) => (
                  <FormItem className="w-full pr-2 h-10">
                    <FormControl>
                      <Input
                        id="enteredSubscriberName"
                        placeholder="Enter Subscriber Name"
                        data-cy="enteredSubscriberNameInput"
                        type="text"
                        aria-label="Subscriber Name"
                        {...field.field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>

        <DialogFooter>
          <EpButton
            styling={EpButtonStyles.DELETE}
            dataCy="removeSubscriberBtn"
            aria-label="Remove Subscriber"
            className="flex gap-1"
            type="submit"
            onClick={form.handleSubmit(handleRemoveSubscriber)}
            disabled={isLoading}
          >
            {isLoading && <LoadingAnimation />}
            {isLoading ? "Processing" : "Remove Subscriber"}
          </EpButton>
          <DialogClose asChild className="flex gap-2">
            <EpButton
              type="button"
              styling={EpButtonStyles.CANCEL}
              dataCy="cancelBtn"
              aria-label="Cancel"
            >
              Cancel
            </EpButton>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

import { SVGProps } from "react";

const OperatorIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const defaultFill = "#6D6D6D";
  const fill = props.fill || defaultFill;

  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1002_4055)">
        <path
          d="M12.2942 5.53394C7.80658 5.53394 4.1687 9.16431 4.1687 13.6418C4.1687 18.1201 7.80658 21.7501 12.2942 21.7501C16.7815 21.7501 20.419 18.1201 20.419 13.6418C20.419 9.16431 16.7818 5.53394 12.2942 5.53394ZM12.7765 12.5944C12.7765 12.7223 12.7256 12.845 12.6352 12.9354C12.5448 13.0259 12.4221 13.0767 12.2942 13.0767C12.1663 13.0767 12.0436 13.0259 11.9532 12.9354C11.8628 12.845 11.812 12.7223 11.812 12.5944V7.23194C11.8169 7.10733 11.8698 6.98946 11.9597 6.90303C12.0496 6.8166 12.1695 6.76833 12.2942 6.76833C12.4189 6.76833 12.5388 6.8166 12.6287 6.90303C12.7186 6.98946 12.7715 7.10733 12.7765 7.23194V12.5944Z"
          fill={fill}
        />
        <path
          d="M23.3685 9.57228C22.5734 7.51518 21.2075 5.72788 19.4314 4.42053C17.6424 3.10558 15.5022 2.35347 13.284 2.26016C11.0537 2.16818 8.8476 2.75121 6.95398 3.93303C5.07448 5.10641 3.55273 6.85053 2.63998 8.89278C1.72723 10.9163 1.4381 13.2267 1.80898 15.4328C2.05685 16.9395 2.63173 18.3927 3.46573 19.6819H4.57873C3.78373 18.4935 3.23698 17.1402 2.99698 15.7433C2.67448 13.8908 2.87398 11.9449 3.56398 10.2274C4.25947 8.48449 5.43736 6.97592 6.9596 5.87853C8.47423 4.78616 10.3035 4.16928 12.1406 4.11903C13.9921 4.06391 15.8169 4.57064 17.3749 5.57253C18.9292 6.57078 20.1559 8.04528 20.8665 9.70428C21.6004 11.3985 21.7912 13.2829 21.4642 15.0634C21.1435 16.8017 20.3194 18.4075 19.0942 19.6815H22.5814C23.2408 18.5402 23.703 17.2959 23.9486 16.0009C24.3487 13.8649 24.1579 11.6157 23.3685 9.57228Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1002_4055">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.901367)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OperatorIcon;

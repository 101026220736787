import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";

import EpButton from "src/components/buttons/EpButton";
import { useEffect, useState } from "react";
import { Plus } from "lucide-react";
import NetworkOperatorContactForm from "./NetworkOperatorContactForm";
import { Contact, Location } from "src/interfaces";
import NetworkOperatorLocationForm from "./NetworkOperatorLocationForm";

export default function AddNewContactDialog() {
  const defaultValues: Contact = {
    email: "",
    first_name: "",
    id: "",
    last_name: "",
    phone: "",
    status: "",
    type: "",
    Location: {
      address1: "",
      city: "",
      country: "",
      id: "",
      state: "",
      uri: "",
      zip: "",
      address2: "",
      status: "",
    },
  };

  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [contactDetails, setContactDetails] = useState<Contact>(defaultValues);

  const [currentFormView, setCurrentFormView] = useState<
    "contact-details" | "location-details"
  >("contact-details");

  function handleNext(contactDetails: Contact) {
    setContactDetails(contactDetails);
    setCurrentFormView("location-details");
  }

  function handleBack(locationDetails: Location) {
    setContactDetails({
      ...contactDetails,
      Location: {
        ...locationDetails,
      },
    });
    setCurrentFormView("contact-details");
  }

  useEffect(() => {
    setContactDetails(defaultValues);
    setCurrentFormView("contact-details");
  }, [isDialogOpened]);

  function renderContactForms() {
    if (currentFormView === "contact-details") {
      return (
        <div className="flex flex-col">
          <NetworkOperatorContactForm
            contactDetails={contactDetails}
            actionType="create"
            handleNext={handleNext}
          />
        </div>
      );
    }

    return (
      <div className="flex flex-col">
        <NetworkOperatorLocationForm
          actionType="create"
          handleBack={handleBack}
          locationDetails={contactDetails.Location!}
          handleCloseDialog={() => setIsDialogOpened(false)}
        />
      </div>
    );
  }
  return (
    <Dialog
      open={isDialogOpened}
      onOpenChange={(value) => setIsDialogOpened(value)}
    >
      <DialogTrigger asChild>
        <EpButton
          aria-label="Add new contact"
          dataCy="add-new-contact-btn"
          className="mt-8"
        >
          <Plus className="h-4 w-4" /> Add Contact
        </EpButton>
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-4xl font-base"
        dataCy="addNewContactDialogCnt"
      >
        <DialogHeader dataCy="addNewContactDialogHeader" borderless>
          <DialogTitle dataCy="addNewContactDialogTitle">
            Add Contact
          </DialogTitle>
        </DialogHeader>
        {renderContactForms()}
      </DialogContent>
    </Dialog>
  );
}

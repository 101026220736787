interface NextButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

const NextButton: React.FC<NextButtonProps> = ({
  children,
  disabled,
  onClick,
}) => {
  const buttonClasses = `
    rounded-r-sm
    px-3 py-2 text-xs font-medium text-[#55857E] bg-transparent border border-[#DBDBDB] 
    ${disabled ? "" : "hover:bg-[#f0f0f0]"}
    disabled:text-[#6D6D6D]
    active:bg-[#DBDBDB] active:text-[#55857E] active:border active:border-[#DBDBDB] active:border-r-0
  `;

  return (
    <button
      className={buttonClasses}
      onClick={onClick}
      disabled={disabled}
      data-cy="nextPageButton"
      aria-label="Go To, Next Page"
    >
      {children}
    </button>
  );
};

export default NextButton;

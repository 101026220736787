import PageContainer from "src/components/page-container/PageContainer";
import NetworkOperatorsList from "./list/NetworkOperatorsList";

export default function NetworkOperator() {
  return (
    <PageContainer>
      <NetworkOperatorsList />
    </PageContainer>
  );
}

import Icon from "src/components/buttons/Icon";
import ClickableTooltip from "src/components/clickable-tooltip/ClickableTooltip";
import { Search } from "src/components/input/Input";

interface SubscribersToolbarProps {
  setSearchFilter: (searchFilter: string) => void;
  clearSearch: () => void;
}

export default function SubscribersToolbar({
  setSearchFilter,
  clearSearch,
}: SubscribersToolbarProps) {
  const handleSearch = (value: string) => {
    if (value !== "") {
      setSearchFilter(value);
    } else {
      clearSearch();
    }
  };

  return (
    <div
      className="flex justify-between items-center"
      data-cy="subscriberToolbarSection"
    >
      <div className="flex items-center max-w-[540px] gap-2">
        <Search
          placeholder="Search"
          className="search min-w-[305px]"
          ctaCallback={handleSearch}
          closeCallback={clearSearch}
          dataCy="searchSubscriberInput"
          aria-label="Search"
        ></Search>
        <ClickableTooltip
          header={<p>Search Terms</p>}
          icon={
            <Icon
              size={30}
              className="pt-[4px]"
              name="exclamation"
              data-cy="searchInfoIcon"
            />
          }
          content={"Search for a subscriber by providing a subscriber name"}
          dataCy="searchSubsciberTooltip"
          className="w-[290px]"
        />
      </div>
    </div>
  );
}

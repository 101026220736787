import { FlowSection } from "src/interfaces/Flow";

export const flowSections = [
  {
    id: "",
    type: "COMPLIANCE",
    flows: [],
    name: "Compliance Flows",
  },
  {
    type: "BOOTSTRAP",
    flows: [
      {
        bytes: "0",
        created_at: "2024-04-07 09:35:31",
        data: [
          {
            actions: [
              {
                action: "pop-vlan",
              },
              {
                action: "goto-table",
                value: "10",
              },
            ],
            bytes: 0,
            cookie: "0x2000",
            created_at: "2024-04-05 13:18:25",
            destination_port: "SELF",
            details: {
              destination_port_name: "FLOOD",
              source_port_name: "WAN",
              inner_label: 10,
              outer_label: 5,
              priority: 10,
              protocol: ",arp",
              openflow_id: "manual:30910920021",
            },
            duration: 0,
            flow_signature: "8f9d50cda87c8021d608777fd991b916",
            flow_signature_data: {
              actions: [
                {
                  action: "goto-table",
                  value: "10",
                },
                {
                  action: "pop-vlan",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "1",
                },
                {
                  field: "vlan-match",
                  value: "5",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: "0",
            idle_time: "0",
            location: {
              table_id: 10,
              table_flow_id: "",
            },
            matches: [
              {
                field: "in-port",
                value: "1",
              },
              {
                field: "vlan-match",
                value: "5",
              },
            ],
            name: "Bootstrap Pop QinQ VLAN",
            packets: 0,
            priority: 10,
            source_port: "1",
            type: "BOOTSTRAP",
            raw_physical: [],
            raw_virtual: [],
            raw_data: {
              stripped_fields: [],
              always_stripped_fields_epcom: [
                "approval_values",
                "created_at",
                "globals",
                "globals_json",
                "guid",
                "level",
                "lft",
                "rgt",
                "updated_at",
              ],
              nonApprovalFields: ["approval_values", "id", "guid"],
              stripped_fields_epcom: [],
              epcom_dont_strip_fields: [],
            },
          },
        ],
        destination_mac: "",
        destination_port: "SELF",
        destination_port_name: "SELF",
        duration: "271062.144",
        idle_time: 0,
        incoming_vlan: 5,
        inner_label: "",
        ip_destination_address: "",
        ip_destination_port: "",
        ip_source_address: "",
        ip_source_port: "",
        name: "Bootstrap Pop QinQ VLAN",
        notes: {
          ebfd45dd8f049e6be82340d655c097bb: "Type: BOOTSTRAP",
          fe8cb6376c2c7f8f8786a38c8b1159dd: "Label: 5",
          d68900e698eceebfc0235da6c1086828: "ODL Table: 10",
        },
        outer_label: "",
        packets: "0",
        physical: [
          {
            actions: [
              {
                action: "pop-vlan",
              },
              {
                action: "goto-table",
                value: "10",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:30",
            destination_port: "SELF",
            details: [],
            duration: "271062.144",
            flow_signature: "8f9d50cda87c8021d608777fd991b916",
            flow_signature_data: {
              actions: [
                {
                  action: "goto-table",
                  value: "10",
                },
                {
                  action: "pop-vlan",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "1",
                },
                {
                  field: "vlan-match",
                  value: "5",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "1",
              },
              {
                field: "vlan-match",
                value: 5,
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "1",
            type: "SERVICE",
            raw_physical: {
              string:
                " cookie=0x2000, duration=271062.144s, table=0, n_packets=0, n_bytes=0, priority=10,in_port=1,dl_vlan=5 actions=pop_vlan,goto_table:10",
            },
            raw_virtual: [],
            raw_data: [],
            source_port_state: "up",
            destination_port_state: "unknown",
          },
        ],
        priority: 10,
        protocol: "",
        signature: "8f9d50cda87c8021d608777fd991b916",
        signature_data: {
          actions: [
            {
              action: "goto-table",
              value: "10",
            },
            {
              action: "pop-vlan",
            },
          ],
          matches: [
            {
              field: "in-port",
              value: "1",
            },
            {
              field: "vlan-match",
              value: "5",
            },
          ],
          priority: "10",
          table: "0",
        },
        source_port: "1",
        source_port_name: "WAN",
        table: 10,
        type: "BOOTSTRAP",
        virtual: [
          {
            actions: [
              {
                action: "pop-vlan",
              },
              {
                action: "goto-table",
                value: "10",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "SELF",
            details: [],
            duration: "271062.799",
            flow_signature: "8f9d50cda87c8021d608777fd991b916",
            flow_signature_data: {
              actions: [
                {
                  action: "goto-table",
                  value: "10",
                },
                {
                  action: "pop-vlan",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "1",
                },
                {
                  field: "vlan-match",
                  value: "5",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "1",
              },
              {
                field: "vlan-match",
                value: 5,
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "1",
            type: "SERVICE",
            raw_physical: [],
            raw_virtual: {
              string:
                " cookie=0x2000, duration=271062.799s, table=0, n_packets=0, n_bytes=0, priority=10,in_port=1,dl_vlan=5 actions=pop_vlan,goto_table:10",
            },
            raw_data: [],
          },
        ],
      },
      {
        bytes: "180",
        created_at: "2024-04-07 09:35:31",
        data: [
          {
            actions: [
              {
                action: "push-vlan",
                value: "CTAG:10",
              },
              {
                action: "push-vlan",
                value: "STAG:5",
              },
              {
                action: "output",
                value: "1",
              },
            ],
            bytes: 0,
            cookie: "0x2000",
            created_at: "2024-04-05 13:18:25",
            destination_port: "1",
            details: {
              destination_port_name: "WAN",
              source_port_name: "N 1",
              inner_label: 10,
              outer_label: 5,
              priority: 10,
              openflow_id: "manual:30910920021",
            },
            duration: 0,
            flow_signature: "8f002268f5f54dc53310231e730c16e6",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "1",
                },
                {
                  action: "push-vlan",
                  value: "STAG:5",
                },
                {
                  action: "push-vlan",
                  value: "CTAG:10",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "2",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: "0",
            idle_time: "0",
            location: {
              table_id: "",
              table_flow_id: "",
            },
            matches: [
              {
                field: "in-port",
                value: "2",
              },
            ],
            name: "Bootstrap Push QinQ VLAN",
            packets: 0,
            priority: 10,
            source_port: "2",
            type: "BOOTSTRAP",
            raw_physical: [],
            raw_virtual: [],
            raw_data: {
              stripped_fields: [],
              always_stripped_fields_epcom: [
                "approval_values",
                "created_at",
                "globals",
                "globals_json",
                "guid",
                "level",
                "lft",
                "rgt",
                "updated_at",
              ],
              nonApprovalFields: ["approval_values", "id", "guid"],
              stripped_fields_epcom: [],
              epcom_dont_strip_fields: [],
            },
          },
        ],
        destination_mac: "",
        destination_port: "1",
        destination_port_name: "WAN",
        duration: "271062.059",
        idle_time: 0,
        incoming_vlan: "",
        inner_label: "10",
        ip_destination_address: "",
        ip_destination_port: "",
        ip_source_address: "",
        ip_source_port: "",
        name: "Bootstrap Push QinQ VLAN",
        notes: {
          ebfd45dd8f049e6be82340d655c097bb: "Type: BOOTSTRAP",
          ee371a12abbbf4c294cc98fe94f91399: "Label: CTAG:10",
          "06c7f8c303c2482a9e00e9658e238719": "Label: STAG:5",
          "1cfa534da20ccfe94f62c819a71dc955": "ODL Table: 0",
        },
        outer_label: "5",
        packets: "2",
        physical: [
          {
            actions: [
              {
                action: "push-vlan",
                value: "CTAG:10",
              },
              {
                action: "push-vlan",
                value: "STAG:5",
              },
              {
                action: "output",
                value: "1",
              },
            ],
            bytes: "180",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:30",
            destination_port: "1",
            details: [],
            duration: "271062.059",
            flow_signature: "8f002268f5f54dc53310231e730c16e6",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "1",
                },
                {
                  action: "push-vlan",
                  value: "STAG:5",
                },
                {
                  action: "push-vlan",
                  value: "CTAG:10",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "2",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "2",
              },
            ],
            name: "",
            packets: "2",
            priority: 10,
            source_port: "2",
            type: "SERVICE",
            raw_physical: {
              string:
                " cookie=0x2000, duration=271062.059s, table=0, n_packets=2, n_bytes=180, priority=10,in_port=2 actions=push_vlan:0x8100,set_vlan_vid:10,push_vlan:0x88a8,set_vlan_vid:5,output:1",
            },
            raw_virtual: [],
            raw_data: [],
            source_port_state: "down",
            destination_port_state: "up",
          },
        ],
        priority: 10,
        protocol: "",
        signature: "8f002268f5f54dc53310231e730c16e6",
        signature_data: {
          actions: [
            {
              action: "output",
              value: "1",
            },
            {
              action: "push-vlan",
              value: "STAG:5",
            },
            {
              action: "push-vlan",
              value: "CTAG:10",
            },
          ],
          matches: [
            {
              field: "in-port",
              value: "2",
            },
          ],
          priority: "10",
          table: "0",
        },
        source_port: "2",
        source_port_name: "N 1",
        table: 0,
        type: "BOOTSTRAP",
        virtual: [
          {
            actions: [
              {
                action: "push-vlan",
                value: "CTAG:10",
              },
              {
                action: "push-vlan",
                value: "STAG:5",
              },
              {
                action: "output",
                value: "1",
              },
            ],
            bytes: "180",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "1",
            details: [],
            duration: "271062.714",
            flow_signature: "8f002268f5f54dc53310231e730c16e6",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "1",
                },
                {
                  action: "push-vlan",
                  value: "STAG:5",
                },
                {
                  action: "push-vlan",
                  value: "CTAG:10",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "2",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "2",
              },
            ],
            name: "",
            packets: "2",
            priority: 10,
            source_port: "2",
            type: "SERVICE",
            raw_physical: [],
            raw_virtual: {
              string:
                " cookie=0x2000, duration=271062.714s, table=0, n_packets=2, n_bytes=180, priority=10,in_port=2 actions=push_vlan:0x8100,set_vlan_vid:10,push_vlan:0x88a8,set_vlan_vid:5,output:1",
            },
            raw_data: [],
          },
        ],
      },
      {
        bytes: "0",
        created_at: "2024-04-07 09:35:31",
        data: [
          {
            actions: [
              {
                action: "push-vlan",
                value: "CTAG:10",
              },
              {
                action: "push-vlan",
                value: "STAG:5",
              },
              {
                action: "output",
                value: "1",
              },
            ],
            bytes: 0,
            cookie: "0x2000",
            created_at: "2024-04-05 13:18:25",
            destination_port: "1",
            details: {
              destination_port_name: "WAN",
              source_port_name: "LAN 2",
              inner_label: 10,
              outer_label: 5,
              priority: 10,
              openflow_id: "manual:30910920021",
            },
            duration: 0,
            flow_signature: "be712710189c330fed5dbf0ad9b519f4",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "1",
                },
                {
                  action: "push-vlan",
                  value: "STAG:5",
                },
                {
                  action: "push-vlan",
                  value: "CTAG:10",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "3",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: "0",
            idle_time: "0",
            location: {
              table_id: "",
              table_flow_id: "",
            },
            matches: [
              {
                field: "in-port",
                value: "3",
              },
            ],
            name: "Bootstrap Push QinQ VLAN",
            packets: 0,
            priority: 10,
            source_port: "3",
            type: "BOOTSTRAP",
            raw_physical: [],
            raw_virtual: [],
            raw_data: {
              stripped_fields: [],
              always_stripped_fields_epcom: [
                "approval_values",
                "created_at",
                "globals",
                "globals_json",
                "guid",
                "level",
                "lft",
                "rgt",
                "updated_at",
              ],
              nonApprovalFields: ["approval_values", "id", "guid"],
              stripped_fields_epcom: [],
              epcom_dont_strip_fields: [],
            },
          },
        ],
        destination_mac: "",
        destination_port: "1",
        destination_port_name: "WAN",
        duration: "271062.029",
        idle_time: 0,
        incoming_vlan: "",
        inner_label: "10",
        ip_destination_address: "",
        ip_destination_port: "",
        ip_source_address: "",
        ip_source_port: "",
        name: "Bootstrap Push QinQ VLAN",
        notes: {
          ebfd45dd8f049e6be82340d655c097bb: "Type: BOOTSTRAP",
          ee371a12abbbf4c294cc98fe94f91399: "Label: CTAG:10",
          "06c7f8c303c2482a9e00e9658e238719": "Label: STAG:5",
          "1cfa534da20ccfe94f62c819a71dc955": "ODL Table: 0",
        },
        outer_label: "5",
        packets: "0",
        physical: [
          {
            actions: [
              {
                action: "push-vlan",
                value: "CTAG:10",
              },
              {
                action: "push-vlan",
                value: "STAG:5",
              },
              {
                action: "output",
                value: "1",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:30",
            destination_port: "1",
            details: [],
            duration: "271062.029",
            flow_signature: "be712710189c330fed5dbf0ad9b519f4",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "1",
                },
                {
                  action: "push-vlan",
                  value: "STAG:5",
                },
                {
                  action: "push-vlan",
                  value: "CTAG:10",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "3",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "3",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "3",
            type: "SERVICE",
            raw_physical: {
              string:
                " cookie=0x2000, duration=271062.029s, table=0, n_packets=0, n_bytes=0, priority=10,in_port=3 actions=push_vlan:0x8100,set_vlan_vid:10,push_vlan:0x88a8,set_vlan_vid:5,output:1",
            },
            raw_virtual: [],
            raw_data: [],
            source_port_state: "down",
            destination_port_state: "up",
          },
        ],
        priority: 10,
        protocol: "",
        signature: "be712710189c330fed5dbf0ad9b519f4",
        signature_data: {
          actions: [
            {
              action: "output",
              value: "1",
            },
            {
              action: "push-vlan",
              value: "STAG:5",
            },
            {
              action: "push-vlan",
              value: "CTAG:10",
            },
          ],
          matches: [
            {
              field: "in-port",
              value: "3",
            },
          ],
          priority: "10",
          table: "0",
        },
        source_port: "3",
        source_port_name: "LAN 2",
        table: 0,
        type: "BOOTSTRAP",
        virtual: [
          {
            actions: [
              {
                action: "push-vlan",
                value: "CTAG:10",
              },
              {
                action: "push-vlan",
                value: "STAG:5",
              },
              {
                action: "output",
                value: "1",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "1",
            details: [],
            duration: "271062.684",
            flow_signature: "be712710189c330fed5dbf0ad9b519f4",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "1",
                },
                {
                  action: "push-vlan",
                  value: "STAG:5",
                },
                {
                  action: "push-vlan",
                  value: "CTAG:10",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "3",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "3",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "3",
            type: "SERVICE",
            raw_physical: [],
            raw_virtual: {
              string:
                " cookie=0x2000, duration=271062.684s, table=0, n_packets=0, n_bytes=0, priority=10,in_port=3 actions=push_vlan:0x8100,set_vlan_vid:10,push_vlan:0x88a8,set_vlan_vid:5,output:1",
            },
            raw_data: [],
          },
        ],
      },
      {
        bytes: "0",
        created_at: "2024-04-07 09:35:32",
        data: [
          {
            actions: [
              {
                action: "push-vlan",
                value: "CTAG:10",
              },
              {
                action: "push-vlan",
                value: "STAG:5",
              },
              {
                action: "output",
                value: "1",
              },
            ],
            bytes: 0,
            cookie: "0x2000",
            created_at: "2024-04-05 13:18:25",
            destination_port: "1",
            details: {
              destination_port_name: "WAN",
              source_port_name: "LAN 3",
              inner_label: 10,
              outer_label: 5,
              priority: 10,
              openflow_id: "manual:30910920021",
            },
            duration: 0,
            flow_signature: "4b94c543e545fdf34b552465aaa5809d",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "1",
                },
                {
                  action: "push-vlan",
                  value: "STAG:5",
                },
                {
                  action: "push-vlan",
                  value: "CTAG:10",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "4",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: "0",
            idle_time: "0",
            location: {
              table_id: "",
              table_flow_id: "",
            },
            matches: [
              {
                field: "in-port",
                value: "4",
              },
            ],
            name: "Bootstrap Push QinQ VLAN",
            packets: 0,
            priority: 10,
            source_port: "4",
            type: "BOOTSTRAP",
            raw_physical: [],
            raw_virtual: [],
            raw_data: {
              stripped_fields: [],
              always_stripped_fields_epcom: [
                "approval_values",
                "created_at",
                "globals",
                "globals_json",
                "guid",
                "level",
                "lft",
                "rgt",
                "updated_at",
              ],
              nonApprovalFields: ["approval_values", "id", "guid"],
              stripped_fields_epcom: [],
              epcom_dont_strip_fields: [],
            },
          },
        ],
        destination_mac: "",
        destination_port: "1",
        destination_port_name: "WAN",
        duration: "271062",
        idle_time: 0,
        incoming_vlan: "",
        inner_label: "10",
        ip_destination_address: "",
        ip_destination_port: "",
        ip_source_address: "",
        ip_source_port: "",
        name: "Bootstrap Push QinQ VLAN",
        notes: {
          ebfd45dd8f049e6be82340d655c097bb: "Type: BOOTSTRAP",
          ee371a12abbbf4c294cc98fe94f91399: "Label: CTAG:10",
          "06c7f8c303c2482a9e00e9658e238719": "Label: STAG:5",
          "1cfa534da20ccfe94f62c819a71dc955": "ODL Table: 0",
        },
        outer_label: "5",
        packets: "0",
        physical: [
          {
            actions: [
              {
                action: "push-vlan",
                value: "CTAG:10",
              },
              {
                action: "push-vlan",
                value: "STAG:5",
              },
              {
                action: "output",
                value: "1",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "1",
            details: [],
            duration: "271062",
            flow_signature: "4b94c543e545fdf34b552465aaa5809d",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "1",
                },
                {
                  action: "push-vlan",
                  value: "STAG:5",
                },
                {
                  action: "push-vlan",
                  value: "CTAG:10",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "4",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "4",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "4",
            type: "SERVICE",
            raw_physical: {
              string:
                " cookie=0x2000, duration=271062s, table=0, n_packets=0, n_bytes=0, priority=10,in_port=4 actions=push_vlan:0x8100,set_vlan_vid:10,push_vlan:0x88a8,set_vlan_vid:5,output:1",
            },
            raw_virtual: [],
            raw_data: [],
            source_port_state: "down",
            destination_port_state: "up",
          },
        ],
        priority: 10,
        protocol: "",
        signature: "4b94c543e545fdf34b552465aaa5809d",
        signature_data: {
          actions: [
            {
              action: "output",
              value: "1",
            },
            {
              action: "push-vlan",
              value: "STAG:5",
            },
            {
              action: "push-vlan",
              value: "CTAG:10",
            },
          ],
          matches: [
            {
              field: "in-port",
              value: "4",
            },
          ],
          priority: "10",
          table: "0",
        },
        source_port: "4",
        source_port_name: "LAN 3",
        table: 0,
        type: "BOOTSTRAP",
        virtual: [
          {
            actions: [
              {
                action: "push-vlan",
                value: "CTAG:10",
              },
              {
                action: "push-vlan",
                value: "STAG:5",
              },
              {
                action: "output",
                value: "1",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "1",
            details: [],
            duration: "271062.655",
            flow_signature: "4b94c543e545fdf34b552465aaa5809d",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "1",
                },
                {
                  action: "push-vlan",
                  value: "STAG:5",
                },
                {
                  action: "push-vlan",
                  value: "CTAG:10",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "4",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "4",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "4",
            type: "SERVICE",
            raw_physical: [],
            raw_virtual: {
              string:
                " cookie=0x2000, duration=271062.655s, table=0, n_packets=0, n_bytes=0, priority=10,in_port=4 actions=push_vlan:0x8100,set_vlan_vid:10,push_vlan:0x88a8,set_vlan_vid:5,output:1",
            },
            raw_data: [],
          },
        ],
      },
      {
        bytes: "0",
        created_at: "2024-04-07 09:35:32",
        data: [
          {
            actions: [
              {
                action: "push-vlan",
                value: "CTAG:10",
              },
              {
                action: "push-vlan",
                value: "STAG:5",
              },
              {
                action: "output",
                value: "1",
              },
            ],
            bytes: 0,
            cookie: "0x2000",
            created_at: "2024-04-05 13:18:25",
            destination_port: "1",
            details: {
              destination_port_name: "WAN",
              source_port_name: "LAN 4",
              inner_label: 10,
              outer_label: 5,
              priority: 10,
              openflow_id: "manual:30910920021",
            },
            duration: 0,
            flow_signature: "ce93a11f7e8a3ed90c3a0c9d637eb0a3",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "1",
                },
                {
                  action: "push-vlan",
                  value: "STAG:5",
                },
                {
                  action: "push-vlan",
                  value: "CTAG:10",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "5",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: "0",
            idle_time: "0",
            location: {
              table_id: "",
              table_flow_id: "",
            },
            matches: [
              {
                field: "in-port",
                value: "5",
              },
            ],
            name: "Bootstrap Push QinQ VLAN",
            packets: 0,
            priority: 10,
            source_port: "5",
            type: "BOOTSTRAP",
            raw_physical: [],
            raw_virtual: [],
            raw_data: {
              stripped_fields: [],
              always_stripped_fields_epcom: [
                "approval_values",
                "created_at",
                "globals",
                "globals_json",
                "guid",
                "level",
                "lft",
                "rgt",
                "updated_at",
              ],
              nonApprovalFields: ["approval_values", "id", "guid"],
              stripped_fields_epcom: [],
              epcom_dont_strip_fields: [],
            },
          },
        ],
        destination_mac: "",
        destination_port: "1",
        destination_port_name: "WAN",
        duration: "271061.971",
        idle_time: 0,
        incoming_vlan: "",
        inner_label: "10",
        ip_destination_address: "",
        ip_destination_port: "",
        ip_source_address: "",
        ip_source_port: "",
        name: "Bootstrap Push QinQ VLAN",
        notes: {
          ebfd45dd8f049e6be82340d655c097bb: "Type: BOOTSTRAP",
          ee371a12abbbf4c294cc98fe94f91399: "Label: CTAG:10",
          "06c7f8c303c2482a9e00e9658e238719": "Label: STAG:5",
          "1cfa534da20ccfe94f62c819a71dc955": "ODL Table: 0",
        },
        outer_label: "5",
        packets: "0",
        physical: [
          {
            actions: [
              {
                action: "push-vlan",
                value: "CTAG:10",
              },
              {
                action: "push-vlan",
                value: "STAG:5",
              },
              {
                action: "output",
                value: "1",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "1",
            details: [],
            duration: "271061.971",
            flow_signature: "ce93a11f7e8a3ed90c3a0c9d637eb0a3",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "1",
                },
                {
                  action: "push-vlan",
                  value: "STAG:5",
                },
                {
                  action: "push-vlan",
                  value: "CTAG:10",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "5",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "5",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "5",
            type: "SERVICE",
            raw_physical: {
              string:
                " cookie=0x2000, duration=271061.971s, table=0, n_packets=0, n_bytes=0, priority=10,in_port=5 actions=push_vlan:0x8100,set_vlan_vid:10,push_vlan:0x88a8,set_vlan_vid:5,output:1",
            },
            raw_virtual: [],
            raw_data: [],
            source_port_state: "down",
            destination_port_state: "up",
          },
        ],
        priority: 10,
        protocol: "",
        signature: "ce93a11f7e8a3ed90c3a0c9d637eb0a3",
        signature_data: {
          actions: [
            {
              action: "output",
              value: "1",
            },
            {
              action: "push-vlan",
              value: "STAG:5",
            },
            {
              action: "push-vlan",
              value: "CTAG:10",
            },
          ],
          matches: [
            {
              field: "in-port",
              value: "5",
            },
          ],
          priority: "10",
          table: "0",
        },
        source_port: "5",
        source_port_name: "LAN 4",
        table: 0,
        type: "BOOTSTRAP",
        virtual: [
          {
            actions: [
              {
                action: "push-vlan",
                value: "CTAG:10",
              },
              {
                action: "push-vlan",
                value: "STAG:5",
              },
              {
                action: "output",
                value: "1",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "1",
            details: [],
            duration: "271062.626",
            flow_signature: "ce93a11f7e8a3ed90c3a0c9d637eb0a3",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "1",
                },
                {
                  action: "push-vlan",
                  value: "STAG:5",
                },
                {
                  action: "push-vlan",
                  value: "CTAG:10",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "5",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "5",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "5",
            type: "SERVICE",
            raw_physical: [],
            raw_virtual: {
              string:
                " cookie=0x2000, duration=271062.626s, table=0, n_packets=0, n_bytes=0, priority=10,in_port=5 actions=push_vlan:0x8100,set_vlan_vid:10,push_vlan:0x88a8,set_vlan_vid:5,output:1",
            },
            raw_data: [],
          },
        ],
      },
      {
        bytes: "0",
        created_at: "2024-04-07 09:35:32",
        data: [
          {
            actions: [
              {
                action: "push-vlan",
                value: "CTAG:10",
              },
              {
                action: "push-vlan",
                value: "STAG:5",
              },
              {
                action: "output",
                value: "1",
              },
            ],
            bytes: 0,
            cookie: "0x2000",
            created_at: "2024-04-05 13:18:25",
            destination_port: "1",
            details: {
              destination_port_name: "WAN",
              source_port_name: "P 1",
              inner_label: 10,
              outer_label: 5,
              priority: 10,
              openflow_id: "manual:30910920021",
            },
            duration: 0,
            flow_signature: "20d2a13d2971049ce70a5e321ac2d024",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "1",
                },
                {
                  action: "push-vlan",
                  value: "STAG:5",
                },
                {
                  action: "push-vlan",
                  value: "CTAG:10",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "6",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: "0",
            idle_time: "0",
            location: {
              table_id: "",
              table_flow_id: "",
            },
            matches: [
              {
                field: "in-port",
                value: "6",
              },
            ],
            name: "Bootstrap Push QinQ VLAN",
            packets: 0,
            priority: 10,
            source_port: "6",
            type: "BOOTSTRAP",
            raw_physical: [],
            raw_virtual: [],
            raw_data: {
              stripped_fields: [],
              always_stripped_fields_epcom: [
                "approval_values",
                "created_at",
                "globals",
                "globals_json",
                "guid",
                "level",
                "lft",
                "rgt",
                "updated_at",
              ],
              nonApprovalFields: ["approval_values", "id", "guid"],
              stripped_fields_epcom: [],
              epcom_dont_strip_fields: [],
            },
          },
        ],
        destination_mac: "",
        destination_port: "1",
        destination_port_name: "WAN",
        duration: "271061.943",
        idle_time: 0,
        incoming_vlan: "",
        inner_label: "10",
        ip_destination_address: "",
        ip_destination_port: "",
        ip_source_address: "",
        ip_source_port: "",
        name: "Bootstrap Push QinQ VLAN",
        notes: {
          ebfd45dd8f049e6be82340d655c097bb: "Type: BOOTSTRAP",
          ee371a12abbbf4c294cc98fe94f91399: "Label: CTAG:10",
          "06c7f8c303c2482a9e00e9658e238719": "Label: STAG:5",
          "1cfa534da20ccfe94f62c819a71dc955": "ODL Table: 0",
        },
        outer_label: "5",
        packets: "0",
        physical: [
          {
            actions: [
              {
                action: "push-vlan",
                value: "CTAG:10",
              },
              {
                action: "push-vlan",
                value: "STAG:5",
              },
              {
                action: "output",
                value: "1",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "1",
            details: [],
            duration: "271061.943",
            flow_signature: "20d2a13d2971049ce70a5e321ac2d024",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "1",
                },
                {
                  action: "push-vlan",
                  value: "STAG:5",
                },
                {
                  action: "push-vlan",
                  value: "CTAG:10",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "6",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "6",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "6",
            type: "SERVICE",
            raw_physical: {
              string:
                " cookie=0x2000, duration=271061.943s, table=0, n_packets=0, n_bytes=0, priority=10,in_port=6 actions=push_vlan:0x8100,set_vlan_vid:10,push_vlan:0x88a8,set_vlan_vid:5,output:1",
            },
            raw_virtual: [],
            raw_data: [],
            source_port_state: "down",
            destination_port_state: "up",
          },
        ],
        priority: 10,
        protocol: "",
        signature: "20d2a13d2971049ce70a5e321ac2d024",
        signature_data: {
          actions: [
            {
              action: "output",
              value: "1",
            },
            {
              action: "push-vlan",
              value: "STAG:5",
            },
            {
              action: "push-vlan",
              value: "CTAG:10",
            },
          ],
          matches: [
            {
              field: "in-port",
              value: "6",
            },
          ],
          priority: "10",
          table: "0",
        },
        source_port: "6",
        source_port_name: "P 1",
        table: 0,
        type: "BOOTSTRAP",
        virtual: [
          {
            actions: [
              {
                action: "push-vlan",
                value: "CTAG:10",
              },
              {
                action: "push-vlan",
                value: "STAG:5",
              },
              {
                action: "output",
                value: "1",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "1",
            details: [],
            duration: "271062.598",
            flow_signature: "20d2a13d2971049ce70a5e321ac2d024",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "1",
                },
                {
                  action: "push-vlan",
                  value: "STAG:5",
                },
                {
                  action: "push-vlan",
                  value: "CTAG:10",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "6",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "6",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "6",
            type: "SERVICE",
            raw_physical: [],
            raw_virtual: {
              string:
                " cookie=0x2000, duration=271062.598s, table=0, n_packets=0, n_bytes=0, priority=10,in_port=6 actions=push_vlan:0x8100,set_vlan_vid:10,push_vlan:0x88a8,set_vlan_vid:5,output:1",
            },
            raw_data: [],
          },
        ],
      },
      {
        bytes: "0",
        created_at: "2024-04-07 09:35:32",
        data: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: 0,
            cookie: "0x2000",
            created_at: "2024-04-05 13:18:25",
            destination_port: "DROP",
            details: {
              destination_port_name: "DROP",
              source_port_name: "N 1",
              inner_label: 10,
              outer_label: 5,
              priority: 10,
              openflow_id: "manual:30910920021",
            },
            duration: 0,
            flow_signature: "72c1f57ca74edc767b07074c51741aa0",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "dl_dst",
                  value: "ff:ff:ff:ff:ff:ff",
                },
                {
                  field: "in-port",
                  value: "2",
                },
                {
                  field: "tp_src",
                  value: "67",
                },
                {
                  field: "udp",
                  value: "udp",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: "0",
            idle_time: "0",
            location: {
              table_id: "",
              table_flow_id: "",
            },
            matches: [
              {
                field: "udp",
                value: "udp",
              },
              {
                field: "in-port",
                value: "2",
              },
              {
                field: "dl_dst",
                value: "ff:ff:ff:ff:ff:ff",
              },
              {
                field: "tp_src",
                value: "67",
              },
            ],
            name: "Drop DHCP",
            packets: 0,
            priority: 10,
            source_port: "2",
            type: "BOOTSTRAP",
            raw_physical: [],
            raw_virtual: [],
            raw_data: {
              stripped_fields: [],
              always_stripped_fields_epcom: [
                "approval_values",
                "created_at",
                "globals",
                "globals_json",
                "guid",
                "level",
                "lft",
                "rgt",
                "updated_at",
              ],
              nonApprovalFields: ["approval_values", "id", "guid"],
              stripped_fields_epcom: [],
              epcom_dont_strip_fields: [],
            },
          },
        ],
        destination_mac: "ff:ff:ff:ff:ff:ff",
        destination_port: "DROP",
        destination_port_name: "DROP",
        duration: "271061.912",
        idle_time: 0,
        incoming_vlan: "",
        inner_label: "",
        ip_destination_address: "",
        ip_destination_port: "",
        ip_source_address: "",
        ip_source_port: "67",
        name: "Drop DHCP",
        notes: {
          ebfd45dd8f049e6be82340d655c097bb: "Type: BOOTSTRAP",
          df7f5bd97fc378a65ed189fc0177d371: "DL Destination: ff:ff:ff:ff:ff:ff",
          "895ad2fba07b9e5347af0c6857b0b3ae": "TP Source: 67",
          "1cfa534da20ccfe94f62c819a71dc955": "ODL Table: 0",
        },
        outer_label: "",
        packets: "0",
        physical: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271061.912",
            flow_signature: "72c1f57ca74edc767b07074c51741aa0",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "dl_dst",
                  value: "ff:ff:ff:ff:ff:ff",
                },
                {
                  field: "in-port",
                  value: "2",
                },
                {
                  field: "tp_src",
                  value: "67",
                },
                {
                  field: "udp",
                  value: "udp",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "2",
              },
              {
                field: "dl_dst",
                value: "ff:ff:ff:ff:ff:ff",
              },
              {
                field: "tp_src",
                value: "67",
              },
              {
                field: "udp",
                value: "udp",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "2",
            type: "SERVICE",
            raw_physical: {
              string:
                " cookie=0x2000, duration=271061.912s, table=0, n_packets=0, n_bytes=0, priority=10,udp,in_port=2,dl_dst=ff:ff:ff:ff:ff:ff,tp_src=67 actions=drop",
            },
            raw_virtual: [],
            raw_data: [],
            source_port_state: "down",
            destination_port_state: "unknown",
          },
        ],
        priority: 10,
        protocol: "",
        signature: "72c1f57ca74edc767b07074c51741aa0",
        signature_data: {
          actions: [
            {
              action: "drop",
            },
          ],
          matches: [
            {
              field: "dl_dst",
              value: "ff:ff:ff:ff:ff:ff",
            },
            {
              field: "in-port",
              value: "2",
            },
            {
              field: "tp_src",
              value: "67",
            },
            {
              field: "udp",
              value: "udp",
            },
          ],
          priority: "10",
          table: "0",
        },
        source_port: "2",
        source_port_name: "N 1",
        table: 0,
        type: "BOOTSTRAP",
        virtual: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271062.567",
            flow_signature: "72c1f57ca74edc767b07074c51741aa0",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "dl_dst",
                  value: "ff:ff:ff:ff:ff:ff",
                },
                {
                  field: "in-port",
                  value: "2",
                },
                {
                  field: "tp_src",
                  value: "67",
                },
                {
                  field: "udp",
                  value: "udp",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "2",
              },
              {
                field: "dl_dst",
                value: "ff:ff:ff:ff:ff:ff",
              },
              {
                field: "tp_src",
                value: "67",
              },
              {
                field: "udp",
                value: "udp",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "2",
            type: "SERVICE",
            raw_physical: [],
            raw_virtual: {
              string:
                " cookie=0x2000, duration=271062.567s, table=0, n_packets=0, n_bytes=0, priority=10,udp,in_port=2,dl_dst=ff:ff:ff:ff:ff:ff,tp_src=67 actions=drop",
            },
            raw_data: [],
          },
        ],
      },
      {
        bytes: "0",
        created_at: "2024-04-07 09:35:32",
        data: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: 0,
            cookie: "0x2000",
            created_at: "2024-04-05 13:18:25",
            destination_port: "DROP",
            details: {
              destination_port_name: "DROP",
              source_port_name: "N 1",
              inner_label: 10,
              outer_label: 5,
              priority: 10,
              openflow_id: "manual:30910920021",
            },
            duration: 0,
            flow_signature: "be87ed81ee4b2598a5f3f301c4af0578",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "2",
                },
                {
                  field: "ip",
                  value: "ip",
                },
                {
                  field: "ipv4-destination",
                  value: "239.255.255.250/32",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: "0",
            idle_time: "0",
            location: {
              table_id: "",
              table_flow_id: "",
            },
            matches: [
              {
                field: "ip",
                value: "ip",
              },
              {
                field: "in-port",
                value: "2",
              },
              {
                field: "ipv4-destination",
                value: "239.255.255.250/32",
              },
            ],
            name: "Drop SSDP",
            packets: 0,
            priority: 10,
            source_port: "2",
            type: "BOOTSTRAP",
            raw_physical: [],
            raw_virtual: [],
            raw_data: {
              stripped_fields: [],
              always_stripped_fields_epcom: [
                "approval_values",
                "created_at",
                "globals",
                "globals_json",
                "guid",
                "level",
                "lft",
                "rgt",
                "updated_at",
              ],
              nonApprovalFields: ["approval_values", "id", "guid"],
              stripped_fields_epcom: [],
              epcom_dont_strip_fields: [],
            },
          },
        ],
        destination_mac: "",
        destination_port: "DROP",
        destination_port_name: "DROP",
        duration: "271061.749",
        idle_time: 0,
        incoming_vlan: "",
        inner_label: "",
        ip_destination_address: "239.255.255.250/32",
        ip_destination_port: "",
        ip_source_address: "",
        ip_source_port: "",
        name: "Drop SSDP",
        notes: {
          ebfd45dd8f049e6be82340d655c097bb: "Type: BOOTSTRAP",
          "6f4d0d90901761a8bc7ddf7eece9be85":
            "IP Destination: 239.255.255.250/32",
          "1cfa534da20ccfe94f62c819a71dc955": "ODL Table: 0",
        },
        outer_label: "",
        packets: "0",
        physical: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271061.749",
            flow_signature: "be87ed81ee4b2598a5f3f301c4af0578",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "2",
                },
                {
                  field: "ip",
                  value: "ip",
                },
                {
                  field: "ipv4-destination",
                  value: "239.255.255.250/32",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "2",
              },
              {
                field: "ipv4-destination",
                value: "239.255.255.250/32",
              },
              {
                field: "ip",
                value: "ip",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "2",
            type: "SERVICE",
            raw_physical: {
              string:
                " cookie=0x2000, duration=271061.749s, table=0, n_packets=0, n_bytes=0, priority=10,ip,in_port=2,nw_dst=239.255.255.250 actions=drop",
            },
            raw_virtual: [],
            raw_data: [],
            source_port_state: "down",
            destination_port_state: "unknown",
          },
        ],
        priority: 10,
        protocol: "",
        signature: "be87ed81ee4b2598a5f3f301c4af0578",
        signature_data: {
          actions: [
            {
              action: "drop",
            },
          ],
          matches: [
            {
              field: "in-port",
              value: "2",
            },
            {
              field: "ip",
              value: "ip",
            },
            {
              field: "ipv4-destination",
              value: "239.255.255.250/32",
            },
          ],
          priority: "10",
          table: "0",
        },
        source_port: "2",
        source_port_name: "N 1",
        table: 0,
        type: "BOOTSTRAP",
        virtual: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271062.404",
            flow_signature: "be87ed81ee4b2598a5f3f301c4af0578",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "2",
                },
                {
                  field: "ip",
                  value: "ip",
                },
                {
                  field: "ipv4-destination",
                  value: "239.255.255.250/32",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "2",
              },
              {
                field: "ipv4-destination",
                value: "239.255.255.250/32",
              },
              {
                field: "ip",
                value: "ip",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "2",
            type: "SERVICE",
            raw_physical: [],
            raw_virtual: {
              string:
                " cookie=0x2000, duration=271062.404s, table=0, n_packets=0, n_bytes=0, priority=10,ip,in_port=2,nw_dst=239.255.255.250 actions=drop",
            },
            raw_data: [],
          },
        ],
      },
      {
        bytes: "0",
        created_at: "2024-04-07 09:35:32",
        data: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: 0,
            cookie: "0x2000",
            created_at: "2024-04-05 13:18:25",
            destination_port: "DROP",
            details: {
              destination_port_name: "DROP",
              source_port_name: "LAN 2",
              inner_label: 10,
              outer_label: 5,
              priority: 10,
              openflow_id: "manual:30910920021",
            },
            duration: 0,
            flow_signature: "b24a6fb8488837544bbcdad246926266",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "dl_dst",
                  value: "ff:ff:ff:ff:ff:ff",
                },
                {
                  field: "in-port",
                  value: "3",
                },
                {
                  field: "tp_src",
                  value: "67",
                },
                {
                  field: "udp",
                  value: "udp",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: "0",
            idle_time: "0",
            location: {
              table_id: "",
              table_flow_id: "",
            },
            matches: [
              {
                field: "udp",
                value: "udp",
              },
              {
                field: "in-port",
                value: "3",
              },
              {
                field: "dl_dst",
                value: "ff:ff:ff:ff:ff:ff",
              },
              {
                field: "tp_src",
                value: "67",
              },
            ],
            name: "Drop DHCP",
            packets: 0,
            priority: 10,
            source_port: "3",
            type: "BOOTSTRAP",
            raw_physical: [],
            raw_virtual: [],
            raw_data: {
              stripped_fields: [],
              always_stripped_fields_epcom: [
                "approval_values",
                "created_at",
                "globals",
                "globals_json",
                "guid",
                "level",
                "lft",
                "rgt",
                "updated_at",
              ],
              nonApprovalFields: ["approval_values", "id", "guid"],
              stripped_fields_epcom: [],
              epcom_dont_strip_fields: [],
            },
          },
        ],
        destination_mac: "ff:ff:ff:ff:ff:ff",
        destination_port: "DROP",
        destination_port_name: "DROP",
        duration: "271061.879",
        idle_time: 0,
        incoming_vlan: "",
        inner_label: "",
        ip_destination_address: "",
        ip_destination_port: "",
        ip_source_address: "",
        ip_source_port: "67",
        name: "Drop DHCP",
        notes: {
          ebfd45dd8f049e6be82340d655c097bb: "Type: BOOTSTRAP",
          df7f5bd97fc378a65ed189fc0177d371: "DL Destination: ff:ff:ff:ff:ff:ff",
          "895ad2fba07b9e5347af0c6857b0b3ae": "TP Source: 67",
          "1cfa534da20ccfe94f62c819a71dc955": "ODL Table: 0",
        },
        outer_label: "",
        packets: "0",
        physical: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271061.879",
            flow_signature: "b24a6fb8488837544bbcdad246926266",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "dl_dst",
                  value: "ff:ff:ff:ff:ff:ff",
                },
                {
                  field: "in-port",
                  value: "3",
                },
                {
                  field: "tp_src",
                  value: "67",
                },
                {
                  field: "udp",
                  value: "udp",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "3",
              },
              {
                field: "dl_dst",
                value: "ff:ff:ff:ff:ff:ff",
              },
              {
                field: "tp_src",
                value: "67",
              },
              {
                field: "udp",
                value: "udp",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "3",
            type: "SERVICE",
            raw_physical: {
              string:
                " cookie=0x2000, duration=271061.879s, table=0, n_packets=0, n_bytes=0, priority=10,udp,in_port=3,dl_dst=ff:ff:ff:ff:ff:ff,tp_src=67 actions=drop",
            },
            raw_virtual: [],
            raw_data: [],
            source_port_state: "down",
            destination_port_state: "unknown",
          },
        ],
        priority: 10,
        protocol: "",
        signature: "b24a6fb8488837544bbcdad246926266",
        signature_data: {
          actions: [
            {
              action: "drop",
            },
          ],
          matches: [
            {
              field: "dl_dst",
              value: "ff:ff:ff:ff:ff:ff",
            },
            {
              field: "in-port",
              value: "3",
            },
            {
              field: "tp_src",
              value: "67",
            },
            {
              field: "udp",
              value: "udp",
            },
          ],
          priority: "10",
          table: "0",
        },
        source_port: "3",
        source_port_name: "LAN 2",
        table: 0,
        type: "BOOTSTRAP",
        virtual: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271062.534",
            flow_signature: "b24a6fb8488837544bbcdad246926266",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "dl_dst",
                  value: "ff:ff:ff:ff:ff:ff",
                },
                {
                  field: "in-port",
                  value: "3",
                },
                {
                  field: "tp_src",
                  value: "67",
                },
                {
                  field: "udp",
                  value: "udp",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "3",
              },
              {
                field: "dl_dst",
                value: "ff:ff:ff:ff:ff:ff",
              },
              {
                field: "tp_src",
                value: "67",
              },
              {
                field: "udp",
                value: "udp",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "3",
            type: "SERVICE",
            raw_physical: [],
            raw_virtual: {
              string:
                " cookie=0x2000, duration=271062.534s, table=0, n_packets=0, n_bytes=0, priority=10,udp,in_port=3,dl_dst=ff:ff:ff:ff:ff:ff,tp_src=67 actions=drop",
            },
            raw_data: [],
          },
        ],
      },
      {
        bytes: "0",
        created_at: "2024-04-07 09:35:32",
        data: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: 0,
            cookie: "0x2000",
            created_at: "2024-04-05 13:18:25",
            destination_port: "DROP",
            details: {
              destination_port_name: "DROP",
              source_port_name: "LAN 2",
              inner_label: 10,
              outer_label: 5,
              priority: 10,
              openflow_id: "manual:30910920021",
            },
            duration: 0,
            flow_signature: "c4fdbce47373adf751d22d7ad034931f",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "3",
                },
                {
                  field: "ip",
                  value: "ip",
                },
                {
                  field: "ipv4-destination",
                  value: "239.255.255.250/32",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: "0",
            idle_time: "0",
            location: {
              table_id: "",
              table_flow_id: "",
            },
            matches: [
              {
                field: "ip",
                value: "ip",
              },
              {
                field: "in-port",
                value: "3",
              },
              {
                field: "ipv4-destination",
                value: "239.255.255.250/32",
              },
            ],
            name: "Drop SSDP",
            packets: 0,
            priority: 10,
            source_port: "3",
            type: "BOOTSTRAP",
            raw_physical: [],
            raw_virtual: [],
            raw_data: {
              stripped_fields: [],
              always_stripped_fields_epcom: [
                "approval_values",
                "created_at",
                "globals",
                "globals_json",
                "guid",
                "level",
                "lft",
                "rgt",
                "updated_at",
              ],
              nonApprovalFields: ["approval_values", "id", "guid"],
              stripped_fields_epcom: [],
              epcom_dont_strip_fields: [],
            },
          },
        ],
        destination_mac: "",
        destination_port: "DROP",
        destination_port_name: "DROP",
        duration: "271061.721",
        idle_time: 0,
        incoming_vlan: "",
        inner_label: "",
        ip_destination_address: "239.255.255.250/32",
        ip_destination_port: "",
        ip_source_address: "",
        ip_source_port: "",
        name: "Drop SSDP",
        notes: {
          ebfd45dd8f049e6be82340d655c097bb: "Type: BOOTSTRAP",
          "6f4d0d90901761a8bc7ddf7eece9be85":
            "IP Destination: 239.255.255.250/32",
          "1cfa534da20ccfe94f62c819a71dc955": "ODL Table: 0",
        },
        outer_label: "",
        packets: "0",
        physical: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271061.721",
            flow_signature: "c4fdbce47373adf751d22d7ad034931f",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "3",
                },
                {
                  field: "ip",
                  value: "ip",
                },
                {
                  field: "ipv4-destination",
                  value: "239.255.255.250/32",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "3",
              },
              {
                field: "ipv4-destination",
                value: "239.255.255.250/32",
              },
              {
                field: "ip",
                value: "ip",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "3",
            type: "SERVICE",
            raw_physical: {
              string:
                " cookie=0x2000, duration=271061.721s, table=0, n_packets=0, n_bytes=0, priority=10,ip,in_port=3,nw_dst=239.255.255.250 actions=drop",
            },
            raw_virtual: [],
            raw_data: [],
            source_port_state: "down",
            destination_port_state: "unknown",
          },
        ],
        priority: 10,
        protocol: "",
        signature: "c4fdbce47373adf751d22d7ad034931f",
        signature_data: {
          actions: [
            {
              action: "drop",
            },
          ],
          matches: [
            {
              field: "in-port",
              value: "3",
            },
            {
              field: "ip",
              value: "ip",
            },
            {
              field: "ipv4-destination",
              value: "239.255.255.250/32",
            },
          ],
          priority: "10",
          table: "0",
        },
        source_port: "3",
        source_port_name: "LAN 2",
        table: 0,
        type: "BOOTSTRAP",
        virtual: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271062.376",
            flow_signature: "c4fdbce47373adf751d22d7ad034931f",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "3",
                },
                {
                  field: "ip",
                  value: "ip",
                },
                {
                  field: "ipv4-destination",
                  value: "239.255.255.250/32",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "3",
              },
              {
                field: "ipv4-destination",
                value: "239.255.255.250/32",
              },
              {
                field: "ip",
                value: "ip",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "3",
            type: "SERVICE",
            raw_physical: [],
            raw_virtual: {
              string:
                " cookie=0x2000, duration=271062.376s, table=0, n_packets=0, n_bytes=0, priority=10,ip,in_port=3,nw_dst=239.255.255.250 actions=drop",
            },
            raw_data: [],
          },
        ],
      },
      {
        bytes: "0",
        created_at: "2024-04-07 09:35:32",
        data: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: 0,
            cookie: "0x2000",
            created_at: "2024-04-05 13:18:25",
            destination_port: "DROP",
            details: {
              destination_port_name: "DROP",
              source_port_name: "LAN 3",
              inner_label: 10,
              outer_label: 5,
              priority: 10,
              openflow_id: "manual:30910920021",
            },
            duration: 0,
            flow_signature: "d48f28706b682fe4b228cfdbdf63b845",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "dl_dst",
                  value: "ff:ff:ff:ff:ff:ff",
                },
                {
                  field: "in-port",
                  value: "4",
                },
                {
                  field: "tp_src",
                  value: "67",
                },
                {
                  field: "udp",
                  value: "udp",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: "0",
            idle_time: "0",
            location: {
              table_id: "",
              table_flow_id: "",
            },
            matches: [
              {
                field: "udp",
                value: "udp",
              },
              {
                field: "in-port",
                value: "4",
              },
              {
                field: "dl_dst",
                value: "ff:ff:ff:ff:ff:ff",
              },
              {
                field: "tp_src",
                value: "67",
              },
            ],
            name: "Drop DHCP",
            packets: 0,
            priority: 10,
            source_port: "4",
            type: "BOOTSTRAP",
            raw_physical: [],
            raw_virtual: [],
            raw_data: {
              stripped_fields: [],
              always_stripped_fields_epcom: [
                "approval_values",
                "created_at",
                "globals",
                "globals_json",
                "guid",
                "level",
                "lft",
                "rgt",
                "updated_at",
              ],
              nonApprovalFields: ["approval_values", "id", "guid"],
              stripped_fields_epcom: [],
              epcom_dont_strip_fields: [],
            },
          },
        ],
        destination_mac: "ff:ff:ff:ff:ff:ff",
        destination_port: "DROP",
        destination_port_name: "DROP",
        duration: "271061.839",
        idle_time: 0,
        incoming_vlan: "",
        inner_label: "",
        ip_destination_address: "",
        ip_destination_port: "",
        ip_source_address: "",
        ip_source_port: "67",
        name: "Drop DHCP",
        notes: {
          ebfd45dd8f049e6be82340d655c097bb: "Type: BOOTSTRAP",
          df7f5bd97fc378a65ed189fc0177d371: "DL Destination: ff:ff:ff:ff:ff:ff",
          "895ad2fba07b9e5347af0c6857b0b3ae": "TP Source: 67",
          "1cfa534da20ccfe94f62c819a71dc955": "ODL Table: 0",
        },
        outer_label: "",
        packets: "0",
        physical: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271061.839",
            flow_signature: "d48f28706b682fe4b228cfdbdf63b845",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "dl_dst",
                  value: "ff:ff:ff:ff:ff:ff",
                },
                {
                  field: "in-port",
                  value: "4",
                },
                {
                  field: "tp_src",
                  value: "67",
                },
                {
                  field: "udp",
                  value: "udp",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "4",
              },
              {
                field: "dl_dst",
                value: "ff:ff:ff:ff:ff:ff",
              },
              {
                field: "tp_src",
                value: "67",
              },
              {
                field: "udp",
                value: "udp",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "4",
            type: "SERVICE",
            raw_physical: {
              string:
                " cookie=0x2000, duration=271061.839s, table=0, n_packets=0, n_bytes=0, priority=10,udp,in_port=4,dl_dst=ff:ff:ff:ff:ff:ff,tp_src=67 actions=drop",
            },
            raw_virtual: [],
            raw_data: [],
            source_port_state: "down",
            destination_port_state: "unknown",
          },
        ],
        priority: 10,
        protocol: "",
        signature: "d48f28706b682fe4b228cfdbdf63b845",
        signature_data: {
          actions: [
            {
              action: "drop",
            },
          ],
          matches: [
            {
              field: "dl_dst",
              value: "ff:ff:ff:ff:ff:ff",
            },
            {
              field: "in-port",
              value: "4",
            },
            {
              field: "tp_src",
              value: "67",
            },
            {
              field: "udp",
              value: "udp",
            },
          ],
          priority: "10",
          table: "0",
        },
        source_port: "4",
        source_port_name: "LAN 3",
        table: 0,
        type: "BOOTSTRAP",
        virtual: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271062.494",
            flow_signature: "d48f28706b682fe4b228cfdbdf63b845",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "dl_dst",
                  value: "ff:ff:ff:ff:ff:ff",
                },
                {
                  field: "in-port",
                  value: "4",
                },
                {
                  field: "tp_src",
                  value: "67",
                },
                {
                  field: "udp",
                  value: "udp",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "4",
              },
              {
                field: "dl_dst",
                value: "ff:ff:ff:ff:ff:ff",
              },
              {
                field: "tp_src",
                value: "67",
              },
              {
                field: "udp",
                value: "udp",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "4",
            type: "SERVICE",
            raw_physical: [],
            raw_virtual: {
              string:
                " cookie=0x2000, duration=271062.494s, table=0, n_packets=0, n_bytes=0, priority=10,udp,in_port=4,dl_dst=ff:ff:ff:ff:ff:ff,tp_src=67 actions=drop",
            },
            raw_data: [],
          },
        ],
      },
      {
        bytes: "0",
        created_at: "2024-04-07 09:35:32",
        data: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: 0,
            cookie: "0x2000",
            created_at: "2024-04-05 13:18:25",
            destination_port: "DROP",
            details: {
              destination_port_name: "DROP",
              source_port_name: "LAN 3",
              inner_label: 10,
              outer_label: 5,
              priority: 10,
              openflow_id: "manual:30910920021",
            },
            duration: 0,
            flow_signature: "ef1400ee144468c7584b4cc13c7af338",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "4",
                },
                {
                  field: "ip",
                  value: "ip",
                },
                {
                  field: "ipv4-destination",
                  value: "239.255.255.250/32",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: "0",
            idle_time: "0",
            location: {
              table_id: "",
              table_flow_id: "",
            },
            matches: [
              {
                field: "ip",
                value: "ip",
              },
              {
                field: "in-port",
                value: "4",
              },
              {
                field: "ipv4-destination",
                value: "239.255.255.250/32",
              },
            ],
            name: "Drop SSDP",
            packets: 0,
            priority: 10,
            source_port: "4",
            type: "BOOTSTRAP",
            raw_physical: [],
            raw_virtual: [],
            raw_data: {
              stripped_fields: [],
              always_stripped_fields_epcom: [
                "approval_values",
                "created_at",
                "globals",
                "globals_json",
                "guid",
                "level",
                "lft",
                "rgt",
                "updated_at",
              ],
              nonApprovalFields: ["approval_values", "id", "guid"],
              stripped_fields_epcom: [],
              epcom_dont_strip_fields: [],
            },
          },
        ],
        destination_mac: "",
        destination_port: "DROP",
        destination_port_name: "DROP",
        duration: "271061.692",
        idle_time: 0,
        incoming_vlan: "",
        inner_label: "",
        ip_destination_address: "239.255.255.250/32",
        ip_destination_port: "",
        ip_source_address: "",
        ip_source_port: "",
        name: "Drop SSDP",
        notes: {
          ebfd45dd8f049e6be82340d655c097bb: "Type: BOOTSTRAP",
          "6f4d0d90901761a8bc7ddf7eece9be85":
            "IP Destination: 239.255.255.250/32",
          "1cfa534da20ccfe94f62c819a71dc955": "ODL Table: 0",
        },
        outer_label: "",
        packets: "0",
        physical: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271061.692",
            flow_signature: "ef1400ee144468c7584b4cc13c7af338",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "4",
                },
                {
                  field: "ip",
                  value: "ip",
                },
                {
                  field: "ipv4-destination",
                  value: "239.255.255.250/32",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "4",
              },
              {
                field: "ipv4-destination",
                value: "239.255.255.250/32",
              },
              {
                field: "ip",
                value: "ip",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "4",
            type: "SERVICE",
            raw_physical: {
              string:
                " cookie=0x2000, duration=271061.692s, table=0, n_packets=0, n_bytes=0, priority=10,ip,in_port=4,nw_dst=239.255.255.250 actions=drop",
            },
            raw_virtual: [],
            raw_data: [],
            source_port_state: "down",
            destination_port_state: "unknown",
          },
        ],
        priority: 10,
        protocol: "",
        signature: "ef1400ee144468c7584b4cc13c7af338",
        signature_data: {
          actions: [
            {
              action: "drop",
            },
          ],
          matches: [
            {
              field: "in-port",
              value: "4",
            },
            {
              field: "ip",
              value: "ip",
            },
            {
              field: "ipv4-destination",
              value: "239.255.255.250/32",
            },
          ],
          priority: "10",
          table: "0",
        },
        source_port: "4",
        source_port_name: "LAN 3",
        table: 0,
        type: "BOOTSTRAP",
        virtual: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271062.347",
            flow_signature: "ef1400ee144468c7584b4cc13c7af338",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "4",
                },
                {
                  field: "ip",
                  value: "ip",
                },
                {
                  field: "ipv4-destination",
                  value: "239.255.255.250/32",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "4",
              },
              {
                field: "ipv4-destination",
                value: "239.255.255.250/32",
              },
              {
                field: "ip",
                value: "ip",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "4",
            type: "SERVICE",
            raw_physical: [],
            raw_virtual: {
              string:
                " cookie=0x2000, duration=271062.347s, table=0, n_packets=0, n_bytes=0, priority=10,ip,in_port=4,nw_dst=239.255.255.250 actions=drop",
            },
            raw_data: [],
          },
        ],
      },
      {
        bytes: "0",
        created_at: "2024-04-07 09:35:32",
        data: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: 0,
            cookie: "0x2000",
            created_at: "2024-04-05 13:18:25",
            destination_port: "DROP",
            details: {
              destination_port_name: "DROP",
              source_port_name: "LAN 4",
              inner_label: 10,
              outer_label: 5,
              priority: 10,
              openflow_id: "manual:30910920021",
            },
            duration: 0,
            flow_signature: "3b3c7488b0caf9615dd3d907ea9fa0a5",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "dl_dst",
                  value: "ff:ff:ff:ff:ff:ff",
                },
                {
                  field: "in-port",
                  value: "5",
                },
                {
                  field: "tp_src",
                  value: "67",
                },
                {
                  field: "udp",
                  value: "udp",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: "0",
            idle_time: "0",
            location: {
              table_id: "",
              table_flow_id: "",
            },
            matches: [
              {
                field: "udp",
                value: "udp",
              },
              {
                field: "in-port",
                value: "5",
              },
              {
                field: "dl_dst",
                value: "ff:ff:ff:ff:ff:ff",
              },
              {
                field: "tp_src",
                value: "67",
              },
            ],
            name: "Drop DHCP",
            packets: 0,
            priority: 10,
            source_port: "5",
            type: "BOOTSTRAP",
            raw_physical: [],
            raw_virtual: [],
            raw_data: {
              stripped_fields: [],
              always_stripped_fields_epcom: [
                "approval_values",
                "created_at",
                "globals",
                "globals_json",
                "guid",
                "level",
                "lft",
                "rgt",
                "updated_at",
              ],
              nonApprovalFields: ["approval_values", "id", "guid"],
              stripped_fields_epcom: [],
              epcom_dont_strip_fields: [],
            },
          },
        ],
        destination_mac: "ff:ff:ff:ff:ff:ff",
        destination_port: "DROP",
        destination_port_name: "DROP",
        duration: "271061.808",
        idle_time: 0,
        incoming_vlan: "",
        inner_label: "",
        ip_destination_address: "",
        ip_destination_port: "",
        ip_source_address: "",
        ip_source_port: "67",
        name: "Drop DHCP",
        notes: {
          ebfd45dd8f049e6be82340d655c097bb: "Type: BOOTSTRAP",
          df7f5bd97fc378a65ed189fc0177d371: "DL Destination: ff:ff:ff:ff:ff:ff",
          "895ad2fba07b9e5347af0c6857b0b3ae": "TP Source: 67",
          "1cfa534da20ccfe94f62c819a71dc955": "ODL Table: 0",
        },
        outer_label: "",
        packets: "0",
        physical: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271061.808",
            flow_signature: "3b3c7488b0caf9615dd3d907ea9fa0a5",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "dl_dst",
                  value: "ff:ff:ff:ff:ff:ff",
                },
                {
                  field: "in-port",
                  value: "5",
                },
                {
                  field: "tp_src",
                  value: "67",
                },
                {
                  field: "udp",
                  value: "udp",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "5",
              },
              {
                field: "dl_dst",
                value: "ff:ff:ff:ff:ff:ff",
              },
              {
                field: "tp_src",
                value: "67",
              },
              {
                field: "udp",
                value: "udp",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "5",
            type: "SERVICE",
            raw_physical: {
              string:
                " cookie=0x2000, duration=271061.808s, table=0, n_packets=0, n_bytes=0, priority=10,udp,in_port=5,dl_dst=ff:ff:ff:ff:ff:ff,tp_src=67 actions=drop",
            },
            raw_virtual: [],
            raw_data: [],
            source_port_state: "down",
            destination_port_state: "unknown",
          },
        ],
        priority: 10,
        protocol: "",
        signature: "3b3c7488b0caf9615dd3d907ea9fa0a5",
        signature_data: {
          actions: [
            {
              action: "drop",
            },
          ],
          matches: [
            {
              field: "dl_dst",
              value: "ff:ff:ff:ff:ff:ff",
            },
            {
              field: "in-port",
              value: "5",
            },
            {
              field: "tp_src",
              value: "67",
            },
            {
              field: "udp",
              value: "udp",
            },
          ],
          priority: "10",
          table: "0",
        },
        source_port: "5",
        source_port_name: "LAN 4",
        table: 0,
        type: "BOOTSTRAP",
        virtual: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271062.463",
            flow_signature: "3b3c7488b0caf9615dd3d907ea9fa0a5",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "dl_dst",
                  value: "ff:ff:ff:ff:ff:ff",
                },
                {
                  field: "in-port",
                  value: "5",
                },
                {
                  field: "tp_src",
                  value: "67",
                },
                {
                  field: "udp",
                  value: "udp",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "5",
              },
              {
                field: "dl_dst",
                value: "ff:ff:ff:ff:ff:ff",
              },
              {
                field: "tp_src",
                value: "67",
              },
              {
                field: "udp",
                value: "udp",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "5",
            type: "SERVICE",
            raw_physical: [],
            raw_virtual: {
              string:
                " cookie=0x2000, duration=271062.463s, table=0, n_packets=0, n_bytes=0, priority=10,udp,in_port=5,dl_dst=ff:ff:ff:ff:ff:ff,tp_src=67 actions=drop",
            },
            raw_data: [],
          },
        ],
      },
      {
        bytes: "0",
        created_at: "2024-04-07 09:35:32",
        data: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: 0,
            cookie: "0x2000",
            created_at: "2024-04-05 13:18:26",
            destination_port: "DROP",
            details: {
              destination_port_name: "DROP",
              source_port_name: "LAN 4",
              inner_label: 10,
              outer_label: 5,
              priority: 10,
              openflow_id: "manual:30910920021",
            },
            duration: 0,
            flow_signature: "e9a3199662bce01b769c6a26f9eadb5b",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "5",
                },
                {
                  field: "ip",
                  value: "ip",
                },
                {
                  field: "ipv4-destination",
                  value: "239.255.255.250/32",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: "0",
            idle_time: "0",
            location: {
              table_id: "",
              table_flow_id: "",
            },
            matches: [
              {
                field: "ip",
                value: "ip",
              },
              {
                field: "in-port",
                value: "5",
              },
              {
                field: "ipv4-destination",
                value: "239.255.255.250/32",
              },
            ],
            name: "Drop SSDP",
            packets: 0,
            priority: 10,
            source_port: "5",
            type: "BOOTSTRAP",
            raw_physical: [],
            raw_virtual: [],
            raw_data: {
              stripped_fields: [],
              always_stripped_fields_epcom: [
                "approval_values",
                "created_at",
                "globals",
                "globals_json",
                "guid",
                "level",
                "lft",
                "rgt",
                "updated_at",
              ],
              nonApprovalFields: ["approval_values", "id", "guid"],
              stripped_fields_epcom: [],
              epcom_dont_strip_fields: [],
            },
          },
        ],
        destination_mac: "",
        destination_port: "DROP",
        destination_port_name: "DROP",
        duration: "271061.662",
        idle_time: 0,
        incoming_vlan: "",
        inner_label: "",
        ip_destination_address: "239.255.255.250/32",
        ip_destination_port: "",
        ip_source_address: "",
        ip_source_port: "",
        name: "Drop SSDP",
        notes: {
          ebfd45dd8f049e6be82340d655c097bb: "Type: BOOTSTRAP",
          "6f4d0d90901761a8bc7ddf7eece9be85":
            "IP Destination: 239.255.255.250/32",
          "1cfa534da20ccfe94f62c819a71dc955": "ODL Table: 0",
        },
        outer_label: "",
        packets: "0",
        physical: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271061.662",
            flow_signature: "e9a3199662bce01b769c6a26f9eadb5b",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "5",
                },
                {
                  field: "ip",
                  value: "ip",
                },
                {
                  field: "ipv4-destination",
                  value: "239.255.255.250/32",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "5",
              },
              {
                field: "ipv4-destination",
                value: "239.255.255.250/32",
              },
              {
                field: "ip",
                value: "ip",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "5",
            type: "SERVICE",
            raw_physical: {
              string:
                " cookie=0x2000, duration=271061.662s, table=0, n_packets=0, n_bytes=0, priority=10,ip,in_port=5,nw_dst=239.255.255.250 actions=drop",
            },
            raw_virtual: [],
            raw_data: [],
            source_port_state: "down",
            destination_port_state: "unknown",
          },
        ],
        priority: 10,
        protocol: "",
        signature: "e9a3199662bce01b769c6a26f9eadb5b",
        signature_data: {
          actions: [
            {
              action: "drop",
            },
          ],
          matches: [
            {
              field: "in-port",
              value: "5",
            },
            {
              field: "ip",
              value: "ip",
            },
            {
              field: "ipv4-destination",
              value: "239.255.255.250/32",
            },
          ],
          priority: "10",
          table: "0",
        },
        source_port: "5",
        source_port_name: "LAN 4",
        table: 0,
        type: "BOOTSTRAP",
        virtual: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271062.317",
            flow_signature: "e9a3199662bce01b769c6a26f9eadb5b",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "5",
                },
                {
                  field: "ip",
                  value: "ip",
                },
                {
                  field: "ipv4-destination",
                  value: "239.255.255.250/32",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "5",
              },
              {
                field: "ipv4-destination",
                value: "239.255.255.250/32",
              },
              {
                field: "ip",
                value: "ip",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "5",
            type: "SERVICE",
            raw_physical: [],
            raw_virtual: {
              string:
                " cookie=0x2000, duration=271062.317s, table=0, n_packets=0, n_bytes=0, priority=10,ip,in_port=5,nw_dst=239.255.255.250 actions=drop",
            },
            raw_data: [],
          },
        ],
      },
      {
        bytes: "0",
        created_at: "2024-04-07 09:35:32",
        data: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: 0,
            cookie: "0x2000",
            created_at: "2024-04-05 13:18:25",
            destination_port: "DROP",
            details: {
              destination_port_name: "DROP",
              source_port_name: "P 1",
              inner_label: 10,
              outer_label: 5,
              priority: 10,
              openflow_id: "manual:30910920021",
            },
            duration: 0,
            flow_signature: "e7e4a1b3090abedfbc149e21ffca9782",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "dl_dst",
                  value: "ff:ff:ff:ff:ff:ff",
                },
                {
                  field: "in-port",
                  value: "6",
                },
                {
                  field: "tp_src",
                  value: "67",
                },
                {
                  field: "udp",
                  value: "udp",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: "0",
            idle_time: "0",
            location: {
              table_id: "",
              table_flow_id: "",
            },
            matches: [
              {
                field: "udp",
                value: "udp",
              },
              {
                field: "in-port",
                value: "6",
              },
              {
                field: "dl_dst",
                value: "ff:ff:ff:ff:ff:ff",
              },
              {
                field: "tp_src",
                value: "67",
              },
            ],
            name: "Drop DHCP",
            packets: 0,
            priority: 10,
            source_port: "6",
            type: "BOOTSTRAP",
            raw_physical: [],
            raw_virtual: [],
            raw_data: {
              stripped_fields: [],
              always_stripped_fields_epcom: [
                "approval_values",
                "created_at",
                "globals",
                "globals_json",
                "guid",
                "level",
                "lft",
                "rgt",
                "updated_at",
              ],
              nonApprovalFields: ["approval_values", "id", "guid"],
              stripped_fields_epcom: [],
              epcom_dont_strip_fields: [],
            },
          },
        ],
        destination_mac: "ff:ff:ff:ff:ff:ff",
        destination_port: "DROP",
        destination_port_name: "DROP",
        duration: "271061.776",
        idle_time: 0,
        incoming_vlan: "",
        inner_label: "",
        ip_destination_address: "",
        ip_destination_port: "",
        ip_source_address: "",
        ip_source_port: "67",
        name: "Drop DHCP",
        notes: {
          ebfd45dd8f049e6be82340d655c097bb: "Type: BOOTSTRAP",
          df7f5bd97fc378a65ed189fc0177d371: "DL Destination: ff:ff:ff:ff:ff:ff",
          "895ad2fba07b9e5347af0c6857b0b3ae": "TP Source: 67",
          "1cfa534da20ccfe94f62c819a71dc955": "ODL Table: 0",
        },
        outer_label: "",
        packets: "0",
        physical: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271061.776",
            flow_signature: "e7e4a1b3090abedfbc149e21ffca9782",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "dl_dst",
                  value: "ff:ff:ff:ff:ff:ff",
                },
                {
                  field: "in-port",
                  value: "6",
                },
                {
                  field: "tp_src",
                  value: "67",
                },
                {
                  field: "udp",
                  value: "udp",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "6",
              },
              {
                field: "dl_dst",
                value: "ff:ff:ff:ff:ff:ff",
              },
              {
                field: "tp_src",
                value: "67",
              },
              {
                field: "udp",
                value: "udp",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "6",
            type: "SERVICE",
            raw_physical: {
              string:
                " cookie=0x2000, duration=271061.776s, table=0, n_packets=0, n_bytes=0, priority=10,udp,in_port=6,dl_dst=ff:ff:ff:ff:ff:ff,tp_src=67 actions=drop",
            },
            raw_virtual: [],
            raw_data: [],
            source_port_state: "down",
            destination_port_state: "unknown",
          },
        ],
        priority: 10,
        protocol: "",
        signature: "e7e4a1b3090abedfbc149e21ffca9782",
        signature_data: {
          actions: [
            {
              action: "drop",
            },
          ],
          matches: [
            {
              field: "dl_dst",
              value: "ff:ff:ff:ff:ff:ff",
            },
            {
              field: "in-port",
              value: "6",
            },
            {
              field: "tp_src",
              value: "67",
            },
            {
              field: "udp",
              value: "udp",
            },
          ],
          priority: "10",
          table: "0",
        },
        source_port: "6",
        source_port_name: "P 1",
        table: 0,
        type: "BOOTSTRAP",
        virtual: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271062.431",
            flow_signature: "e7e4a1b3090abedfbc149e21ffca9782",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "dl_dst",
                  value: "ff:ff:ff:ff:ff:ff",
                },
                {
                  field: "in-port",
                  value: "6",
                },
                {
                  field: "tp_src",
                  value: "67",
                },
                {
                  field: "udp",
                  value: "udp",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "6",
              },
              {
                field: "dl_dst",
                value: "ff:ff:ff:ff:ff:ff",
              },
              {
                field: "tp_src",
                value: "67",
              },
              {
                field: "udp",
                value: "udp",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "6",
            type: "SERVICE",
            raw_physical: [],
            raw_virtual: {
              string:
                " cookie=0x2000, duration=271062.431s, table=0, n_packets=0, n_bytes=0, priority=10,udp,in_port=6,dl_dst=ff:ff:ff:ff:ff:ff,tp_src=67 actions=drop",
            },
            raw_data: [],
          },
        ],
      },
      {
        bytes: "0",
        created_at: "2024-04-07 09:35:32",
        data: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: 0,
            cookie: "0x2000",
            created_at: "2024-04-05 13:18:26",
            destination_port: "DROP",
            details: {
              destination_port_name: "DROP",
              source_port_name: "P 1",
              inner_label: 10,
              outer_label: 5,
              priority: 10,
              openflow_id: "manual:30910920021",
            },
            duration: 0,
            flow_signature: "a51cd9a3d3d47b0cb3d37916e992de78",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "6",
                },
                {
                  field: "ip",
                  value: "ip",
                },
                {
                  field: "ipv4-destination",
                  value: "239.255.255.250/32",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: "0",
            idle_time: "0",
            location: {
              table_id: "",
              table_flow_id: "",
            },
            matches: [
              {
                field: "ip",
                value: "ip",
              },
              {
                field: "in-port",
                value: "6",
              },
              {
                field: "ipv4-destination",
                value: "239.255.255.250/32",
              },
            ],
            name: "Drop SSDP",
            packets: 0,
            priority: 10,
            source_port: "6",
            type: "BOOTSTRAP",
            raw_physical: [],
            raw_virtual: [],
            raw_data: {
              stripped_fields: [],
              always_stripped_fields_epcom: [
                "approval_values",
                "created_at",
                "globals",
                "globals_json",
                "guid",
                "level",
                "lft",
                "rgt",
                "updated_at",
              ],
              nonApprovalFields: ["approval_values", "id", "guid"],
              stripped_fields_epcom: [],
              epcom_dont_strip_fields: [],
            },
          },
        ],
        destination_mac: "",
        destination_port: "DROP",
        destination_port_name: "DROP",
        duration: "271061.635",
        idle_time: 0,
        incoming_vlan: "",
        inner_label: "",
        ip_destination_address: "239.255.255.250/32",
        ip_destination_port: "",
        ip_source_address: "",
        ip_source_port: "",
        name: "Drop SSDP",
        notes: {
          ebfd45dd8f049e6be82340d655c097bb: "Type: BOOTSTRAP",
          "6f4d0d90901761a8bc7ddf7eece9be85":
            "IP Destination: 239.255.255.250/32",
          "1cfa534da20ccfe94f62c819a71dc955": "ODL Table: 0",
        },
        outer_label: "",
        packets: "0",
        physical: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271061.635",
            flow_signature: "a51cd9a3d3d47b0cb3d37916e992de78",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "6",
                },
                {
                  field: "ip",
                  value: "ip",
                },
                {
                  field: "ipv4-destination",
                  value: "239.255.255.250/32",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "6",
              },
              {
                field: "ipv4-destination",
                value: "239.255.255.250/32",
              },
              {
                field: "ip",
                value: "ip",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "6",
            type: "SERVICE",
            raw_physical: {
              string:
                " cookie=0x2000, duration=271061.635s, table=0, n_packets=0, n_bytes=0, priority=10,ip,in_port=6,nw_dst=239.255.255.250 actions=drop",
            },
            raw_virtual: [],
            raw_data: [],
            source_port_state: "down",
            destination_port_state: "unknown",
          },
        ],
        priority: 10,
        protocol: "",
        signature: "a51cd9a3d3d47b0cb3d37916e992de78",
        signature_data: {
          actions: [
            {
              action: "drop",
            },
          ],
          matches: [
            {
              field: "in-port",
              value: "6",
            },
            {
              field: "ip",
              value: "ip",
            },
            {
              field: "ipv4-destination",
              value: "239.255.255.250/32",
            },
          ],
          priority: "10",
          table: "0",
        },
        source_port: "6",
        source_port_name: "P 1",
        table: 0,
        type: "BOOTSTRAP",
        virtual: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271062.290",
            flow_signature: "a51cd9a3d3d47b0cb3d37916e992de78",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "6",
                },
                {
                  field: "ip",
                  value: "ip",
                },
                {
                  field: "ipv4-destination",
                  value: "239.255.255.250/32",
                },
              ],
              priority: "10",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [
              {
                field: "in-port",
                value: "6",
              },
              {
                field: "ipv4-destination",
                value: "239.255.255.250/32",
              },
              {
                field: "ip",
                value: "ip",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "6",
            type: "SERVICE",
            raw_physical: [],
            raw_virtual: {
              string:
                " cookie=0x2000, duration=271062.290s, table=0, n_packets=0, n_bytes=0, priority=10,ip,in_port=6,nw_dst=239.255.255.250 actions=drop",
            },
            raw_data: [],
          },
        ],
      },
      {
        bytes: "0",
        created_at: "2024-04-07 09:35:31",
        data: [
          {
            actions: [
              {
                action: "pop-vlan",
              },
              {
                action: "output",
                value: "FLOOD",
              },
            ],
            bytes: 0,
            cookie: "0x2000",
            created_at: "2024-04-05 13:18:25",
            destination_port: "FLOOD",
            details: {
              destination_port_name: "FLOOD",
              source_port_name: "WAN",
              inner_label: 10,
              outer_label: 5,
              priority: 10,
              source_ip_address: "10.64.0.0/24",
              openflow_id: "manual:30910920021",
            },
            duration: 0,
            flow_signature: "146c5ca7620cc14a53d24f01fd1cc235",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "FLOOD",
                },
                {
                  action: "pop-vlan",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "1",
                },
                {
                  field: "ip",
                  value: "ip",
                },
                {
                  field: "nw_src",
                  value: "10.64.0.0/24",
                },
                {
                  field: "vlan-match",
                  value: "10",
                },
              ],
              priority: "10",
              table: "10",
            },
            hard_time: "0",
            idle_time: "0",
            location: {
              table_id: 10,
              table_flow_id: "",
            },
            matches: [
              {
                field: "ip",
                value: "ip",
              },
              {
                field: "in-port",
                value: "1",
              },
              {
                field: "vlan-match",
                value: "10",
              },
              {
                field: "nw_src",
                value: "10.64.0.0/24",
              },
            ],
            name: "Bootstrap Pop Network Range",
            packets: 0,
            priority: 10,
            source_port: "SELF",
            type: "BOOTSTRAP",
            raw_physical: [],
            raw_virtual: [],
            raw_data: {
              stripped_fields: [],
              always_stripped_fields_epcom: [
                "approval_values",
                "created_at",
                "globals",
                "globals_json",
                "guid",
                "level",
                "lft",
                "rgt",
                "updated_at",
              ],
              nonApprovalFields: ["approval_values", "id", "guid"],
              stripped_fields_epcom: [],
              epcom_dont_strip_fields: [],
            },
          },
        ],
        destination_mac: "",
        destination_port: "FLOOD",
        destination_port_name: "FLOOD",
        duration: "271062.087",
        idle_time: 0,
        incoming_vlan: 10,
        inner_label: "",
        ip_destination_address: "",
        ip_destination_port: "",
        ip_source_address: "10.64.0.0/24",
        ip_source_port: "",
        name: "Bootstrap Pop Network Range",
        notes: {
          ebfd45dd8f049e6be82340d655c097bb: "Type: BOOTSTRAP",
          "337c8933135509a597cc8b7d49bc8822": "Label: 10",
          "5ee0a5dc70502b6abd9584ad3474e205": "NW Source: 10.64.0.0/24",
          d68900e698eceebfc0235da6c1086828: "ODL Table: 10",
        },
        outer_label: "",
        packets: "0",
        physical: [
          {
            actions: [
              {
                action: "pop-vlan",
              },
              {
                action: "output",
                value: "FLOOD",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "FLOOD",
            details: [],
            duration: "271062.087",
            flow_signature: "146c5ca7620cc14a53d24f01fd1cc235",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "FLOOD",
                },
                {
                  action: "pop-vlan",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "1",
                },
                {
                  field: "ip",
                  value: "ip",
                },
                {
                  field: "nw_src",
                  value: "10.64.0.0/24",
                },
                {
                  field: "vlan-match",
                  value: "10",
                },
              ],
              priority: "10",
              table: "10",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 10,
            },
            matches: [
              {
                field: "in-port",
                value: "1",
              },
              {
                field: "vlan-match",
                value: 10,
              },
              {
                field: "nw_src",
                value: "10.64.0.0/24",
              },
              {
                field: "ip",
                value: "ip",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "SELF",
            type: "SERVICE",
            raw_physical: {
              string:
                " cookie=0x2000, duration=271062.087s, table=10, n_packets=0, n_bytes=0, priority=10,ip,in_port=1,dl_vlan=10,nw_src=10.64.0.0/24 actions=pop_vlan,FLOOD",
            },
            raw_virtual: [],
            raw_data: [],
            source_port_state: "unknown",
            destination_port_state: "unknown",
          },
        ],
        priority: 10,
        protocol: "",
        signature: "146c5ca7620cc14a53d24f01fd1cc235",
        signature_data: {
          actions: [
            {
              action: "output",
              value: "FLOOD",
            },
            {
              action: "pop-vlan",
            },
          ],
          matches: [
            {
              field: "in-port",
              value: "1",
            },
            {
              field: "ip",
              value: "ip",
            },
            {
              field: "nw_src",
              value: "10.64.0.0/24",
            },
            {
              field: "vlan-match",
              value: "10",
            },
          ],
          priority: "10",
          table: "10",
        },
        source_port: "SELF",
        source_port_name: "SELF",
        table: 10,
        type: "BOOTSTRAP",
        virtual: [
          {
            actions: [
              {
                action: "pop-vlan",
              },
              {
                action: "output",
                value: "FLOOD",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "FLOOD",
            details: [],
            duration: "271062.742",
            flow_signature: "146c5ca7620cc14a53d24f01fd1cc235",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "FLOOD",
                },
                {
                  action: "pop-vlan",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "1",
                },
                {
                  field: "ip",
                  value: "ip",
                },
                {
                  field: "nw_src",
                  value: "10.64.0.0/24",
                },
                {
                  field: "vlan-match",
                  value: "10",
                },
              ],
              priority: "10",
              table: "10",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 10,
            },
            matches: [
              {
                field: "in-port",
                value: "1",
              },
              {
                field: "vlan-match",
                value: 10,
              },
              {
                field: "nw_src",
                value: "10.64.0.0/24",
              },
              {
                field: "ip",
                value: "ip",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "SELF",
            type: "SERVICE",
            raw_physical: [],
            raw_virtual: {
              string:
                " cookie=0x2000, duration=271062.742s, table=10, n_packets=0, n_bytes=0, priority=10,ip,in_port=1,dl_vlan=10,nw_src=10.64.0.0/24 actions=pop_vlan,FLOOD",
            },
            raw_data: [],
          },
        ],
      },
      {
        bytes: "0",
        created_at: "2024-04-07 09:35:31",
        data: [
          {
            actions: [
              {
                action: "pop-vlan",
              },
              {
                action: "output",
                value: "FLOOD",
              },
            ],
            bytes: 0,
            cookie: "0x2000",
            created_at: "2024-04-05 13:18:25",
            destination_port: "FLOOD",
            details: {
              destination_port_name: "FLOOD",
              source_port_name: "WAN",
              inner_label: 10,
              outer_label: 5,
              priority: 10,
              protocol: ",arp",
              openflow_id: "manual:30910920021",
            },
            duration: 0,
            flow_signature: "5c881c1ae5953bf2297f3edd36cb5ace",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "FLOOD",
                },
                {
                  action: "pop-vlan",
                },
              ],
              matches: [
                {
                  field: "arp",
                  value: "arp",
                },
                {
                  field: "in-port",
                  value: "1",
                },
                {
                  field: "vlan-match",
                  value: "10",
                },
              ],
              priority: "10",
              table: "10",
            },
            hard_time: "0",
            idle_time: "0",
            location: {
              table_id: 10,
              table_flow_id: "",
            },
            matches: [
              {
                field: "arp",
                value: "arp",
              },
              {
                field: "in-port",
                value: "1",
              },
              {
                field: "vlan-match",
                value: "10",
              },
            ],
            name: "Bootstrap Pop QinQ VLAN",
            packets: 0,
            priority: 10,
            source_port: "SELF",
            type: "BOOTSTRAP",
            raw_physical: [],
            raw_virtual: [],
            raw_data: {
              stripped_fields: [],
              always_stripped_fields_epcom: [
                "approval_values",
                "created_at",
                "globals",
                "globals_json",
                "guid",
                "level",
                "lft",
                "rgt",
                "updated_at",
              ],
              nonApprovalFields: ["approval_values", "id", "guid"],
              stripped_fields_epcom: [],
              epcom_dont_strip_fields: [],
            },
          },
        ],
        destination_mac: "",
        destination_port: "FLOOD",
        destination_port_name: "FLOOD",
        duration: "271062.116",
        idle_time: 0,
        incoming_vlan: 10,
        inner_label: "",
        ip_destination_address: "",
        ip_destination_port: "",
        ip_source_address: "",
        ip_source_port: "",
        name: "Bootstrap Pop QinQ VLAN",
        notes: {
          ebfd45dd8f049e6be82340d655c097bb: "Type: BOOTSTRAP",
          "337c8933135509a597cc8b7d49bc8822": "Label: 10",
          d68900e698eceebfc0235da6c1086828: "ODL Table: 10",
        },
        outer_label: "",
        packets: "0",
        physical: [
          {
            actions: [
              {
                action: "pop-vlan",
              },
              {
                action: "output",
                value: "FLOOD",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "FLOOD",
            details: [],
            duration: "271062.116",
            flow_signature: "5c881c1ae5953bf2297f3edd36cb5ace",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "FLOOD",
                },
                {
                  action: "pop-vlan",
                },
              ],
              matches: [
                {
                  field: "arp",
                  value: "arp",
                },
                {
                  field: "in-port",
                  value: "1",
                },
                {
                  field: "vlan-match",
                  value: "10",
                },
              ],
              priority: "10",
              table: "10",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 10,
            },
            matches: [
              {
                field: "in-port",
                value: "1",
              },
              {
                field: "vlan-match",
                value: 10,
              },
              {
                field: "arp",
                value: "arp",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "SELF",
            type: "SERVICE",
            raw_physical: {
              string:
                " cookie=0x2000, duration=271062.116s, table=10, n_packets=0, n_bytes=0, priority=10,arp,in_port=1,dl_vlan=10 actions=pop_vlan,FLOOD",
            },
            raw_virtual: [],
            raw_data: [],
            source_port_state: "unknown",
            destination_port_state: "unknown",
          },
        ],
        priority: 10,
        protocol: "",
        signature: "5c881c1ae5953bf2297f3edd36cb5ace",
        signature_data: {
          actions: [
            {
              action: "output",
              value: "FLOOD",
            },
            {
              action: "pop-vlan",
            },
          ],
          matches: [
            {
              field: "arp",
              value: "arp",
            },
            {
              field: "in-port",
              value: "1",
            },
            {
              field: "vlan-match",
              value: "10",
            },
          ],
          priority: "10",
          table: "10",
        },
        source_port: "SELF",
        source_port_name: "SELF",
        table: 10,
        type: "BOOTSTRAP",
        virtual: [
          {
            actions: [
              {
                action: "pop-vlan",
              },
              {
                action: "output",
                value: "FLOOD",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "FLOOD",
            details: [],
            duration: "271062.771",
            flow_signature: "5c881c1ae5953bf2297f3edd36cb5ace",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "FLOOD",
                },
                {
                  action: "pop-vlan",
                },
              ],
              matches: [
                {
                  field: "arp",
                  value: "arp",
                },
                {
                  field: "in-port",
                  value: "1",
                },
                {
                  field: "vlan-match",
                  value: "10",
                },
              ],
              priority: "10",
              table: "10",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 10,
            },
            matches: [
              {
                field: "in-port",
                value: "1",
              },
              {
                field: "vlan-match",
                value: 10,
              },
              {
                field: "arp",
                value: "arp",
              },
            ],
            name: "",
            packets: "0",
            priority: 10,
            source_port: "SELF",
            type: "SERVICE",
            raw_physical: [],
            raw_virtual: {
              string:
                " cookie=0x2000, duration=271062.771s, table=10, n_packets=0, n_bytes=0, priority=10,arp,in_port=1,dl_vlan=10 actions=pop_vlan,FLOOD",
            },
            raw_data: [],
          },
        ],
      },
      {
        bytes: "0",
        created_at: "2024-04-07 09:35:31",
        data: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: 0,
            cookie: "0x2000",
            created_at: "2024-04-05 13:18:25",
            destination_port: "DROP",
            details: {
              destination_port_name: "DROP",
              source_port_name: "WAN",
              inner_label: 10,
              outer_label: 5,
              priority: 9,
              openflow_id: "manual:30910920021",
            },
            duration: 0,
            flow_signature: "4f4e1d3fda8fafd58e5fdc46ebb16974",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "1",
                },
                {
                  field: "vlan-match",
                  value: "10",
                },
              ],
              priority: "9",
              table: "10",
            },
            hard_time: "0",
            idle_time: "0",
            location: {
              table_id: 10,
              table_flow_id: "",
            },
            matches: [
              {
                field: "in-port",
                value: "1",
              },
              {
                field: "vlan-match",
                value: "10",
              },
            ],
            name: "Bootstrap Drop C-Tag",
            packets: 0,
            priority: 9,
            source_port: "SELF",
            type: "BOOTSTRAP",
            raw_physical: [],
            raw_virtual: [],
            raw_data: {
              stripped_fields: [],
              always_stripped_fields_epcom: [
                "approval_values",
                "created_at",
                "globals",
                "globals_json",
                "guid",
                "level",
                "lft",
                "rgt",
                "updated_at",
              ],
              nonApprovalFields: ["approval_values", "id", "guid"],
              stripped_fields_epcom: [],
              epcom_dont_strip_fields: [],
            },
          },
        ],
        destination_mac: "",
        destination_port: "DROP",
        destination_port_name: "DROP",
        duration: "271062.201",
        idle_time: 0,
        incoming_vlan: 10,
        inner_label: "",
        ip_destination_address: "",
        ip_destination_port: "",
        ip_source_address: "",
        ip_source_port: "",
        name: "Bootstrap Drop C-Tag",
        notes: {
          ebfd45dd8f049e6be82340d655c097bb: "Type: BOOTSTRAP",
          "337c8933135509a597cc8b7d49bc8822": "Label: 10",
          d68900e698eceebfc0235da6c1086828: "ODL Table: 10",
        },
        outer_label: "",
        packets: "0",
        physical: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271062.201",
            flow_signature: "4f4e1d3fda8fafd58e5fdc46ebb16974",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "1",
                },
                {
                  field: "vlan-match",
                  value: "10",
                },
              ],
              priority: "9",
              table: "10",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 10,
            },
            matches: [
              {
                field: "in-port",
                value: "1",
              },
              {
                field: "vlan-match",
                value: 10,
              },
            ],
            name: "",
            packets: "0",
            priority: 9,
            source_port: "SELF",
            type: "SERVICE",
            raw_physical: {
              string:
                " cookie=0x2000, duration=271062.201s, table=10, n_packets=0, n_bytes=0, priority=9,in_port=1,dl_vlan=10 actions=drop",
            },
            raw_virtual: [],
            raw_data: [],
            source_port_state: "unknown",
            destination_port_state: "unknown",
          },
        ],
        priority: 9,
        protocol: "",
        signature: "4f4e1d3fda8fafd58e5fdc46ebb16974",
        signature_data: {
          actions: [
            {
              action: "drop",
            },
          ],
          matches: [
            {
              field: "in-port",
              value: "1",
            },
            {
              field: "vlan-match",
              value: "10",
            },
          ],
          priority: "9",
          table: "10",
        },
        source_port: "SELF",
        source_port_name: "SELF",
        table: 10,
        type: "BOOTSTRAP",
        virtual: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271062.856",
            flow_signature: "4f4e1d3fda8fafd58e5fdc46ebb16974",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "1",
                },
                {
                  field: "vlan-match",
                  value: "10",
                },
              ],
              priority: "9",
              table: "10",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 10,
            },
            matches: [
              {
                field: "in-port",
                value: "1",
              },
              {
                field: "vlan-match",
                value: 10,
              },
            ],
            name: "",
            packets: "0",
            priority: 9,
            source_port: "SELF",
            type: "SERVICE",
            raw_physical: [],
            raw_virtual: {
              string:
                " cookie=0x2000, duration=271062.856s, table=10, n_packets=0, n_bytes=0, priority=9,in_port=1,dl_vlan=10 actions=drop",
            },
            raw_data: [],
          },
        ],
      },
      {
        bytes: "0",
        created_at: "2024-04-07 09:35:31",
        data: [
          {
            actions: [
              {
                action: "output",
                value: "FLOOD",
              },
            ],
            bytes: 0,
            cookie: "0x2000",
            created_at: "2024-04-05 13:18:25",
            destination_port: "FLOOD",
            details: {
              destination_port_name: "FLOOD",
              source_port_name: "WAN",
              inner_label: 10,
              outer_label: 5,
              priority: 8,
              protocol: ",arp",
              openflow_id: "manual:30910920021",
            },
            duration: 0,
            flow_signature: "c2d7848a5561bb2b14aecf400549fc1c",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "FLOOD",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "1",
                },
              ],
              priority: "8",
              table: "10",
            },
            hard_time: "0",
            idle_time: "0",
            location: {
              table_id: 10,
              table_flow_id: "",
            },
            matches: [
              {
                field: "in-port",
                value: "1",
              },
            ],
            name: "Bootstrap Pop QinQ VLAN",
            packets: 0,
            priority: 8,
            source_port: "SELF",
            type: "BOOTSTRAP",
            raw_physical: [],
            raw_virtual: [],
            raw_data: {
              stripped_fields: [],
              always_stripped_fields_epcom: [
                "approval_values",
                "created_at",
                "globals",
                "globals_json",
                "guid",
                "level",
                "lft",
                "rgt",
                "updated_at",
              ],
              nonApprovalFields: ["approval_values", "id", "guid"],
              stripped_fields_epcom: [],
              epcom_dont_strip_fields: [],
            },
          },
        ],
        destination_mac: "",
        destination_port: "FLOOD",
        destination_port_name: "FLOOD",
        duration: "271062.172",
        idle_time: 0,
        incoming_vlan: "",
        inner_label: "",
        ip_destination_address: "",
        ip_destination_port: "",
        ip_source_address: "",
        ip_source_port: "",
        name: "Bootstrap Pop QinQ VLAN",
        notes: {
          ebfd45dd8f049e6be82340d655c097bb: "Type: BOOTSTRAP",
          d68900e698eceebfc0235da6c1086828: "ODL Table: 10",
        },
        outer_label: "",
        packets: "0",
        physical: [
          {
            actions: [
              {
                action: "output",
                value: "FLOOD",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "FLOOD",
            details: [],
            duration: "271062.172",
            flow_signature: "c2d7848a5561bb2b14aecf400549fc1c",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "FLOOD",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "1",
                },
              ],
              priority: "8",
              table: "10",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 10,
            },
            matches: [
              {
                field: "in-port",
                value: "1",
              },
            ],
            name: "",
            packets: "0",
            priority: 8,
            source_port: "SELF",
            type: "SERVICE",
            raw_physical: {
              string:
                " cookie=0x2000, duration=271062.172s, table=10, n_packets=0, n_bytes=0, priority=8,in_port=1 actions=FLOOD",
            },
            raw_virtual: [],
            raw_data: [],
            source_port_state: "unknown",
            destination_port_state: "unknown",
          },
        ],
        priority: 8,
        protocol: "",
        signature: "c2d7848a5561bb2b14aecf400549fc1c",
        signature_data: {
          actions: [
            {
              action: "output",
              value: "FLOOD",
            },
          ],
          matches: [
            {
              field: "in-port",
              value: "1",
            },
          ],
          priority: "8",
          table: "10",
        },
        source_port: "SELF",
        source_port_name: "SELF",
        table: 10,
        type: "BOOTSTRAP",
        virtual: [
          {
            actions: [
              {
                action: "output",
                value: "FLOOD",
              },
            ],
            bytes: "0",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "FLOOD",
            details: [],
            duration: "271062.827",
            flow_signature: "c2d7848a5561bb2b14aecf400549fc1c",
            flow_signature_data: {
              actions: [
                {
                  action: "output",
                  value: "FLOOD",
                },
              ],
              matches: [
                {
                  field: "in-port",
                  value: "1",
                },
              ],
              priority: "8",
              table: "10",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 10,
            },
            matches: [
              {
                field: "in-port",
                value: "1",
              },
            ],
            name: "",
            packets: "0",
            priority: 8,
            source_port: "SELF",
            type: "SERVICE",
            raw_physical: [],
            raw_virtual: {
              string:
                " cookie=0x2000, duration=271062.827s, table=10, n_packets=0, n_bytes=0, priority=8,in_port=1 actions=FLOOD",
            },
            raw_data: [],
          },
        ],
      },
      {
        bytes: "31416796",
        created_at: "2024-04-07 09:35:32",
        data: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: 0,
            cookie: "0x2000",
            created_at: "2024-04-05 13:18:26",
            destination_port: "DROP",
            details: {
              destination_port_name: "Drop",
              source_port_name: "Any",
              priority: "0",
              openflow_id: "manual:30910920021",
            },
            duration: 0,
            flow_signature: "21ffe70893e855841d0d6f2233ded64f",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [],
              priority: "0",
              table: "0",
            },
            hard_time: "0",
            idle_time: "0",
            location: {
              table_id: "",
              table_flow_id: "",
            },
            matches: [],
            name: "Drop All",
            packets: 0,
            priority: "",
            source_port: "ANY",
            type: "BOOTSTRAP",
            raw_physical: [],
            raw_virtual: [],
            raw_data: {
              stripped_fields: [],
              always_stripped_fields_epcom: [
                "approval_values",
                "created_at",
                "globals",
                "globals_json",
                "guid",
                "level",
                "lft",
                "rgt",
                "updated_at",
              ],
              nonApprovalFields: ["approval_values", "id", "guid"],
              stripped_fields_epcom: [],
              epcom_dont_strip_fields: [],
            },
          },
        ],
        destination_mac: "",
        destination_port: "DROP",
        destination_port_name: "DROP",
        duration: "271061.607",
        idle_time: 0,
        incoming_vlan: "",
        inner_label: "",
        ip_destination_address: "",
        ip_destination_port: "",
        ip_source_address: "",
        ip_source_port: "",
        name: "Unmatched packets drop",
        notes: {
          ebfd45dd8f049e6be82340d655c097bb: "Type: BOOTSTRAP",
          "1cfa534da20ccfe94f62c819a71dc955": "ODL Table: 0",
        },
        outer_label: "",
        packets: "434535",
        physical: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "31416796",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:32",
            destination_port: "",
            details: [],
            duration: "271061.607",
            flow_signature: "21ffe70893e855841d0d6f2233ded64f",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [],
              priority: "0",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [],
            name: "",
            packets: "434535",
            priority: 0,
            source_port: "",
            type: "CORE",
            raw_physical: {
              string:
                " cookie=0x2000, duration=271061.607s, table=0, n_packets=434535, n_bytes=31416796, priority=0 actions=drop",
            },
            raw_virtual: [],
            raw_data: [],
            source_port_state: "unknown",
            destination_port_state: "unknown",
          },
        ],
        priority: 0,
        protocol: "",
        signature: "21ffe70893e855841d0d6f2233ded64f",
        signature_data: {
          actions: [
            {
              action: "drop",
            },
          ],
          matches: [],
          priority: "0",
          table: "0",
        },
        source_port: "ANY",
        source_port_name: "ANY",
        table: 0,
        type: "BOOTSTRAP",
        virtual: [
          {
            actions: [
              {
                action: "drop",
              },
            ],
            bytes: "31439781",
            cookie: "0x2000",
            created_at: "2024-04-07 09:35:31",
            destination_port: "",
            details: [],
            duration: "271062.262",
            flow_signature: "21ffe70893e855841d0d6f2233ded64f",
            flow_signature_data: {
              actions: [
                {
                  action: "drop",
                },
              ],
              matches: [],
              priority: "0",
              table: "0",
            },
            hard_time: 0,
            idle_time: 0,
            location: {
              table_id: 0,
            },
            matches: [],
            name: "",
            packets: "434694",
            priority: 0,
            source_port: "",
            type: "CORE",
            raw_physical: [],
            raw_virtual: {
              string:
                " cookie=0x2000, duration=271062.262s, table=0, n_packets=434694, n_bytes=31439781, priority=0 actions=drop",
            },
            raw_data: [],
          },
        ],
      },
    ],
    name: "Walled Garden Flows",
  },
  {
    id: "",
    type: "CORE",
    flows: [],
    name: "Core Flows",
  },
] as FlowSection[];

import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "../toast/Toast";
import { useToast } from "../toast/useToast";

type ToastConfig = {
  [key: string]: {
    label: string;
    icon: string;
  };
};

const toastConfig: ToastConfig = {
  Error: {
    label: "An Error Occured",
    icon: "/Warning.svg",
  },
  "An Error Occured": {
    label: "An Error Occured",
    icon: "/Warning.svg",
  },
  Warning: {
    label: "Warning",
    icon: "/WarningOctagon.svg",
  },
  Success: {
    label: "Success",
    icon: "/CheckRounded.svg",
  },
  Default: {
    label: "",
    icon: "",
  },
};

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        const { label, icon } =
          toastConfig[title || "Default"] || toastConfig["Default"];
        return (
          <Toast key={id} className="border pr-4" {...props}>
            <div className="flex">
              <div className="w-16 mr-4 flex-shrink-0 flex items-center">
                {icon && <img src={icon} />}
              </div>

              <div className="flex flex-col m-auto">
                {label && <ToastTitle>{label}</ToastTitle>}
                {description && (
                  <ToastDescription>{description}</ToastDescription>
                )}
              </div>
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}

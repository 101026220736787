import Loading from "src/components/loading/InitialLoading";
import Pagination from "src/components/pagination";
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/table/Table";

import NetworkOperatorListToolbar from "./NetworkOperatorListToolbar";
import NetworkOperatorActionCells from "./NetworkOperatorActionCells";
import { Dot } from "src/components/dot/Dot";
import { networkOperator } from "../__mock__/network-operator";

const networkOperatorsPageSize = 8;

export type NetworkOperator = typeof networkOperator;

export default function NetworkOperatorsList() {
  const hash = window.location.hash.replace("#", "");
  const [lastPage, setLastPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>(
    hash !== "" ? hash : "*",
  );
  const [isLoading, setIsLoading] = useState(false);
  const [networkOperators, setNetworkOperators] = useState<NetworkOperator[]>(
    new Array(30).fill(networkOperator, 0, 30).map((item, idx) => {
      return {
        ...item,
        id: item.id + idx,
      };
    }),
  );

  const [networkOperatorsSearch, setNetworkOperatorsSearch] = useState<
    NetworkOperator[]
  >([]);

  const searchNetworkOperator = async (query: string, page: number = 1) => {
    setIsLoading(true);
    const searchFilteredResults = networkOperators.filter((networkOperator) => {
      if (networkOperator.name.toLowerCase().includes(query.toLowerCase())) {
        return true;
      }
      return false;
    });
    const maxNumberOfPages = Math.ceil(
      searchFilteredResults.length / networkOperatorsPageSize,
    );
    // perform client side pagination.
    const startIndex = (page - 1) * networkOperatorsPageSize;
    const endIndex = startIndex + networkOperatorsPageSize;
    const paginatedResults = searchFilteredResults.slice(startIndex, endIndex);

    setLastPage(maxNumberOfPages);
    setNetworkOperatorsSearch(paginatedResults);
    setIsLoading(false);
  };

  const clearSearchNetworkOperator = () => {
    setSearchQuery("*");
    setNetworkOperatorsSearch([]);
  };

  const handleActionsCellClick = (
    event: React.MouseEvent<HTMLTableCellElement>,
  ) => {
    event.stopPropagation();
  };

  const handleSelectPage = (page: number) => {
    setCurrentPage(page);
    searchNetworkOperator(searchQuery, page);
  };

  const addOperatorToNetwork = (operator: NetworkOperator) => {
    setNetworkOperators([operator, ...networkOperators]);
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
  }, []);

  function renderNetworkOperatorsList() {
    if (isLoading) {
      return <Loading></Loading>;
    }

    if (
      networkOperators.length > 0 &&
      networkOperatorsSearch.length < 1 &&
      searchQuery === "*"
    ) {
      return (
        <div className="mt-10 p-10 text-center min-h-[calc(100vh-340px-2rem)]">
          <p className="font-bold mb-10">
            There are currently {networkOperators.length} network operators
            available for searching
          </p>
          <p>Please enter a search term to load results</p>
        </div>
      );
    }

    if (networkOperatorsSearch.length < 1 && searchQuery !== "*") {
      return (
        <div className="mt-10 p-10 text-center min-h-[calc(100vh-340px-2rem)]">
          <p className="font-bold mb-10">No matching search results</p>
          <p>Try again using another search term</p>
        </div>
      );
    }

    return (
      <>
        <Table dataCy="networkOperatorsTable">
          <TableHeader
            dataCy="networkOperatorsTableHeader"
            className="sticky top-0"
          >
            <TableRow dataCy="networkOperatorsTableHeaderRow">
              <TableHead className="text-left">Name</TableHead>
              <TableHead className="text-center w-[100px]">Action</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody dataCy="networkOperatorsTableBody">
            {networkOperatorsSearch &&
              networkOperatorsSearch.map((networkOperator) => (
                <TableRow
                  key={networkOperator.id}
                  className="font-medium"
                  dataCy={`${networkOperator.id}Row`}
                >
                  <TableCell
                    dataCy={`${networkOperator.id}networkOperatorNameCell`}
                  >
                    <div className="flex items-center gap-2">
                      {networkOperator.status === "ACTIVE" ? (
                        <Dot className="h-2 w-2" variant={"active"} />
                      ) : (
                        <Dot className="h-2 w-2" variant={"inactive"} />
                      )}
                      {networkOperator.name}
                    </div>
                  </TableCell>
                  <TableCell
                    className="text-right"
                    onClick={handleActionsCellClick}
                    dataCy={`${networkOperator.id}networkOperatorActionsCell`}
                  >
                    <NetworkOperatorActionCells
                      removeNetworkOperator={() => {
                        setNetworkOperators(
                          networkOperators.slice(
                            0,
                            networkOperators.length - 1,
                          ),
                        );
                      }}
                      operator={networkOperator}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <div
          className={`flex flex-wrap items-center ${
            lastPage > 1 ? "justify-between" : "justify-start"
          } space-x-0 mt-4`}
        >
          {networkOperatorsSearch && networkOperators.length >= 1 && (
            <div className="text-xs font-normal font-secondary">
              Showing{" "}
              {lastPage > 1
                ? `${(currentPage - 1) * networkOperatorsPageSize + 1} to ${
                    (currentPage - 1) * networkOperatorsPageSize +
                      networkOperatorsPageSize >
                    networkOperators.length
                      ? networkOperators.length
                      : (currentPage - 1) * networkOperatorsPageSize +
                        networkOperatorsPageSize
                  }`
                : `${networkOperators?.length}`}{" "}
              of {networkOperators?.length} entries
            </div>
          )}
          {lastPage > 1 && networkOperatorsSearch.length >= 1 && (
            <div className="flex items-center">
              <Pagination
                pageCount={lastPage > 4 ? 4 : lastPage}
                currentPage={currentPage}
                lastPage={lastPage}
                onSelect={handleSelectPage}
              />
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <div className="flex flex-col gap-6">
      <NetworkOperatorListToolbar
        setSearchFilter={(query: string) => {
          setSearchQuery(query);
          searchNetworkOperator(query, currentPage);
        }}
        clearSearch={clearSearchNetworkOperator}
        addOperatorToNetwork={addOperatorToNetwork}
      ></NetworkOperatorListToolbar>
      {renderNetworkOperatorsList()}
    </div>
  );
}

import React from "react";
import { Link, useLocation } from "react-router-dom";
import { PageConfig } from "../../routes";
import { NavigationMenuLink } from "../../components/navigation-menu/NavigationMenu";
import { cn } from "../../utils/cn";

type SubMenuItemProps = React.ComponentPropsWithoutRef<"a"> & {
  option: PageConfig;
};

const SubMenuItem = React.forwardRef<HTMLAnchorElement, SubMenuItemProps>(
  ({ className, option, ...props }, ref) => {
    const location = useLocation();
    const isSelected =
      location.pathname === option.path
        ? " text-[#55857E] font-bold bg-neutrals-hover-color"
        : "";

    return (
      <li>
        <NavigationMenuLink asChild>
          <Link
            ref={ref}
            className={cn(
              "w-full block select-none space-y-1 p-3 leading-none no-underline outline-none transition-colors font-medium",
              "hover:bg-neutrals-hover-color hover:font-semibold hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
              className,
              isSelected,
            )}
            {...props}
            to={`${option.path}`}
          >
            <div className={"text-xs leading-none whitespace-nowrap"}>
              {option.title}
            </div>
          </Link>
        </NavigationMenuLink>
      </li>
    );
  },
);
SubMenuItem.displayName = "SubMenuItem";

export default SubMenuItem;

import { useEffect, useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";
import EpButton from "src/components/buttons/EpButton";
import {
  EpButtonSizes,
  EpButtonStyles,
} from "src/components/buttons/constants";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/table/Table";

import Icon from "src/components/buttons/Icon";
import ClickableTooltip from "src/components/clickable-tooltip/ClickableTooltip";
import { Search } from "src/components/input/Input";
import { NetworkOperator } from "../list/NetworkOperatorsList";
import Loading from "src/components/loading/Loading";
import { Dot } from "src/components/dot/Dot";
import LoadNetworkOperatorActions from "./LoadNetworkOperatorActions";
import ScrollArea from "src/components/scroll-area/ScrollArea";
import { toast } from "src/components/toast/useToast";
import { networkOperator } from "../__mock__/network-operator";

type LoadNetworkOperatorDialogProps = {
  addOperatorToNetwork: (operator: NetworkOperator) => void;
};

export default function LoadNetworkOperatorDialog({
  addOperatorToNetwork,
}: LoadNetworkOperatorDialogProps) {
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const networkOperators = new Array(30).fill(
    networkOperator,
    0,
    30,
  ) as NetworkOperator[];

  const [networkOperatorsSearch, setNetworkOperatorsSearch] = useState<
    NetworkOperator[]
  >([]);

  const handleSearch = (value: string) => {
    if (value !== "") {
      setSearchQuery(value);
      searchNetworkOperator(value);
    } else {
      clearSearchNetworkOperator();
    }
  };

  const handleActionsCellClick = (
    event: React.MouseEvent<HTMLTableCellElement>,
  ) => {
    event.stopPropagation();
  };

  const searchNetworkOperator = async (query: string) => {
    setIsLoading(true);
    const searchFilteredResults = networkOperators.filter((networkOperator) => {
      if (networkOperator.name.toLowerCase().includes(query.toLowerCase())) {
        return true;
      }
      return false;
    });
    if (searchFilteredResults.length < 1) {
      setSearchQuery("*");
    }
    setNetworkOperatorsSearch(searchFilteredResults);
    setIsLoading(false);
  };

  const clearSearchNetworkOperator = () => {
    setSearchQuery("");
    setNetworkOperatorsSearch([]);
  };

  const handleAddNetworkOperator = (operator: NetworkOperator) => {
    addOperatorToNetwork(operator);
    const updateNetworkOperators = networkOperatorsSearch.slice(0, -1);
    setNetworkOperatorsSearch(updateNetworkOperators);
    toast({
      title: "Success",
      description: (
        <>
          {operator.name} Loaded
          <br />
        </>
      ),
    });
  };

  function renderLoadableNetworkOperators() {
    if (isLoading) {
      return <Loading></Loading>;
    }

    if (networkOperatorsSearch.length < 1 && searchQuery === "*") {
      return (
        <div className="mt-4 p-2 bg-[#F9CB2840] border border-[#FCE8A1] rounded-md text-xs">
          <p className="font-bold text-neutral-700 text-left">
            No matching provider(s) found.
          </p>
        </div>
      );
    }

    if (searchQuery === "") {
      return null;
    }

    return (
      <ScrollArea className="max-h-[calc(55vh-theme(space.52))]">
        <Table dataCy="loadNetworkOperatorsTable">
          <TableHeader
            dataCy="loadNetworkOperatorsTableHeader"
            className="sticky top-0"
          >
            <TableRow dataCy="loadNetworkOperatorsTableHeaderRow">
              <TableHead className="text-left">Name</TableHead>
              <TableHead className="text-left">Location</TableHead>
              <TableHead className="text-center w-[100px]">Action</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody dataCy="loadNetworkOperatorsTableBody">
            {networkOperatorsSearch &&
              networkOperatorsSearch.map((networkOperator) => (
                <TableRow
                  key={networkOperator.id}
                  className="font-medium"
                  dataCy={`${networkOperator.id}Row`}
                >
                  <TableCell
                    dataCy={`${networkOperator.id}loadNetworkOperatorNameCell`}
                  >
                    <div className="flex items-center gap-2">
                      {networkOperator.status === "ACTIVE" ? (
                        <Dot className="h-2 w-2" variant={"active"} />
                      ) : (
                        <Dot className="h-2 w-2" variant={"inactive"} />
                      )}
                      {networkOperator.name}
                    </div>
                  </TableCell>
                  <TableCell
                    dataCy={`${networkOperator.id}loadNetworkOperatorLocationCell`}
                  >
                    {networkOperator.location}
                  </TableCell>
                  <TableCell
                    className="text-right"
                    onClick={handleActionsCellClick}
                    dataCy={`${networkOperator.id}loadNetworkOperatorActionsCell`}
                  >
                    <LoadNetworkOperatorActions
                      handleAddNetworkOperator={handleAddNetworkOperator}
                      operator={networkOperator}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </ScrollArea>
    );
  }

  useEffect(() => {
    if (!isDialogOpened) {
      setSearchQuery("");
      setNetworkOperatorsSearch([]);
    }
  }, [isDialogOpened]);

  return (
    <Dialog
      open={isDialogOpened}
      onOpenChange={(value) => setIsDialogOpened(value)}
    >
      <DialogTrigger asChild>
        <EpButton
          sizing={EpButtonSizes.LARGE}
          aria-label="Load Network Operator"
          dataCy="loadNetworkOperatorDialogActionBtn"
        >
          Load Network Operator
        </EpButton>
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-4xl font-base"
        dataCy="loadNetworkOperatorDialogCnt"
      >
        <DialogHeader dataCy="loadNetworkOperatorDialogHeader" borderless>
          <DialogTitle dataCy="loadNetworkOperatorDialogTitle">
            Load Network Operator
          </DialogTitle>
        </DialogHeader>
        <p className="font-bold text-sm -mt-2">
          Search available Network Operators to load them to your network.
        </p>
        <div
          className="flex justify-between items-center"
          data-cy="networkOperatorToolbarSection"
        >
          <div className="flex items-center max-w-[540px] gap-2">
            <Search
              placeholder="Search"
              className="search min-w-[305px]"
              ctaCallback={handleSearch}
              closeCallback={clearSearchNetworkOperator}
              dataCy="searchOperatorsInput"
              aria-label="Search"
            ></Search>
            <ClickableTooltip
              header={<p>Search Terms</p>}
              icon={
                <Icon
                  size={30}
                  className="pt-[4px]"
                  name="exclamation"
                  data-cy="searchInfoIcon"
                />
              }
              content={
                "Search for a network operators by providing a operators name"
              }
              dataCy="searchOperatorsTooltip"
              className="w-[290px]"
            />
          </div>
          {networkOperatorsSearch.length ? (
            <p className="text-xs text-neutral-600">
              Showing {networkOperatorsSearch.length} entries
            </p>
          ) : null}
        </div>
        {renderLoadableNetworkOperators()}
        <DialogFooter className="mt-4">
          <DialogClose asChild>
            <EpButton
              dataCy="modalCancel"
              styling={EpButtonStyles.CANCEL}
              aria-label="cancel"
              className="ml-auto"
            >
              Cancel
            </EpButton>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

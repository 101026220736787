import { Device } from "src/interfaces";

export const devicesMockData = [
  {
    id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
    ansible_update_flag: null,
    description: "VegaOS / Monarch - VGL",
    hardware_version: "FWS-2273",
    installed_at: "2024-03-06 23:49:00",
    ip_address: "10.128.0.10",
    ip_address_num: null,
    ipv6_address: null,
    ipv6_address_num: null,
    json: null,
    mac_address: "00:07:ba:31:03:80",
    notes: "VGL VBG used for testing for Ammon",
    physicallocation_id: "beefbf3c-af33-11ee-a459-4c5a54464154",
    openflow_id: "manual:33188545408",
    password: "admin",
    product_id: "a1d8d920-055d-11ea-bd75-535a4f504839",
    reset_delay: "5",
    rack_id: null,
    rack_unit_end: "0",
    rack_unit_start: "0",
    sdn_server_id: "76ca8990-cd30-11ea-bd34-43415959444d",
    sdn_server_local: false,
    serial_number: "VL1942000111",
    snmp_id: null,
    snmp_server_id: null,
    software_version: "2.1.5.2",
    status: "ACTIVE",
    switch_port_id: null,
    type: "VBG",
    uid: "Lab10-1010-VGL",
    username: "admin",
    created_at: "2024-01-09 14:11:56",
    updated_at: "2024-04-03 10:25:41",
    Product: {
      id: "a1d8d920-055d-11ea-bd75-535a4f504839",
      acronym: "aaeon",
      description: "Subscriber Premise Indoor VBG - Rev 2",
      fa_supported: null,
      hardware: "FWS-2273",
      has_api: true,
      manufacturer_id: "bd876c6e-db90-11ea-bae1-365141315955",
      make: "Aaeon",
      model: "2273",
      part_number: "FWS-2273",
      ssl_enabled: false,
      status: "ACTIVE",
      type: "VBG",
      created_at: "2022-01-20 11:47:09",
      updated_at: "2023-03-21 15:00:56",
    },
    Physicallocation: {
      id: "beefbf3c-af33-11ee-a459-4c5a54464154",
      apartment_number: "",
      city: "Ammon",
      country: "US",
      elevation: "0",
      house_number: "",
      lat: "43.469637",
      lon: "-111.966636",
      post_directional: "",
      pre_directional: "",
      state: "CA",
      status: "ACTIVE",
      street: "",
      uri: "https://www.google.com/maps/place/43.4696366,-111.9666355",
      zip: "83406",
      created_at: "2024-01-09 14:12:07",
      updated_at: "2024-04-03 15:21:02",
    },
    Portinterfaces: [
      {
        id: "b84a624a-af33-11ee-999a-355a54325357",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Management1",
        mac_address_text: "00:07:ba:31:03:80",
        name: "Management1",
        number: "14",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "OPEN",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b849d320-af33-11ee-a4a0-3649574f4c52",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest7",
        mac_address_text: "00:07:ba:31:03:88",
        name: "Guest7",
        number: "12",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b848f324-af33-11ee-85be-425955513159",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest4",
        mac_address_text: "00:07:ba:31:03:85",
        name: "Guest4",
        number: "9",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b847028a-af33-11ee-abeb-475949573445",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: true,
        display_name: "LAN 2",
        mac_address_text: null,
        name: "GigabitEth2",
        number: "3",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-01-09 14:12:07",
        Subscriptions: [
          {
            id: "259af70d-ead1-4040-8631-49c985b1fc0f",
            account_id: "a3d72348-d021-11ee-996c-4f33504e4b50",
            expire_at: null,
            port_interface_id: "f040661c-dd79-11ee-bd1d-474b50584937",
            service_provider_label: "",
            serviceplan_id: "cba3a9cc-cc6c-11ee-935e-52364357434a",
            status: "ACTIVE",
            triggered_by: "USER",
            user_id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
            virtual_system_id: null,
            created_at: "2024-03-20 14:55:23",
            updated_at: "2024-03-20 14:55:25",
            Serviceplan: {
              id: "cba3a9cc-cc6c-11ee-935e-52364357434a",
              approval_values: [],
              description: "This is the 2024 basic service plan edited",
              expire_time: "0",
              image: "",
              name: "Basic 2024",
              ratings_sum: "0",
              service_id: "c3fc38a6-cc6c-11ee-b3a0-41304c54534f",
              status: "ACTIVE",
              total_reviews: "0",
              created_at: "2024-02-15 18:43:34",
              updated_at: "2024-04-05 17:13:50",
              fields: {
                Name: "Basic 2024",
                LinkSpeed: "10",
                Down: "150",
                Up: "1",
                Price: "19.99",
              },
              Service: {
                id: "c3fc38a6-cc6c-11ee-b3a0-41304c54534f",
                access_code: "X4QOBS4G",
                account_types: '["RESIDENTIAL"]',
                approval_values: {
                  account_types: {
                    value: '["RESIDENTIAL",]',
                    status: "PUBLISHED",
                    published_values: ['["RESIDENTIAL",]'],
                  },
                },
                description: "This is the Service 2024 in this Automated town.",
                display_type: "PUBLIC",
                image: null,
                ip_range: "",
                json: [],
                marketing_url: "",
                name: "Service 2024",
                network_operator_label: "123",
                provider_id: "7aa49c76-85a3-11ee-b78b-34543646364c",
                servicetype_id: "5f207676-7a21-11ec-9c1e-374742515a4d",
                standby_settings: {
                  enabled: "1",
                  on_initially: "0",
                },
                status: "ACTIVE",
                subscription_redirect_url: "",
                support_url: "",
                virtual_system_id: null,
                walled_garden_label: "123",
                created_at: "2024-02-15 18:43:21",
                updated_at: "2024-04-05 17:15:27",
                Provider: {
                  id: "7aa49c76-85a3-11ee-b78b-34543646364c",
                  auto_approve: [],
                  description: "Provider Account for DEV system",
                  has_portal: true,
                  image: null,
                  name: "QA Dev Provider",
                  parent_id: "02ced310-5a6d-11eb-bd67-35323941484f",
                  status: "ACTIVE",
                  type: "SERVICE",
                  created_at: "2023-11-20 12:26:07",
                  updated_at: "2024-04-05 17:15:31",
                  level: "1",
                  Contacts: [
                    {
                      id: "35f1795e-a404-11ed-a815-5138434e3238",
                      email: "dherrick@entpnt.com",
                      first_name: "Devyn",
                      last_name: "Herrick",
                      location_id: "35f18bb0-a404-11ed-95de-344437433133",
                      phone: "2087168185",
                      status: "ACTIVE",
                      type: "SUPPORT",
                      created_at: "2023-02-03 13:49:11",
                      updated_at: "2023-02-03 13:49:11",
                    },
                    {
                      id: "b356d3e8-92f2-11ee-a6fa-475a54303751",
                      email: "Testeringss@nopes.test",
                      first_name: "Contact",
                      last_name: "Testering",
                      location_id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
                      phone: "8002223333",
                      status: "ACTIVE",
                      type: "SUPPORT",
                      created_at: "2023-12-04 15:15:58",
                      updated_at: "2024-03-12 14:06:33",
                      Location: {
                        id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
                        address1: "1000 Drive",
                        address2: "",
                        city: "Ammon",
                        country: "US",
                        elevation: null,
                        lat: null,
                        lon: null,
                        state: "ID",
                        status: "ACTIVE",
                        uri: "",
                        zip: "00000",
                        created_at: "2023-12-04 15:15:58",
                        updated_at: "2023-12-04 15:15:58",
                      },
                    },
                    // {
                    //   id: "b356d3e8-92f2-11ee-a6fa-475a54303751",
                    //   email: "Testeringss@nopes.test",
                    //   first_name: "Contact",
                    //   last_name: "Testering",
                    //   location_id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
                    //   phone: "8002223333",
                    //   status: "ACTIVE",
                    //   type: "SHIPPING",
                    //   created_at: "2023-12-04 15:15:58",
                    //   updated_at: "2024-03-12 14:06:33",
                    //   Location: {
                    //     id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
                    //     address1: "1000 Drive",
                    //     address2: "",
                    //     city: "Ammon",
                    //     country: "US",
                    //     elevation: null,
                    //     lat: null,
                    //     lon: null,
                    //     state: "ID",
                    //     status: "ACTIVE",
                    //     uri: "",
                    //     zip: "00000",
                    //     created_at: "2023-12-04 15:15:58",
                    //     updated_at: "2023-12-04 15:15:58",
                    //   },
                    // },
                  ],
                },
              },
            },
            User: {
              id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
              auth_location: "LDAP",
              contact_id: "d99b87b4-0492-11ed-ae58-544259474838",
              is_subscriber: true,
              roles: ["SUBSCRIBER"],
              status: "ACTIVE",
              username: "test",
              created_at: "2022-07-15 17:07:08",
              updated_at: "2024-04-10 09:01:21",
              can_review: true,
              default_url_subscriber_portal: "/dashboard",
              messaging: {
                OPERATOR_MESSAGE: ["PORTAL", "EMAIL"],
                ACCOUNT: [],
                USER: [],
                SUBSCRIPTION: [],
                FIRMWARE: [],
              },
              force_username: false,
              default_url_management_portal: "",
              answered_security_questions: true,
            },
          },
        ],
      },
      {
        id: "b844152a-af33-11ee-af25-483941344743",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: true,
        display_name: "LAN 1",
        mac_address_text: null,
        name: "GigabitEth1",
        number: "2",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-01-09 14:12:07",
        Subscriptions: [
          {
            id: "259af70d-ead1-4040-8631-49c985b1fc0f",
            account_id: "a3d72348-d021-11ee-996c-4f33504e4b50",
            expire_at: null,
            port_interface_id: "f040661c-dd79-11ee-bd1d-474b50584937",
            service_provider_label: "",
            serviceplan_id: "cba3a9cc-cc6c-11ee-935e-52364357434a",
            status: "ACTIVE",
            triggered_by: "USER",
            user_id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
            virtual_system_id: null,
            created_at: "2024-03-20 14:55:23",
            updated_at: "2024-03-20 14:55:25",
            Serviceplan: {
              id: "cba3a9cc-cc6c-11ee-935e-52364357434a",
              approval_values: [],
              description: "This is the 2024 basic service plan edited",
              expire_time: "0",
              image: "",
              name: "Basic 2024",
              ratings_sum: "0",
              service_id: "c3fc38a6-cc6c-11ee-b3a0-41304c54534f",
              status: "ACTIVE",
              total_reviews: "0",
              created_at: "2024-02-15 18:43:34",
              updated_at: "2024-04-05 17:13:50",
              fields: {
                Name: "Basic 2024",
                LinkSpeed: "10",
                Down: "150",
                Up: "1",
                Price: "19.99",
              },
              Service: {
                id: "c3fc38a6-cc6c-11ee-b3a0-41304c54534f",
                access_code: "X4QOBS4G",
                account_types: '["RESIDENTIAL"]',
                approval_values: {
                  account_types: {
                    value: '["RESIDENTIAL",]',
                    status: "PUBLISHED",
                    published_values: ['["RESIDENTIAL",]'],
                  },
                },
                description: "This is the Service 2024 in this Automated town.",
                display_type: "PUBLIC",
                image: null,
                ip_range: "",
                json: [],
                marketing_url: "",
                name: "Service 2024",
                network_operator_label: "123",
                provider_id: "7aa49c76-85a3-11ee-b78b-34543646364c",
                servicetype_id: "5f207676-7a21-11ec-9c1e-374742515a4d",
                standby_settings: {
                  enabled: "1",
                  on_initially: "0",
                },
                status: "ACTIVE",
                subscription_redirect_url: "",
                support_url: "",
                virtual_system_id: null,
                walled_garden_label: "123",
                created_at: "2024-02-15 18:43:21",
                updated_at: "2024-04-05 17:15:27",
                Provider: {
                  id: "7aa49c76-85a3-11ee-b78b-34543646364c",
                  auto_approve: [],
                  description: "Provider Account for DEV system",
                  has_portal: true,
                  image: null,
                  name: "QA Dev Provider",
                  parent_id: "02ced310-5a6d-11eb-bd67-35323941484f",
                  status: "ACTIVE",
                  type: "SERVICE",
                  created_at: "2023-11-20 12:26:07",
                  updated_at: "2024-04-05 17:15:31",
                  level: "1",
                  Contacts: [
                    {
                      id: "35f1795e-a404-11ed-a815-5138434e3238",
                      email: "dherrick@entpnt.com",
                      first_name: "Devyn",
                      last_name: "Herrick",
                      location_id: "35f18bb0-a404-11ed-95de-344437433133",
                      phone: "2087168185",
                      status: "ACTIVE",
                      type: "SUPPORT",
                      created_at: "2023-02-03 13:49:11",
                      updated_at: "2023-02-03 13:49:11",
                    },
                    {
                      id: "b356d3e8-92f2-11ee-a6fa-475a54303751",
                      email: "Testeringss@nopes.test",
                      first_name: "Contact",
                      last_name: "Testering",
                      location_id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
                      phone: "8002223333",
                      status: "ACTIVE",
                      type: "SUPPORT",
                      created_at: "2023-12-04 15:15:58",
                      updated_at: "2024-03-12 14:06:33",
                      Location: {
                        id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
                        address1: "1000 Drive",
                        address2: "",
                        city: "Ammon",
                        country: "US",
                        elevation: null,
                        lat: null,
                        lon: null,
                        state: "ID",
                        status: "ACTIVE",
                        uri: "",
                        zip: "00000",
                        created_at: "2023-12-04 15:15:58",
                        updated_at: "2023-12-04 15:15:58",
                      },
                    },
                    {
                      id: "b356d3e8-92f2-11ee-a6fa-475a54303751",
                      email: "Testeringss@nopes.test",
                      first_name: "Contact",
                      last_name: "Testering",
                      location_id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
                      phone: "8002223333",
                      status: "ACTIVE",
                      type: "SHIPPING",
                      created_at: "2023-12-04 15:15:58",
                      updated_at: "2024-03-12 14:06:33",
                      Location: {
                        id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
                        address1: "1000 Drive",
                        address2: "",
                        city: "Ammon",
                        country: "US",
                        elevation: null,
                        lat: null,
                        lon: null,
                        state: "ID",
                        status: "ACTIVE",
                        uri: "",
                        zip: "00000",
                        created_at: "2023-12-04 15:15:58",
                        updated_at: "2023-12-04 15:15:58",
                      },
                    },
                  ],
                },
              },
            },
            User: {
              id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
              auth_location: "LDAP",
              contact_id: "d99b87b4-0492-11ed-ae58-544259474838",
              is_subscriber: true,
              roles: ["SUBSCRIBER"],
              status: "ACTIVE",
              username: "test",
              created_at: "2022-07-15 17:07:08",
              updated_at: "2024-04-10 09:01:21",
              can_review: true,
              default_url_subscriber_portal: "/dashboard",
              messaging: {
                OPERATOR_MESSAGE: ["PORTAL", "EMAIL"],
                ACCOUNT: [],
                USER: [],
                SUBSCRIPTION: [],
                FIRMWARE: [],
              },
              force_username: false,
              default_url_management_portal: "",
              answered_security_questions: true,
            },
          },
        ],
      },
      {
        id: "b843b5ee-af33-11ee-a4d8-4b4453434b4a",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: true,
        display_name: "WAN",
        mac_address_text: null,
        name: "WAN",
        number: "1",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "OPEN",
        status: "ACTIVE",
        type: "WAN",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-01-09 14:12:07",
      },
      {
        id: "b84aad90-af33-11ee-a989-4c56304b3857",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Management2",
        mac_address_text: "00:07:ba:31:03:81",
        name: "Management2",
        number: "15",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b84a1ba0-af33-11ee-9c58-4d41394c325a",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest8",
        mac_address_text: "00:07:ba:31:03:89",
        name: "Guest8",
        number: "13",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b848a4be-af33-11ee-a24b-4d5954503557",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest3",
        mac_address_text: "00:07:ba:31:03:84",
        name: "Guest3",
        number: "8",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b847b87e-af33-11ee-b618-4e4948514741",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: true,
        display_name: "LAN 4",
        mac_address_text: null,
        name: "GigabitEth4",
        number: "5",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "INACTIVE",
        type: "LAN",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-02-02 09:09:03",
        Subscriptions: [
          {
            id: "259af70d-ead1-4040-8631-49c985b1fc0f",
            account_id: "a3d72348-d021-11ee-996c-4f33504e4b50",
            expire_at: null,
            port_interface_id: "f040661c-dd79-11ee-bd1d-474b50584937",
            service_provider_label: "",
            serviceplan_id: "cba3a9cc-cc6c-11ee-935e-52364357434a",
            status: "ACTIVE",
            triggered_by: "USER",
            user_id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
            virtual_system_id: null,
            created_at: "2024-03-20 14:55:23",
            updated_at: "2024-03-20 14:55:25",
            Serviceplan: {
              id: "cba3a9cc-cc6c-11ee-935e-52364357434a",
              approval_values: [],
              description: "This is the 2024 basic service plan edited",
              expire_time: "0",
              image: "",
              name: "Basic 2024",
              ratings_sum: "0",
              service_id: "c3fc38a6-cc6c-11ee-b3a0-41304c54534f",
              status: "ACTIVE",
              total_reviews: "0",
              created_at: "2024-02-15 18:43:34",
              updated_at: "2024-04-05 17:13:50",
              fields: {
                Name: "Basic 2024",
                LinkSpeed: "10",
                Down: "150",
                Up: "1",
                Price: "19.99",
              },
              Service: {
                id: "c3fc38a6-cc6c-11ee-b3a0-41304c54534f",
                access_code: "X4QOBS4G",
                account_types: '["RESIDENTIAL"]',
                approval_values: {
                  account_types: {
                    value: '["RESIDENTIAL",]',
                    status: "PUBLISHED",
                    published_values: ['["RESIDENTIAL",]'],
                  },
                },
                description: "This is the Service 2024 in this Automated town.",
                display_type: "PUBLIC",
                image: null,
                ip_range: "",
                json: [],
                marketing_url: "",
                name: "Service 2024",
                network_operator_label: "123",
                provider_id: "7aa49c76-85a3-11ee-b78b-34543646364c",
                servicetype_id: "5f207676-7a21-11ec-9c1e-374742515a4d",
                standby_settings: {
                  enabled: "1",
                  on_initially: "0",
                },
                status: "ACTIVE",
                subscription_redirect_url: "",
                support_url: "",
                virtual_system_id: null,
                walled_garden_label: "123",
                created_at: "2024-02-15 18:43:21",
                updated_at: "2024-04-05 17:15:27",
                Provider: {
                  id: "7aa49c76-85a3-11ee-b78b-34543646364c",
                  auto_approve: [],
                  description: "Provider Account for DEV system",
                  has_portal: true,
                  image: null,
                  name: "QA Dev Provider",
                  parent_id: "02ced310-5a6d-11eb-bd67-35323941484f",
                  status: "ACTIVE",
                  type: "SERVICE",
                  created_at: "2023-11-20 12:26:07",
                  updated_at: "2024-04-05 17:15:31",
                  level: "1",
                  Contacts: [
                    {
                      id: "b356d3e8-92f2-11ee-a6fa-475a54303751",
                      email: "Testeringss@nopes.test",
                      first_name: "Contact",
                      last_name: "Testering",
                      location_id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
                      phone: "8002223333",
                      status: "ACTIVE",
                      type: "SHIPPING",
                      created_at: "2023-12-04 15:15:58",
                      updated_at: "2024-03-12 14:06:33",
                      Location: {
                        id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
                        address1: "1000 Drive",
                        address2: "",
                        city: "Ammon",
                        country: "US",
                        elevation: null,
                        lat: null,
                        lon: null,
                        state: "ID",
                        status: "ACTIVE",
                        uri: "",
                        zip: "00000",
                        created_at: "2023-12-04 15:15:58",
                        updated_at: "2023-12-04 15:15:58",
                      },
                    },
                  ],
                },
              },
            },
            User: {
              id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
              auth_location: "LDAP",
              contact_id: "d99b87b4-0492-11ed-ae58-544259474838",
              is_subscriber: true,
              roles: ["SUBSCRIBER"],
              status: "ACTIVE",
              username: "test",
              created_at: "2022-07-15 17:07:08",
              updated_at: "2024-04-10 09:01:21",
              can_review: true,
              default_url_subscriber_portal: "/dashboard",
              messaging: {
                OPERATOR_MESSAGE: ["PORTAL", "EMAIL"],
                ACCOUNT: [],
                USER: [],
                SUBSCRIPTION: [],
                FIRMWARE: [],
              },
              force_username: false,
              default_url_management_portal: "",
              answered_security_questions: true,
            },
          },
        ],
      },
      {
        id: "b84805ae-af33-11ee-88f4-4f3834353842",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest1",
        mac_address_text: "00:07:ba:31:03:82",
        name: "Guest1",
        number: "6",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b8494068-af33-11ee-ba02-4f5137303938",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest5",
        mac_address_text: "00:07:ba:31:03:86",
        name: "Guest5",
        number: "10",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b8484ee2-af33-11ee-a436-5335444d4334",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest2",
        mac_address_text: "00:07:ba:31:03:83",
        name: "Guest2",
        number: "7",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b8476716-af33-11ee-ad4b-54304b534653",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: true,
        display_name: "LAN 3",
        mac_address_text: null,
        name: "GigabitEth3",
        number: "4",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-01-09 14:12:07",
        Subscriptions: [
          {
            id: "259af70d-ead1-4040-8631-49c985b1fc0f",
            account_id: "a3d72348-d021-11ee-996c-4f33504e4b50",
            expire_at: null,
            port_interface_id: "f040661c-dd79-11ee-bd1d-474b50584937",
            service_provider_label: "",
            serviceplan_id: "cba3a9cc-cc6c-11ee-935e-52364357434a",
            status: "ACTIVE",
            triggered_by: "USER",
            user_id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
            virtual_system_id: null,
            created_at: "2024-03-20 14:55:23",
            updated_at: "2024-03-20 14:55:25",
            Serviceplan: {
              id: "cba3a9cc-cc6c-11ee-935e-52364357434a",
              approval_values: [],
              description: "This is the 2024 basic service plan edited",
              expire_time: "0",
              image: "",
              name: "Basic 2024",
              ratings_sum: "0",
              service_id: "c3fc38a6-cc6c-11ee-b3a0-41304c54534f",
              status: "ACTIVE",
              total_reviews: "0",
              created_at: "2024-02-15 18:43:34",
              updated_at: "2024-04-05 17:13:50",
              fields: {
                Name: "Basic 2024",
                LinkSpeed: "10",
                Down: "150",
                Up: "1",
                Price: "19.99",
              },
              Service: {
                id: "c3fc38a6-cc6c-11ee-b3a0-41304c54534f",
                access_code: "X4QOBS4G",
                account_types: '["RESIDENTIAL"]',
                approval_values: {
                  account_types: {
                    value: '["RESIDENTIAL",]',
                    status: "PUBLISHED",
                    published_values: ['["RESIDENTIAL",]'],
                  },
                },
                description: "This is the Service 2024 in this Automated town.",
                display_type: "PUBLIC",
                image: null,
                ip_range: "",
                json: [],
                marketing_url: "",
                name: "Service 2024",
                network_operator_label: "123",
                provider_id: "7aa49c76-85a3-11ee-b78b-34543646364c",
                servicetype_id: "5f207676-7a21-11ec-9c1e-374742515a4d",
                standby_settings: {
                  enabled: "1",
                  on_initially: "0",
                },
                status: "ACTIVE",
                subscription_redirect_url: "",
                support_url: "",
                virtual_system_id: null,
                walled_garden_label: "123",
                created_at: "2024-02-15 18:43:21",
                updated_at: "2024-04-05 17:15:27",
                Provider: {
                  id: "7aa49c76-85a3-11ee-b78b-34543646364c",
                  auto_approve: [],
                  description: "Provider Account for DEV system",
                  has_portal: true,
                  image: null,
                  name: "QA Dev Provider",
                  parent_id: "02ced310-5a6d-11eb-bd67-35323941484f",
                  status: "ACTIVE",
                  type: "SERVICE",
                  created_at: "2023-11-20 12:26:07",
                  updated_at: "2024-04-05 17:15:31",
                  level: "1",
                  Contacts: [
                    {
                      id: "35f1795e-a404-11ed-a815-5138434e3238",
                      email: "dherrick@entpnt.com",
                      first_name: "Devyn",
                      last_name: "Herrick",
                      location_id: "35f18bb0-a404-11ed-95de-344437433133",
                      phone: "2087168185",
                      status: "ACTIVE",
                      type: "SUPPORT",
                      created_at: "2023-02-03 13:49:11",
                      updated_at: "2023-02-03 13:49:11",
                    },
                    {
                      id: "b356d3e8-92f2-11ee-a6fa-475a54303751",
                      email: "Testeringss@nopes.test",
                      first_name: "Contact",
                      last_name: "Testering",
                      location_id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
                      phone: "8002223333",
                      status: "ACTIVE",
                      type: "SUPPORT",
                      created_at: "2023-12-04 15:15:58",
                      updated_at: "2024-03-12 14:06:33",
                      Location: {
                        id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
                        address1: "1000 Drive",
                        address2: "",
                        city: "Ammon",
                        country: "US",
                        elevation: null,
                        lat: null,
                        lon: null,
                        state: "ID",
                        status: "ACTIVE",
                        uri: "",
                        zip: "00000",
                        created_at: "2023-12-04 15:15:58",
                        updated_at: "2023-12-04 15:15:58",
                      },
                    },
                  ],
                },
              },
            },
            User: {
              id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
              auth_location: "LDAP",
              contact_id: "d99b87b4-0492-11ed-ae58-544259474838",
              is_subscriber: true,
              roles: ["SUBSCRIBER"],
              status: "ACTIVE",
              username: "test",
              created_at: "2022-07-15 17:07:08",
              updated_at: "2024-04-10 09:01:21",
              can_review: true,
              default_url_subscriber_portal: "/dashboard",
              messaging: {
                OPERATOR_MESSAGE: ["PORTAL", "EMAIL"],
                ACCOUNT: [],
                USER: [],
                SUBSCRIPTION: [],
                FIRMWARE: [],
              },
              force_username: false,
              default_url_management_portal: "",
              answered_security_questions: true,
            },
          },
        ],
      },
      {
        id: "b8498960-af33-11ee-b55b-544f4f593256",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest6",
        mac_address_text: "00:07:ba:31:03:87",
        name: "Guest6",
        number: "11",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
    ],
    Accounts: [
      {
        id: "c20611be-e36c-11ed-9055-5953494e545a",
        account_number: "10_Sumo_10_Sumo_Way",
        name: "10, Sumo",
        notes: "",
        status: "ACTIVE",
        type: "RESIDENTIAL",
        created_at: "2023-04-25 07:26:16",
        updated_at: "2024-03-05 09:32:34",
        Users: [
          {
            id: "93b62b50-0f90-11ee-8ea5-394d4e334a51",
            auth_location: "LDAP",
            contact_id: "93b64126-0f90-11ee-9109-343045423248",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "adaptivetest@gmail.com",
            created_at: "2023-06-20 11:33:32",
            updated_at: "2024-04-09 14:37:25",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            force_password: true,
            force_username: false,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            force_created_at: "2024-04-01 12:21:25",
            default_url_management_portal: "",
            answered_security_questions: true,
            Contact: {
              id: "93b64126-0f90-11ee-9109-343045423248",
              email: "adaptivetest@gmail.com",
              first_name: "Test",
              last_name: "Test",
              location_id: "93b65c10-0f90-11ee-8e4a-495450543557",
              phone: "(121)-212-1212",
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2023-06-20 11:33:32",
              updated_at: "2023-06-20 11:33:32",
            },
          },
          {
            id: "c0c59a18-e36c-11ed-ad60-36564c423141",
            auth_location: "LDAP",
            contact_id: "c0c5b016-e36c-11ed-8be2-575237424632",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "sumo10@gmail.com",
            created_at: "2023-04-25 07:26:14",
            updated_at: "2024-04-02 14:05:57",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            force_password: false,
            force_username: false,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            default_url_management_portal: "",
            answered_security_questions: true,
            Contact: {
              id: "c0c5b016-e36c-11ed-8be2-575237424632",
              email: "sumo10@gmail.com",
              first_name: "Sumo",
              last_name: "10",
              location_id: "c0c5cc4a-e36c-11ed-bf00-51323453564f",
              phone: "1010101010",
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2023-04-25 07:26:14",
              updated_at: "2024-01-26 14:20:28",
            },
          },
          {
            id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
            auth_location: "LDAP",
            contact_id: "d99b87b4-0492-11ed-ae58-544259474838",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "test",
            created_at: "2022-07-15 17:07:08",
            updated_at: "2024-04-10 09:01:21",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            messaging: {
              OPERATOR_MESSAGE: ["PORTAL", "EMAIL"],
              ACCOUNT: [],
              USER: [],
              SUBSCRIPTION: [],
              FIRMWARE: [],
            },
            force_username: false,
            default_url_management_portal: "",
            answered_security_questions: true,
            Contact: {
              id: "d99b87b4-0492-11ed-ae58-544259474838",
              email: "deletingadmin0007one@yopmail.com",
              first_name: "John",
              last_name: "Do",
              location_id: "d99bc832-0492-11ed-81fc-45425753504d",
              phone: "(224)-123-1234",
              status: "PENDING",
              type: "BILLING",
              created_at: "2022-07-15 17:07:08",
              updated_at: "2024-04-10 09:01:21",
            },
          },
          {
            id: "80cc90a0-c463-11ee-9da9-483831413547",
            auth_location: "DB",
            contact_id: "80ccadd8-c463-11ee-bef7-4d4e4c504c43",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "INACTIVE",
            username: "DemoMonday2",
            created_at: "2024-02-05 13:16:53",
            updated_at: "2024-02-19 15:01:54",
            can_review: false,
            default_url_management_portal: "",
            default_url_subscriber_portal: "",
            messaging: "",
            answered_security_questions: false,
            Contact: {
              id: "80ccadd8-c463-11ee-bef7-4d4e4c504c43",
              email: "mario.alvarado@gopello.com",
              first_name: "DemoUser",
              last_name: "CoolLastname",
              location_id: "80ccc98a-c463-11ee-8402-4d4f44344149",
              phone: "1717171716",
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2024-02-05 13:16:53",
              updated_at: "2024-02-05 13:17:08",
            },
          },
          {
            id: "b220b79a-15df-11ee-b8de-464f424c4e34",
            auth_location: "LDAP",
            contact_id: "b220cef6-15df-11ee-a146-593743554b45",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "INACTIVE",
            username: "Accountflow@gmail.com",
            created_at: "2023-06-28 12:15:00",
            updated_at: "2024-04-02 14:06:00",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            force_password: true,
            force_username: false,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            force_created_at: "2023-11-22 12:13:15",
            default_url_management_portal: "/dashboard#/provider/list",
            answered_security_questions: true,
            Contact: {
              id: "b220cef6-15df-11ee-a146-593743554b45",
              email: "Accountflow@gmail.com",
              first_name: "Flow",
              last_name: "Test",
              location_id: "b220e904-15df-11ee-94d6-564a53454731",
              phone: "(518)-101-0101",
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2023-06-28 12:15:00",
              updated_at: "2023-06-28 12:15:00",
            },
          },
          {
            id: "ccd4fa4a-bc94-11ee-ba75-393832555544",
            auth_location: "LDAP",
            contact_id: "ccd51660-bc94-11ee-863d-393358524944",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "00969@testing.tests",
            created_at: "2024-01-26 14:49:37",
            updated_at: "2024-04-09 14:18:47",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            force_password: true,
            force_username: false,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            force_created_at: "2024-04-03 10:50:14",
            default_url_management_portal: "",
            answered_security_questions: true,
            Contact: {
              id: "ccd51660-bc94-11ee-863d-393358524944",
              email: "00969@testing.test",
              first_name: "Testing",
              last_name: "Qa",
              location_id: "ccd53334-bc94-11ee-9833-4c5437373632",
              phone: "(555)-000-0009",
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2024-01-26 14:49:37",
              updated_at: "2024-04-03 17:09:30",
            },
          },
          {
            id: "e403fefc-896b-11ee-a8da-4c3048334643",
            auth_location: "LDAP",
            contact_id: "e4041522-896b-11ee-80ac-4d4c43374b43",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "removingtest",
            created_at: "2023-11-22 12:18:17",
            updated_at: "2024-03-19 10:45:30",
            force_password: false,
            can_review: true,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            default_url_management_portal: "",
            default_url_subscriber_portal: "",
            answered_security_questions: false,
            Contact: {
              id: "e4041522-896b-11ee-80ac-4d4c43374b43",
              email: "remove@testing.test",
              first_name: null,
              last_name: null,
              location_id: null,
              phone: null,
              status: "ACTIVE",
              type: "SALES",
              created_at: "2023-11-22 12:18:17",
              updated_at: "2023-11-22 12:18:17",
            },
          },
          {
            id: "ff46af72-e398-11ed-81fd-354c5a34445a",
            auth_location: "LDAP",
            contact_id: "ff46ce62-e398-11ed-8929-534453484939",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "INACTIVE",
            username: "123@gmail.com",
            created_at: "2023-04-25 12:35:48",
            updated_at: "2024-04-02 14:05:57",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            force_password: false,
            force_username: false,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            default_url_management_portal: "",
            answered_security_questions: true,
            Contact: {
              id: "ff46ce62-e398-11ed-8929-534453484939",
              email: "123@gmail.com",
              first_name: "1",
              last_name: "2",
              location_id: "ff46ea14-e398-11ed-ae34-53503544394c",
              phone: "1231231232",
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2023-04-25 12:35:48",
              updated_at: "2023-04-25 12:35:48",
            },
          },
        ],
      },
      {
        id: "a3d72348-d021-11ee-996c-4f33504e4b50",
        account_number: "admin",
        name: "admin",
        notes: "",
        status: "ACTIVE",
        type: "RESIDENTIAL",
        created_at: "2024-02-20 11:55:39",
        updated_at: "2024-03-08 11:35:36",
        Users: [
          {
            id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
            auth_location: "LDAP",
            contact_id: "d99b87b4-0492-11ed-ae58-544259474838",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "test",
            created_at: "2022-07-15 17:07:08",
            updated_at: "2024-04-10 09:01:21",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            messaging: {
              OPERATOR_MESSAGE: ["PORTAL", "EMAIL"],
              ACCOUNT: [],
              USER: [],
              SUBSCRIPTION: [],
              FIRMWARE: [],
            },
            force_username: false,
            default_url_management_portal: "",
            answered_security_questions: true,
            Contact: {
              id: "d99b87b4-0492-11ed-ae58-544259474838",
              email: "deletingadmin0007one@yopmail.com",
              first_name: "John",
              last_name: "Do",
              location_id: "d99bc832-0492-11ed-81fc-45425753504d",
              phone: "(224)-123-1234",
              status: "PENDING",
              type: "BILLING",
              created_at: "2022-07-15 17:07:08",
              updated_at: "2024-04-10 09:01:21",
            },
          },
          {
            id: "35f15c08-a404-11ed-a632-3835525a3059",
            auth_location: "LDAP",
            contact_id: "35f1795e-a404-11ed-a815-5138434e3238",
            is_subscriber: false,
            roles: ["ADMIN"],
            status: "ACTIVE",
            username: "dherrick",
            created_at: "2023-02-03 13:49:11",
            updated_at: "2024-04-02 14:05:58",
            can_review: true,
            default_url_management_portal: "/dashboard#/device/list",
            default_url_subscriber_portal: "/dashboard",
            force_password: false,
            force_username: false,
            messaging: "",
            answered_security_questions: false,
            Contact: {
              id: "35f1795e-a404-11ed-a815-5138434e3238",
              email: "dherrick@entpnt.com",
              first_name: "Devyn",
              last_name: "Herrick",
              location_id: "35f18bb0-a404-11ed-95de-344437433133",
              phone: "2087168185",
              status: "ACTIVE",
              type: "SUPPORT",
              created_at: "2023-02-03 13:49:11",
              updated_at: "2023-02-03 13:49:11",
            },
          },
        ],
      },
      {
        id: "6ba32912-40f3-11ee-a05b-435a33585a55",
        account_number: "1000a_Account_One",
        name: "1000a_Account_Ones",
        notes: "",
        status: "ACTIVE",
        type: "RESIDENTIAL",
        created_at: "2023-08-22 07:54:32",
        updated_at: "2024-02-01 14:24:15",
        Users: [
          {
            id: "543a5606-40f3-11ee-bfa9-355a56385652",
            auth_location: "LDAP",
            contact_id: "543a729e-40f3-11ee-b683-43594f505932",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "accountone@gmail.com",
            created_at: "2023-08-22 07:53:52",
            updated_at: "2024-01-29 14:59:58",
            can_review: false,
            force_username: true,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            force_password: true,
            default_url_management_portal: "",
            default_url_subscriber_portal: "",
            answered_security_questions: false,
            Contact: {
              id: "543a729e-40f3-11ee-b683-43594f505932",
              email: "accountone@gmail.com",
              first_name: "Account ",
              last_name: "One",
              location_id: "543a8cd4-40f3-11ee-83a7-4b4f534d584c",
              phone: "1234567809",
              status: "ACTIVE",
              type: "SALES",
              created_at: "2023-08-22 07:53:52",
              updated_at: "2024-02-01 14:25:51",
            },
          },
          {
            id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
            auth_location: "LDAP",
            contact_id: "d99b87b4-0492-11ed-ae58-544259474838",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "test",
            created_at: "2022-07-15 17:07:08",
            updated_at: "2024-04-10 09:01:21",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            messaging: {
              OPERATOR_MESSAGE: ["PORTAL", "EMAIL"],
              ACCOUNT: [],
              USER: [],
              SUBSCRIPTION: [],
              FIRMWARE: [],
            },
            force_username: false,
            default_url_management_portal: "",
            answered_security_questions: true,
            Contact: {
              id: "d99b87b4-0492-11ed-ae58-544259474838",
              email: "deletingadmin0007one@yopmail.com",
              first_name: "John",
              last_name: "Do",
              location_id: "d99bc832-0492-11ed-81fc-45425753504d",
              phone: "(224)-123-1234",
              status: "PENDING",
              type: "BILLING",
              created_at: "2022-07-15 17:07:08",
              updated_at: "2024-04-10 09:01:21",
            },
          },
          {
            id: "38c6a994-aa6e-11ee-bbe4-4d3342563143",
            auth_location: "DB",
            contact_id: "38c6bcd6-aa6e-11ee-a446-435a4a4c3532",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "INACTIVE",
            username: "Delete me",
            created_at: "2024-01-03 12:28:07",
            updated_at: "2024-01-03 12:28:54",
            force_password: false,
            can_review: true,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            default_url_management_portal: "",
            default_url_subscriber_portal: "",
            answered_security_questions: false,
            Contact: {
              id: "38c6bcd6-aa6e-11ee-a446-435a4a4c3532",
              email: "edess@testing.test",
              first_name: null,
              last_name: null,
              location_id: null,
              phone: null,
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2024-01-03 12:28:07",
              updated_at: "2024-01-03 12:28:07",
            },
          },
        ],
      },
    ],
  },
  {
    id: "f0395a48-dd79-11ee-a68d-35343031464e",
    ansible_update_flag: null,
    description: "EntryPoint Monarch - Veneon",
    hardware_version: "H1-J1900",
    installed_at: "2024-04-09 19:48:41",
    ip_address: "10.128.0.11",
    ip_address_num: null,
    ipv6_address: null,
    ipv6_address_num: null,
    json: null,
    mac_address: "00:e0:67:30:62:31",
    notes: "",
    physicallocation_id: "f19472b0-dd79-11ee-b04b-574c44534350",
    openflow_id: "manual:963803898417",
    password: "EntryPoint#",
    product_id: "68a20860-5cea-11eb-8ba5-4a47484b4443",
    reset_delay: "5",
    rack_id: null,
    rack_unit_end: "0",
    rack_unit_start: "0",
    sdn_server_id: "76ca8990-cd30-11ea-bd34-43415959444d",
    sdn_server_local: false,
    serial_number: "HYSZ20230601418",
    snmp_id: null,
    snmp_server_id: null,
    software_version: "2.1.7.5",
    status: "ACTIVE",
    switch_port_id: null,
    type: "VBG",
    uid: "lab-venoen-test2",
    username: "entrypoint",
    created_at: "2024-03-08 11:30:28",
    updated_at: "2024-04-09 13:48:41",
    Product: {
      id: "68a20860-5cea-11eb-8ba5-4a47484b4443",
      acronym: "veneon",
      description: "Subscriber Premise Indoor Venoen NUC VBG",
      fa_supported: null,
      hardware: "H1-J1900",
      has_api: true,
      manufacturer_id: "6124c525-bc8c-4720-8d1a-13234c034349",
      make: "Venoen",
      model: "H1-J1900",
      part_number: "H1-J1900",
      ssl_enabled: false,
      status: "ACTIVE",
      type: "VBG",
      created_at: "2022-01-20 11:47:09",
      updated_at: "2024-02-15 11:34:39",
    },
    Physicallocation: {
      id: "f19472b0-dd79-11ee-b04b-574c44534350",
      apartment_number: "1002",
      city: "Ammon",
      country: "US",
      elevation: "0",
      house_number: "1",
      lat: "43.469637",
      lon: "-111.966636",
      post_directional: "",
      pre_directional: "N",
      state: "ID",
      status: "ACTIVE",
      street: "123 Test Str",
      uri: "https://www.google.com/maps/place/43.4696366,-111.9666355",
      zip: "83406",
      created_at: "2024-03-08 11:30:30",
      updated_at: "2024-03-19 11:44:33",
    },
    Portinterfaces: [
      {
        id: "b84a624a-af33-11ee-999a-355a54325357",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Management1",
        mac_address_text: "00:07:ba:31:03:80",
        name: "Management1",
        number: "14",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "OPEN",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b849d320-af33-11ee-a4a0-3649574f4c52",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest7",
        mac_address_text: "00:07:ba:31:03:88",
        name: "Guest7",
        number: "12",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b848f324-af33-11ee-85be-425955513159",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest4",
        mac_address_text: "00:07:ba:31:03:85",
        name: "Guest4",
        number: "9",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b847028a-af33-11ee-abeb-475949573445",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: true,
        display_name: "LAN 2",
        mac_address_text: null,
        name: "GigabitEth2",
        number: "3",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-01-09 14:12:07",
      },
      {
        id: "b844152a-af33-11ee-af25-483941344743",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: true,
        display_name: "LAN 1",
        mac_address_text: null,
        name: "GigabitEth1",
        number: "2",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-01-09 14:12:07",
      },
      {
        id: "b843b5ee-af33-11ee-a4d8-4b4453434b4a",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: true,
        display_name: "WAN",
        mac_address_text: null,
        name: "WAN",
        number: "1",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "OPEN",
        status: "ACTIVE",
        type: "WAN",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-01-09 14:12:07",
      },
      {
        id: "b84aad90-af33-11ee-a989-4c56304b3857",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Management2",
        mac_address_text: "00:07:ba:31:03:81",
        name: "Management2",
        number: "15",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b84a1ba0-af33-11ee-9c58-4d41394c325a",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest8",
        mac_address_text: "00:07:ba:31:03:89",
        name: "Guest8",
        number: "13",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b848a4be-af33-11ee-a24b-4d5954503557",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest3",
        mac_address_text: "00:07:ba:31:03:84",
        name: "Guest3",
        number: "8",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b847b87e-af33-11ee-b618-4e4948514741",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: true,
        display_name: "LAN 4",
        mac_address_text: null,
        name: "GigabitEth4",
        number: "5",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "INACTIVE",
        type: "LAN",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-02-02 09:09:03",
      },
      {
        id: "b84805ae-af33-11ee-88f4-4f3834353842",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest1",
        mac_address_text: "00:07:ba:31:03:82",
        name: "Guest1",
        number: "6",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b8494068-af33-11ee-ba02-4f5137303938",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest5",
        mac_address_text: "00:07:ba:31:03:86",
        name: "Guest5",
        number: "10",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b8484ee2-af33-11ee-a436-5335444d4334",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest2",
        mac_address_text: "00:07:ba:31:03:83",
        name: "Guest2",
        number: "7",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b8476716-af33-11ee-ad4b-54304b534653",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: true,
        display_name: "LAN 3",
        mac_address_text: null,
        name: "GigabitEth3",
        number: "4",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-01-09 14:12:07",
      },
      {
        id: "b8498960-af33-11ee-b55b-544f4f593256",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest6",
        mac_address_text: "00:07:ba:31:03:87",
        name: "Guest6",
        number: "11",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
    ],
    Accounts: [
      {
        id: "c20611be-e36c-11ed-9055-5953494e545a",
        account_number: "10_Sumo_10_Sumo_Way",
        name: "10, Sumo",
        notes: "",
        status: "ACTIVE",
        type: "RESIDENTIAL",
        created_at: "2023-04-25 07:26:16",
        updated_at: "2024-03-05 09:32:34",
        Users: [
          {
            id: "93b62b50-0f90-11ee-8ea5-394d4e334a51",
            auth_location: "LDAP",
            contact_id: "93b64126-0f90-11ee-9109-343045423248",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "adaptivetest@gmail.com",
            created_at: "2023-06-20 11:33:32",
            updated_at: "2024-04-09 14:37:25",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            force_password: true,
            force_username: false,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            force_created_at: "2024-04-01 12:21:25",
            default_url_management_portal: "",
            answered_security_questions: true,
            Contact: {
              id: "93b64126-0f90-11ee-9109-343045423248",
              email: "adaptivetest@gmail.com",
              first_name: "Test",
              last_name: "Test",
              location_id: "93b65c10-0f90-11ee-8e4a-495450543557",
              phone: "(121)-212-1212",
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2023-06-20 11:33:32",
              updated_at: "2023-06-20 11:33:32",
            },
          },
          {
            id: "c0c59a18-e36c-11ed-ad60-36564c423141",
            auth_location: "LDAP",
            contact_id: "c0c5b016-e36c-11ed-8be2-575237424632",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "sumo10@gmail.com",
            created_at: "2023-04-25 07:26:14",
            updated_at: "2024-04-02 14:05:57",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            force_password: false,
            force_username: false,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            default_url_management_portal: "",
            answered_security_questions: true,
            Contact: {
              id: "c0c5b016-e36c-11ed-8be2-575237424632",
              email: "sumo10@gmail.com",
              first_name: "Sumo",
              last_name: "10",
              location_id: "c0c5cc4a-e36c-11ed-bf00-51323453564f",
              phone: "1010101010",
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2023-04-25 07:26:14",
              updated_at: "2024-01-26 14:20:28",
            },
          },
          {
            id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
            auth_location: "LDAP",
            contact_id: "d99b87b4-0492-11ed-ae58-544259474838",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "test",
            created_at: "2022-07-15 17:07:08",
            updated_at: "2024-04-10 09:01:21",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            messaging: {
              OPERATOR_MESSAGE: ["PORTAL", "EMAIL"],
              ACCOUNT: [],
              USER: [],
              SUBSCRIPTION: [],
              FIRMWARE: [],
            },
            force_username: false,
            default_url_management_portal: "",
            answered_security_questions: true,
            Contact: {
              id: "d99b87b4-0492-11ed-ae58-544259474838",
              email: "deletingadmin0007one@yopmail.com",
              first_name: "John",
              last_name: "Do",
              location_id: "d99bc832-0492-11ed-81fc-45425753504d",
              phone: "(224)-123-1234",
              status: "PENDING",
              type: "BILLING",
              created_at: "2022-07-15 17:07:08",
              updated_at: "2024-04-10 09:01:21",
            },
          },
          {
            id: "80cc90a0-c463-11ee-9da9-483831413547",
            auth_location: "DB",
            contact_id: "80ccadd8-c463-11ee-bef7-4d4e4c504c43",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "INACTIVE",
            username: "DemoMonday2",
            created_at: "2024-02-05 13:16:53",
            updated_at: "2024-02-19 15:01:54",
            can_review: false,
            default_url_management_portal: "",
            default_url_subscriber_portal: "",
            messaging: "",
            answered_security_questions: false,
            Contact: {
              id: "80ccadd8-c463-11ee-bef7-4d4e4c504c43",
              email: "mario.alvarado@gopello.com",
              first_name: "DemoUser",
              last_name: "CoolLastname",
              location_id: "80ccc98a-c463-11ee-8402-4d4f44344149",
              phone: "1717171716",
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2024-02-05 13:16:53",
              updated_at: "2024-02-05 13:17:08",
            },
          },
          {
            id: "b220b79a-15df-11ee-b8de-464f424c4e34",
            auth_location: "LDAP",
            contact_id: "b220cef6-15df-11ee-a146-593743554b45",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "INACTIVE",
            username: "Accountflow@gmail.com",
            created_at: "2023-06-28 12:15:00",
            updated_at: "2024-04-02 14:06:00",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            force_password: true,
            force_username: false,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            force_created_at: "2023-11-22 12:13:15",
            default_url_management_portal: "/dashboard#/provider/list",
            answered_security_questions: true,
            Contact: {
              id: "b220cef6-15df-11ee-a146-593743554b45",
              email: "Accountflow@gmail.com",
              first_name: "Flow",
              last_name: "Test",
              location_id: "b220e904-15df-11ee-94d6-564a53454731",
              phone: "(518)-101-0101",
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2023-06-28 12:15:00",
              updated_at: "2023-06-28 12:15:00",
            },
          },
          {
            id: "ccd4fa4a-bc94-11ee-ba75-393832555544",
            auth_location: "LDAP",
            contact_id: "ccd51660-bc94-11ee-863d-393358524944",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "00969@testing.tests",
            created_at: "2024-01-26 14:49:37",
            updated_at: "2024-04-09 14:18:47",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            force_password: true,
            force_username: false,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            force_created_at: "2024-04-03 10:50:14",
            default_url_management_portal: "",
            answered_security_questions: true,
            Contact: {
              id: "ccd51660-bc94-11ee-863d-393358524944",
              email: "00969@testing.test",
              first_name: "Testing",
              last_name: "Qa",
              location_id: "ccd53334-bc94-11ee-9833-4c5437373632",
              phone: "(555)-000-0009",
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2024-01-26 14:49:37",
              updated_at: "2024-04-03 17:09:30",
            },
          },
          {
            id: "e403fefc-896b-11ee-a8da-4c3048334643",
            auth_location: "LDAP",
            contact_id: "e4041522-896b-11ee-80ac-4d4c43374b43",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "removingtest",
            created_at: "2023-11-22 12:18:17",
            updated_at: "2024-03-19 10:45:30",
            force_password: false,
            can_review: true,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            default_url_management_portal: "",
            default_url_subscriber_portal: "",
            answered_security_questions: false,
            Contact: {
              id: "e4041522-896b-11ee-80ac-4d4c43374b43",
              email: "remove@testing.test",
              first_name: null,
              last_name: null,
              location_id: null,
              phone: null,
              status: "ACTIVE",
              type: "SALES",
              created_at: "2023-11-22 12:18:17",
              updated_at: "2023-11-22 12:18:17",
            },
          },
          {
            id: "ff46af72-e398-11ed-81fd-354c5a34445a",
            auth_location: "LDAP",
            contact_id: "ff46ce62-e398-11ed-8929-534453484939",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "INACTIVE",
            username: "123@gmail.com",
            created_at: "2023-04-25 12:35:48",
            updated_at: "2024-04-02 14:05:57",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            force_password: false,
            force_username: false,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            default_url_management_portal: "",
            answered_security_questions: true,
            Contact: {
              id: "ff46ce62-e398-11ed-8929-534453484939",
              email: "123@gmail.com",
              first_name: "1",
              last_name: "2",
              location_id: "ff46ea14-e398-11ed-ae34-53503544394c",
              phone: "1231231232",
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2023-04-25 12:35:48",
              updated_at: "2023-04-25 12:35:48",
            },
          },
        ],
      },
      {
        id: "a3d72348-d021-11ee-996c-4f33504e4b50",
        account_number: "admin",
        name: "admin",
        notes: "",
        status: "ACTIVE",
        type: "RESIDENTIAL",
        created_at: "2024-02-20 11:55:39",
        updated_at: "2024-03-08 11:35:36",
        Users: [
          {
            id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
            auth_location: "LDAP",
            contact_id: "d99b87b4-0492-11ed-ae58-544259474838",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "test",
            created_at: "2022-07-15 17:07:08",
            updated_at: "2024-04-10 09:01:21",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            messaging: {
              OPERATOR_MESSAGE: ["PORTAL", "EMAIL"],
              ACCOUNT: [],
              USER: [],
              SUBSCRIPTION: [],
              FIRMWARE: [],
            },
            force_username: false,
            default_url_management_portal: "",
            answered_security_questions: true,
            Contact: {
              id: "d99b87b4-0492-11ed-ae58-544259474838",
              email: "deletingadmin0007one@yopmail.com",
              first_name: "John",
              last_name: "Do",
              location_id: "d99bc832-0492-11ed-81fc-45425753504d",
              phone: "(224)-123-1234",
              status: "PENDING",
              type: "BILLING",
              created_at: "2022-07-15 17:07:08",
              updated_at: "2024-04-10 09:01:21",
            },
          },
          {
            id: "35f15c08-a404-11ed-a632-3835525a3059",
            auth_location: "LDAP",
            contact_id: "35f1795e-a404-11ed-a815-5138434e3238",
            is_subscriber: false,
            roles: ["ADMIN"],
            status: "ACTIVE",
            username: "dherrick",
            created_at: "2023-02-03 13:49:11",
            updated_at: "2024-04-02 14:05:58",
            can_review: true,
            default_url_management_portal: "/dashboard#/device/list",
            default_url_subscriber_portal: "/dashboard",
            force_password: false,
            force_username: false,
            messaging: "",
            answered_security_questions: false,
            Contact: {
              id: "35f1795e-a404-11ed-a815-5138434e3238",
              email: "dherrick@entpnt.com",
              first_name: "Devyn",
              last_name: "Herrick",
              location_id: "35f18bb0-a404-11ed-95de-344437433133",
              phone: "2087168185",
              status: "ACTIVE",
              type: "SUPPORT",
              created_at: "2023-02-03 13:49:11",
              updated_at: "2023-02-03 13:49:11",
            },
          },
        ],
      },
      {
        id: "6ba32912-40f3-11ee-a05b-435a33585a55",
        account_number: "1000a_Account_One",
        name: "1000a_Account_Ones",
        notes: "",
        status: "ACTIVE",
        type: "RESIDENTIAL",
        created_at: "2023-08-22 07:54:32",
        updated_at: "2024-02-01 14:24:15",
        Users: [
          {
            id: "543a5606-40f3-11ee-bfa9-355a56385652",
            auth_location: "LDAP",
            contact_id: "543a729e-40f3-11ee-b683-43594f505932",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "accountone@gmail.com",
            created_at: "2023-08-22 07:53:52",
            updated_at: "2024-01-29 14:59:58",
            can_review: false,
            force_username: true,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            force_password: true,
            default_url_management_portal: "",
            default_url_subscriber_portal: "",
            answered_security_questions: false,
            Contact: {
              id: "543a729e-40f3-11ee-b683-43594f505932",
              email: "accountone@gmail.com",
              first_name: "Account ",
              last_name: "One",
              location_id: "543a8cd4-40f3-11ee-83a7-4b4f534d584c",
              phone: "1234567809",
              status: "ACTIVE",
              type: "SALES",
              created_at: "2023-08-22 07:53:52",
              updated_at: "2024-02-01 14:25:51",
            },
          },
          {
            id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
            auth_location: "LDAP",
            contact_id: "d99b87b4-0492-11ed-ae58-544259474838",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "test",
            created_at: "2022-07-15 17:07:08",
            updated_at: "2024-04-10 09:01:21",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            messaging: {
              OPERATOR_MESSAGE: ["PORTAL", "EMAIL"],
              ACCOUNT: [],
              USER: [],
              SUBSCRIPTION: [],
              FIRMWARE: [],
            },
            force_username: false,
            default_url_management_portal: "",
            answered_security_questions: true,
            Contact: {
              id: "d99b87b4-0492-11ed-ae58-544259474838",
              email: "deletingadmin0007one@yopmail.com",
              first_name: "John",
              last_name: "Do",
              location_id: "d99bc832-0492-11ed-81fc-45425753504d",
              phone: "(224)-123-1234",
              status: "PENDING",
              type: "BILLING",
              created_at: "2022-07-15 17:07:08",
              updated_at: "2024-04-10 09:01:21",
            },
          },
          {
            id: "38c6a994-aa6e-11ee-bbe4-4d3342563143",
            auth_location: "DB",
            contact_id: "38c6bcd6-aa6e-11ee-a446-435a4a4c3532",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "INACTIVE",
            username: "Delete me",
            created_at: "2024-01-03 12:28:07",
            updated_at: "2024-01-03 12:28:54",
            force_password: false,
            can_review: true,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            default_url_management_portal: "",
            default_url_subscriber_portal: "",
            answered_security_questions: false,
            Contact: {
              id: "38c6bcd6-aa6e-11ee-a446-435a4a4c3532",
              email: "edess@testing.test",
              first_name: null,
              last_name: null,
              location_id: null,
              phone: null,
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2024-01-03 12:28:07",
              updated_at: "2024-01-03 12:28:07",
            },
          },
        ],
      },
    ],
  },
  {
    id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
    ansible_update_flag: null,
    description: "VegaOS / Monarch - VGL",
    hardware_version: "FWS-2273",
    installed_at: "2024-03-06 23:49:00",
    ip_address: "10.128.0.10",
    ip_address_num: null,
    ipv6_address: null,
    ipv6_address_num: null,
    json: null,
    mac_address: "00:07:ba:31:03:80",
    notes: "VGL VBG used for testing for Ammon",
    physicallocation_id: "beefbf3c-af33-11ee-a459-4c5a54464154",
    openflow_id: "manual:33188545408",
    password: "admin",
    product_id: "a1d8d920-055d-11ea-bd75-535a4f504839",
    reset_delay: "5",
    rack_id: null,
    rack_unit_end: "0",
    rack_unit_start: "0",
    sdn_server_id: "76ca8990-cd30-11ea-bd34-43415959444d",
    sdn_server_local: false,
    serial_number: "VL1942000111",
    snmp_id: null,
    snmp_server_id: null,
    software_version: "2.1.5.2",
    status: "ACTIVE",
    switch_port_id: null,
    type: "VBG",
    uid: "Lab10-1010-VGL",
    username: "admin",
    created_at: "2024-01-09 14:11:56",
    updated_at: "2024-04-03 10:25:41",
    Product: {
      id: "a1d8d920-055d-11ea-bd75-535a4f504839",
      acronym: "aaeon",
      description: "Subscriber Premise Indoor VBG - Rev 2",
      fa_supported: null,
      hardware: "FWS-2273",
      has_api: true,
      manufacturer_id: "bd876c6e-db90-11ea-bae1-365141315955",
      make: "Aaeon",
      model: "2273",
      part_number: "FWS-2273",
      ssl_enabled: false,
      status: "ACTIVE",
      type: "VBG",
      created_at: "2022-01-20 11:47:09",
      updated_at: "2023-03-21 15:00:56",
    },
    Physicallocation: {
      id: "beefbf3c-af33-11ee-a459-4c5a54464154",
      apartment_number: "",
      city: "Ammon",
      country: "US",
      elevation: "0",
      house_number: "",
      lat: "43.469637",
      lon: "-111.966636",
      post_directional: "",
      pre_directional: "",
      state: "CA",
      status: "ACTIVE",
      street: "",
      uri: "https://www.google.com/maps/place/43.4696366,-111.9666355",
      zip: "83406",
      created_at: "2024-01-09 14:12:07",
      updated_at: "2024-04-03 15:21:02",
    },
    Portinterfaces: [
      {
        id: "b84a624a-af33-11ee-999a-355a54325357",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Management1",
        mac_address_text: "00:07:ba:31:03:80",
        name: "Management1",
        number: "14",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "OPEN",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b849d320-af33-11ee-a4a0-3649574f4c52",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest7",
        mac_address_text: "00:07:ba:31:03:88",
        name: "Guest7",
        number: "12",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b848f324-af33-11ee-85be-425955513159",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest4",
        mac_address_text: "00:07:ba:31:03:85",
        name: "Guest4",
        number: "9",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b847028a-af33-11ee-abeb-475949573445",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: true,
        display_name: "LAN 2",
        mac_address_text: null,
        name: "GigabitEth2",
        number: "3",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-01-09 14:12:07",
      },
      {
        id: "b844152a-af33-11ee-af25-483941344743",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: true,
        display_name: "LAN 1",
        mac_address_text: null,
        name: "GigabitEth1",
        number: "2",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-01-09 14:12:07",
      },
      {
        id: "b843b5ee-af33-11ee-a4d8-4b4453434b4a",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: true,
        display_name: "WAN",
        mac_address_text: null,
        name: "WAN",
        number: "1",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "OPEN",
        status: "ACTIVE",
        type: "WAN",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-01-09 14:12:07",
      },
      {
        id: "b84aad90-af33-11ee-a989-4c56304b3857",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Management2",
        mac_address_text: "00:07:ba:31:03:81",
        name: "Management2",
        number: "15",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b84a1ba0-af33-11ee-9c58-4d41394c325a",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest8",
        mac_address_text: "00:07:ba:31:03:89",
        name: "Guest8",
        number: "13",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b848a4be-af33-11ee-a24b-4d5954503557",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest3",
        mac_address_text: "00:07:ba:31:03:84",
        name: "Guest3",
        number: "8",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b847b87e-af33-11ee-b618-4e4948514741",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: true,
        display_name: "LAN 4",
        mac_address_text: null,
        name: "GigabitEth4",
        number: "5",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-02-02 09:09:03",
      },
      {
        id: "b84805ae-af33-11ee-88f4-4f3834353842",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest1",
        mac_address_text: "00:07:ba:31:03:82",
        name: "Guest1",
        number: "6",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b8494068-af33-11ee-ba02-4f5137303938",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest5",
        mac_address_text: "00:07:ba:31:03:86",
        name: "Guest5",
        number: "10",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b8484ee2-af33-11ee-a436-5335444d4334",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest2",
        mac_address_text: "00:07:ba:31:03:83",
        name: "Guest2",
        number: "7",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
      {
        id: "b8476716-af33-11ee-ad4b-54304b534653",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: true,
        display_name: "LAN 3",
        mac_address_text: null,
        name: "GigabitEth3",
        number: "4",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-01-09 14:12:07",
      },
      {
        id: "b8498960-af33-11ee-b55b-544f4f593256",
        description: null,
        device_id: "b83bcbe0-af33-11ee-a7e2-415855544e33",
        display: false,
        display_name: "Guest6",
        mac_address_text: "00:07:ba:31:03:87",
        name: "Guest6",
        number: "11",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2024-01-09 14:11:56",
        updated_at: "2024-03-15 09:35:16",
      },
    ],
    Accounts: [],
  },
  {
    id: "17453464-7b10-11ec-b8b0-433849325950",
    ansible_update_flag: null,
    description: "EntryPoint Monarch - Aaeon",
    hardware_version: "FWS-2273",
    installed_at: "2023-12-18 22:41:00",
    ip_address: "10.128.0.2",
    ip_address_num: null,
    ipv6_address: null,
    ipv6_address_num: null,
    json: null,
    mac_address: "00:07:32:81:71:93",
    notes: "",
    physicallocation_id: "1ef63e42-7b10-11ec-94dd-424438464b36",
    openflow_id: "manual:30912115091",
    password: "test1234",
    product_id: "a1d8d920-055d-11ea-bd75-535a4f504839",
    reset_delay: "5",
    rack_id: null,
    rack_unit_end: "0",
    rack_unit_start: "0",
    sdn_server_id: "76ca8990-cd30-11ea-bd34-43415959444d",
    sdn_server_local: false,
    serial_number: "P20C07708",
    snmp_id: null,
    snmp_server_id: null,
    software_version: "2.1.7.6",
    status: "ACTIVE",
    switch_port_id: null,
    type: "VBG",
    uid: "Lab2-1002",
    username: "admin",
    created_at: "2022-01-21 16:15:58",
    updated_at: "2024-04-03 14:52:31",
    Product: {
      id: "a1d8d920-055d-11ea-bd75-535a4f504839",
      acronym: "aaeon",
      description: "Subscriber Premise Indoor VBG - Rev 2",
      fa_supported: null,
      hardware: "FWS-2273",
      has_api: true,
      manufacturer_id: "bd876c6e-db90-11ea-bae1-365141315955",
      make: "Aaeon",
      model: "2273",
      part_number: "FWS-2273",
      ssl_enabled: false,
      status: "ACTIVE",
      type: "VBG",
      created_at: "2022-01-20 11:47:09",
      updated_at: "2023-03-21 15:00:56",
    },
    Physicallocation: {
      id: "1ef63e42-7b10-11ec-94dd-424438464b36",
      apartment_number: "Suite 2",
      city: "Ammon",
      country: "US",
      elevation: "10050",
      house_number: "Lab2",
      lat: "43.482571",
      lon: "-112.033599",
      post_directional: "54",
      pre_directional: "E",
      state: "CA",
      status: "ACTIVE",
      street: "17th Street",
      uri: "https://www.google.com/maps/place/43.482571,-112.0335991",
      zip: "83406",
      created_at: "2022-01-21 16:16:11",
      updated_at: "2024-03-28 10:41:58",
    },
    Portinterfaces: [
      {
        id: "17bd5bd8-7b10-11ec-b51d-334f58355235",
        description: null,
        device_id: "17453464-7b10-11ec-b8b0-433849325950",
        display: false,
        display_name: "Guest10",
        mac_address_text: "e2:ab:91:ab:bc:0c",
        name: "Guest10",
        number: "16",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2022-01-21 16:15:58",
        updated_at: "2024-02-17 23:26:06",
      },
      {
        id: "17bdfdb8-7b10-11ec-bb36-3352474f3835",
        description: null,
        device_id: "17453464-7b10-11ec-b8b0-433849325950",
        display: false,
        display_name: "Guest3",
        mac_address_text: "f2:2e:87:32:69:3d",
        name: "Guest3",
        number: "9",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2022-01-21 16:15:58",
        updated_at: "2023-12-27 14:59:40",
      },
      {
        id: "17be9e26-7b10-11ec-a10f-363155394230",
        description: null,
        device_id: "17453464-7b10-11ec-b8b0-433849325950",
        display: false,
        display_name: "Guest5",
        mac_address_text: "7e:84:10:3a:28:50",
        name: "Guest5",
        number: "11",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2022-01-21 16:15:58",
        updated_at: "2023-12-27 14:59:40",
      },
      {
        id: "17be4aac-7b10-11ec-a5c7-3633314a394f",
        description: null,
        device_id: "17453464-7b10-11ec-b8b0-433849325950",
        display: false,
        display_name: "Guest4",
        mac_address_text: "96:53:db:e1:70:54",
        name: "Guest4",
        number: "10",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2022-01-21 16:15:58",
        updated_at: "2023-12-27 14:59:40",
      },
      {
        id: "17c04d20-7b10-11ec-bfee-365034524943",
        description: null,
        device_id: "17453464-7b10-11ec-b8b0-433849325950",
        display: false,
        display_name: "Management1",
        mac_address_text: "00:07:32:81:71:93",
        name: "Management1",
        number: "17",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "OPEN",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2022-01-21 16:15:58",
        updated_at: "2023-12-27 14:59:40",
      },
      {
        id: "17696a5a-7b10-11ec-8cf5-383043595842",
        description: null,
        device_id: "17453464-7b10-11ec-b8b0-433849325950",
        display: true,
        display_name: "LAN 1",
        mac_address_text: null,
        name: "GigabitEth1",
        number: "2",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2022-01-21 16:15:58",
        updated_at: "2024-02-15 23:19:14",
      },
      {
        id: "17beedf4-7b10-11ec-ad73-4250444f5a37",
        description: null,
        device_id: "17453464-7b10-11ec-b8b0-433849325950",
        display: false,
        display_name: "Guest6",
        mac_address_text: "32:bf:a5:0e:dd:78",
        name: "Guest6",
        number: "12",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2022-01-21 16:15:58",
        updated_at: "2023-12-27 14:59:40",
      },
      {
        id: "17bc6642-7b10-11ec-b0f1-4752574c5949",
        description: null,
        device_id: "17453464-7b10-11ec-b8b0-433849325950",
        display: true,
        display_name: "LAN 4",
        mac_address_text: null,
        name: "GigabitEth4",
        number: "5",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2022-01-21 16:15:58",
        updated_at: "2023-12-11 12:42:30",
      },
      {
        id: "17bd0b56-7b10-11ec-83c7-484442485550",
        description: null,
        device_id: "17453464-7b10-11ec-b8b0-433849325950",
        display: false,
        display_name: "Guest12",
        mac_address_text: "b2:61:77:33:3a:5f",
        name: "Guest1",
        number: "7",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2022-01-21 16:15:58",
        updated_at: "2024-01-23 13:39:25",
      },
      {
        id: "17bf4cb8-7b10-11ec-adfb-495738324d35",
        description: null,
        device_id: "17453464-7b10-11ec-b8b0-433849325950",
        display: false,
        display_name: "Guest7",
        mac_address_text: "fe:fb:75:18:93:52",
        name: "Guest7",
        number: "13",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2022-01-21 16:15:58",
        updated_at: "2023-12-27 14:59:40",
      },
      {
        id: "17bffe38-7b10-11ec-9f5c-4b30564b4d39",
        description: null,
        device_id: "17453464-7b10-11ec-b8b0-433849325950",
        display: false,
        display_name: "Guest9",
        mac_address_text: "b2:63:b8:27:c0:aa",
        name: "Guest9",
        number: "15",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2022-01-21 16:15:58",
        updated_at: "2023-12-27 14:59:40",
      },
      {
        id: "17576a76-7b10-11ec-80d7-4f4638445850",
        description: null,
        device_id: "17453464-7b10-11ec-b8b0-433849325950",
        display: true,
        display_name: "WAN",
        mac_address_text: null,
        name: "WAN",
        number: "1",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "OPEN",
        status: "ACTIVE",
        type: "WAN",
        created_at: "2022-01-21 16:15:58",
        updated_at: "2022-01-21 16:16:11",
      },
      {
        id: "1769ca9a-7b10-11ec-ae93-4f584f594c30",
        description: null,
        device_id: "17453464-7b10-11ec-b8b0-433849325950",
        display: true,
        display_name: "LAN 2",
        mac_address_text: null,
        name: "GigabitEth2",
        number: "3",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2022-01-21 16:15:58",
        updated_at: "2024-03-12 14:40:19",
      },
      {
        id: "17bdae08-7b10-11ec-a36d-514444304e58",
        description: null,
        device_id: "17453464-7b10-11ec-b8b0-433849325950",
        display: false,
        display_name: "Guest2",
        mac_address_text: "b6:18:dd:5f:55:ee",
        name: "Guest2",
        number: "8",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2022-01-21 16:15:58",
        updated_at: "2023-12-27 14:59:40",
      },
      {
        id: "17bfad16-7b10-11ec-c03c-524d38385054",
        description: null,
        device_id: "17453464-7b10-11ec-b8b0-433849325950",
        display: false,
        display_name: "Guest8",
        mac_address_text: "de:bc:75:b2:c3:52",
        name: "Guest8",
        number: "14",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2022-01-21 16:15:58",
        updated_at: "2023-12-27 14:59:40",
      },
      {
        id: "17c0a0a4-7b10-11ec-be34-54454b413334",
        description: null,
        device_id: "17453464-7b10-11ec-b8b0-433849325950",
        display: false,
        display_name: "Management2",
        mac_address_text: "00:07:32:81:71:94",
        name: "Management2",
        number: "18",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2022-01-21 16:15:58",
        updated_at: "2023-12-27 14:59:40",
      },
      {
        id: "17bcb728-7b10-11ec-8287-573851385648",
        description: null,
        device_id: "17453464-7b10-11ec-b8b0-433849325950",
        display: true,
        display_name: "SFP 1",
        mac_address_text: null,
        name: "GigabitEth5",
        number: "6",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2022-01-21 16:15:58",
        updated_at: "2022-01-21 16:16:11",
      },
      {
        id: "17bc0efe-7b10-11ec-b869-585754444537",
        description: null,
        device_id: "17453464-7b10-11ec-b8b0-433849325950",
        display: true,
        display_name: "LAN 3",
        mac_address_text: null,
        name: "GigabitEth3",
        number: "4",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2022-01-21 16:15:58",
        updated_at: "2022-01-21 16:16:11",
      },
    ],
    Accounts: [
      {
        id: "7006ddc2-6881-11ee-b03c-453241494835",
        account_number: "Rosario_Peter_2501_Vineyard_Drive",
        name: "Rosario, Peter",
        notes: "",
        status: "ACTIVE",
        type: "RESIDENTIAL",
        created_at: "2023-10-11 15:59:23",
        updated_at: "2023-11-03 12:18:34",
        Users: [
          {
            id: "6851885c-6881-11ee-a33e-434154464b58",
            auth_location: "LDAP",
            contact_id: "6851a04e-6881-11ee-8d21-3849324b5141",
            is_subscriber: true,
            roles: ["AUDIT", "SUBSCRIBER"],
            status: "ACTIVE",
            username: "regrohiffeuddo-5314@yopmail.com",
            created_at: "2023-10-11 15:59:10",
            updated_at: "2024-04-02 14:05:59",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            force_password: true,
            force_username: true,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            default_url_management_portal: "/dashboard#/account/list",
            answered_security_questions: true,
            Contact: {
              id: "6851a04e-6881-11ee-8d21-3849324b5141",
              email: "regrohiffeuddo-5314@yopmail.com",
              first_name: "Peter",
              last_name: "Rosario",
              location_id: "6851bb1a-6881-11ee-bb84-525a55513042",
              phone: "(440)-358-5990",
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2023-10-11 15:59:10",
              updated_at: "2023-11-01 10:37:10",
            },
          },
        ],
      },
      {
        id: "c20611be-e36c-11ed-9055-5953494e545a",
        account_number: "10_Sumo_10_Sumo_Way",
        name: "10, Sumo",
        notes: "",
        status: "ACTIVE",
        type: "RESIDENTIAL",
        created_at: "2023-04-25 07:26:16",
        updated_at: "2024-03-05 09:32:34",
        Users: [
          {
            id: "93b62b50-0f90-11ee-8ea5-394d4e334a51",
            auth_location: "LDAP",
            contact_id: "93b64126-0f90-11ee-9109-343045423248",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "adaptivetest@gmail.com",
            created_at: "2023-06-20 11:33:32",
            updated_at: "2024-04-09 14:37:25",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            force_password: true,
            force_username: false,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            force_created_at: "2024-04-01 12:21:25",
            default_url_management_portal: "",
            answered_security_questions: true,
            Contact: {
              id: "93b64126-0f90-11ee-9109-343045423248",
              email: "adaptivetest@gmail.com",
              first_name: "Test",
              last_name: "Test",
              location_id: "93b65c10-0f90-11ee-8e4a-495450543557",
              phone: "(121)-212-1212",
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2023-06-20 11:33:32",
              updated_at: "2023-06-20 11:33:32",
            },
          },
          {
            id: "c0c59a18-e36c-11ed-ad60-36564c423141",
            auth_location: "LDAP",
            contact_id: "c0c5b016-e36c-11ed-8be2-575237424632",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "sumo10@gmail.com",
            created_at: "2023-04-25 07:26:14",
            updated_at: "2024-04-02 14:05:57",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            force_password: false,
            force_username: false,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            default_url_management_portal: "",
            answered_security_questions: true,
            Contact: {
              id: "c0c5b016-e36c-11ed-8be2-575237424632",
              email: "sumo10@gmail.com",
              first_name: "Sumo",
              last_name: "10",
              location_id: "c0c5cc4a-e36c-11ed-bf00-51323453564f",
              phone: "1010101010",
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2023-04-25 07:26:14",
              updated_at: "2024-01-26 14:20:28",
            },
          },
          {
            id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
            auth_location: "LDAP",
            contact_id: "d99b87b4-0492-11ed-ae58-544259474838",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "test",
            created_at: "2022-07-15 17:07:08",
            updated_at: "2024-04-10 09:01:21",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            messaging: {
              OPERATOR_MESSAGE: ["PORTAL", "EMAIL"],
              ACCOUNT: [],
              USER: [],
              SUBSCRIPTION: [],
              FIRMWARE: [],
            },
            force_username: false,
            default_url_management_portal: "",
            answered_security_questions: true,
            Contact: {
              id: "d99b87b4-0492-11ed-ae58-544259474838",
              email: "deletingadmin0007one@yopmail.com",
              first_name: "John",
              last_name: "Do",
              location_id: "d99bc832-0492-11ed-81fc-45425753504d",
              phone: "(224)-123-1234",
              status: "PENDING",
              type: "BILLING",
              created_at: "2022-07-15 17:07:08",
              updated_at: "2024-04-10 09:01:21",
            },
          },
          {
            id: "80cc90a0-c463-11ee-9da9-483831413547",
            auth_location: "DB",
            contact_id: "80ccadd8-c463-11ee-bef7-4d4e4c504c43",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "INACTIVE",
            username: "DemoMonday2",
            created_at: "2024-02-05 13:16:53",
            updated_at: "2024-02-19 15:01:54",
            can_review: false,
            default_url_management_portal: "",
            default_url_subscriber_portal: "",
            messaging: "",
            answered_security_questions: false,
            Contact: {
              id: "80ccadd8-c463-11ee-bef7-4d4e4c504c43",
              email: "mario.alvarado@gopello.com",
              first_name: "DemoUser",
              last_name: "CoolLastname",
              location_id: "80ccc98a-c463-11ee-8402-4d4f44344149",
              phone: "1717171716",
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2024-02-05 13:16:53",
              updated_at: "2024-02-05 13:17:08",
            },
          },
          {
            id: "b220b79a-15df-11ee-b8de-464f424c4e34",
            auth_location: "LDAP",
            contact_id: "b220cef6-15df-11ee-a146-593743554b45",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "INACTIVE",
            username: "Accountflow@gmail.com",
            created_at: "2023-06-28 12:15:00",
            updated_at: "2024-04-02 14:06:00",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            force_password: true,
            force_username: false,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            force_created_at: "2023-11-22 12:13:15",
            default_url_management_portal: "/dashboard#/provider/list",
            answered_security_questions: true,
            Contact: {
              id: "b220cef6-15df-11ee-a146-593743554b45",
              email: "Accountflow@gmail.com",
              first_name: "Flow",
              last_name: "Test",
              location_id: "b220e904-15df-11ee-94d6-564a53454731",
              phone: "(518)-101-0101",
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2023-06-28 12:15:00",
              updated_at: "2023-06-28 12:15:00",
            },
          },
          {
            id: "ccd4fa4a-bc94-11ee-ba75-393832555544",
            auth_location: "LDAP",
            contact_id: "ccd51660-bc94-11ee-863d-393358524944",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "00969@testing.tests",
            created_at: "2024-01-26 14:49:37",
            updated_at: "2024-04-09 14:18:47",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            force_password: true,
            force_username: false,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            force_created_at: "2024-04-03 10:50:14",
            default_url_management_portal: "",
            answered_security_questions: true,
            Contact: {
              id: "ccd51660-bc94-11ee-863d-393358524944",
              email: "00969@testing.test",
              first_name: "Testing",
              last_name: "Qa",
              location_id: "ccd53334-bc94-11ee-9833-4c5437373632",
              phone: "(555)-000-0009",
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2024-01-26 14:49:37",
              updated_at: "2024-04-03 17:09:30",
            },
          },
          {
            id: "e403fefc-896b-11ee-a8da-4c3048334643",
            auth_location: "LDAP",
            contact_id: "e4041522-896b-11ee-80ac-4d4c43374b43",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "removingtest",
            created_at: "2023-11-22 12:18:17",
            updated_at: "2024-03-19 10:45:30",
            force_password: false,
            can_review: true,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            default_url_management_portal: "",
            default_url_subscriber_portal: "",
            answered_security_questions: false,
            Contact: {
              id: "e4041522-896b-11ee-80ac-4d4c43374b43",
              email: "remove@testing.test",
              first_name: null,
              last_name: null,
              location_id: null,
              phone: null,
              status: "ACTIVE",
              type: "SALES",
              created_at: "2023-11-22 12:18:17",
              updated_at: "2023-11-22 12:18:17",
            },
          },
          {
            id: "ff46af72-e398-11ed-81fd-354c5a34445a",
            auth_location: "LDAP",
            contact_id: "ff46ce62-e398-11ed-8929-534453484939",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "INACTIVE",
            username: "123@gmail.com",
            created_at: "2023-04-25 12:35:48",
            updated_at: "2024-04-02 14:05:57",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            force_password: false,
            force_username: false,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            default_url_management_portal: "",
            answered_security_questions: true,
            Contact: {
              id: "ff46ce62-e398-11ed-8929-534453484939",
              email: "123@gmail.com",
              first_name: "1",
              last_name: "2",
              location_id: "ff46ea14-e398-11ed-ae34-53503544394c",
              phone: "1231231232",
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2023-04-25 12:35:48",
              updated_at: "2023-04-25 12:35:48",
            },
          },
        ],
      },
    ],
  },
  {
    id: "9b810a50-52b9-11ee-ad1b-443547335252",
    ansible_update_flag: null,
    description: "EntryPoint Monarch - Aaeon",
    hardware_version: "VBG-M8105",
    installed_at: "2024-03-19 16:42:00",
    ip_address: "10.128.0.3",
    ip_address_num: null,
    ipv6_address: null,
    ipv6_address_num: null,
    json: null,
    mac_address: "00:07:32:6f:35:55",
    notes: "",
    physicallocation_id: "9ee75c12-52b9-11ee-9eb8-4d483559484d",
    openflow_id: "manual:30910920021",
    password: "admin",
    product_id: "b7618a68-49e9-11e7-9d72-413956514c47",
    reset_delay: "5",
    rack_id: null,
    rack_unit_end: "0",
    rack_unit_start: "0",
    sdn_server_id: "76ca8990-cd30-11ea-bd34-43415959444d",
    sdn_server_local: false,
    serial_number: "LAB3-1003-AUTOMATED",
    snmp_id: null,
    snmp_server_id: null,
    software_version: "2.1.7.6",
    status: "PENDING",
    switch_port_id: "c0a1b016-ec81-11ee-a287-39394c453030",
    type: "VBG",
    uid: "Lab3-1003-Automated",
    username: "admin",
    created_at: "2023-09-13 22:46:02",
    updated_at: "2024-04-05 13:17:53",
    Product: {
      id: "b7618a68-49e9-11e7-9d72-413956514c47",
      acronym: "vbgm8105",
      description: "Subscriber Premise Outdoor VBG",
      fa_supported: null,
      hardware: "VBG-M8105",
      has_api: true,
      manufacturer_id: "b76195bc-49e9-11e7-bc6c-583242593945",
      make: "VGL",
      model: "M8105",
      part_number: "VBG-M8105",
      ssl_enabled: null,
      status: "ACTIVE",
      type: "VBG",
      created_at: "2022-01-20 11:47:09",
      updated_at: "2023-09-12 16:59:42",
    },
    Physicallocation: {
      id: "9ee75c12-52b9-11ee-9eb8-4d483559484d",
      apartment_number: "null",
      city: "Ammon",
      country: "US",
      elevation: "0",
      house_number: "null",
      lat: "43.469637",
      lon: "-111.966636",
      post_directional: "null",
      pre_directional: "null",
      state: "ID",
      status: "ACTIVE",
      street: "null",
      uri: "https://www.google.com/maps/place/43.4696366,-111.9666355",
      zip: "83402",
      created_at: "2023-09-13 22:46:08",
      updated_at: "2024-04-05 13:17:38",
    },
    Portinterfaces: [
      {
        id: "9b964e4c-52b9-11ee-b5e3-34374f343557",
        description: null,
        device_id: "9b810a50-52b9-11ee-ad1b-443547335252",
        display: false,
        display_name: "Management2",
        mac_address_text: "00:07:32:6f:35:56",
        name: "Management2",
        number: "18",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:46:02",
        updated_at: "2024-04-05 13:18:26",
      },
      {
        id: "9b9604c8-52b9-11ee-a9a8-3538524a5036",
        description: null,
        device_id: "9b810a50-52b9-11ee-ad1b-443547335252",
        display: false,
        display_name: "Management1",
        mac_address_text: "00:07:32:6f:35:55",
        name: "Management1",
        number: "17",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "OPEN",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:46:02",
        updated_at: "2024-04-05 13:18:26",
      },
      {
        id: "9b8e80cc-52b9-11ee-9374-363634454735",
        description: null,
        device_id: "9b810a50-52b9-11ee-ad1b-443547335252",
        display: true,
        display_name: "N 1",
        mac_address_text: null,
        name: "GigabitEth1",
        number: "2",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "CLOSED",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2023-09-13 22:46:02",
        updated_at: "2024-04-04 10:49:55",
      },
      {
        id: "9b900e24-52b9-11ee-a5b0-425733545a59",
        description: null,
        device_id: "9b810a50-52b9-11ee-ad1b-443547335252",
        display: false,
        display_name: "Guest1",
        mac_address_text: "3e:2c:e6:48:5d:b4",
        name: "Guest1",
        number: "7",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:46:02",
        updated_at: "2024-04-05 13:18:26",
      },
      {
        id: "9b95be50-52b9-11ee-a042-44344347464b",
        description: null,
        device_id: "9b810a50-52b9-11ee-ad1b-443547335252",
        display: false,
        display_name: "Guest9",
        mac_address_text: "fa:e4:8b:5c:4d:d1",
        name: "Guest9",
        number: "15",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:46:02",
        updated_at: "2024-04-05 13:18:26",
      },
      {
        id: "9b8fb186-52b9-11ee-a012-453345363045",
        description: null,
        device_id: "9b810a50-52b9-11ee-ad1b-443547335252",
        display: true,
        display_name: "P 1",
        mac_address_text: null,
        name: "GigabitEth5",
        number: "6",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "CLOSED",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2023-09-13 22:46:02",
        updated_at: "2024-04-04 10:50:51",
      },
      {
        id: "9b95265c-52b9-11ee-b88c-475a51343832",
        description: null,
        device_id: "9b810a50-52b9-11ee-ad1b-443547335252",
        display: false,
        display_name: "Guest7",
        mac_address_text: "32:b9:4e:5d:03:d3",
        name: "Guest7",
        number: "13",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:46:02",
        updated_at: "2024-04-05 13:18:26",
      },
      {
        id: "9b8f1ca8-52b9-11ee-9161-49515231554f",
        description: null,
        device_id: "9b810a50-52b9-11ee-ad1b-443547335252",
        display: true,
        display_name: "LAN 3",
        mac_address_text: null,
        name: "GigabitEth3",
        number: "4",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "CLOSED",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2023-09-13 22:46:02",
        updated_at: "2024-03-18 13:26:02",
      },
      {
        id: "9b8e2ee2-52b9-11ee-b641-4f364d385143",
        description: null,
        device_id: "9b810a50-52b9-11ee-ad1b-443547335252",
        display: true,
        display_name: "WAN",
        mac_address_text: null,
        name: "WAN",
        number: "1",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "OPEN",
        status: "ACTIVE",
        type: "WAN",
        created_at: "2023-09-13 22:46:02",
        updated_at: "2023-09-13 22:46:08",
      },
      {
        id: "9b91be90-52b9-11ee-8d34-50493239444f",
        description: null,
        device_id: "9b810a50-52b9-11ee-ad1b-443547335252",
        display: false,
        display_name: "Guest6",
        mac_address_text: "36:85:f4:cc:42:98",
        name: "Guest6",
        number: "12",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:46:02",
        updated_at: "2024-04-05 13:18:26",
      },
      {
        id: "9b9056cc-52b9-11ee-a988-524f4a48594f",
        description: null,
        device_id: "9b810a50-52b9-11ee-ad1b-443547335252",
        display: false,
        display_name: "Guest10",
        mac_address_text: "be:9c:36:c9:7d:d5",
        name: "Guest10",
        number: "16",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:46:02",
        updated_at: "2024-04-05 13:18:26",
      },
      {
        id: "9b909d08-52b9-11ee-96eb-5337525a4d41",
        description: null,
        device_id: "9b810a50-52b9-11ee-ad1b-443547335252",
        display: false,
        display_name: "Guest2",
        mac_address_text: "4a:c4:0d:37:1c:51",
        name: "Guest2",
        number: "8",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:46:02",
        updated_at: "2024-04-05 13:18:26",
      },
      {
        id: "9b95762a-52b9-11ee-b2dc-54324f415346",
        description: null,
        device_id: "9b810a50-52b9-11ee-ad1b-443547335252",
        display: false,
        display_name: "Guest8",
        mac_address_text: "da:02:80:3a:83:48",
        name: "Guest8",
        number: "14",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:46:02",
        updated_at: "2024-04-05 13:18:26",
      },
      {
        id: "9b90e0e2-52b9-11ee-b785-544446415745",
        description: null,
        device_id: "9b810a50-52b9-11ee-ad1b-443547335252",
        display: false,
        display_name: "Guest3",
        mac_address_text: "12:30:86:9e:3e:f3",
        name: "Guest3",
        number: "9",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:46:02",
        updated_at: "2024-04-05 13:18:26",
      },
      {
        id: "9b8f6cf8-52b9-11ee-b8bd-555533323636",
        description: null,
        device_id: "9b810a50-52b9-11ee-ad1b-443547335252",
        display: true,
        display_name: "LAN 4",
        mac_address_text: null,
        name: "GigabitEth4",
        number: "5",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "CLOSED",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2023-09-13 22:46:02",
        updated_at: "2024-03-18 13:26:02",
      },
      {
        id: "9b91276e-52b9-11ee-81a1-56424d4a4132",
        description: null,
        device_id: "9b810a50-52b9-11ee-ad1b-443547335252",
        display: false,
        display_name: "Guest4",
        mac_address_text: "9e:95:b3:c7:e7:93",
        name: "Guest4",
        number: "10",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:46:02",
        updated_at: "2024-04-05 13:18:26",
      },
      {
        id: "9b8ec7e4-52b9-11ee-a72d-5858344a4356",
        description: null,
        device_id: "9b810a50-52b9-11ee-ad1b-443547335252",
        display: true,
        display_name: "LAN 2",
        mac_address_text: null,
        name: "GigabitEth2",
        number: "3",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "CLOSED",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2023-09-13 22:46:02",
        updated_at: "2024-03-18 13:26:02",
      },
      {
        id: "9b916e22-52b9-11ee-9e22-59334b334538",
        description: null,
        device_id: "9b810a50-52b9-11ee-ad1b-443547335252",
        display: false,
        display_name: "Guest5",
        mac_address_text: "b2:3e:d0:aa:c3:ae",
        name: "Guest5",
        number: "11",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:46:02",
        updated_at: "2024-04-05 13:18:26",
      },
    ],
    Accounts: [],
  },
  {
    id: "7e81cce6-52b9-11ee-a603-4758324b3544",
    ansible_update_flag: null,
    description: "EntryPoint Monarch - Aaeon",
    hardware_version: "FWS-2273",
    installed_at: "2024-04-02 00:00:00",
    ip_address: "10.128.0.5",
    ip_address_num: null,
    ipv6_address: null,
    ipv6_address_num: null,
    json: null,
    mac_address: "00:07:32:81:72:98",
    notes: "This is a really cool note",
    physicallocation_id: "81e9c618-52b9-11ee-b2df-4b4e53354d41",
    openflow_id: "manual:30912115352",
    password: "admin",
    product_id: "a1d8d920-055d-11ea-bd75-535a4f504839",
    reset_delay: "3",
    rack_id: null,
    rack_unit_end: "0",
    rack_unit_start: "0",
    sdn_server_id: "76ca8990-cd30-11ea-bd34-43415959444d",
    sdn_server_local: false,
    serial_number: "P20C07756",
    snmp_id: null,
    snmp_server_id: null,
    software_version: "2.1.7.5",
    status: "ACTIVE",
    switch_port_id: null,
    type: "VBG",
    uid: "Lab5-1005-Marketing",
    username: "admin",
    created_at: "2023-09-13 22:45:13",
    updated_at: "2024-04-03 14:01:36",
    Product: {
      id: "a1d8d920-055d-11ea-bd75-535a4f504839",
      acronym: "aaeon",
      description: "Subscriber Premise Indoor VBG - Rev 2",
      fa_supported: null,
      hardware: "FWS-2273",
      has_api: true,
      manufacturer_id: "bd876c6e-db90-11ea-bae1-365141315955",
      make: "Aaeon",
      model: "2273",
      part_number: "FWS-2273",
      ssl_enabled: false,
      status: "ACTIVE",
      type: "VBG",
      created_at: "2022-01-20 11:47:09",
      updated_at: "2023-03-21 15:00:56",
    },
    Physicallocation: {
      id: "81e9c618-52b9-11ee-b2df-4b4e53354d41",
      apartment_number: "Ste #3",
      city: "Ammon",
      country: "US",
      elevation: "0",
      house_number: "Lab5",
      lat: "0.000000",
      lon: "0.000000",
      post_directional: "#",
      pre_directional: "E",
      state: "ID",
      status: "ACTIVE",
      street: "17th St",
      uri: "",
      zip: "83406",
      created_at: "2023-09-13 22:45:19",
      updated_at: "2023-09-14 13:00:53",
    },
    Portinterfaces: [
      {
        id: "7e86dd4e-52b9-11ee-9d3a-324b564f5a4f",
        description: null,
        device_id: "7e81cce6-52b9-11ee-a603-4758324b3544",
        display: true,
        display_name: "WAN",
        mac_address_text: null,
        name: "WAN",
        number: "1",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "OPEN",
        status: "ACTIVE",
        type: "WAN",
        created_at: "2023-09-13 22:45:14",
        updated_at: "2023-09-13 22:45:19",
      },
      {
        id: "7e8739d8-52b9-11ee-87ee-325a3347454a",
        description: null,
        device_id: "7e81cce6-52b9-11ee-a603-4758324b3544",
        display: true,
        display_name: "LAN 1",
        mac_address_text: null,
        name: "GigabitEth1",
        number: "2",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2023-09-13 22:45:14",
        updated_at: "2023-09-13 22:45:19",
      },
      {
        id: "7e8d8ad6-52b9-11ee-b825-38313159334f",
        description: null,
        device_id: "7e81cce6-52b9-11ee-a603-4758324b3544",
        display: false,
        display_name: "Guest3",
        mac_address_text: "00:07:32:81:72:9c",
        name: "Guest3",
        number: "9",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:45:14",
        updated_at: "2023-11-27 08:39:06",
      },
      {
        id: "7e8eabc8-52b9-11ee-b435-415153475758",
        description: null,
        device_id: "7e81cce6-52b9-11ee-a603-4758324b3544",
        display: false,
        display_name: "Guest7",
        mac_address_text: "00:07:32:81:72:a0",
        name: "Guest7",
        number: "13",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:45:14",
        updated_at: "2023-11-27 08:39:06",
      },
      {
        id: "7e8fc986-52b9-11ee-b54e-443658515046",
        description: null,
        device_id: "7e81cce6-52b9-11ee-a603-4758324b3544",
        display: false,
        display_name: "Management2",
        mac_address_text: "00:07:32:81:72:99",
        name: "Management2",
        number: "18",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:45:14",
        updated_at: "2023-11-27 08:39:06",
      },
      {
        id: "7e886f56-52b9-11ee-8c98-4934374f505a",
        description: null,
        device_id: "7e81cce6-52b9-11ee-a603-4758324b3544",
        display: true,
        display_name: "SFP 1",
        mac_address_text: null,
        name: "GigabitEth5",
        number: "6",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2023-09-13 22:45:14",
        updated_at: "2023-09-13 22:45:19",
      },
      {
        id: "7e882488-52b9-11ee-8f4b-4d4f4131494a",
        description: null,
        device_id: "7e81cce6-52b9-11ee-a603-4758324b3544",
        display: true,
        display_name: "LAN 4",
        mac_address_text: null,
        name: "GigabitEth4",
        number: "5",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2023-09-13 22:45:14",
        updated_at: "2023-09-13 22:45:19",
      },
      {
        id: "7e8ef6aa-52b9-11ee-bbda-4e33504c4d43",
        description: null,
        device_id: "7e81cce6-52b9-11ee-a603-4758324b3544",
        display: false,
        display_name: "Guest8",
        mac_address_text: "00:07:32:81:72:a1",
        name: "Guest8",
        number: "14",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:45:14",
        updated_at: "2023-11-27 08:39:06",
      },
      {
        id: "7e8f3c5a-52b9-11ee-b5de-4e494a535547",
        description: null,
        device_id: "7e81cce6-52b9-11ee-a603-4758324b3544",
        display: false,
        display_name: "Guest9",
        mac_address_text: "00:07:32:81:72:a2",
        name: "Guest9",
        number: "15",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:45:14",
        updated_at: "2023-11-27 08:39:06",
      },
      {
        id: "7e878a1e-52b9-11ee-9ee4-4e5053364a31",
        description: null,
        device_id: "7e81cce6-52b9-11ee-a603-4758324b3544",
        display: true,
        display_name: "LAN 2",
        mac_address_text: null,
        name: "GigabitEth2",
        number: "3",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2023-09-13 22:45:14",
        updated_at: "2023-09-13 22:45:19",
      },
      {
        id: "7e87daaa-52b9-11ee-8a8f-4f5938484c30",
        description: null,
        device_id: "7e81cce6-52b9-11ee-a603-4758324b3544",
        display: true,
        display_name: "LAN 3",
        mac_address_text: null,
        name: "GigabitEth3",
        number: "4",
        network_protocol: null,
        patch_id: null,
        speed: "0",
        state: "OPEN",
        status: "ACTIVE",
        type: "LAN",
        created_at: "2023-09-13 22:45:14",
        updated_at: "2023-09-13 22:45:19",
      },
      {
        id: "7e88ce6a-52b9-11ee-b418-503237514836",
        description: null,
        device_id: "7e81cce6-52b9-11ee-a603-4758324b3544",
        display: false,
        display_name: "Guest1",
        mac_address_text: "00:07:32:81:72:9a",
        name: "Guest1",
        number: "7",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:45:14",
        updated_at: "2023-11-27 08:39:06",
      },
      {
        id: "7e8d3ebe-52b9-11ee-a5d0-54304b53574d",
        description: null,
        device_id: "7e81cce6-52b9-11ee-a603-4758324b3544",
        display: false,
        display_name: "Guest2",
        mac_address_text: "00:07:32:81:72:9b",
        name: "Guest2",
        number: "8",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:45:14",
        updated_at: "2023-11-27 08:39:06",
      },
      {
        id: "7e8e1cee-52b9-11ee-95ef-564836515333",
        description: null,
        device_id: "7e81cce6-52b9-11ee-a603-4758324b3544",
        display: false,
        display_name: "Guest5",
        mac_address_text: "00:07:32:81:72:9e",
        name: "Guest5",
        number: "11",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:45:14",
        updated_at: "2023-11-27 08:39:06",
      },
      {
        id: "7e8e64e2-52b9-11ee-9d92-58364a314844",
        description: null,
        device_id: "7e81cce6-52b9-11ee-a603-4758324b3544",
        display: false,
        display_name: "Guest6",
        mac_address_text: "00:07:32:81:72:9f",
        name: "Guest6",
        number: "12",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:45:14",
        updated_at: "2023-11-27 08:39:06",
      },
      {
        id: "7e8cef2c-52b9-11ee-be7a-5843534f5356",
        description: null,
        device_id: "7e81cce6-52b9-11ee-a603-4758324b3544",
        display: false,
        display_name: "Guest10",
        mac_address_text: "00:07:32:81:72:a3",
        name: "Guest10",
        number: "16",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:45:14",
        updated_at: "2023-11-27 08:39:06",
      },
      {
        id: "7e8dd5c2-52b9-11ee-9fd4-5a4d36484e49",
        description: null,
        device_id: "7e81cce6-52b9-11ee-a603-4758324b3544",
        display: false,
        display_name: "Guest4",
        mac_address_text: "00:07:32:81:72:9d",
        name: "Guest4",
        number: "10",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "CLOSED",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:45:14",
        updated_at: "2023-11-27 08:39:06",
      },
      {
        id: "7e8f7fe4-52b9-11ee-b385-5a565a595330",
        description: null,
        device_id: "7e81cce6-52b9-11ee-a603-4758324b3544",
        display: false,
        display_name: "Management1",
        mac_address_text: "00:07:32:81:72:98",
        name: "Management1",
        number: "17",
        network_protocol: null,
        patch_id: null,
        speed: "1000",
        state: "OPEN",
        status: "ACTIVE",
        type: "VIRT",
        created_at: "2023-09-13 22:45:14",
        updated_at: "2023-11-27 08:39:06",
      },
    ],
    Accounts: [
      {
        id: "29dfa2ba-45cd-11ee-af3f-314f44433452",
        account_number: "MARKETING-DEMO",
        name: "MARKETING-DEMO",
        notes: "Test account for Devin Cox",
        status: "ACTIVE",
        type: "RESIDENTIAL",
        created_at: "2023-08-28 12:03:16",
        updated_at: "2023-10-05 16:51:15",
        Users: [
          {
            id: "15856c3c-45cd-11ee-8b7e-4c50495a5338",
            auth_location: "LDAP",
            contact_id: "158589ce-45cd-11ee-9e4c-374957345a4d",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "dcox@entpnt.com",
            created_at: "2023-08-28 12:02:42",
            updated_at: "2024-04-02 14:06:01",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            force_password: false,
            force_username: false,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            default_url_management_portal: "",
            answered_security_questions: false,
            Contact: {
              id: "158589ce-45cd-11ee-9e4c-374957345a4d",
              email: "dcox@entpnt.com",
              first_name: "Devin",
              last_name: "Cox",
              location_id: "1585a580-45cd-11ee-aa69-59303635514b",
              phone: "801.123.1234",
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2023-08-28 12:02:42",
              updated_at: "2023-08-28 12:04:01",
            },
          },
        ],
      },
      {
        id: "c9f36c10-7de4-11ee-93c0-423142313243",
        account_number: "MARKETING-ISP",
        name: "MARKETING-ISP",
        notes: "Marketing ISP Account for Single service demo",
        status: "ACTIVE",
        type: "COMMERCIAL",
        created_at: "2023-11-07 20:13:28",
        updated_at: "2023-11-07 21:16:58",
        Users: [
          {
            id: "7cf48bf2-7ded-11ee-9167-4c424b384b5a",
            auth_location: "LDAP",
            contact_id: "7cf4a4d4-7ded-11ee-858d-393659465047",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "dcox-isp@entpnt.com",
            created_at: "2023-11-07 21:15:45",
            updated_at: "2023-11-07 21:16:58",
            force_password: false,
            can_review: true,
            messaging: {
              ACCOUNT: ["EMAIL", "PORTAL"],
              FIRMWARE: ["EMAIL", "PORTAL"],
              OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
              SUBSCRIPTION: ["EMAIL", "PORTAL"],
              USER: ["EMAIL", "PORTAL"],
            },
            default_url_management_portal: "",
            default_url_subscriber_portal: "",
            answered_security_questions: false,
            Contact: {
              id: "7cf4a4d4-7ded-11ee-858d-393659465047",
              email: "dcox-isp@entpnt.com",
              first_name: "Devin",
              last_name: "Cox",
              location_id: "8c128cd8-7ded-11ee-bffe-3745425a574b",
              phone: "801-555-1234",
              status: "ACTIVE",
              type: "BILLING",
              created_at: "2023-11-07 21:15:45",
              updated_at: "2023-11-07 21:16:10",
            },
          },
        ],
      },
    ],
  },
] as any as Device[];

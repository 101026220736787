import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/table/Table";
import { Dot } from "src/components/dot/Dot";
import ManageDetailsPlanActionCell from "./ManageDetailsPlanActionCell";
import ScrollArea from "src/components/scroll-area/ScrollArea";
import EpButton from "src/components/buttons/EpButton";
import { DialogClose } from "@radix-ui/react-dialog";
import { EpButtonStyles } from "src/components/buttons/constants";
import AddNewGlobalServicePlan from "../actions/AddPlanDialog";

const plan = {
  id: "550e8400-e29b-41d4-a716-4466",
  planName: "Basic 5 - 15mbs/15mbs",
  status: "ACTIVE",
  domain: "PUBLIC",
  type: "Residential",
  provider: "QWKnet",
  description: "15mbs/15mbs",
  expireTime: 0,
  linkSpeed: 0,
  down: 15,
  up: 15,
  price: 5.88,
  displayType: "PUBLIC",
  accessCode: "9OIXVEQD",
  accountTypes: ["Residential"],
  createdAt: new Date(),
  updatedAt: new Date(),
  name: "QWKnet",
  ipRange: "",
  marketingURL: "http://www.qwk.net/residential-fiber/ammon/",
  subscriptionRedirectURL:
    "http://ammon.qwk.net/Ammon.Subscription.API/Welcome.aspx",
  supportURL: "https://customers.qwk.net/dp",
  standByEnabled: "Yes",
  standByInitiallyOn: "Intially On",
};

export type GlobalServicePlan = typeof plan;

export default function ManageDetailsPlans() {
  const plansMockData = new Array(20).fill(
    plan,
    0,
    20,
  ) as Array<GlobalServicePlan>;

  return (
    <>
      <ScrollArea className="max-h-[calc(65vh-theme(space.52))] p-1 my-4">
        <Table dataCy="globalServicesPlanTable">
          <TableHeader
            dataCy="globalServicesPlanTableHeader"
            className="sticky top-0"
          >
            <TableRow dataCy="globalServicesPlanTableHeaderRow">
              <TableHead className="text-left">Name</TableHead>
              <TableHead>Provider</TableHead>
              <TableHead className="text-center w-[90px]">Action</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody dataCy="globalServicesPlanTableBody">
            {plansMockData.map((globalServicePlan) => (
              <TableRow
                key={globalServicePlan.id}
                className="font-medium"
                dataCy={`${globalServicePlan.id}Row`}
              >
                <TableCell
                  tooltipText="View Plan Details"
                  dataCy={`${globalServicePlan.id}globalServicePlanNameCell`}
                >
                  <div className="flex items-center gap-2">
                    {globalServicePlan.status === "ACTIVE" ? (
                      <Dot className="h-2 w-2" variant={"active"} />
                    ) : (
                      <Dot className="h-2 w-2" variant={"inactive"} />
                    )}
                    {globalServicePlan.name}
                  </div>
                </TableCell>
                <TableCell
                  tooltipText="View Plan Details"
                  dataCy={`${globalServicePlan.id}ProviderCell`}
                >
                  {globalServicePlan.provider}
                </TableCell>
                <TableCell
                  className="text-center"
                  dataCy={`${globalServicePlan.id}globalServicePlanActionsCell`}
                >
                  <ManageDetailsPlanActionCell
                    servicePlan={globalServicePlan}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ScrollArea>
      <div className="mt-4 pb-1 flex justify-between items-center">
        <AddNewGlobalServicePlan />

        <DialogClose asChild>
          <EpButton
            dataCy="modalCancel"
            styling={EpButtonStyles.CANCEL}
            aria-label="cancle"
          >
            Cancel
          </EpButton>
        </DialogClose>
      </div>
    </>
  );
}

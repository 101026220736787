import { useState } from "react";

import { Link } from "react-router-dom";
import { EpButtonStyles } from "src/components/buttons/constants";

import EpButton from "src/components/buttons/EpButton";
import WebIcon from "src/components/icons/WebIcon";
import ChatQuestion from "src/components/icons/ChatQuestion";
import { Device, PortInterface } from "src/interfaces";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "src/components/dialog/Dialog";
import UnsubscriberLanDetailsConfirmationDialog from "./UnsubscribeConfirmationDialog";

interface SubscriptionDetailsProps {
  currentDevice: Device;
  currentPort: PortInterface;
  setCurrentPort: (port: PortInterface | undefined) => void;
}

export default function SubscriberLanDetailsDialog({
  currentDevice,
  currentPort,
  setCurrentPort,
}: SubscriptionDetailsProps) {
  const currentSubscription = currentPort.Subscriptions[0];

  let imageURL = "https://management.dev.entpnt.dev/img/";

  const sp = currentSubscription?.Serviceplan;
  const sr = sp?.Service;
  const pr = sr?.Provider;
  const user = currentSubscription?.User;
  if (sp?.image) {
    imageURL += `plan/${sp.image}`;
  } else if (sr?.image) {
    imageURL += `service/${sr.image}`;
  } else if (pr?.image) {
    imageURL += `provider/${pr.image}`;
  } else {
    imageURL = "/QwkNetLogo.png";
  }
  const currentAccount = currentDevice?.Accounts?.filter(
    (account) => account.id === currentSubscription?.account_id,
  );
  let contacts = currentAccount?.map((account) => account.Users)[0];
  contacts = contacts?.filter((contact) => contact.Contact?.type !== "SALES");
  const prcontacts = pr?.Contacts;

  const [isDialogOpened, setIsDialogOpened] = useState(true);
  const [isConfirmationDialogOpened, setIsConfirmationDialogOpened] =
    useState(false);

  function closeDialog() {
    setIsDialogOpened(false);
    setCurrentPort(undefined);
  }

  return (
    <Dialog open={isDialogOpened} onOpenChange={closeDialog}>
      <DialogContent
        className="sm:max-w-4xl font-base"
        dataCy="subscriberLanDetailsDialogCnt"
      >
        <DialogHeader dataCy="subscriberLanDetailsDialogHeader" borderless>
          <DialogTitle dataCy="subscriberLanDetailsDialogTitle">
            {currentPort.display_name} Details
          </DialogTitle>
        </DialogHeader>
        <div className="flex max-h-[calc(100vh-theme(space.52))] py-4 overflow-y-scroll scrollbar-hide flex-wrap">
          <div className="w-1/2 pr-10">
            <div className="flex justify-center py-6">
              {imageURL !== "" && (
                <img src={imageURL} alt="Subscription image" />
              )}
            </div>

            <h3 className="text-lg font-base mb-4">Service Plan Details</h3>
            <table data-cy="servicePlanDetailsTable" className="w-full">
              <tbody
                data-cy="servicePlanDetailsTableBody"
                className="text-left"
              >
                <tr className="even:bg-white odd:bg-neutral-200 text-sm px-4 text-[#6D6D6D]">
                  <th className="p-2 px-4 font-semibold">Name</th>
                  <th
                    data-cy="servicePlanDetailsTableNameValue"
                    className="p-2 px-4 font-semibold"
                  >
                    {sp?.fields.Name}
                  </th>
                </tr>
                <tr className="even:bg-white odd:bg-neutral-200 text-sm text-[#6D6D6D]">
                  <th className="p-2 px-4 font-semibold">Link Speed</th>
                  <th
                    data-cy="servicePlanDetailsTableLinkSpeedValue"
                    className="p-2 px-4 font-semibold"
                  >
                    {sp?.fields.LinkSpeed}
                  </th>
                </tr>
                <tr className="even:bg-white odd:bg-neutral-200 text-sm text-[#6D6D6D]">
                  <th className="p-2 px-4 font-semibold">Down</th>
                  <th
                    data-cy="servicePlanDetailsTableDownValue"
                    className="p-2 px-4 font-semibold"
                  >
                    {sp?.fields.Down}
                  </th>
                </tr>
                <tr className="even:bg-white odd:bg-neutral-200 text-sm text-[#6D6D6D]">
                  <th className="p-2 px-4 font-semibold">Up</th>
                  <th
                    data-cy="servicePlanDetailsTableUpValue"
                    className="p-2 px-4 font-semibold"
                  >
                    {sp?.fields.Up}
                  </th>
                </tr>
                <tr className="even:bg-white odd:bg-neutral-200 text-sm text-[#6D6D6D]">
                  <th className="p-2 px-4 font-semibold">Price</th>
                  <th
                    data-cy="servicePlanDetailsTablePriceValue"
                    className="p-2 px-4 font-semibold"
                  >
                    {sp?.fields.Price}
                  </th>
                </tr>
              </tbody>
            </table>
            <h3 className="text-lg font-base mb-4 pt-10">
              Newtwork Operator Contact Information
            </h3>
            {sr?.marketing_url && sr?.support_url && (
              <div className="flex justify-end">
                <Link to={sr?.marketing_url || ""}>
                  <WebIcon />
                </Link>
                <div className="inline-block pr-4" />
                <Link to={sr?.support_url || ""}>
                  <ChatQuestion />
                </Link>
              </div>
            )}

            {prcontacts && prcontacts.length > 0 && (
              <table
                data-cy="networkOperatorContactInfoTable"
                className="w-full pt-4"
              >
                <tbody
                  data-cy="networkOperatorContactInfoTableBody"
                  className="text-left"
                >
                  {prcontacts.map((prct) => (
                    <tr
                      data-cy={`networkOperatorContactInfoTableRow-${prct.id}`}
                      className="even:bg-white odd:bg-neutral-200 text-sm text-[#6D6D6D]"
                    >
                      <th
                        data-cy={`networkOperatorContactInfoTableRow-${prct.id}-type`}
                        className="p-2 px-4 font-semibold"
                      >
                        {prct?.type}
                      </th>
                      <th
                        data-cy={`networkOperatorContactInfoTableRow-${prct.id}-address`}
                        className="p-2 px-4 font-semibold"
                      >
                        {prct?.first_name} {prct?.last_name} <br />
                        {prct?.Location?.address1} <br />
                        {prct?.Location?.city} {prct.Location?.state}, ID{" "}
                        {prct?.Location?.zip}
                        <br />
                        <a href={`tel:${prct?.phone}`}>{prct?.phone}</a>
                        <br />
                        <a href={`mailto:${prct?.email}`}>{prct?.email}</a>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <div className="w-1/2 pr-4">
            <h3 className="text-lg font-base mb-4">Subscription Details</h3>
            <table data-cy="subscriptionDetailsTable" className="w-full">
              <tbody
                data-cy="subscriptionDetailsTableBody"
                className="text-left"
              >
                <tr className="even:bg-white odd:bg-neutral-200 text-sm text-[#6D6D6D]">
                  <th className="p-2 px-4 font-semibold">User</th>
                  <th
                    data-cy="subscriptionDetailsTableUsernameValue"
                    className="p-2 px-4 font-semibold"
                  >
                    {user?.username}
                  </th>
                </tr>
                <tr className="even:bg-white odd:bg-neutral-200 text-sm text-[#6D6D6D]">
                  <th className="p-2 px-4 font-semibold">Triggered By</th>
                  <th className="p-2 px-4 font-semibold">USER</th>
                </tr>
                <tr className="even:bg-white odd:bg-neutral-200 text-sm text-[#6D6D6D]">
                  <th className="p-2 px-4 font-semibold">Created at</th>
                  <th
                    data-cy="subscriptionDetailsTableCreatedAtValue"
                    className="p-2 px-4 font-semibold"
                  >
                    {user?.created_at}
                  </th>
                </tr>
                <tr className="even:bg-white odd:bg-neutral-200 text-sm text-[#6D6D6D]">
                  <th className="p-2 px-4 font-semibold">Last Updated</th>
                  <th
                    data-cy="subscriptionDetailsTableUpdatedAtValue"
                    className="p-2 px-4 font-semibold"
                  >
                    {user?.updated_at}
                  </th>
                </tr>
                <tr className="even:bg-white odd:bg-neutral-200 text-sm text-[#6D6D6D]">
                  <th className="p-2 px-4 font-semibold">Expires</th>
                  <th
                    data-cy="subscriptionDetailsTableExpiresAtValue"
                    className="p-2 px-4 font-semibold"
                  >
                    {sp?.expire_time}
                  </th>
                </tr>
              </tbody>
            </table>
            <h3 className="text-lg font-base mb-4 pt-10">
              Account Contact Information
            </h3>
            {contacts && contacts.length > 0 && (
              <table data-cy="accountContactInfoTable" className="w-full">
                <tbody
                  data-cy="accountContactInfoTableBody"
                  className="text-left"
                >
                  {contacts.map((ct) => (
                    <tr
                      data-cy={`accountContactInfoTable-${ct.id}-row`}
                      className="even:bg-white odd:bg-neutral-200 text-sm text-[#6D6D6D]"
                    >
                      <th
                        data-cy={`accountContactInfoTable-${ct.id}-type`}
                        className="p-2 px-4 font-semibold"
                      >
                        {ct?.Contact?.type}
                      </th>
                      <th
                        data-cy={`accountContactInfoTable-${ct.id}-address`}
                        className="p-2 px-4 font-semibold"
                      >
                        {ct?.Contact?.first_name} {ct?.Contact?.last_name}{" "}
                        <br />
                        {ct?.Contact?.Location?.address1} <br />
                        {ct?.Contact?.Location?.city}, ID{" "}
                        {ct?.Contact?.Location?.zip}
                        <br />
                        <a href={`tel:${ct?.Contact?.phone}`}>
                          {ct?.Contact?.phone}
                        </a>
                        <br />
                        <a href={`mailto:${ct?.Contact?.email}`}>
                          {ct?.Contact?.email}
                        </a>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <DialogFooter>
          <EpButton
            styling={EpButtonStyles.DELETE}
            dataCy="subscription-lan-details-remove-btn"
            onClick={() => setIsConfirmationDialogOpened(true)}
            aria-label="Remove Service"
          >
            Remove Service
          </EpButton>
          <DialogClose asChild className="flex gap-2 ml-auto">
            <EpButton
              type="button"
              styling={EpButtonStyles.CANCEL}
              dataCy="cancelBtn"
              aria-label="Cancel"
            >
              Cancel
            </EpButton>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
      <UnsubscriberLanDetailsConfirmationDialog
        isDialogOpened={isConfirmationDialogOpened}
        setCurrentPort={setCurrentPort}
        setIsDialogOpened={setIsConfirmationDialogOpened}
      />
    </Dialog>
  );
}

import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import LoadingAnimation from "src/components/spinners/LoadingAnimation";
import { z } from "zod";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";
import {
  FormField,
  FormItem,
  FormControl,
  FormMessage,
  Form,
} from "src/components/form/Form";
import { Input } from "src/components/input/Input";
import IconButton from "src/components/buttons/IconButton";
import WarningIcon from "src/components/icons/WarningIcon";
import { toast } from "src/components/toast/useToast";
import { GlobalServicePlan } from "../manage-global-service/ManageDetailsPlans";

interface RemoveGlobalServiceDialogProps {
  globalService: GlobalServicePlan;
  removeGlobalService: (globalService: GlobalServicePlan) => void;
}

const RemoveGlobalServiceFormSchema = z
  .object({
    enteredServiceName: z.string(),
    serviceName: z.string(),
  })
  .refine((data) => data.serviceName === data.enteredServiceName, {
    message: "Entered service name doesn’t match",
    path: ["enteredServiceName"],
  });

export default function RemoveGlobalService({
  globalService,
  removeGlobalService,
}: RemoveGlobalServiceDialogProps) {
  const form = useForm<z.infer<typeof RemoveGlobalServiceFormSchema>>({
    resolver: zodResolver(RemoveGlobalServiceFormSchema),
    mode: "onBlur",
    defaultValues: {
      enteredServiceName: "",
      serviceName: globalService.provider,
    },
  });

  const handleCloseDialog = () => {
    setIsDialogOpened(!isDialogOpened);
  };

  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  function handleremoveGlobalService(
    data: z.infer<typeof RemoveGlobalServiceFormSchema>,
  ) {
    setIsLoading(true);
    setTimeout(() => {
      toast({
        title: "Success",
        description: (
          <>
            {data.enteredServiceName} Removed
            <br />
          </>
        ),
      });
      removeGlobalService(globalService);
      setIsLoading(false);
      handleCloseDialog();
    }, 1500);
  }

  useMemo(() => form.reset(), [isDialogOpened]);

  return (
    <Dialog open={isDialogOpened} onOpenChange={handleCloseDialog}>
      <DialogTrigger asChild>
        <IconButton
          name="remove"
          dataCy="removeGlobalServiceActionBtn"
          tooltipText={`Remove Service`}
          ariaLabel={`Remove Service`}
        />
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-[425px] font-base"
        dataCy="removeGlobalServiceDialogCnt"
      >
        <DialogHeader dataCy="removeGlobalServiceDialogHeader" borderless>
          <DialogTitle dataCy="removeGlobalServiceDialogTitle">
            Remove Service
          </DialogTitle>
        </DialogHeader>
        <p className="text-sm flex items-center gap-2 text-[#434343] -mt-4">
          <WarningIcon fill="#EC0000" />
          Are you sure you want to remove {globalService.provider}
        </p>
        <p className="text-sm text-red-600 font-semibold">
          Enter <span className="text-black">{globalService.provider}</span>{" "}
          below to remove the account.
        </p>
        <div className="flex flex-col w-full">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleremoveGlobalService)}
              className="flex flex-col w-full"
            >
              <FormField
                control={form.control}
                name="enteredServiceName"
                render={(field) => (
                  <FormItem className="w-full pr-2 h-10">
                    <FormControl>
                      <Input
                        id="enteredServiceName"
                        placeholder="Enter Service Name"
                        data-cy="enteredServiceNameInput"
                        type="text"
                        aria-label="Service Name"
                        {...field.field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>

        <DialogFooter>
          <EpButton
            styling={EpButtonStyles.DELETE}
            dataCy="removeGlobalServiceBtn"
            aria-label="Remove Service"
            className="flex gap-1"
            type="submit"
            onClick={form.handleSubmit(handleremoveGlobalService)}
            disabled={isLoading}
          >
            {isLoading && <LoadingAnimation />}
            {isLoading ? "Processing" : "Remove Service"}
          </EpButton>
          <DialogClose asChild className="flex gap-2">
            <EpButton
              type="button"
              styling={EpButtonStyles.CANCEL}
              dataCy="cancelBtn"
              aria-label="Cancel"
            >
              Cancel
            </EpButton>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { CheckIcon } from "lucide-react";

import { Command, CommandItem, CommandList } from "../command/Command";
import { cn } from "src/utils/cn";
import ScrollArea from "../scroll-area/ScrollArea";
import { Select, SelectContent, SelectTrigger } from "../select/Select";

const multiSelectVariants = cva(
  "m-1 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300",
  {
    variants: {
      variant: {
        default:
          "border-foreground/10 text-foreground bg-card hover:bg-card/80",
        secondary:
          "border-foreground/10 bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
        inverted: "inverted",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

interface MultiSelectFormFieldProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof multiSelectVariants> {
  asChild?: boolean;
  options: {
    label: string;
    value: string;
  }[];
  defaultValue?: string[];
  disabled?: boolean;
  placeholder: string;
  className?: string;
  onValueChange: (value: string[]) => void;
}

const MultiSelectFormField = React.forwardRef<
  HTMLButtonElement,
  MultiSelectFormFieldProps
>(
  (
    {
      className,
      variant,
      asChild = false,
      options,
      defaultValue,
      onValueChange,
      disabled,
      placeholder,
      ...props
    },
    ref,
  ) => {
    const [selectedValues, setSelectedValues] = React.useState<string[]>(
      defaultValue || [],
    );
    const selectedValuesSet = React.useRef(new Set(selectedValues));
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

    React.useEffect(() => {
      setSelectedValues(defaultValue || []);
      selectedValuesSet.current = new Set(defaultValue);
    }, [defaultValue]);

    const toggleOption = (value: string) => {
      if (selectedValuesSet.current.has(value)) {
        selectedValuesSet.current.delete(value);
        setSelectedValues(selectedValues.filter((v) => v !== value));
      } else {
        selectedValuesSet.current.add(value);
        setSelectedValues([...selectedValues, value]);
      }
      onValueChange([...selectedValuesSet.current]);
    };

    const clearSelectedOptions = () => {
      setSelectedValues([]);
      selectedValuesSet.current.clear();
      onValueChange([]);
    };

    return (
      <Select open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <SelectTrigger
          ref={ref}
          canClear
          onClear={clearSelectedOptions}
          className="h-auto"
          {...props}
        >
          {selectedValues.length > 0 ? (
            <div className="flex justify-start flex-wrap gap-1 items-center w-full">
              {selectedValues.map((value, idx) => {
                return (
                  <span key={value}>
                    {value}
                    {idx !== selectedValues.length - 1 ? "," : ""}
                  </span>
                );
              })}
            </div>
          ) : (
            <div className="flex items-center justify-between w-full mx-auto">
              <span className="text-muted-foreground font-medium">
                {placeholder}
              </span>
            </div>
          )}
        </SelectTrigger>
        <SelectContent onEscapeKeyDown={() => setIsPopoverOpen(false)}>
          <Command>
            <CommandList>
              <ScrollArea className="max-h-60 px-0">
                {options.map((option) => {
                  const isSelected = selectedValuesSet.current.has(
                    option.value,
                  );
                  return (
                    <CommandItem
                      key={option.value}
                      onSelect={() => toggleOption(option.value)}
                      style={{
                        pointerEvents: "auto",
                        opacity: 1,
                      }}
                      data-cy={`multi-select-option-${option.value}`}
                      className={cn(isSelected ? "bg-neutral-100" : "")}
                    >
                      <span>{option.label}</span>
                      <div
                        className={cn(
                          isSelected
                            ? "text-primary-foreground"
                            : "opacity-50 [&_svg]:invisible",
                        )}
                      >
                        <CheckIcon className="h-4 w-4 bg-transparent text-[#434343]" />
                      </div>
                    </CommandItem>
                  );
                })}
              </ScrollArea>
            </CommandList>
          </Command>
        </SelectContent>
      </Select>
    );
  },
);

MultiSelectFormField.displayName = "MultiSelectFormField";

export default MultiSelectFormField;

import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";
import ManageDetailsForm from "../manage-global-service/ManageDetailsForm";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonSizes } from "src/components/buttons/constants";
import { GlobalServicePlan } from "../manage-global-service/ManageDetailsPlans";
import ScrollArea from "src/components/scroll-area/ScrollArea";

export default function AddServiceDialog() {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const defaultServicePlanValues: GlobalServicePlan = {
    accessCode: "",
    accountTypes: [],
    createdAt: new Date(),
    description: "",
    displayType: "",
    down: 0,
    expireTime: 0,
    id: "",
    ipRange: "",
    linkSpeed: 0,
    marketingURL: "",
    name: "",
    planName: "",
    domain: "",
    type: "",
    price: 0,
    provider: "",
    standByEnabled: "",
    standByInitiallyOn: "",
    status: "",
    subscriptionRedirectURL: "",
    supportURL: "",
    up: 0,
    updatedAt: new Date(),
  };

  return (
    <Dialog
      open={isDialogOpened}
      onOpenChange={(value) => setIsDialogOpened(value)}
    >
      <DialogTrigger asChild>
        <EpButton
          sizing={EpButtonSizes.LARGE}
          dataCy="addServiceActionBtn"
          aria-label="Add Service"
        >
          Add Service
        </EpButton>
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-4xl font-base"
        dataCy="addServiceDialogCnt"
      >
        <DialogHeader dataCy="addServiceDialogHeader" borderless>
          <DialogTitle dataCy="addServiceDialogTitle">Add Service</DialogTitle>
        </DialogHeader>
        <ScrollArea className="max-h-[calc(85vh-100px)]">
          <ManageDetailsForm
            operationType="create"
            serviceDetails={defaultServicePlanValues}
          />
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}

import { Device } from "src/interfaces";

export const deviceMockData = {
  id: "f0395a48-dd79-11ee-a68d-35343031464e",
  ansible_update_flag: null,
  description: "EntryPoint Monarch - Veneon",
  hardware_version: "H1-J1900",
  installed_at: "2024-04-09 19:48:41",
  ip_address: "10.128.0.11",
  ip_address_num: null,
  ipv6_address: null,
  ipv6_address_num: null,
  json: null,
  mac_address: "00:e0:67:30:62:31",
  notes: "",
  physicallocation_id: "f19472b0-dd79-11ee-b04b-574c44534350",
  openflow_id: "manual:963803898417",
  password: "EntryPoint#",
  product_id: "68a20860-5cea-11eb-8ba5-4a47484b4443",
  reset_delay: "5",
  rack_id: null,
  rack_unit_end: "0",
  rack_unit_start: "0",
  sdn_server_id: "76ca8990-cd30-11ea-bd34-43415959444d",
  sdn_server_local: false,
  serial_number: "HYSZ20230601418",
  snmp_id: null,
  snmp_server_id: null,
  software_version: "2.1.7.5",
  status: "ACTIVE",
  switch_port_id: null,
  type: "VBG",
  uid: "lab-venoen-test2",
  username: "entrypoint",
  created_at: "2024-03-08 11:30:28",
  updated_at: "2024-04-09 13:48:41",
  Accounts: [
    {
      id: "c20611be-e36c-11ed-9055-5953494e545a",
      account_number: "10_Sumo_10_Sumo_Way",
      name: "10, Sumo",
      notes: "",
      status: "ACTIVE",
      type: "RESIDENTIAL",
      created_at: "2023-04-25 07:26:16",
      updated_at: "2024-03-05 09:32:34",
      Users: [
        {
          id: "93b62b50-0f90-11ee-8ea5-394d4e334a51",
          auth_location: "LDAP",
          contact_id: "93b64126-0f90-11ee-9109-343045423248",
          is_subscriber: true,
          roles: ["SUBSCRIBER"],
          status: "ACTIVE",
          username: "adaptivetest@gmail.com",
          created_at: "2023-06-20 11:33:32",
          updated_at: "2024-04-09 14:37:25",
          can_review: true,
          default_url_subscriber_portal: "/dashboard",
          force_password: true,
          force_username: false,
          messaging: {
            ACCOUNT: ["EMAIL", "PORTAL"],
            FIRMWARE: ["EMAIL", "PORTAL"],
            OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
            SUBSCRIPTION: ["EMAIL", "PORTAL"],
            USER: ["EMAIL", "PORTAL"],
          },
          force_created_at: "2024-04-01 12:21:25",
          default_url_management_portal: "",
          answered_security_questions: true,
          Contact: {
            id: "93b64126-0f90-11ee-9109-343045423248",
            email: "adaptivetest@gmail.com",
            first_name: "Test",
            last_name: "Test",
            location_id: "93b65c10-0f90-11ee-8e4a-495450543557",
            phone: "(121)-212-1212",
            status: "ACTIVE",
            type: "BILLING",
            created_at: "2023-06-20 11:33:32",
            updated_at: "2023-06-20 11:33:32",
            Location: {
              id: "93b65c10-0f90-11ee-8e4a-495450543557",
              address1: "100 Main St",
              address2: "",
              city: "Atl",
              country: "US",
              elevation: null,
              lat: null,
              lon: null,
              state: "GA",
              status: "ACTIVE",
              uri: "",
              zip: "12345",
              created_at: "2023-06-20 11:33:32",
              updated_at: "2023-06-20 11:33:32",
            },
          },
        },
        {
          id: "c0c59a18-e36c-11ed-ad60-36564c423141",
          auth_location: "LDAP",
          contact_id: "c0c5b016-e36c-11ed-8be2-575237424632",
          is_subscriber: true,
          roles: ["SUBSCRIBER"],
          status: "ACTIVE",
          username: "sumo10@gmail.com",
          created_at: "2023-04-25 07:26:14",
          updated_at: "2024-04-02 14:05:57",
          can_review: true,
          default_url_subscriber_portal: "/dashboard",
          force_password: false,
          force_username: false,
          messaging: {
            ACCOUNT: ["EMAIL", "PORTAL"],
            FIRMWARE: ["EMAIL", "PORTAL"],
            OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
            SUBSCRIPTION: ["EMAIL", "PORTAL"],
            USER: ["EMAIL", "PORTAL"],
          },
          default_url_management_portal: "",
          answered_security_questions: true,
          Contact: {
            id: "c0c5b016-e36c-11ed-8be2-575237424632",
            email: "sumo10@gmail.com",
            first_name: "Sumo",
            last_name: "10",
            location_id: "c0c5cc4a-e36c-11ed-bf00-51323453564f",
            phone: "1010101010",
            status: "ACTIVE",
            type: "BILLING",
            created_at: "2023-04-25 07:26:14",
            updated_at: "2024-01-26 14:20:28",
            Location: {
              id: "c0c5cc4a-e36c-11ed-bf00-51323453564f",
              address1: "10 Sumo Way",
              address2: "",
              city: "Ammon",
              country: "US",
              elevation: null,
              lat: null,
              lon: null,
              state: "ID",
              status: "ACTIVE",
              uri: "",
              zip: "12345",
              created_at: "2023-04-25 07:26:14",
              updated_at: "2023-04-25 07:26:14",
            },
          },
        },
        {
          id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
          auth_location: "LDAP",
          contact_id: "d99b87b4-0492-11ed-ae58-544259474838",
          is_subscriber: true,
          roles: ["SUBSCRIBER"],
          status: "ACTIVE",
          username: "test",
          created_at: "2022-07-15 17:07:08",
          updated_at: "2024-04-10 09:01:21",
          can_review: true,
          default_url_subscriber_portal: "/dashboard",
          messaging: {
            OPERATOR_MESSAGE: ["PORTAL", "EMAIL"],
            ACCOUNT: [],
            USER: [],
            SUBSCRIPTION: [],
            FIRMWARE: [],
          },
          force_username: false,
          default_url_management_portal: "",
          answered_security_questions: true,
          Contact: {
            id: "d99b87b4-0492-11ed-ae58-544259474838",
            email: "deletingadmin0007one@yopmail.com",
            first_name: "John",
            last_name: "Do",
            location_id: "d99bc832-0492-11ed-81fc-45425753504d",
            phone: "(224)-123-1234",
            status: "PENDING",
            type: "BILLING",
            created_at: "2022-07-15 17:07:08",
            updated_at: "2024-04-10 09:01:21",
            Location: {
              id: "d99bc832-0492-11ed-81fc-45425753504d",
              address1: "100 Anystreet Rd",
              address2: "234 N",
              city: "Fiber City",
              country: "US",
              elevation: null,
              lat: null,
              lon: null,
              state: "CA",
              status: "ACTIVE",
              uri: "",
              zip: "55555",
              created_at: "2022-07-15 17:07:08",
              updated_at: "2023-12-26 12:14:12",
            },
          },
        },
        {
          id: "80cc90a0-c463-11ee-9da9-483831413547",
          auth_location: "DB",
          contact_id: "80ccadd8-c463-11ee-bef7-4d4e4c504c43",
          is_subscriber: true,
          roles: ["SUBSCRIBER"],
          status: "INACTIVE",
          username: "DemoMonday2",
          created_at: "2024-02-05 13:16:53",
          updated_at: "2024-02-19 15:01:54",
          can_review: false,
          default_url_management_portal: "",
          default_url_subscriber_portal: "",
          messaging: "",
          answered_security_questions: false,
          Contact: {
            id: "80ccadd8-c463-11ee-bef7-4d4e4c504c43",
            email: "mario.alvarado@gopello.com",
            first_name: "DemoUser",
            last_name: "CoolLastname",
            location_id: "80ccc98a-c463-11ee-8402-4d4f44344149",
            phone: "1717171716",
            status: "ACTIVE",
            type: "BILLING",
            created_at: "2024-02-05 13:16:53",
            updated_at: "2024-02-05 13:17:08",
            Location: {
              id: "80ccc98a-c463-11ee-8402-4d4f44344149",
              address1: "17 ACH Driveee",
              address2: "",
              city: "Test",
              country: "US",
              elevation: null,
              lat: null,
              lon: null,
              state: "CA",
              status: "ACTIVE",
              uri: null,
              zip: "20123",
              created_at: "2024-02-05 13:16:53",
              updated_at: "2024-02-05 13:16:53",
            },
          },
        },
        {
          id: "b220b79a-15df-11ee-b8de-464f424c4e34",
          auth_location: "LDAP",
          contact_id: "b220cef6-15df-11ee-a146-593743554b45",
          is_subscriber: true,
          roles: ["SUBSCRIBER"],
          status: "INACTIVE",
          username: "Accountflow@gmail.com",
          created_at: "2023-06-28 12:15:00",
          updated_at: "2024-04-02 14:06:00",
          can_review: true,
          default_url_subscriber_portal: "/dashboard",
          force_password: true,
          force_username: false,
          messaging: {
            ACCOUNT: ["EMAIL", "PORTAL"],
            FIRMWARE: ["EMAIL", "PORTAL"],
            OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
            SUBSCRIPTION: ["EMAIL", "PORTAL"],
            USER: ["EMAIL", "PORTAL"],
          },
          force_created_at: "2023-11-22 12:13:15",
          default_url_management_portal: "/dashboard#/provider/list",
          answered_security_questions: true,
          Contact: {
            id: "b220cef6-15df-11ee-a146-593743554b45",
            email: "Accountflow@gmail.com",
            first_name: "Flow",
            last_name: "Test",
            location_id: "b220e904-15df-11ee-94d6-564a53454731",
            phone: "(518)-101-0101",
            status: "ACTIVE",
            type: "BILLING",
            created_at: "2023-06-28 12:15:00",
            updated_at: "2023-06-28 12:15:00",
            Location: {
              id: "b220e904-15df-11ee-94d6-564a53454731",
              address1: "128",
              address2: "",
              city: "Boise",
              country: "US",
              elevation: null,
              lat: null,
              lon: null,
              state: "ID",
              status: "ACTIVE",
              uri: "",
              zip: "12345",
              created_at: "2023-06-28 12:15:00",
              updated_at: "2023-06-28 12:15:00",
            },
          },
        },
        {
          id: "ccd4fa4a-bc94-11ee-ba75-393832555544",
          auth_location: "LDAP",
          contact_id: "ccd51660-bc94-11ee-863d-393358524944",
          is_subscriber: true,
          roles: ["SUBSCRIBER"],
          status: "ACTIVE",
          username: "00969@testing.tests",
          created_at: "2024-01-26 14:49:37",
          updated_at: "2024-04-09 14:18:47",
          can_review: true,
          default_url_subscriber_portal: "/dashboard",
          force_password: true,
          force_username: false,
          messaging: {
            ACCOUNT: ["EMAIL", "PORTAL"],
            FIRMWARE: ["EMAIL", "PORTAL"],
            OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
            SUBSCRIPTION: ["EMAIL", "PORTAL"],
            USER: ["EMAIL", "PORTAL"],
          },
          force_created_at: "2024-04-03 10:50:14",
          default_url_management_portal: "",
          answered_security_questions: true,
          Contact: {
            id: "ccd51660-bc94-11ee-863d-393358524944",
            email: "00969@testing.test",
            first_name: "Testing",
            last_name: "Qa",
            location_id: "ccd53334-bc94-11ee-9833-4c5437373632",
            phone: "(555)-000-0009",
            status: "ACTIVE",
            type: "BILLING",
            created_at: "2024-01-26 14:49:37",
            updated_at: "2024-04-03 17:09:30",
            Location: {
              id: "ccd53334-bc94-11ee-9833-4c5437373632",
              address1: "1009 West Street",
              address2: "",
              city: "Ammon",
              country: "US",
              elevation: null,
              lat: null,
              lon: null,
              state: "ID",
              status: "ACTIVE",
              uri: "",
              zip: "83012",
              created_at: "2024-01-26 14:49:37",
              updated_at: "2024-02-16 09:47:19",
            },
          },
        },
        {
          id: "e403fefc-896b-11ee-a8da-4c3048334643",
          auth_location: "LDAP",
          contact_id: "e4041522-896b-11ee-80ac-4d4c43374b43",
          is_subscriber: true,
          roles: ["SUBSCRIBER"],
          status: "ACTIVE",
          username: "removingtest",
          created_at: "2023-11-22 12:18:17",
          updated_at: "2024-03-19 10:45:30",
          force_password: false,
          can_review: true,
          messaging: {
            ACCOUNT: ["EMAIL", "PORTAL"],
            FIRMWARE: ["EMAIL", "PORTAL"],
            OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
            SUBSCRIPTION: ["EMAIL", "PORTAL"],
            USER: ["EMAIL", "PORTAL"],
          },
          default_url_management_portal: "",
          default_url_subscriber_portal: "",
          answered_security_questions: false,
          Contact: {
            id: "e4041522-896b-11ee-80ac-4d4c43374b43",
            email: "remove@testing.test",
            first_name: null,
            last_name: null,
            location_id: null,
            phone: null,
            status: "ACTIVE",
            type: "SALES",
            created_at: "2023-11-22 12:18:17",
            updated_at: "2023-11-22 12:18:17",
            Location: null,
          },
        },
        {
          id: "ff46af72-e398-11ed-81fd-354c5a34445a",
          auth_location: "LDAP",
          contact_id: "ff46ce62-e398-11ed-8929-534453484939",
          is_subscriber: true,
          roles: ["SUBSCRIBER"],
          status: "INACTIVE",
          username: "123@gmail.com",
          created_at: "2023-04-25 12:35:48",
          updated_at: "2024-04-02 14:05:57",
          can_review: true,
          default_url_subscriber_portal: "/dashboard",
          force_password: false,
          force_username: false,
          messaging: {
            ACCOUNT: ["EMAIL", "PORTAL"],
            FIRMWARE: ["EMAIL", "PORTAL"],
            OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
            SUBSCRIPTION: ["EMAIL", "PORTAL"],
            USER: ["EMAIL", "PORTAL"],
          },
          default_url_management_portal: "",
          answered_security_questions: true,
          Contact: {
            id: "ff46ce62-e398-11ed-8929-534453484939",
            email: "123@gmail.com",
            first_name: "1",
            last_name: "2",
            location_id: "ff46ea14-e398-11ed-ae34-53503544394c",
            phone: "1231231232",
            status: "ACTIVE",
            type: "BILLING",
            created_at: "2023-04-25 12:35:48",
            updated_at: "2023-04-25 12:35:48",
            Location: {
              id: "ff46ea14-e398-11ed-ae34-53503544394c",
              address1: "123",
              address2: "",
              city: "123",
              country: "US",
              elevation: null,
              lat: null,
              lon: null,
              state: "ID",
              status: "ACTIVE",
              uri: "",
              zip: "12345",
              created_at: "2023-04-25 12:35:48",
              updated_at: "2023-05-05 11:46:47",
            },
          },
        },
      ],
    },
    {
      id: "a3d72348-d021-11ee-996c-4f33504e4b50",
      account_number: "admin",
      name: "admin",
      notes: "",
      status: "ACTIVE",
      type: "RESIDENTIAL",
      created_at: "2024-02-20 11:55:39",
      updated_at: "2024-03-08 11:35:36",
      Users: [
        {
          id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
          auth_location: "LDAP",
          contact_id: "d99b87b4-0492-11ed-ae58-544259474838",
          is_subscriber: true,
          roles: ["SUBSCRIBER"],
          status: "ACTIVE",
          username: "test",
          created_at: "2022-07-15 17:07:08",
          updated_at: "2024-04-10 09:01:21",
          can_review: true,
          default_url_subscriber_portal: "/dashboard",
          messaging: {
            OPERATOR_MESSAGE: ["PORTAL", "EMAIL"],
            ACCOUNT: [],
            USER: [],
            SUBSCRIPTION: [],
            FIRMWARE: [],
          },
          force_username: false,
          default_url_management_portal: "",
          answered_security_questions: true,
          Contact: {
            id: "d99b87b4-0492-11ed-ae58-544259474838",
            email: "deletingadmin0007one@yopmail.com",
            first_name: "John",
            last_name: "Do",
            location_id: "d99bc832-0492-11ed-81fc-45425753504d",
            phone: "(224)-123-1234",
            status: "PENDING",
            type: "BILLING",
            created_at: "2022-07-15 17:07:08",
            updated_at: "2024-04-10 09:01:21",
            Location: {
              id: "d99bc832-0492-11ed-81fc-45425753504d",
              address1: "100 Anystreet Rd",
              address2: "234 N",
              city: "Fiber City",
              country: "US",
              elevation: null,
              lat: null,
              lon: null,
              state: "CA",
              status: "ACTIVE",
              uri: "",
              zip: "55555",
              created_at: "2022-07-15 17:07:08",
              updated_at: "2023-12-26 12:14:12",
            },
          },
        },
        {
          id: "35f15c08-a404-11ed-a632-3835525a3059",
          auth_location: "LDAP",
          contact_id: "35f1795e-a404-11ed-a815-5138434e3238",
          is_subscriber: false,
          roles: ["ADMIN"],
          status: "ACTIVE",
          username: "dherrick",
          created_at: "2023-02-03 13:49:11",
          updated_at: "2024-04-02 14:05:58",
          can_review: true,
          default_url_management_portal: "/dashboard#/device/list",
          default_url_subscriber_portal: "/dashboard",
          force_password: false,
          force_username: false,
          messaging: "",
          answered_security_questions: false,
          Contact: {
            id: "35f1795e-a404-11ed-a815-5138434e3238",
            email: "dherrick@entpnt.com",
            first_name: "Devyn",
            last_name: "Herrick",
            location_id: "35f18bb0-a404-11ed-95de-344437433133",
            phone: "2087168185",
            status: "ACTIVE",
            type: "SUPPORT",
            created_at: "2023-02-03 13:49:11",
            updated_at: "2023-02-03 13:49:11",
            Location: {
              id: "35f18bb0-a404-11ed-95de-344437433133",
              address1: "4215 Willow Canyon Dr",
              address2: "",
              city: "Ammon",
              country: "US",
              elevation: null,
              lat: null,
              lon: null,
              state: "ID",
              status: "ACTIVE",
              uri: "",
              zip: "83406",
              created_at: "2023-02-03 13:49:11",
              updated_at: "2023-02-07 14:40:04",
            },
          },
        },
      ],
    },
    {
      id: "6ba32912-40f3-11ee-a05b-435a33585a55",
      account_number: "1000a_Account_One",
      name: "1000a_Account_Ones",
      notes: "",
      status: "ACTIVE",
      type: "RESIDENTIAL",
      created_at: "2023-08-22 07:54:32",
      updated_at: "2024-02-01 14:24:15",
      Users: [
        {
          id: "543a5606-40f3-11ee-bfa9-355a56385652",
          auth_location: "LDAP",
          contact_id: "543a729e-40f3-11ee-b683-43594f505932",
          is_subscriber: true,
          roles: ["SUBSCRIBER"],
          status: "ACTIVE",
          username: "accountone@gmail.com",
          created_at: "2023-08-22 07:53:52",
          updated_at: "2024-01-29 14:59:58",
          can_review: false,
          force_username: true,
          messaging: {
            ACCOUNT: ["EMAIL", "PORTAL"],
            FIRMWARE: ["EMAIL", "PORTAL"],
            OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
            SUBSCRIPTION: ["EMAIL", "PORTAL"],
            USER: ["EMAIL", "PORTAL"],
          },
          force_password: true,
          default_url_management_portal: "",
          default_url_subscriber_portal: "",
          answered_security_questions: false,
          Contact: {
            id: "543a729e-40f3-11ee-b683-43594f505932",
            email: "accountone@gmail.com",
            first_name: "Account ",
            last_name: "One",
            location_id: "543a8cd4-40f3-11ee-83a7-4b4f534d584c",
            phone: "1234567809",
            status: "ACTIVE",
            type: "SALES",
            created_at: "2023-08-22 07:53:52",
            updated_at: "2024-02-01 14:25:51",
            Location: {
              id: "543a8cd4-40f3-11ee-83a7-4b4f534d584c",
              address1: "",
              address2: "",
              city: "Idaho Falls",
              country: "US",
              elevation: null,
              lat: null,
              lon: null,
              state: "ID",
              status: "ACTIVE",
              uri: "",
              zip: "80001",
              created_at: "2023-08-22 07:53:52",
              updated_at: "2024-02-01 14:24:48",
            },
          },
        },
        {
          id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
          auth_location: "LDAP",
          contact_id: "d99b87b4-0492-11ed-ae58-544259474838",
          is_subscriber: true,
          roles: ["SUBSCRIBER"],
          status: "ACTIVE",
          username: "test",
          created_at: "2022-07-15 17:07:08",
          updated_at: "2024-04-10 09:01:21",
          can_review: true,
          default_url_subscriber_portal: "/dashboard",
          messaging: {
            OPERATOR_MESSAGE: ["PORTAL", "EMAIL"],
            ACCOUNT: [],
            USER: [],
            SUBSCRIPTION: [],
            FIRMWARE: [],
          },
          force_username: false,
          default_url_management_portal: "",
          answered_security_questions: true,
          Contact: {
            id: "d99b87b4-0492-11ed-ae58-544259474838",
            email: "deletingadmin0007one@yopmail.com",
            first_name: "John",
            last_name: "Do",
            location_id: "d99bc832-0492-11ed-81fc-45425753504d",
            phone: "(224)-123-1234",
            status: "PENDING",
            type: "BILLING",
            created_at: "2022-07-15 17:07:08",
            updated_at: "2024-04-10 09:01:21",
            Location: {
              id: "d99bc832-0492-11ed-81fc-45425753504d",
              address1: "100 Anystreet Rd",
              address2: "234 N",
              city: "Fiber City",
              country: "US",
              elevation: null,
              lat: null,
              lon: null,
              state: "CA",
              status: "ACTIVE",
              uri: "",
              zip: "55555",
              created_at: "2022-07-15 17:07:08",
              updated_at: "2023-12-26 12:14:12",
            },
          },
        },
        {
          id: "38c6a994-aa6e-11ee-bbe4-4d3342563143",
          auth_location: "DB",
          contact_id: "38c6bcd6-aa6e-11ee-a446-435a4a4c3532",
          is_subscriber: true,
          roles: ["SUBSCRIBER"],
          status: "INACTIVE",
          username: "Delete me",
          created_at: "2024-01-03 12:28:07",
          updated_at: "2024-01-03 12:28:54",
          force_password: false,
          can_review: true,
          messaging: {
            ACCOUNT: ["EMAIL", "PORTAL"],
            FIRMWARE: ["EMAIL", "PORTAL"],
            OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
            SUBSCRIPTION: ["EMAIL", "PORTAL"],
            USER: ["EMAIL", "PORTAL"],
          },
          default_url_management_portal: "",
          default_url_subscriber_portal: "",
          answered_security_questions: false,
          Contact: {
            id: "38c6bcd6-aa6e-11ee-a446-435a4a4c3532",
            email: "edess@testing.test",
            first_name: null,
            last_name: null,
            location_id: null,
            phone: null,
            status: "ACTIVE",
            type: "BILLING",
            created_at: "2024-01-03 12:28:07",
            updated_at: "2024-01-03 12:28:07",
            Location: null,
          },
        },
      ],
    },
  ],
  Physicallocation: {
    id: "f19472b0-dd79-11ee-b04b-574c44534350",
    apartment_number: "1002",
    city: "Ammon",
    country: "US",
    elevation: "0",
    house_number: "1",
    lat: "43.469637",
    lon: "-111.966636",
    post_directional: "",
    pre_directional: "N",
    state: "ID",
    status: "ACTIVE",
    street: "123 Test Str",
    uri: "https://www.google.com/maps/place/43.4696366,-111.9666355",
    zip: "83406",
    created_at: "2024-03-08 11:30:30",
    updated_at: "2024-03-19 11:44:33",
  },
  Portinterfaces: [
    {
      id: "f041587e-dd79-11ee-957a-3450524b3042",
      description: null,
      device_id: "f0395a48-dd79-11ee-a68d-35343031464e",
      display: false,
      display_name: "Management2",
      mac_address_text: null,
      name: "Management2",
      number: "7",
      network_protocol: null,
      patch_id: null,
      speed: "1000",
      state: "CLOSED",
      status: "ACTIVE",
      type: "VIRT",
      created_at: "2024-03-08 11:30:28",
      updated_at: "2024-03-08 11:30:29",
      Subscriptions: [],
    },
    {
      id: "f03e3a04-dd79-11ee-97cb-36494c335031",
      description: null,
      device_id: "f0395a48-dd79-11ee-a68d-35343031464e",
      display: true,
      display_name: "WAN",
      mac_address_text: null,
      name: "WAN",
      number: "1",
      network_protocol: null,
      patch_id: null,
      speed: "1000",
      state: "OPEN",
      status: "ACTIVE",
      type: "WAN",
      created_at: "2024-03-08 11:30:28",
      updated_at: "2024-03-08 11:30:29",
      Subscriptions: [],
    },
    {
      id: "f041077a-dd79-11ee-a0e7-433241305147",
      description: null,
      device_id: "f0395a48-dd79-11ee-a68d-35343031464e",
      display: false,
      display_name: "Management1",
      mac_address_text: null,
      name: "Management1",
      number: "6",
      network_protocol: null,
      patch_id: null,
      speed: "1000",
      state: "OPEN",
      status: "ACTIVE",
      type: "VIRT",
      created_at: "2024-03-08 11:30:28",
      updated_at: "2024-03-08 11:30:29",
      Subscriptions: [],
    },
    {
      id: "f040661c-dd79-11ee-bd1d-474b50584937",
      description: null,
      device_id: "f0395a48-dd79-11ee-a68d-35343031464e",
      display: true,
      display_name: "LAN 2",
      mac_address_text: null,
      name: "GigabitEth2",
      number: "3",
      network_protocol: null,
      patch_id: null,
      speed: "1000",
      state: "OPEN",
      status: "ACTIVE",
      type: "LAN",
      created_at: "2024-03-08 11:30:28",
      updated_at: "2024-03-08 11:30:29",
      Subscriptions: [
        {
          id: "259af70d-ead1-4040-8631-49c985b1fc0f",
          account_id: "a3d72348-d021-11ee-996c-4f33504e4b50",
          expire_at: null,
          port_interface_id: "f040661c-dd79-11ee-bd1d-474b50584937",
          service_provider_label: "",
          serviceplan_id: "cba3a9cc-cc6c-11ee-935e-52364357434a",
          status: "ACTIVE",
          triggered_by: "USER",
          user_id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
          virtual_system_id: null,
          created_at: "2024-03-20 14:55:23",
          updated_at: "2024-03-20 14:55:25",
          Serviceplan: {
            id: "cba3a9cc-cc6c-11ee-935e-52364357434a",
            approval_values: [],
            description: "This is the 2024 basic service plan edited",
            expire_time: "0",
            image: "",
            name: "Basic 2024",
            ratings_sum: "0",
            service_id: "c3fc38a6-cc6c-11ee-b3a0-41304c54534f",
            status: "ACTIVE",
            total_reviews: "0",
            created_at: "2024-02-15 18:43:34",
            updated_at: "2024-04-05 17:13:50",
            fields: {
              Name: "Basic 2024",
              LinkSpeed: "10",
              Down: "150",
              Up: "1",
              Price: "19.99",
            },
            Service: {
              id: "c3fc38a6-cc6c-11ee-b3a0-41304c54534f",
              access_code: "X4QOBS4G",
              account_types: '["RESIDENTIAL"]',
              approval_values: {
                account_types: {
                  value: '["RESIDENTIAL",]',
                  status: "PUBLISHED",
                  published_values: ['["RESIDENTIAL",]'],
                },
              },
              description: "This is the Service 2024 in this Automated town.",
              display_type: "PUBLIC",
              image: null,
              ip_range: "",
              json: [],
              marketing_url: "",
              name: "Service 2024",
              network_operator_label: "123",
              provider_id: "7aa49c76-85a3-11ee-b78b-34543646364c",
              servicetype_id: "5f207676-7a21-11ec-9c1e-374742515a4d",
              standby_settings: {
                enabled: "1",
                on_initially: "0",
              },
              status: "ACTIVE",
              subscription_redirect_url: "",
              support_url: "",
              virtual_system_id: null,
              walled_garden_label: "123",
              created_at: "2024-02-15 18:43:21",
              updated_at: "2024-04-05 17:15:27",
              Provider: {
                id: "7aa49c76-85a3-11ee-b78b-34543646364c",
                auto_approve: [],
                description: "Provider Account for DEV system",
                has_portal: true,
                image: null,
                name: "QA Dev Provider",
                parent_id: "02ced310-5a6d-11eb-bd67-35323941484f",
                status: "ACTIVE",
                type: "SERVICE",
                created_at: "2023-11-20 12:26:07",
                updated_at: "2024-04-05 17:15:31",
                level: "1",
                Contacts: [
                  {
                    id: "b356d3e8-92f2-11ee-a6fa-475a54303751",
                    email: "Testeringss@nopes.test",
                    first_name: "Contact",
                    last_name: "Testering",
                    location_id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
                    phone: "8002223333",
                    status: "ACTIVE",
                    type: "SHIPPING",
                    created_at: "2023-12-04 15:15:58",
                    updated_at: "2024-03-12 14:06:33",
                    Location: {
                      id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
                      address1: "1000 Drive",
                      address2: "",
                      city: "Ammon",
                      country: "US",
                      elevation: null,
                      lat: null,
                      lon: null,
                      state: "ID",
                      status: "ACTIVE",
                      uri: "",
                      zip: "00000",
                      created_at: "2023-12-04 15:15:58",
                      updated_at: "2023-12-04 15:15:58",
                    },
                  },
                ],
              },
            },
          },
          User: {
            id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
            auth_location: "LDAP",
            contact_id: "d99b87b4-0492-11ed-ae58-544259474838",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "test",
            created_at: "2022-07-15 17:07:08",
            updated_at: "2024-04-10 09:01:21",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            messaging: {
              OPERATOR_MESSAGE: ["PORTAL", "EMAIL"],
              ACCOUNT: [],
              USER: [],
              SUBSCRIPTION: [],
              FIRMWARE: [],
            },
            force_username: false,
            default_url_management_portal: "",
            answered_security_questions: true,
          },
        },
      ],
    },
    {
      id: "f040b572-dd79-11ee-9f8d-57315a475147",
      description: null,
      device_id: "f0395a48-dd79-11ee-a68d-35343031464e",
      display: true,
      display_name: "SFP 2",
      mac_address_text: null,
      name: "GigabitEth3",
      number: "4",
      network_protocol: null,
      patch_id: null,
      speed: "1000",
      state: "OPEN",
      status: "ACTIVE",
      type: "LAN",
      created_at: "2024-03-08 11:30:28",
      updated_at: "2024-03-08 11:30:29",
      Subscriptions: [
        {
          id: "e7eb2564-5121-491c-be4a-106bcaa3f362",
          account_id: "a3d72348-d021-11ee-996c-4f33504e4b50",
          expire_at: null,
          port_interface_id: "f040b572-dd79-11ee-9f8d-57315a475147",
          service_provider_label: "",
          serviceplan_id: "cba3a9cc-cc6c-11ee-935e-52364357434a",
          status: "ACTIVE",
          triggered_by: "USER",
          user_id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
          virtual_system_id: null,
          created_at: "2024-03-20 14:57:14",
          updated_at: "2024-03-20 14:57:16",
          Serviceplan: {
            id: "cba3a9cc-cc6c-11ee-935e-52364357434a",
            approval_values: [],
            description: "This is the 2024 basic service plan edited",
            expire_time: "0",
            image: "",
            name: "Basic 2024",
            ratings_sum: "0",
            service_id: "c3fc38a6-cc6c-11ee-b3a0-41304c54534f",
            status: "ACTIVE",
            total_reviews: "0",
            created_at: "2024-02-15 18:43:34",
            updated_at: "2024-04-05 17:13:50",
            fields: {
              Name: "Basic 2024",
              LinkSpeed: "10",
              Down: "150",
              Up: "1",
              Price: "19.99",
            },
            Service: {
              id: "c3fc38a6-cc6c-11ee-b3a0-41304c54534f",
              access_code: "X4QOBS4G",
              account_types: '["RESIDENTIAL"]',
              approval_values: {
                account_types: {
                  value: '["RESIDENTIAL",]',
                  status: "PUBLISHED",
                  published_values: ['["RESIDENTIAL",]'],
                },
              },
              description: "This is the Service 2024 in this Automated town.",
              display_type: "PUBLIC",
              image: null,
              ip_range: "",
              json: [],
              marketing_url: "",
              name: "Service 2024",
              network_operator_label: "123",
              provider_id: "7aa49c76-85a3-11ee-b78b-34543646364c",
              servicetype_id: "5f207676-7a21-11ec-9c1e-374742515a4d",
              standby_settings: {
                enabled: "1",
                on_initially: "0",
              },
              status: "ACTIVE",
              subscription_redirect_url: "",
              support_url: "",
              virtual_system_id: null,
              walled_garden_label: "123",
              created_at: "2024-02-15 18:43:21",
              updated_at: "2024-04-05 17:15:27",
              Provider: {
                id: "7aa49c76-85a3-11ee-b78b-34543646364c",
                auto_approve: [],
                description: "Provider Account for DEV system",
                has_portal: true,
                image: null,
                name: "QA Dev Provider",
                parent_id: "02ced310-5a6d-11eb-bd67-35323941484f",
                status: "ACTIVE",
                type: "SERVICE",
                created_at: "2023-11-20 12:26:07",
                updated_at: "2024-04-05 17:15:31",
                level: "1",
                Contacts: [
                  {
                    id: "b356d3e8-92f2-11ee-a6fa-475a54303751",
                    email: "Testeringss@nopes.test",
                    first_name: "Contact",
                    last_name: "Testering",
                    location_id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
                    phone: "8002223333",
                    status: "ACTIVE",
                    type: "SHIPPING",
                    created_at: "2023-12-04 15:15:58",
                    updated_at: "2024-03-12 14:06:33",
                    Location: {
                      id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
                      address1: "1000 Drive",
                      address2: "",
                      city: "Ammon",
                      country: "US",
                      elevation: null,
                      lat: null,
                      lon: null,
                      state: "ID",
                      status: "ACTIVE",
                      uri: "",
                      zip: "00000",
                      created_at: "2023-12-04 15:15:58",
                      updated_at: "2023-12-04 15:15:58",
                    },
                  },
                ],
              },
            },
          },
          User: {
            id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
            auth_location: "LDAP",
            contact_id: "d99b87b4-0492-11ed-ae58-544259474838",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "test",
            created_at: "2022-07-15 17:07:08",
            updated_at: "2024-04-10 09:01:21",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            messaging: {
              OPERATOR_MESSAGE: ["PORTAL", "EMAIL"],
              ACCOUNT: [],
              USER: [],
              SUBSCRIPTION: [],
              FIRMWARE: [],
            },
            force_username: false,
            default_url_management_portal: "",
            answered_security_questions: true,
          },
        },
      ],
    },
    {
      id: "f03e9710-dd79-11ee-ae14-594d57325637",
      description: null,
      device_id: "f0395a48-dd79-11ee-a68d-35343031464e",
      display: true,
      display_name: "LAN 1",
      mac_address_text: null,
      name: "GigabitEth1",
      number: "2",
      network_protocol: null,
      patch_id: null,
      speed: "1000",
      state: "OPEN",
      status: "ACTIVE",
      type: "LAN",
      created_at: "2024-03-08 11:30:28",
      updated_at: "2024-03-08 11:30:29",
      Subscriptions: [
        {
          id: "85fc4cf1-4e2a-4a33-b558-89a7d79ebce6",
          account_id: "a3d72348-d021-11ee-996c-4f33504e4b50",
          expire_at: null,
          port_interface_id: "f03e9710-dd79-11ee-ae14-594d57325637",
          service_provider_label: "",
          serviceplan_id: "cba3a9cc-cc6c-11ee-935e-52364357434a",
          status: "ACTIVE",
          triggered_by: "USER",
          user_id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
          virtual_system_id: null,
          created_at: "2024-03-20 14:54:27",
          updated_at: "2024-03-20 14:54:29",
          Serviceplan: {
            id: "cba3a9cc-cc6c-11ee-935e-52364357434a",
            approval_values: [],
            description: "This is the 2024 basic service plan edited",
            expire_time: "0",
            image: "",
            name: "Basic 2024",
            ratings_sum: "0",
            service_id: "c3fc38a6-cc6c-11ee-b3a0-41304c54534f",
            status: "ACTIVE",
            total_reviews: "0",
            created_at: "2024-02-15 18:43:34",
            updated_at: "2024-04-05 17:13:50",
            fields: {
              Name: "Basic 2024",
              LinkSpeed: "10",
              Down: "150",
              Up: "1",
              Price: "19.99",
            },
            Service: {
              id: "c3fc38a6-cc6c-11ee-b3a0-41304c54534f",
              access_code: "X4QOBS4G",
              account_types: '["RESIDENTIAL"]',
              approval_values: {
                account_types: {
                  value: '["RESIDENTIAL",]',
                  status: "PUBLISHED",
                  published_values: ['["RESIDENTIAL",]'],
                },
              },
              description: "This is the Service 2024 in this Automated town.",
              display_type: "PUBLIC",
              image: null,
              ip_range: "",
              json: [],
              marketing_url: "",
              name: "Service 2024",
              network_operator_label: "123",
              provider_id: "7aa49c76-85a3-11ee-b78b-34543646364c",
              servicetype_id: "5f207676-7a21-11ec-9c1e-374742515a4d",
              standby_settings: {
                enabled: "1",
                on_initially: "0",
              },
              status: "ACTIVE",
              subscription_redirect_url: "",
              support_url: "",
              virtual_system_id: null,
              walled_garden_label: "123",
              created_at: "2024-02-15 18:43:21",
              updated_at: "2024-04-05 17:15:27",
              Provider: {
                id: "7aa49c76-85a3-11ee-b78b-34543646364c",
                auto_approve: [],
                description: "Provider Account for DEV system",
                has_portal: true,
                image: null,
                name: "QA Dev Provider",
                parent_id: "02ced310-5a6d-11eb-bd67-35323941484f",
                status: "ACTIVE",
                type: "SERVICE",
                created_at: "2023-11-20 12:26:07",
                updated_at: "2024-04-05 17:15:31",
                level: "1",
                Contacts: [
                  {
                    id: "b356d3e8-92f2-11ee-a6fa-475a54303751",
                    email: "Testeringss@nopes.test",
                    first_name: "Contact",
                    last_name: "Testering",
                    location_id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
                    phone: "8002223333",
                    status: "ACTIVE",
                    type: "SHIPPING",
                    created_at: "2023-12-04 15:15:58",
                    updated_at: "2024-03-12 14:06:33",
                    Location: {
                      id: "b35bd5aa-92f2-11ee-9482-4437454d3636",
                      address1: "1000 Drive",
                      address2: "",
                      city: "Ammon",
                      country: "US",
                      elevation: null,
                      lat: null,
                      lon: null,
                      state: "ID",
                      status: "ACTIVE",
                      uri: "",
                      zip: "00000",
                      created_at: "2023-12-04 15:15:58",
                      updated_at: "2023-12-04 15:15:58",
                    },
                  },
                ],
              },
            },
          },
          User: {
            id: "d99b3b2e-0492-11ed-8a7f-5037355a4154",
            auth_location: "LDAP",
            contact_id: "d99b87b4-0492-11ed-ae58-544259474838",
            is_subscriber: true,
            roles: ["SUBSCRIBER"],
            status: "ACTIVE",
            username: "test",
            created_at: "2022-07-15 17:07:08",
            updated_at: "2024-04-10 09:01:21",
            can_review: true,
            default_url_subscriber_portal: "/dashboard",
            messaging: {
              OPERATOR_MESSAGE: ["PORTAL", "EMAIL"],
              ACCOUNT: [],
              USER: [],
              SUBSCRIPTION: [],
              FIRMWARE: [],
            },
            force_username: false,
            default_url_management_portal: "",
            answered_security_questions: true,
          },
        },
      ],
    },
  ],
  Sdnserver: {
    id: "76ca8990-cd30-11ea-bd34-43415959444d",
    can_be_local: false,
    config_json: "[]",
    controller_port: "6633",
    controller_protocol: "tcp",
    controller_type: "MANUAL",
    controller_version: "BETA",
    description: "FlowOps Beta",
    name: "FlowOps Beta",
    password: "",
    status: "ACTIVE",
    sdn_type: "OPENFLOW",
    uri: "127.0.0.1",
    uri_port: "8181",
    uri_protocol: "http",
    username: "",
    created_at: "2022-01-20 11:47:07",
    updated_at: "2024-04-05 13:18:26",
  },
  Switchport: null,
  Xrefaccountdevice: [
    {
      id: "dadc7a90-f052-11ee-8d70-365a4c5a3846",
      account_id: "c20611be-e36c-11ed-9055-5953494e545a",
      device_id: "f0395a48-dd79-11ee-a68d-35343031464e",
      ports_json:
        '["f040661c-dd79-11ee-bd1d-474b50584937","f040b572-dd79-11ee-9f8d-57315a475147","f03e9710-dd79-11ee-ae14-594d57325637"]',
    },
    {
      id: "497c498a-dd7a-11ee-b49f-4154484a5356",
      account_id: "a3d72348-d021-11ee-996c-4f33504e4b50",
      device_id: "f0395a48-dd79-11ee-a68d-35343031464e",
      ports_json:
        '["f040661c-dd79-11ee-bd1d-474b50584937","f040b572-dd79-11ee-9f8d-57315a475147","f03e9710-dd79-11ee-ae14-594d57325637"]',
    },
    {
      id: "10f6fd3e-e55f-11ee-85e9-594e5a373357",
      account_id: "6ba32912-40f3-11ee-a05b-435a33585a55",
      device_id: "f0395a48-dd79-11ee-a68d-35343031464e",
      ports_json:
        '["f040661c-dd79-11ee-bd1d-474b50584937","f040b572-dd79-11ee-9f8d-57315a475147","f03e9710-dd79-11ee-ae14-594d57325637"]',
    },
  ],
} as any as Device;

interface PageButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
  onClick?: () => void;
  dataCy?: string;
}

const PageButton: React.FC<PageButtonProps> = ({
  children,
  disabled,
  selected = false,
  onClick,
  dataCy,
  ...props
}) => {
  const buttonClasses = `
    border-r-0
    px-3 py-2 text-xs font-medium border
    ${
      selected
        ? "text-white bg-[#55857E] border-[#55857E]"
        : "text-[#55857E] bg-transparent border-[#DBDBDB]"
    }
    ${disabled ? "" : "hover:bg-[#f0f0f0] hover:text-[#55857E]"}
    ${disabled && !selected ? "disabled:text-[#6D6D6D]" : ""}
    active:bg-[#DBDBDB] active:backdrop-opacity-20 active:text-[#55857E] active:border active:border-[#DBDBDB] active:border-r-0
  `;

  return (
    <button
      className={buttonClasses}
      onClick={onClick}
      disabled={disabled}
      data-cy={dataCy}
      aria-current={selected}
      {...props}
    >
      {children}
    </button>
  );
};

export default PageButton;

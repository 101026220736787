import { zodResolver } from "@hookform/resolvers/zod";
import { DialogClose } from "@radix-ui/react-dialog";
import { ChevronLeft } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import { DialogFooter } from "src/components/dialog/Dialog";

import {
  FormField,
  FormItem,
  FormControl,
  FormMessage,
  Form,
  FormLabel,
} from "src/components/form/Form";
import { Input } from "src/components/input/Input";
import ScrollArea from "src/components/scroll-area/ScrollArea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/select/Select";
import LoadingAnimation from "src/components/spinners/LoadingAnimation";
import { toast } from "src/components/toast/useToast";
import { Location } from "src/interfaces";
import { z } from "zod";

const NetworkOperatorLocationFormScheme = z.object({
  address1: z.string().min(1, {
    message: "Please enter a valid address 1",
  }),
  address2: z.string().min(1, {
    message: "Please enter a valid address 2",
  }),
  city: z.string().min(2, {
    message: "Please enter a valid city name",
  }),
  postalcode: z
    .string()
    .min(5, {
      message: "Please enter a valid postal code",
    })
    .max(9, {
      message: "Please enter a valid postal code",
    }),
  country: z.string().min(1, { message: "Required" }),
  state: z.string().min(1, { message: "Required" }),
  lat: z.string().optional(),
  lon: z.string().optional(),
  elevation: z.string().optional(),
  uri: z.string().optional(),
});

const STATES = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const COUNTRIES = ["United States"];

type NetworkOperatorLocationFormProps = {
  locationDetails: Location;
  actionType: "update" | "create";
  handleBack: (locationDetails: Location) => void;
  handleCloseDialog: () => void;
};

export default function NetworkOperatorLocationForm({
  actionType,
  handleBack,
  locationDetails,
  handleCloseDialog,
}: NetworkOperatorLocationFormProps) {
  const NetworkOperatorLocationForm = useForm<
    z.infer<typeof NetworkOperatorLocationFormScheme>
  >({
    resolver: zodResolver(NetworkOperatorLocationFormScheme),
    mode: "onBlur",
    defaultValues: {
      ...locationDetails,
    },
  });

  const [isProcessing, setIsProcessing] = useState(false);

  const handleUpdateLocationInformation = (
    data: z.infer<typeof NetworkOperatorLocationFormScheme>,
  ) => {
    const locationData = {
      ...locationDetails,
      ...data,
    };
    setIsProcessing(true);
    if (actionType === "create") {
      setTimeout(() => {
        setIsProcessing(false);
        toast({
          title: "Success",
          description: (
            <>
              Contact details added successfully
              <br />
            </>
          ),
        });
        console.log({ locationData });
        handleCloseDialog();
      }, 1200);
    } else {
      setTimeout(() => {
        setIsProcessing(false);
        toast({
          title: "Success",
          description: (
            <>
              Contact details updated successfully
              <br />
            </>
          ),
        });
      }, 1200);
    }
  };

  const handleBackAction = () => {
    const formData = NetworkOperatorLocationForm.getValues();
    const locationData = {
      ...locationDetails,
      ...formData,
    };
    handleBack(locationData);
  };

  function renderFooter() {
    if (actionType === "create") {
      return (
        <DialogFooter className="mt-4 flex justify-between">
          <div className="flex gap-4 items-center">
            <EpButton
              styling={EpButtonStyles.SECONDARY}
              type="button"
              dataCy="backBtn"
              aria-label="Go back"
              onClick={handleBackAction}
            >
              <ChevronLeft className="h-5 w-5" /> Back
            </EpButton>
            <DialogClose asChild>
              <EpButton
                dataCy="modalCancel"
                styling={EpButtonStyles.CANCEL}
                aria-label="cancel"
              >
                Cancel
              </EpButton>
            </DialogClose>
          </div>
          <EpButton type="submit" dataCy="saveBtn" aria-label="Save Details">
            {isProcessing && <LoadingAnimation />}
            {isProcessing ? "Saving" : "Save"}
          </EpButton>
        </DialogFooter>
      );
    }

    return (
      <DialogFooter className="mt-4 flex justify-between">
        <EpButton type="submit" dataCy="saveBtn" aria-label="Save Details">
          {isProcessing && <LoadingAnimation />}
          {isProcessing ? "Saving" : "Save"}
        </EpButton>
        <DialogClose asChild>
          <EpButton
            dataCy="modalCancel"
            styling={EpButtonStyles.CANCEL}
            aria-label="cancel"
          >
            Cancel
          </EpButton>
        </DialogClose>
      </DialogFooter>
    );
  }

  return (
    <ScrollArea className="max-h-[calc(85vh-theme(space.52))] p-1">
      <Form {...NetworkOperatorLocationForm}>
        <form
          onSubmit={NetworkOperatorLocationForm.handleSubmit(
            handleUpdateLocationInformation,
          )}
          className="flex flex-col px-1"
        >
          <p className="font-bold text-sm mb-4">
            Enter Contact&apos;s Location Info. Fields with “*” are required.
          </p>
          <div className="grid grid-cols-2 gap-2 grid-rows-1">
            <FormField
              control={NetworkOperatorLocationForm.control}
              name="address1"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>ADDRESS 1 *</FormLabel>
                  <FormControl>
                    <Input
                      autoFocus
                      id="address1"
                      placeholder="Address"
                      data-cy="address1Input"
                      type="text"
                      aria-label="Address 1"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={NetworkOperatorLocationForm.control}
              name="address2"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>ADDRESS 2 *</FormLabel>
                  <FormControl>
                    <Input
                      id="address2"
                      placeholder="Address"
                      data-cy="address2Input"
                      type="text"
                      aria-label="Address 2"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="grid grid-cols-4 gap-2 grid-rows-1">
            <FormField
              control={NetworkOperatorLocationForm.control}
              name="city"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>CITY *</FormLabel>
                  <FormControl>
                    <Input
                      id="city"
                      placeholder="City"
                      data-cy="cityInput"
                      type="text"
                      aria-label="City"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={NetworkOperatorLocationForm.control}
              name="state"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>STATE *</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      NetworkOperatorLocationForm.setValue(
                        field.field.name,
                        value,
                      );
                      NetworkOperatorLocationForm.trigger(field.field.name);
                    }}
                    defaultValue={field.field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        aria-label="User state"
                        data-cy="operator-location-state-select"
                        onBlur={() =>
                          NetworkOperatorLocationForm.trigger(field.field.name)
                        }
                      >
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {STATES.map((stat) => (
                        <SelectItem
                          key={stat}
                          value={stat}
                          data-cy={`operator-location-state-${stat}`}
                        >
                          {stat}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={NetworkOperatorLocationForm.control}
              name="postalcode"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>POSTAL CODE *</FormLabel>
                  <FormControl>
                    <Input
                      id="postalcode"
                      placeholder="Postal Code"
                      data-cy="postalCodeInput"
                      type="text"
                      aria-label="Postal Code"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={NetworkOperatorLocationForm.control}
              name="country"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>COUNTRY *</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      NetworkOperatorLocationForm.setValue(
                        field.field.name,
                        value,
                      );
                      NetworkOperatorLocationForm.trigger(field.field.name);
                    }}
                    defaultValue={field.field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        aria-label="Operator country"
                        data-cy="operator-location-country-select"
                        onBlur={() =>
                          NetworkOperatorLocationForm.trigger(field.field.name)
                        }
                      >
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {COUNTRIES.map((country) => (
                        <SelectItem
                          key={country}
                          value={country}
                          data-cy={`operator-location-country-select-${country}`}
                        >
                          {country}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="grid grid-cols-3 gap-2 grid-row-1">
            <FormField
              control={NetworkOperatorLocationForm.control}
              name="lat"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>LATTITUDE</FormLabel>
                  <FormControl>
                    <Input
                      id="lat"
                      placeholder="Lattitude"
                      data-cy="lattitudeInput"
                      type="text"
                      aria-label="Lattitude"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={NetworkOperatorLocationForm.control}
              name="lon"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>LONGITUDE</FormLabel>
                  <FormControl>
                    <Input
                      id="lon"
                      placeholder="Longitude"
                      data-cy="longitudeInput"
                      type="text"
                      aria-label="Longitude"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={NetworkOperatorLocationForm.control}
              name="elevation"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>ELEVATION</FormLabel>
                  <FormControl>
                    <Input
                      id="elevation"
                      placeholder="Elevation"
                      data-cy="elevationInput"
                      type="text"
                      aria-label="Elevation"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          {renderFooter()}
        </form>
      </Form>
    </ScrollArea>
  );
}

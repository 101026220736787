import React, { useEffect, useRef, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../popover/Popover";
import { cn } from "src/utils/cn";

interface ClickableProps {
  icon?: React.ReactNode;
  header?: React.ReactNode;
  content?: React.ReactNode;
  side?: "top" | "bottom" | "left" | "right";
  className?: string;
  dataCy?: string;
  openOnHover?: boolean;
}

const ClickableTooltip: React.FC<ClickableProps> = ({
  icon,
  header,
  content,
  side = "top",
  className,
  openOnHover = false,
  dataCy,
}) => {
  const handleCloseTooltip = () => {
    setIsOpen(false);
  };

  const handleOpenTooltip = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (
      contentRef.current &&
      !(contentRef.current as any).contains(e.target) &&
      !(triggerRef.current as any).contains(e.target)
    ) {
      handleCloseTooltip();
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);
  const triggerRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  const tooltipHeader = (
    <div className="flex justify-between items-center px-3 py-1.5 font-semibold text-xs bg-[#F0F0F0]">
      {header}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        className="cursor-pointer"
        data-cy="closeTooltipBtn"
        onClick={handleCloseTooltip}
      >
        <path
          d="M11.0974 0.911345C11.0225 0.836342 10.9336 0.776838 10.8358 0.736238C10.7379 0.695638 10.633 0.67474 10.527 0.67474C10.421 0.67474 10.3161 0.695638 10.2183 0.736238C10.1204 0.776838 10.0315 0.836342 9.95662 0.911345L6.00033 4.85955L2.04403 0.903254C1.96912 0.82835 1.8802 0.768932 1.78233 0.728394C1.68446 0.687857 1.57957 0.666992 1.47364 0.666992C1.36771 0.666992 1.26282 0.687857 1.16495 0.728394C1.06708 0.768932 0.978158 0.82835 0.903254 0.903254C0.82835 0.978158 0.768932 1.06708 0.728394 1.16495C0.687857 1.26282 0.666992 1.36771 0.666992 1.47364C0.666992 1.57957 0.687857 1.68446 0.728394 1.78233C0.768932 1.8802 0.82835 1.96912 0.903254 2.04403L4.85955 6.00033L0.903254 9.95662C0.82835 10.0315 0.768932 10.1205 0.728394 10.2183C0.687857 10.3162 0.666992 10.4211 0.666992 10.527C0.666992 10.6329 0.687857 10.7378 0.728394 10.8357C0.768932 10.9336 0.82835 11.0225 0.903254 11.0974C0.978158 11.1723 1.06708 11.2317 1.16495 11.2723C1.26282 11.3128 1.36771 11.3337 1.47364 11.3337C1.57957 11.3337 1.68446 11.3128 1.78233 11.2723C1.8802 11.2317 1.96912 11.1723 2.04403 11.0974L6.00033 7.1411L9.95662 11.0974C10.0315 11.1723 10.1205 11.2317 10.2183 11.2723C10.3162 11.3128 10.4211 11.3337 10.527 11.3337C10.6329 11.3337 10.7378 11.3128 10.8357 11.2723C10.9336 11.2317 11.0225 11.1723 11.0974 11.0974C11.1723 11.0225 11.2317 10.9336 11.2723 10.8357C11.3128 10.7378 11.3337 10.6329 11.3337 10.527C11.3337 10.4211 11.3128 10.3162 11.2723 10.2183C11.2317 10.1205 11.1723 10.0315 11.0974 9.95662L7.1411 6.00033L11.0974 2.04403C11.4048 1.73659 11.4048 1.21879 11.0974 0.911345Z"
          fill="#434343"
        />
      </svg>
    </div>
  );

  return (
    <Popover open={isOpen}>
      <PopoverTrigger
        className="flex gap-2 items-center justify-center"
        onClick={handleOpenTooltip}
        ref={triggerRef}
        onMouseEnter={openOnHover ? handleOpenTooltip : undefined}
        onMouseLeave={openOnHover ? handleCloseTooltip : undefined}
      >
        <div className="flex items-center justify-center">{icon}</div>
      </PopoverTrigger>
      <PopoverContent
        className={cn(
          "z-200 overflow-hidden rounded-md text-xs mx-10 text-popover-foreground shadow-tooltip animate-in fade-in-0 zoom-in-95",
          "data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
          "drop-shadow-tooltip",
          "font-secondary",
          className,
        )}
        style={{
          boxShadow:
            "0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
          zIndex: 1000,
        }}
        data-cy={dataCy}
        side={side}
        ref={contentRef}
      >
        {tooltipHeader}
        <div className="px-3 py-1.5 bg-popover">{content}</div>
      </PopoverContent>
    </Popover>
  );
};

export default ClickableTooltip;

export type ButtonProps = {
  dataCy?: string;
} & JSX.IntrinsicElements["button"];

export enum EpButtonSizes {
  SMALL = "small",
  LARGE = "large",
}

export enum EpButtonStyles {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  DELETE = "delete",
  WARNING = "warning",
  OUTLINE = "outline",
  CANCEL = "cancel",
}

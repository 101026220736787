import { ReactNode } from "react";
import { cn } from "src/utils/cn";

interface PageContainerProps {
  children: ReactNode;
  className?: string;
}

export default function PageContainer({
  children,
  className,
}: PageContainerProps) {
  const containerClassName = cn(
    `flex flex-col w-full h-fit text-gray-900 text-xl rounded-xl shadow-green mt-6 p-4 bg-white`,
    className,
  );
  return <div className={containerClassName}>{children}</div>;
}

import { zodResolver } from "@hookform/resolvers/zod";
import { DialogClose } from "@radix-ui/react-dialog";
import { ChevronRight } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import { DialogFooter } from "src/components/dialog/Dialog";

import {
  FormField,
  FormItem,
  FormControl,
  FormMessage,
  Form,
  FormLabel,
} from "src/components/form/Form";
import { Input } from "src/components/input/Input";
import ScrollArea from "src/components/scroll-area/ScrollArea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/select/Select";
import LoadingAnimation from "src/components/spinners/LoadingAnimation";
import { toast } from "src/components/toast/useToast";
import { Contact } from "src/interfaces/Contact";
import { z } from "zod";

const NetworkOperatorContactFormScheme = z.object({
  first_name: z.string().min(1, {
    message: "Please enter a valid firstname",
  }),
  last_name: z.string().min(1, {
    message: "Please enter a valid lastname",
  }),
  email: z.string().email({
    message: "Please enter a valid email address",
  }),
  phone: z.string().min(9, {
    message: "Please enter a valid phone number",
  }),
  status: z.string().min(1, { message: "Required" }),
  type: z.string().min(1, { message: "Required" }),
});

const STATUS = [
  "Active",
  "Inactive",
  "Locked",
  "Paused",
  "Pending",
  "Provision",
  "Repaired",
  "Replaced",
  "RMA",
  "Standby",
  "Suspended",
];

const TYPES = [
  "Residential",
  "Billing",
  "Sales",
  "Support",
  "Shipping",
  "Service",
];

type NetworkOperatorContactFormProps = {
  contactDetails: Contact;
  actionType: "update" | "create";
  handleNext: (contactDetails: Contact) => void;
};

export default function NetworkOperatorContactForm({
  contactDetails,
  handleNext,
  actionType,
}: NetworkOperatorContactFormProps) {
  const NetworkOperatorContactForm = useForm<
    z.infer<typeof NetworkOperatorContactFormScheme>
  >({
    resolver: zodResolver(NetworkOperatorContactFormScheme),
    mode: "onBlur",
    defaultValues: {
      ...contactDetails,
    },
  });

  const [isProcessing, setIsProcessing] = useState(false);

  const handleContactDetailsUpdate = (
    data: z.infer<typeof NetworkOperatorContactFormScheme>,
  ) => {
    const updatedDetails = {
      ...contactDetails,
      ...data,
    };
    if (actionType === "create") {
      handleNext(updatedDetails);
    } else {
      // handle update
      setIsProcessing(true);
      setTimeout(() => {
        setIsProcessing(false);
        toast({
          title: "Success",
          description: (
            <>
              Updated successfully
              <br />
            </>
          ),
        });
      }, 1200);
    }
  };

  function renderFooter() {
    if (actionType === "create") {
      return (
        <DialogFooter className="mt-4 flex justify-between">
          <DialogClose asChild>
            <EpButton
              dataCy="modalCancel"
              styling={EpButtonStyles.CANCEL}
              aria-label="cancel"
            >
              Cancel
            </EpButton>
          </DialogClose>
          <EpButton
            type="submit"
            dataCy="contactFormNextStep"
            aria-label="Next Step"
          >
            Next <ChevronRight aria-hidden="true" className="h-5 w-5" />
          </EpButton>
        </DialogFooter>
      );
    }

    return (
      <DialogFooter className="mt-4 flex justify-between">
        <EpButton type="submit" dataCy="saveBtn" aria-label="Save Details">
          {isProcessing && <LoadingAnimation />}
          {isProcessing ? "Saving" : "Save"}
        </EpButton>
        <DialogClose asChild>
          <EpButton
            dataCy="modalCancel"
            styling={EpButtonStyles.CANCEL}
            aria-label="cancel"
          >
            Cancel
          </EpButton>
        </DialogClose>
      </DialogFooter>
    );
  }

  return (
    <ScrollArea className="max-h-[calc(85vh-theme(space.52))] p-1">
      <Form {...NetworkOperatorContactForm}>
        <form
          onSubmit={NetworkOperatorContactForm.handleSubmit(
            handleContactDetailsUpdate,
          )}
          className="px-1"
        >
          <p className="font-bold text-sm">All fields are required.</p>
          {actionType === "update" ? (
            <div className="grid grid-rows-1 grid-cols-2 font-secondary text-xs mt-3">
              <span>CREATED: {contactDetails.created_at}</span>
              <span>UPDATED: {contactDetails.updated_at}</span>
            </div>
          ) : null}
          <div className="grid grid-cols-2 gap-x-2 grid-rows-3 mt-4">
            <FormField
              control={NetworkOperatorContactForm.control}
              name="first_name"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>FIRST NAME</FormLabel>
                  <FormControl>
                    <Input
                      autoFocus
                      id="first_name"
                      placeholder="First Name"
                      data-cy="firstnameInput"
                      type="text"
                      aria-label="First Name"
                      {...field.field}
                      onBlur={() => NetworkOperatorContactForm.trigger()}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={NetworkOperatorContactForm.control}
              name="last_name"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>LAST NAME</FormLabel>
                  <FormControl>
                    <Input
                      id="last_name"
                      placeholder="Last Name"
                      data-cy="lastnameInput"
                      type="text"
                      aria-label="Last Name"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={NetworkOperatorContactForm.control}
              name="email"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>EMAIL</FormLabel>
                  <FormControl>
                    <Input
                      id="email"
                      placeholder="Email Address"
                      data-cy="emailInput"
                      type="email"
                      aria-label="Email Address"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={NetworkOperatorContactForm.control}
              name="phone"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>Phone</FormLabel>
                  <FormControl>
                    <Input
                      id="phone"
                      placeholder="Phone Number"
                      data-cy="phoneNumberInput"
                      type="tel"
                      aria-label="Phone Number"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={NetworkOperatorContactForm.control}
              name="status"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>STATUS</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      NetworkOperatorContactForm.setValue(
                        field.field.name,
                        value,
                      );
                      NetworkOperatorContactForm.trigger(field.field.name);
                    }}
                    defaultValue={field.field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        aria-label="Contact status"
                        data-cy="contact-status-select"
                        onBlur={() =>
                          NetworkOperatorContactForm.trigger(field.field.name)
                        }
                      >
                        <SelectValue>{field.field.value}</SelectValue>
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {STATUS.map((stat) => (
                        <SelectItem
                          key={stat}
                          value={stat}
                          data-cy={`contact-status-${stat}`}
                        >
                          {stat}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={NetworkOperatorContactForm.control}
              name="type"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>TYPE</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      NetworkOperatorContactForm.setValue(
                        field.field.name,
                        value,
                      );
                      NetworkOperatorContactForm.trigger(field.field.name);
                    }}
                    value={field.field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        aria-label="Contact type"
                        data-cy="contact-type-select"
                        onBlur={() =>
                          NetworkOperatorContactForm.trigger(field.field.name)
                        }
                      >
                        <SelectValue>{field.field.value}</SelectValue>
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {TYPES.map((stat) => (
                        <SelectItem
                          key={stat}
                          value={stat}
                          data-cy={`contact-type-${stat}`}
                        >
                          {stat}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          {renderFooter()}
        </form>
      </Form>
    </ScrollArea>
  );
}

import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import LoadingAnimation from "src/components/spinners/LoadingAnimation";
import { z } from "zod";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";
import {
  FormField,
  FormItem,
  FormControl,
  FormMessage,
  Form,
} from "src/components/form/Form";
import { Input } from "src/components/input/Input";
import IconButton from "src/components/buttons/IconButton";
import WarningIcon from "src/components/icons/WarningIcon";
import { toast } from "src/components/toast/useToast";
import { NetworkOperator } from "../list/NetworkOperatorsList";

interface RemoveNetworkOperatorDialogProps {
  operator: NetworkOperator;
  removeNetworkOperator: (operator: NetworkOperator) => void;
}

const RemoveNetworkOperatorFormSchema = z
  .object({
    enteredOperatorName: z.string(),
    operatorName: z.string(),
  })
  .refine((data) => data.operatorName === data.enteredOperatorName, {
    message: "Entered operator name doesn’t match",
    path: ["enteredOperatorName"],
  });

export default function RemoveNetworkOperatorDialog({
  operator,
  removeNetworkOperator,
}: RemoveNetworkOperatorDialogProps) {
  const form = useForm<z.infer<typeof RemoveNetworkOperatorFormSchema>>({
    resolver: zodResolver(RemoveNetworkOperatorFormSchema),
    mode: "onBlur",
    defaultValues: {
      operatorName: operator.name,
    },
  });

  const handleCloseDialog = () => {
    setIsDialogOpened(!isDialogOpened);
  };

  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  function handleremoveNetworkOperator(
    data: z.infer<typeof RemoveNetworkOperatorFormSchema>,
  ) {
    setIsLoading(true);
    removeNetworkOperator(operator);
    setTimeout(() => {
      toast({
        title: "Success",
        description: (
          <>
            {data.enteredOperatorName} Removed
            <br />
          </>
        ),
      });
      setIsLoading(false);
      handleCloseDialog();
    }, 1500);
  }

  useMemo(() => form.reset(), [isDialogOpened]);

  return (
    <Dialog open={isDialogOpened} onOpenChange={handleCloseDialog}>
      <DialogTrigger asChild>
        <IconButton
          name="remove"
          dataCy="removeNetworkOperatorActionBtn"
          tooltipText={`Remove Network Operator`}
          aria-label={`Remove Network Operator`}
        />
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-[425px] font-base"
        dataCy="removeNetworkOperatorDialogCnt"
      >
        <DialogHeader dataCy="removeNetworkOperatorDialogHeader" borderless>
          <DialogTitle dataCy="removeNetworkOperatorDialogTitle">
            Remove Operator
          </DialogTitle>
        </DialogHeader>
        <p className="text-sm flex items-center gap-2 text-[#434343] -mt-4">
          <WarningIcon fill="#EC0000" />
          Are you sure you want to remove {operator.name}?
        </p>
        <p className="text-sm text-red-600 font-semibold">
          Enter <span className="text-black">{operator.name}</span> below to
          remove the account.
        </p>
        <div className="flex flex-col w-full">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleremoveNetworkOperator)}
              className="flex flex-col w-full"
            >
              <FormField
                control={form.control}
                name="enteredOperatorName"
                render={(field) => (
                  <FormItem className="w-full pr-2 h-10">
                    <FormControl>
                      <Input
                        id="enteredOperatorName"
                        placeholder="Enter Operator Name"
                        data-cy="enteredOperatorNameInput"
                        type="text"
                        aria-label="Operator Name"
                        {...field.field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>

        <DialogFooter>
          <EpButton
            styling={EpButtonStyles.DELETE}
            dataCy="removeNetworkOperatorBtn"
            aria-label="Remove Operator"
            className="flex gap-1"
            type="submit"
            onClick={form.handleSubmit(handleremoveNetworkOperator)}
            disabled={isLoading}
          >
            {isLoading && <LoadingAnimation />}
            {isLoading ? "Processing" : "Remove Operator"}
          </EpButton>
          <DialogClose asChild className="flex gap-2">
            <EpButton
              type="button"
              styling={EpButtonStyles.CANCEL}
              dataCy="cancelBtn"
              aria-label="Cancel"
            >
              Cancel
            </EpButton>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

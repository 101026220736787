import { useFormContext } from "react-hook-form";
import { cn } from "../../utils/cn";

interface LoginInputProps {
  name: string;
  id: string;
  error?: string;
  type?: "password";
}

const LoginInput: React.FC<LoginInputProps> = ({ name, id, type, error }) => {
  const { register } = useFormContext();
  const classInfo =
    type === "password" ? "text-5xl pb-2 tracking-tighter" : "text-base";
  return (
    <>
      <input
        type={type}
        id={id}
        aria-invalid={!!error}
        aria-errormessage={`${name}Error`}
        autoComplete="on"
        className={cn(
          `text-[#6D6D6D] pl-3 bg-white border rounded-2xl h-14 w-full md:w-96 ${classInfo}`,
          error ? "border-[#EC0000] border-2" : "border-secondary-font",
        )}
        {...register(name, { required: true })}
      />
      <span
        id={`${name}Error`}
        className="h-4 text-[#EC0000] text-xs text-start"
        hidden={!error}
      >
        {error}
      </span>
    </>
  );
};

export default LoginInput;

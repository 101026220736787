import { Outlet } from "react-router-dom";
import Logo from "/public/QwkNetLogo.png";
import ScrollArea from "src/components/scroll-area/ScrollArea";
import ProviderIcon from "src/components/icons/ProviderIcon";
import Footer from "../Footer";

const AnonymousLayout = () => {
  return (
    <>
      <ScrollArea className="h-[calc(100vh-50px)]" data-cy="mainScrollArea">
        <div className="flex flex-col items-center justify-center mt-44 lg:mt-56 bg-main-content">
          <div className="flex justify-center items-center h-full gap-8 lg:gap-0 flex-col lg:flex-row">
            <header className="flex w-fulltext-neutrals-medium items-center justify-start sm:pr-5 md:pr-10 lg:pr-20">
              <h1 className="md:min-w-[307px] lg:min-w-[384px] -ml-6 md:ml-0">
                <span className="w-full flex justify-end align-bottom items-center text-primary-color text-4xl md:text-5xl font-extrabold">
                  <ProviderIcon
                    className="object-cover mr-1 fill-primary-color h-14 md:h-20"
                    height="72"
                    width="75"
                  />
                  Provider
                </span>
                <span className="w-full flex justify-end align-top text-lg md:text-2xl text-primary-font font-medium mt-[-12px]">
                  Portal
                </span>
              </h1>
            </header>
            <main
              id="mainContent"
              className="flex flex-col w-full bg-main-content lg:px-20 lg:border-l-[3px] lg:border-neutrals-light"
            >
              <div className="flex w-full h-full text-neutrals-medium items-center justify-left">
                <Outlet />
              </div>
            </main>
          </div>

          <div className="w-full flex justify-center items-center pb-0 mt-10">
            <img src={Logo} className="object-cover h-12 mt-8" alt="Logo" />
          </div>
        </div>
      </ScrollArea>
      <Footer />
    </>
  );
};
export default AnonymousLayout;

import { SVGProps } from "react";

const ReportsIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const defaultFill = "#434343";
  const fill = props.fill || defaultFill;
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill={fill}
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.09978 9.36833C7.93572 9.53219 7.71332 9.62423 7.48145 9.62423C7.24957 9.62423 7.02718 9.53219 6.86311 9.36833L3.56261 6.069C3.39855 5.90485 3.3064 5.68225 3.30646 5.45017C3.30651 5.21809 3.39876 4.99553 3.56291 4.83146C3.72705 4.66739 3.94965 4.57525 4.18174 4.5753C4.41382 4.57536 4.63638 4.6676 4.80045 4.83175L7.48145 7.51275L10.1624 4.83175C10.3274 4.67228 10.5484 4.58398 10.7778 4.58587C11.0072 4.58775 11.2267 4.67967 11.389 4.84183C11.5514 5.00398 11.6435 5.2234 11.6456 5.45282C11.6477 5.68225 11.5596 5.90332 11.4003 6.06842L8.10036 9.36892L8.09978 9.36833Z"
        />
      </g>
    </svg>
  );
};

export default ReportsIcon;

import React, { HTMLProps } from "react";
import { cn } from "src/utils/cn";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../tooltip/Tooltip";

interface IconProps extends HTMLProps<HTMLButtonElement> {
  name: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  tooltipText?: string;
  tooltipContentClassName?: string;
  tooltipHeader?: React.ReactNode;
  className?: string;
  dataCy?: string;
  ariaLabel?: string;
  disabled?: boolean;
  hidden?: boolean;
}

const iconColorMap: { [name: string]: string } = {
  "view-file":
    "bg-[#9F44C8] hover:bg-[#7E32AB] active:bg-[#642294] disabled:bg-gray",
  send: "bg-[#2E9BFF] hover:bg-[#1873DB] active:bg-[#0853BD] disabled:bg-gray",
  activate:
    "bg-[#488B5B] hover:bg-[#3A7848] active:bg-[#31663B] disabled:bg-gray",
  remove:
    "bg-[#EC0000] hover:bg-[#C40000] active:bg-[#A80000] disabled:bg-gray",
  edit: "bg-[#FF7A00] hover:bg-[#DB5F00] active:bg-[#BD4800] disabled:bg-gray",
  "mark-as-installed":
    "bg-[#A8CC1A] hover:bg-[#83AB0A] active:bg-[#689400] disabled:bg-gray",
  "mark-as-uninstalled":
    "bg-[#7AB8B1] hover:bg-[#458F87] active:bg-[#458F87] disabled:bg-gray",
  ssh: "bg-[#F366AA] hover:bg-[#D14D86] active:bg-[#B53A75] disabled:bg-gray",
  "reset-device":
    "bg-[#F9CB28] hover:bg-[#D6A213] active:bg-[#B87F04] disabled:bg-gray",
  unlink:
    "bg-[#A71F13] hover:bg-[#8F0A06] active:bg-[#7A0000] disabled:bg-gray",
  "check-network-connection":
    "bg-[#488B5B] hover:bg-[#3A7848] active:bg-[#31663B] disabled:bg-gray",
  change:
    "bg-[#50EA7D] hover:bg-[#3BCC5F] active:bg-[#27AB42] disabled:bg-gray",
  mail: "bg-[#1D19E0] hover:bg-[#0808C2] active:bg-[#0000A6] disabled:bg-gray",
  "open-email":
    "bg-[#1D19E0] hover:bg-[#0808C2] active:bg-[#0000A6] disabled:bg-gray",
  pressed:
    "bg-[#42D4F4] hover:bg-[#2CADD4] active:bg-[#1B89B5] disabled:bg-gray",
  color: "bg-[#A95ED1] hover:bg-[#A75ED1] active:bg-[#894AB5] disabled:bg-gray",
  shapes:
    "bg-[#2D928C] hover:bg-[#1F7D72] active:bg-[#146B5D] disabled:bg-gray",
  "virtual-assistant":
    "bg-[#4C5878] hover:bg-[#3D4666] active:bg-[#333959] disabled:bg-gray",
  dollar:
    "bg-[#6D6D6D] hover:bg-[#615A5A] active:bg-[#4D4848] disabled:bg-gray",
  cancel:
    "bg-[#7AB8B1] hover:bg-[#71ACA5] active:bg-[#458F87] disabled:bg-gray",
  "open-file":
    "bg-[#416661] hover:bg-[#416661] active:bg-[#416661] disabled:bg-gray",
  add: "bg-[#DBDBDB] hover:bg-[#B5B5B5] active:bg-[#908E8E] disabled:bg-gray focus:outline-2 focus:outline-offset-0 outline-[#434343]",
  update:
    "bg-[#0075E1] hover:bg-[#016AB7] active:bg-[#0260B7] disabled:bg-gray",
  expand:
    "bg-transparent hover:bg-transparent active:bg-transparent disabled:bg-gray",
  collapse:
    "bg-transparent hover:bg-transparent active:bg-transparent disabled:bg-gray",
  review:
    "bg-[#B71074] hover:bg-[#A10C66] active:bg-[#8C0A5C] disabled:bg-gray",
  refresh:
    "bg-[#488B5B] hover:bg-[#3A7848] active:bg-[#31663B] disabled:bg-gray",
  copy: "bg-[#42D4F4] hover:bg-[#3EBFDC] active:bg-[#2EA4BE] disabled:bg-gray",
};

const IconButton = React.forwardRef<HTMLButtonElement, IconProps>(
  (
    {
      name,
      onClick,
      tooltipText = undefined,
      tooltipContentClassName = undefined,
      tooltipHeader = undefined,
      className,
      dataCy,
      ariaLabel,
      disabled,
      hidden,
      type,
    },
    ref,
  ) => {
    const disability = disabled
      ? "bg-disabled-button-bg text-disabled-button-text"
      : `${iconColorMap[name]} hover:shadow-icon-enabled focus:outline focus:outline-2 focus:outline-offset-1 focus:outline-slate-500`;

    const iconButton = (
      <button
        className={cn(
          "rounded-[7.2px] h-6 w-6 p-1 overflow-hidden",
          disability,
          className,
        )}
        ref={ref}
        data-cy={dataCy}
        aria-label={ariaLabel}
        disabled={disabled}
        hidden={hidden}
        type={type as JSX.IntrinsicElements["button"]["type"]}
        {...(onClick ? { onClick } : {})}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25}>
          <use href={`/IconButtons.svg#${name}`} />
        </svg>
      </button>
    );
    return tooltipText ? (
      <TooltipProvider delayDuration={500} skipDelayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>{iconButton}</TooltipTrigger>
          <TooltipContent
            className={tooltipContentClassName}
            header={tooltipHeader}
          >
            <p>{tooltipText}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    ) : (
      iconButton
    );
  },
);
export default IconButton;

import { useEffect, useState } from "react";
import { User } from "../../interfaces";
// import decodeJwt from "../../utils/decodeJwt";
// import { GetUser } from "../../api/endpoints";

export interface UserHookType {
  isInitialLoading: boolean;
  user: User | null;
  isAuthenticated: boolean;
  setUser: (user: User | null) => void;
  loginNow: (username: string, password: string) => void;
  logout: () => void;
  error: string | null;
}

export const useUser = (): UserHookType => {
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [errorText, setErrorText] = useState("");

  // const errorHandlers: ApiErrorHandlers = {
  //   409: async (response) => {
  //     if (response) {
  //       try {
  //         const copiedResponse = response.clone();
  //         const body = await copiedResponse.json();
  //         setErrorText(body.message);
  //         toast({
  //           title: "An Error Occured",
  //           description: (
  //             <>
  //               <span className="text-[#EC0000]">{body.message}</span>
  //             </>
  //           ),
  //         });
  //       } catch (authError) {
  //         setErrorText("Server Error");
  //         throw Error(response.statusText);
  //       }
  //     }
  //   },
  // };

  useEffect(() => {
    // const checkToken = async () => {
    //   const token = localStorage.getItem("token");
    //   if (token) {
    //     const decodedToken = decodeJwt(token) as
    //       | { exp: number; iat: number; user_id: string }
    //       | undefined;
    //     if (decodedToken && decodedToken?.user_id) {
    //       const isExpired =
    //         decodedToken?.exp && Date.now() >= decodedToken?.exp * 1000;
    //       if (!isExpired) {
    //         const data = await GetUser(decodedToken.user_id);
    //         setUser(data.data.user);
    //         setIsInitialLoading(false);
    //         return;
    //       }
    //     }
    //   }
    //   localStorage.removeItem("token");
    setIsInitialLoading(false);
    // };
    // checkToken();
  }, [isInitialLoading]);

  useEffect(() => {
    if (localStorage.getItem("token") === "mock_token_for_testing") {
      setUser({
        id: "2ff2211c-7f8d-11ec-857b-5737574e3930",
        auth_location: "LDAP",
        contact_id: "2ff2211c-7f8d-11ec-857b-5737574e3930",
        is_subscriber: false,
        roles: ["ADMIN"],
        status: "ACTIVE",
        username: "admin",
        created_at: "2022-01-27 09:21:31",
        updated_at: "2024-03-04 12:14:28",
        default_url_management_portal: "/dashboard#/provider/list",
        can_review: false,
        force_password: true,
        default_url_subscriber_portal: "",
        messaging: "",
        answered_security_questions: true,
        force_username: false,
      });
      setIsAuthenticated(true);
    }
  }, []);

  const loginNow = async (username: string, password: string) => {
    console.log("username", username);
    console.log("password", password);
    setErrorText("");

    setIsAuthenticated(true); // This needs to be updated in the future
    setUser({
      id: "2ff2211c-7f8d-11ec-857b-5737574e3930",
      auth_location: "LDAP",
      contact_id: "2ff2211c-7f8d-11ec-857b-5737574e3930",
      is_subscriber: false,
      roles: ["ADMIN"],
      status: "ACTIVE",
      username: "admin",
      created_at: "2022-01-27 09:21:31",
      updated_at: "2024-03-04 12:14:28",
      default_url_management_portal: "/dashboard#/provider/list",
      can_review: false,
      force_password: true,
      default_url_subscriber_portal: "",
      messaging: "",
      answered_security_questions: true,
      force_username: false,
    });
    // Not using useQuery here to avoid caching in case the user reset their password or something in between.
    // Add useQuery here on an interval of ~58 minutes to prevent getting logged out when the token expires?
    // const data = await UserLogin(username, password, { errorHandlers });
    localStorage.setItem("token", "mock_token_for_testing");
  };

  const logout = async () => {
    setUser(null);
    localStorage.removeItem("token");
  };

  return {
    user,
    setUser,
    isAuthenticated,
    logout,
    loginNow,
    isInitialLoading,
    error: errorText,
  };
};

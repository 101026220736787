import { SVGProps } from "react";

const LayoutIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.75 1.5H1.25C1.05116 1.50023 0.860528 1.57932 0.719926 1.71993C0.579324 1.86053 0.500232 2.05116 0.5 2.25V14C0.500232 14.1988 0.579324 14.3895 0.719926 14.5301C0.860528 14.6707 1.05116 14.7498 1.25 14.75H14.75C14.9488 14.7498 15.1395 14.6707 15.2801 14.5301C15.4207 14.3895 15.4998 14.1988 15.5 14V2.25C15.4998 2.05116 15.4207 1.86053 15.2801 1.71993C15.1395 1.57932 14.9488 1.50023 14.75 1.5ZM14.5 2.5V4.5H1.5V2.5H14.5ZM1.5 5.5H7.5V13.75H1.5V5.5ZM8.5 13.75V5.5H14.5V13.75H8.5Z"
        fill="inherit"
      />
    </svg>
  );
};

export default LayoutIcon;

import { SVGProps } from "react";

// Pages
import Dashboard from "../pages/dashboard/Dashboard";
import Login from "../pages/login/Login";
import GlobalServices from "src/pages/global-services";
import UserProfile from "src/pages/profile";
import Subscribers from "src/pages/subscribers";
import NetworkOperator from "src/pages/network-operator";
import ExternalConfiguration from "src/pages/manage/external-configuration";
import Approval from "src/pages/approval/Approval";
import SubscriptionHistory from "src/pages/reports/subscription-history";
import WebhookHistory from "src/pages/reports/webhook-history";
import Webhooks from "src/pages/webhooks/Webhooks";

// Icons
import DevicesIcon from "../components/icons/DevicesIcon";
import GlobalServicesIcon from "src/components/icons/GlobalServicesIcon";
import ManageIcon from "src/components/icons/ManageIcon";
import CheckIcon from "src/components/icons/CheckIcon";
import UsersIcon from "src/components/icons/UsersIcon";
import UserProfileIcon from "src/components/icons/UserProfileIcon";
import ReportsIcon from "src/components/icons/ReportsIcon";
import WebhooksIcon from "src/components/icons/WebhooksIcon";
import OperatorIcon from "src/components/icons/OperatorIcon";

export const menuHierarchy: { [key: string]: string[] } = {
  root: [
    "approval",
    "manage",
    "dashboard",
    "global-services",
    "reports",
    "webhooks",
    "network-operator",
    "subscribers",
  ],
  manage: ["external-configuration"],
  reports: ["subsription-history", "webhook-history"],
};

export interface PageConfig {
  label: string;
  title: string;
  component?: React.FC;
  path: string;
  icon?: React.FC<SVGProps<SVGSVGElement>>;
}

export const publicPages: { [key: string]: PageConfig } = {
  login: {
    label: "Login",
    title: "Login",
    component: Login,
    path: "/login",
  },
};

export const protectedPages: { [key: string]: PageConfig } = {
  manage: {
    label: "Manage",
    title: "Manage",
    icon: ManageIcon,
    path: "/manage",
  },
  "network-operator": {
    label: "Network Operator",
    title: "Network Operator",
    component: NetworkOperator,
    icon: OperatorIcon,
    path: "/network-operator",
  },
  approval: {
    label: "Approval",
    title: "Approval",
    component: Approval,
    path: "/approval",
    icon: CheckIcon,
  },
  reports: {
    label: "Reports",
    title: "Reports",
    icon: ReportsIcon,
    path: "/reports",
  },
  "subsription-history": {
    label: "Subscription History",
    title: "Subscription History",
    component: SubscriptionHistory,
    path: "/reports/subsription-history",
  },
  "webhook-history": {
    label: "Webhook History",
    title: "Webhook History",
    component: WebhookHistory,
    path: "/reports/webhook-history",
  },
  dashboard: {
    label: "Dashboard",
    title: "Dashboard",
    component: Dashboard,
    path: "/dashboard",
    icon: DevicesIcon,
  },
  // about: {
  //   label: "About",
  //   title: "About",
  //   component: About,
  //   icon: AboutIcon,
  //   path: "/about",
  // },
  "global-services": {
    label: "Global Services",
    title: "Global Services",
    component: GlobalServices,
    icon: GlobalServicesIcon,
    path: "/global-services",
  },
  subscribers: {
    label: "Subscribers",
    title: "Subscribers",
    component: Subscribers,
    icon: UsersIcon,
    path: "/subscribers",
  },
  profile: {
    label: "User Profile",
    title: "User Profile",
    component: UserProfile,
    icon: UserProfileIcon,
    path: "/profile",
  },
  webhooks: {
    label: "Webhooks",
    title: "Webhooks",
    component: Webhooks,
    icon: WebhooksIcon,
    path: "/webhooks",
  },
  "external-configuration": {
    label: "External Configuration",
    title: "External Configuration",
    component: ExternalConfiguration,
    path: "/manage/external-configuration",
  },
};

import { useRef } from "react";
import EpButton from "../buttons/EpButton";
import { Label } from "../label/Label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../select/Select";

type SearchFiltererProps = {
  setSelectedClass: (value: string | undefined) => void;
  setSelectedProvider: (value: string | undefined) => void;
};

export default function SearchFilterer({
  setSelectedClass,
  setSelectedProvider,
}: SearchFiltererProps) {
  const selectedClassValueRef = useRef<string | null>(null);
  const selectedProviderValueRef = useRef<string | null>(null);

  function handleSearch() {
    if (selectedClassValueRef.current) {
      setSelectedClass(selectedClassValueRef.current);
    }

    if (selectedProviderValueRef.current) {
      setSelectedProvider(selectedProviderValueRef.current);
    }
  }

  return (
    <div className="flex flex-col sm:flex-row items-start sm:items-end gap-4 mt-6">
      <div>
        <Label
          htmlFor="class_selector"
          className="uppercase text-xs text-neutrals-form-gray-dark"
        >
          Class
        </Label>
        <Select
          onValueChange={(value) => {
            selectedClassValueRef.current = value;
          }}
        >
          <SelectTrigger
            id="class_selector"
            data-cy="filterClass"
            className="w-[300px]"
          >
            <SelectValue placeholder="All Approvals" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem data-cy="allApprovals" value="All Approvals">
                All Approvals
              </SelectItem>
              <SelectItem data-cy="service" value="Service">
                Service
              </SelectItem>
              <SelectItem data-cy="servicePlan" value="Service Plan">
                Service Plan
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <div>
        <Label
          htmlFor="providers_selector"
          className="uppercase text-xs text-neutrals-form-gray-dark"
        >
          Providers
        </Label>
        <Select
          onValueChange={(value) => {
            selectedProviderValueRef.current = value;
          }}
        >
          <SelectTrigger
            id="providers_selector"
            data-cy="filterProviders"
            className="w-[300px]"
          >
            <SelectValue placeholder="All Providers" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem data-cy="allProviders" value="All Providers">
                All Providers
              </SelectItem>
              <SelectItem data-cy="devLabProovider" value="Dev Lab Provider">
                Dev Lab Provider
              </SelectItem>
              <SelectItem data-cy="ent" value="Ent">
                Ent
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <EpButton dataCy="searchButton" onClick={handleSearch} className="py-3.5">
        Search
      </EpButton>
    </div>
  );
}

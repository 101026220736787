import Icon from "src/components/buttons/Icon";
import ClickableTooltip from "src/components/clickable-tooltip/ClickableTooltip";
import { Search } from "src/components/input/Input";
import AddServiceDialog from "../actions/AddServiceDialog";

interface GlobalServicesToolbarProps {
  setSearchFilter: (searchFilter: string) => void;
  clearSearch: () => void;
}

export default function GlobalServicesToolbar({
  setSearchFilter,
  clearSearch,
}: GlobalServicesToolbarProps) {
  const handleSearch = (value: string) => {
    if (value !== "") {
      setSearchFilter(value);
    } else {
      clearSearch();
    }
  };

  return (
    <div
      className="flex justify-between items-center"
      data-cy="globalServicesToolbarSection"
    >
      <div className="flex items-center max-w-[540px] gap-2">
        <Search
          placeholder="Search"
          className="search min-w-[305px]"
          ctaCallback={handleSearch}
          closeCallback={clearSearch}
          dataCy="searchGlobalServiceInput"
        ></Search>
        <ClickableTooltip
          header={<p>Search Terms</p>}
          icon={
            <Icon
              size={30}
              className="pt-[4px]"
              name="exclamation"
              data-cy="searchInfoIcon"
            />
          }
          content={
            "Search for a global service by providing a global service name"
          }
          dataCy="searchGlobalServiceTooltip"
          className="w-[290px]"
        />
      </div>
      <AddServiceDialog />
    </div>
  );
}

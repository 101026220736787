import { useState } from "react";
import EpButton from "src/components/buttons/EpButton";
import IconButton from "src/components/buttons/IconButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";
import { Subscriber } from "../list/SubscribersList";
import DeviceInfo from "../view-details/Info";
import TabMenu from "src/components/tabMenu";
import { TabConfig } from "src/components/tabMenu/TabMenu";
import DeviceFlows from "../view-details/Flows";

type SubscriberDetailsDialogProps = {
  subscriber: Subscriber;
};

export default function SubscriberDetailsDialog({
  subscriber,
}: SubscriberDetailsDialogProps) {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const subscriberDetailTabs: TabConfig[] = [
    {
      key: "info",
      title: "Info",
      titleAttribute: "Device Info",
      component: <DeviceInfo />,
      dataCy: "deviceInfoTab",
    },
    {
      key: "flows",
      title: "Flows",
      titleAttribute: "Device Flows",
      component: <DeviceFlows />,
      dataCy: "deviceFlowsTab",
    },
  ];

  return (
    <Dialog
      open={isDialogOpened}
      onOpenChange={(value) => setIsDialogOpened(value)}
    >
      <DialogTrigger>
        <IconButton
          name="view-file"
          dataCy="subscriberDetailsActionBtn"
          tooltipText={`View details`}
          ariaLabel={`View details`}
        />
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-4xl font-base"
        dataCy="subscriberDetailsDialogCnt"
      >
        <DialogHeader dataCy="subscriberDetailsDialogHeader" borderless>
          <DialogTitle dataCy="subscriberDetailsDialogTitle">
            View {subscriber.name} Details
          </DialogTitle>
        </DialogHeader>
        <TabMenu
          config={subscriberDetailTabs}
          dataCy="subscriberDetailTabMenu"
        ></TabMenu>
        <DialogFooter>
          <DialogClose asChild className="flex gap-2 ml-auto">
            <EpButton
              type="button"
              styling={EpButtonStyles.CANCEL}
              dataCy="cancelBtn"
              aria-label="cancel"
            >
              Cancel
            </EpButton>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

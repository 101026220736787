import Logo from "/public/QwkNetLogo.png";
import MainMenuItem from "./MainMenuItem";
import TreeMenuItem from "./TreeMenuItem";
import Footer from "../Footer";
import {
  NavigationMenu,
  NavigationMenuList,
} from "../../components/navigation-menu/NavigationMenu";
import { menuHierarchy, protectedPages } from "../../routes";

export default function Sidebar() {
  return (
    <aside className="w-32 flex-shrink-0 flex flex-col">
      <div className="flex-grow">
        <div className="my-4 mt-6 aspect-[98/50] mb-1.5 px-4 flex justify-center items-center">
          <img
            className="object-contain h-8 w-36"
            src={Logo}
            alt="Qwk net logo"
          />
        </div>
        <nav className="flex h-full text-xs py-1">
          <NavigationMenu>
            <NavigationMenuList>
              {menuHierarchy.root.map((key) => {
                const option = protectedPages[key];
                if (!option) {
                  console.log(
                    `A "${key}" element is in the menu, but it needs supplemental data in "protectedPages".`,
                  );
                  return <></>;
                }
                if (menuHierarchy[key]) {
                  return (
                    <TreeMenuItem key={key} menuKey={key} option={option} />
                  );
                }
                return <MainMenuItem key={key} menuKey={key} option={option} />;
              })}
            </NavigationMenuList>
          </NavigationMenu>
        </nav>
      </div>
      <div className="h-[50px] mt-auto">
        <Footer />
      </div>
    </aside>
  );
}

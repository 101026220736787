import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import { DialogClose } from "src/components/dialog/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/form/Form";
import { Input } from "src/components/input/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/select/Select";
import { Textarea } from "src/components/textarea/Textarea";
import { z } from "zod";
import PublishDetailsDialog from "../manage-network-operator-services/PublishNetworkOperatorServiceDialog";
import { Serviceplan } from "src/interfaces";
import PlanDetailsGlobalValues from "./PlanDetailsGlobalValues";
import { useEffect, useState } from "react";
import LoadingAnimation from "src/components/spinners/LoadingAnimation";
import ScrollArea from "src/components/scroll-area/ScrollArea";

const STATUS = [
  "Active",
  "Inactive",
  "Locked",
  "Paused",
  "Pending",
  "Provision",
  "Repaired",
  "Replaced",
  "RMA",
  "Standby",
  "Suspended",
];

const capitalTocamelCaseMapping = {
  NAME: "name",
  STATUS: "status",
  DESCRIPITON: "description",
  "EXPIRE TIME": "expire_time",
} as const;

const ManageNetworkOperatorServicePlanDetailsFormSchema = z.object({
  name: z.string().min(1, {
    message: "Please enter a valid name",
  }),
  status: z.string().nonempty({ message: "Required" }),
  id: z.string().readonly(),
  description: z.string().min(4, {
    message: "Please enter a valid description",
  }),
  expire_time: z.coerce.number(),
});

type ManageNetworkOperatorServicePlanDetailsProps = {
  servicePlan: Serviceplan;
};

export default function ManageNetworkOperatorServicePlanDetails({
  servicePlan,
}: ManageNetworkOperatorServicePlanDetailsProps) {
  const ManageNetworkOperatorServicePlanDetailsForm = useForm<
    z.infer<typeof ManageNetworkOperatorServicePlanDetailsFormSchema>
  >({
    resolver: zodResolver(ManageNetworkOperatorServicePlanDetailsFormSchema),
    mode: "onBlur",
    defaultValues: {
      ...servicePlan,
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [planDetailsGlobalValues, setPlanDetailsGlobalValues] = useState<
    Record<string, boolean>
  >({
    NAME: true,
    STATUS: true,
    DESCRIPITON: true,
    "EXPIRE TIME": true,
  });

  function handleManageDetailsSave(
    data: z.infer<typeof ManageNetworkOperatorServicePlanDetailsFormSchema>,
  ) {
    console.log({ data });
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1300);
  }

  function handleFormStateChange() {
    const defaultValues =
      ManageNetworkOperatorServicePlanDetailsForm.formState.defaultValues;
    const actualFormValues =
      ManageNetworkOperatorServicePlanDetailsForm.getValues();

    if (defaultValues) {
      const loopalbleKeys = Object.keys(capitalTocamelCaseMapping) as Array<
        keyof typeof capitalTocamelCaseMapping
      >;
      for (let i = 0; i < loopalbleKeys.length; i++) {
        const keyItem = loopalbleKeys[i];
        const camelCaseKey = capitalTocamelCaseMapping[keyItem];
        setPlanDetailsGlobalValues((originalState) => {
          return {
            ...originalState,
            [keyItem]:
              defaultValues[camelCaseKey] === actualFormValues[camelCaseKey],
          };
        });
      }
    }
  }

  function handleGlobalPlanCheckboxesValuesChange() {
    const loopalbleKeys = Object.keys(capitalTocamelCaseMapping) as Array<
      keyof typeof capitalTocamelCaseMapping
    >;
    const defaultValues = ManageNetworkOperatorServicePlanDetailsForm.formState
      .defaultValues as any;

    for (let i = 0; i < loopalbleKeys.length; i++) {
      const keyItem = loopalbleKeys[i];

      if (planDetailsGlobalValues[keyItem]) {
        const camelCaseKey = capitalTocamelCaseMapping[keyItem];
        ManageNetworkOperatorServicePlanDetailsForm.setValue(
          camelCaseKey,
          defaultValues[camelCaseKey],
        );
      }
    }
  }

  const status = ManageNetworkOperatorServicePlanDetailsForm.watch("status");
  const { isValidating } =
    ManageNetworkOperatorServicePlanDetailsForm.formState;

  useEffect(() => {
    handleFormStateChange();
  }, [status, isValidating]);

  useEffect(() => {
    handleGlobalPlanCheckboxesValuesChange();
  }, [planDetailsGlobalValues]);

  return (
    <ScrollArea className="max-h-[calc(85vh-theme(space.52))] p-1">
      <Form {...ManageNetworkOperatorServicePlanDetailsForm}>
        <div className="grid grid-rows-1 grid-cols-2 mb-4 text-neutral-500 font-secondary text-xs">
          <span>CREATED: {servicePlan.created_at}</span>
          <span>UPDATED: {servicePlan.updated_at}</span>
        </div>
        <form
          onSubmit={ManageNetworkOperatorServicePlanDetailsForm.handleSubmit(
            handleManageDetailsSave,
          )}
        >
          <div className="grid grid-cols-2 gap-2 grid-rows-1">
            <FormField
              control={ManageNetworkOperatorServicePlanDetailsForm.control}
              name="name"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>NAME</FormLabel>
                  <FormControl>
                    <Input
                      autoFocus
                      id="name"
                      placeholder="Name"
                      data-cy="nameInput"
                      type="text"
                      aria-label="Name"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={ManageNetworkOperatorServicePlanDetailsForm.control}
              name="id"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>ID</FormLabel>
                  <FormControl>
                    <Input
                      readOnly
                      id="id"
                      placeholder="ID"
                      data-cy="idInput"
                      type="text"
                      aria-label="ID"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={ManageNetworkOperatorServicePlanDetailsForm.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>DESCRIPTION *</FormLabel>
                <FormControl>
                  <Textarea
                    required
                    placeholder="Description"
                    data-cy="descriptionInput"
                    aria-label="Service Description"
                    className={"h-[50px] text-neutral-700"}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="grid grid-cols-2 gap-2 grid-rows-1 mt-4">
            <FormField
              control={ManageNetworkOperatorServicePlanDetailsForm.control}
              name="status"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>STATUS</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      ManageNetworkOperatorServicePlanDetailsForm.setValue(
                        field.field.name,
                        value,
                      );
                      ManageNetworkOperatorServicePlanDetailsForm.trigger(
                        field.field.name,
                      );
                    }}
                    defaultValue={field.field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        aria-label="Service plan status"
                        data-cy="service-plan-status-select"
                        onBlur={() =>
                          ManageNetworkOperatorServicePlanDetailsForm.trigger(
                            field.field.name,
                          )
                        }
                      >
                        <SelectValue>{field.field.value}</SelectValue>
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {STATUS.map((stat) => (
                        <SelectItem
                          key={stat}
                          value={stat}
                          data-cy={`service-plan-status-${stat}`}
                        >
                          {stat}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={ManageNetworkOperatorServicePlanDetailsForm.control}
              name="expire_time"
              render={(field) => (
                <FormItem className="w-full mb-4">
                  <FormLabel>EXPIRE TIME</FormLabel>
                  <FormControl>
                    <Input
                      id="expire_time"
                      placeholder="Expire Time"
                      data-cy="expireTimeInput"
                      type="number"
                      aria-label="Expire Time"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <span className="text-xs font-base">IMAGE</span>
          <p className="text-xs text-neutrals-form-gray-dark mt-2">
            To set an image, send the image to the Network Operator so they can
            upload it to their system.
          </p>
          <PlanDetailsGlobalValues
            planDetailsGlobalValues={planDetailsGlobalValues}
            setPlanDetailsGlobalValues={setPlanDetailsGlobalValues}
          />
          <div className="flex gap-4 items-center justify-start mt-6">
            <EpButton
              aria-label="Save updated service plan"
              dataCy="saveUpdatedServicePlanBtn"
              disabled={isLoading}
            >
              {isLoading && <LoadingAnimation />}
              {isLoading ? "Saving.." : "Save"}
            </EpButton>
            <span className="text-xs font-normal">
              <span className="font-medium mr-2">Last saved</span>{" "}
              {servicePlan.updated_at}
            </span>
          </div>
          <div className="flex items-center justify-between mt-8">
            <div className="flex justify-start gap-4 items-center border border-neutral-200 rounded-md p-2">
              <PublishDetailsDialog updatedAt={servicePlan.updated_at} />
              <span className="text-xs font-normal">
                <span className="font-medium mr-2">Last published</span>{" "}
                {servicePlan.updated_at}
              </span>
            </div>
            <DialogClose asChild className="flex gap-2 ml-auto">
              <EpButton
                type="button"
                styling={EpButtonStyles.CANCEL}
                dataCy="cancelBtn"
                aria-label="cancel"
              >
                Cancel
              </EpButton>
            </DialogClose>
          </div>
        </form>
      </Form>
    </ScrollArea>
  );
}

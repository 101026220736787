import { ReactElement, useState } from "react";

export interface TabConfig {
  key: string;
  title: string;
  titleAttribute: string;
  component: ReactElement;
  dataCy?: string;
}

interface TabMenuProps {
  config: TabConfig[];
  data?: any;
  dataCy?: string;
}

const TabMenu: React.FC<TabMenuProps> = ({ config, data, dataCy }) => {
  const [currentTab, setCurrentTab] = useState<TabConfig>(config[0]);
  return (
    <>
      <div
        className="menu fix text-[#6D6D6D] text-base font-normal border-b w-full border-[#DBDBDB]"
        data-cy={dataCy}
      >
        {config.map((tab: TabConfig, idx: number) => {
          if (currentTab.key === tab.key) {
            return (
              <a
                href="#"
                title={tab.titleAttribute}
                className="mr-5 inline-block cursor-default text-[#55857E] font-semibold border-b-2 border-[#55857E] font-secondary"
                key={`${tab.key}-${idx}`}
                data-cy={tab.dataCy ?? `${tab.key}Tab`}
              >
                {tab.title}
              </a>
            );
          }
          return (
            <a
              href="#"
              title={tab.titleAttribute}
              className="mr-5 inline-block cursor-pointer hover:text-[#55857E] hover:font-semibold font-secondary"
              onClick={() => setCurrentTab(tab)}
              key={`${tab.key}-${idx}`}
              data-cy={tab.dataCy ?? `${tab.key}Tab`}
            >
              {tab.title}
            </a>
          );
        })}
      </div>
      <div>
        {data ? (
          <currentTab.component.type
            {...currentTab.component.props}
            data={data}
          />
        ) : (
          <currentTab.component.type {...currentTab.component.props} />
        )}
      </div>
    </>
  );
};

export default TabMenu;

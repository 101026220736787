import { useRef } from "react";
import EpButton from "src/components/buttons/EpButton";
import { Label } from "src/components/label/Label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/select/Select";
import CreateWebhookDialog from "../actions/CreateWebhookDialog";

type WebhooksFiltererProps = {
  setSelectedService: (value: string | undefined) => void;
  setSelectedServicePlan: (value: string | undefined) => void;
};

export default function WebhooksFilterer({
  setSelectedService,
  setSelectedServicePlan,
}: WebhooksFiltererProps) {
  const selectedServiceValueRef = useRef<string | null>(null);
  const selectedServicePlanValueRef = useRef<string | null>(null);

  function handleSearch() {
    if (selectedServiceValueRef.current) {
      setSelectedService(selectedServiceValueRef.current);
    }

    if (selectedServicePlanValueRef.current) {
      setSelectedServicePlan(selectedServicePlanValueRef.current);
    }
  }

  return (
    <div className="flex flex-col sm:flex-row items-start sm:items-end gap-4">
      <div>
        <Label
          htmlFor="service_selector"
          className="uppercase text-xs text-neutrals-form-gray-dark"
        >
          Service
        </Label>
        <Select
          onValueChange={(value) => {
            selectedServiceValueRef.current = value;
          }}
        >
          <SelectTrigger
            id="service_selector"
            data-cy="filterService"
            className="w-[300px]"
          >
            <SelectValue placeholder="All Services" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem data-cy="allServices" value="All Services">
                All Services
              </SelectItem>
              <SelectItem data-cy="exampleService1" value="Example Service 1">
                Example Service 1
              </SelectItem>
              <SelectItem data-cy="exampleService2" value="Example Service 2">
                Example Service 2
              </SelectItem>
              <SelectItem data-cy="exampleService3" value="Example Service 3">
                Example Service 3
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <div>
        <Label
          htmlFor="service_plan_selector"
          className="uppercase text-xs text-neutrals-form-gray-dark"
        >
          SERVICE PLANS
        </Label>
        <Select
          onValueChange={(value) => {
            selectedServicePlanValueRef.current = value;
          }}
        >
          <SelectTrigger
            id="service_plan_selector"
            data-cy="filterServicePlans"
            className="w-[300px]"
          >
            <SelectValue placeholder="All Service Plans" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem data-cy="allServicePlans" value="All Service Plans">
                All Service Plans
              </SelectItem>
              <SelectItem
                data-cy="devLabProovider"
                value="Dev Lab Service Plan"
              >
                Dev Lab Service Plan
              </SelectItem>
              <SelectItem data-cy="ent" value="Ent">
                Ent
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <EpButton dataCy="searchButton" onClick={handleSearch} className="py-3.5">
        Search
      </EpButton>
      <CreateWebhookDialog />
    </div>
  );
}

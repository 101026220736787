import PageContainer from "src/components/page-container/PageContainer";
import GlobalServicesList from "./list/GlobalServicesList";

export default function GlobalServices() {
  return (
    <PageContainer>
      <GlobalServicesList />
    </PageContainer>
  );
}

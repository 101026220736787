import React from "react";
import { EpButtonSizes, EpButtonStyles } from "./constants";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../tooltip/Tooltip";
import { cn } from "src/utils/cn";

export interface EPButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  sizing?: EpButtonSizes;
  styling?: EpButtonStyles;
  disabled?: boolean;
  className?: string;
  dataCy?: string;
  tooltipText?: string;
  tooltipContentClassName?: string;
  tooltipHeader?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const EpButton = React.forwardRef<HTMLButtonElement, EPButtonProps>(
  (
    {
      children,
      sizing = EpButtonSizes.SMALL,
      styling = EpButtonStyles.PRIMARY,
      disabled,
      className,
      dataCy,
      tooltipText = undefined,
      tooltipContentClassName = undefined,
      tooltipHeader = undefined,
      type,
      onClick,
      ...props
    },
    ref,
  ) => {
    const textColor =
      styling === EpButtonStyles.PRIMARY ? "text-[#253331]" : "text-white";
    const verticalPadding =
      sizing === EpButtonSizes.LARGE ? "h-[32px]" : "py-1";
    let bgColor =
      "bg-[#D7F1DA] active:bg-[#C5DCC8] border-[#253331] border-2 focus:outline-offset-1";
    if (styling === EpButtonStyles.SECONDARY) {
      bgColor = "bg-[#416661] active:bg-[#02575C] focus:outline-offset-2 ";
    } else if (styling === EpButtonStyles.CANCEL) {
      bgColor = "bg-[#6C6C6C] active:bg-[#434343] focus:outline-offset-2";
    } else if (styling === EpButtonStyles.DELETE) {
      bgColor = "bg-[#EC0000] active:bg-[#B40909] focus:outline-offset-2";
    } else if (styling === EpButtonStyles.WARNING) {
      bgColor = "bg-[#FF7A00] active:bg-[#DD6A00] focus:outline-offset-2";
    } else if (styling === EpButtonStyles.OUTLINE) {
      bgColor =
        "bg-white active:bg-[#ECEBEB] border border-[#D0D5DD] font-primary text-primary focus:outline-offset-2";
    }

    const disability = disabled
      ? "bg-disabled-button-bg text-disabled-button-text"
      : `${bgColor} hover:shadow-small active:shadow-none`;

    const epButton = (
      <button
        className={cn(
          "text-xs font-semibold px-2 rounded-md transition duration-300 ease-in-out h-6",
          verticalPadding,
          textColor,
          "hover:outline-0 active:border-[#253331]",
          disability,
          className,
          "flex items-center gap-1",
          "focus:outline focus:outline-2 focus:outline-[#2E9BFF]",
        )}
        type={type}
        {...(onClick ? { onClick } : {})}
        disabled={disabled}
        ref={ref}
        data-cy={dataCy}
        aria-label={props["aria-label"]}
      >
        {children}
      </button>
    );

    return tooltipText ? (
      <TooltipProvider delayDuration={500} skipDelayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>{epButton}</TooltipTrigger>
          <TooltipContent
            className={tooltipContentClassName}
            header={tooltipHeader}
          >
            <p>{tooltipText}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    ) : (
      epButton
    );
  },
);

export default EpButton;

import { useState } from "react";
import IconButton from "src/components/buttons/IconButton";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";
import TabMenu, { TabConfig } from "src/components/tabMenu/TabMenu";
import { NetworkOperator } from "../list/NetworkOperatorsList";
import ManageNetworkOperatorDetails from "../manage-network-operator/ManageNetworkOperatorDetails";
import ManageNetworkOperatorContacts from "../manage-network-operator/ManageNetworkOperatorContacts";
import ManageNetworkOperatorServices from "../manage-network-operator/ManageNetworkOperatorServices";
import ManageNetworkOperatorPlans from "../manage-network-operator/ManageNetworkOperatorPlans";

type ManageNetworkOperatorDialogProps = {
  networkOperator: NetworkOperator;
};

export default function ManageNetworkOperatorDialog({
  networkOperator,
}: ManageNetworkOperatorDialogProps) {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const manageNetworkOperatorTabsConfig: TabConfig[] = [
    {
      key: "details",
      title: "Details",
      titleAttribute: "Manage Details",
      component: (
        <ManageNetworkOperatorDetails
          networkOperatorDetails={networkOperator}
        />
      ),
      dataCy: "manageDetailsTab",
    },
    {
      key: "contacts",
      title: "Contacts",
      titleAttribute: "Manage Contacts",
      component: (
        <ManageNetworkOperatorContacts
          networkOperatorDetails={networkOperator}
        />
      ),
      dataCy: "manageContactsTab",
    },
    {
      key: "services",
      title: "Services",
      titleAttribute: "Manage Services",
      component: (
        <ManageNetworkOperatorServices services={networkOperator.Services} />
      ),
      dataCy: "manageServicesTab",
    },
    {
      key: "plans",
      title: "Plans",
      titleAttribute: "Manage Plans",
      component: (
        <ManageNetworkOperatorPlans services={networkOperator.Plans} />
      ),
      dataCy: "managePlansTab",
    },
  ];

  return (
    <Dialog
      open={isDialogOpened}
      onOpenChange={(value) => setIsDialogOpened(value)}
    >
      <DialogTrigger asChild>
        <IconButton
          name="edit"
          dataCy="manageNetworkOperatorDetailsActionBtn"
          tooltipText={`Manage network operator details`}
          ariaLabel={`Manage network operator details`}
        />
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-4xl font-base"
        dataCy="manageNetworkOperatorDialogCnt"
      >
        <DialogHeader dataCy="manageNetworkOperatorDialogHeader" borderless>
          <DialogTitle dataCy="manageNetworkOperatorDialogTitle">
            Manage {networkOperator.name}
          </DialogTitle>
        </DialogHeader>
        <TabMenu
          config={manageNetworkOperatorTabsConfig}
          dataCy="manageNetworkOperatorTabMenu"
        ></TabMenu>
      </DialogContent>
    </Dialog>
  );
}

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/table/Table";

import ScrollArea from "src/components/scroll-area/ScrollArea";
import { DialogClose } from "@radix-ui/react-dialog";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import { Serviceplan } from "src/interfaces";
import { Dot } from "src/components/dot/Dot";
import ClickableTooltip from "src/components/clickable-tooltip/ClickableTooltip";
import ManageManageNetworkOperatorPlansActions from "../manage-network-operator-plans/ManageNetworkOperatorPlansActions";

export default function ManageNetworkOperatorPlans({
  services,
}: {
  services: Serviceplan[];
}) {
  const handleActionsCellClick = (
    event: React.MouseEvent<HTMLTableCellElement>,
  ) => {
    event.stopPropagation();
  };

  function renderTableBody() {
    if (services.length < 1) {
      return (
        <TableRow className="font-medium" dataCy={`noRecordsRow`}>
          <TableCell dataCy={`noRecordsTextCell`}>
            <p className="text-neutral-600 text-sm">No records found</p>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <>
        {services.map((service) => (
          <TableRow
            key={service.id}
            className="font-medium"
            dataCy={`${service.id}networkOperatorPlansRow`}
          >
            <TableCell dataCy={`${service.id}networkOperatorPlansNameCell`}>
              <div className="flex items-center gap-1">
                {service.status === "ACTIVE" ? (
                  <Dot className="h-2 w-2" variant={"active"} />
                ) : (
                  <Dot className="h-2 w-2" variant={"inactive"} />
                )}
                {service.name}
              </div>
            </TableCell>
            <TableCell
              dataCy={`${service.id}networkOperatorPlansServiceNameCell`}
            >
              {service.Service?.name}
            </TableCell>
            <TableCell
              dataCy={`${service.id}networkOperatorPlansPendingApprovalsCell`}
            >
              <ClickableTooltip
                header={<p>Has Approval Values</p>}
                icon={
                  <span className="hover:underline hover:text-blue-700">
                    (
                    {
                      service.approval_values?.description
                        ?.pending_approval_values?.length
                    }
                    ) Pending Approvals
                  </span>
                }
                content={
                  <div className="py-2">
                    <p className="mb-2">
                      Description:{" "}
                      {service.approval_values?.description?.pending_approval_values?.at(
                        0,
                      )}
                    </p>
                    <span>Published values:</span>
                    <ul
                      data-cy={`${service.id}networkOperatorPlansPendingApprovalsToolTipContent`}
                      className="flex flex-col py-1 font-base"
                    >
                      {service.approval_values.description?.published_values?.map(
                        (value: string) => {
                          return (
                            <li
                              key={value}
                              className="ml-2"
                              data-cy={`${service.id}-${value}-networkOperatorPlansPendingApprovalsToolTipContentItem`}
                            >
                              &bull;{" "}
                              <span className="font-medium">{value}</span>
                            </li>
                          );
                        },
                      )}
                    </ul>
                  </div>
                }
                dataCy={`contactInformationTooltip-${service.id}`}
                className="w-[290px]"
              />
            </TableCell>
            <TableCell
              className="text-center"
              onClick={handleActionsCellClick}
              dataCy={`${service.id}networkOperatorPlansActionsCell`}
            >
              <ManageManageNetworkOperatorPlansActions service={service} />
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  }

  return (
    <>
      <ScrollArea className="max-h-[calc(85vh-theme(space.52))] my-4 p-0">
        <Table dataCy="networkOperatorPlansTable">
          <TableHeader
            dataCy="networkOperatorPlansTableHeader"
            className="sticky top-0"
          >
            <TableRow dataCy="networkOperatorPlansTableHeaderRow">
              <TableHead className="text-left">Name</TableHead>
              <TableHead className="text-left">Service</TableHead>
              <TableHead className="text-left">Pending Approvals</TableHead>
              <TableHead className="text-center w-[90px]">Action</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody dataCy="networkOperatorPlansTableBody">
            {renderTableBody()}
          </TableBody>
        </Table>
      </ScrollArea>
      <div className="flex items-center justify-between mt-6">
        <EpButton type="button" dataCy="saveBtn" aria-label="Save">
          Save
        </EpButton>
        <DialogClose asChild className="flex gap-2 ml-auto">
          <EpButton
            type="button"
            styling={EpButtonStyles.CANCEL}
            dataCy="cancelBtn"
            aria-label="cancel"
          >
            Cancel
          </EpButton>
        </DialogClose>
      </div>
    </>
  );
}

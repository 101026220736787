import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";

import {
  FormField,
  FormItem,
  FormControl,
  FormMessage,
  Form,
  FormLabel,
} from "src/components/form/Form";
import { Input } from "src/components/input/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/select/Select";
import LoadingAnimation from "src/components/spinners/LoadingAnimation";
import { toast } from "src/components/toast/useToast";
import { useSession } from "src/hooks/session/useSession";
import { Contact } from "src/interfaces/Contact";
import { z } from "zod";

const UserContactFormScheme = z.object({
  firstname: z.string().min(1, {
    message: "Please enter a valid firstname",
  }),
  lastname: z.string().min(1, {
    message: "Please enter a valid lastname",
  }),
  email: z.string().email({
    message: "Please enter a valid email address",
  }),
  phone: z.string().min(9, {
    message: "Please enter a valid phone number",
  }),
  status: z.string().nonempty({ message: "Required" }),
  type: z.string().nonempty({ message: "Required" }),
});

const STATUS = [
  "Active",
  "Inactive",
  "Locked",
  "Paused",
  "Pending",
  "Provision",
  "Repaired",
  "Replaced",
  "RMA",
  "Standby",
  "Suspended",
];

const TYPES = [
  "Residential",
  "Billing",
  "Sales",
  "Support",
  "Shipping",
  "Service",
];

export default function UserContactForm() {
  const { useUser } = useSession();
  const { user, setUser } = useUser;

  const contact = user?.Contact
    ? user?.Contact
    : ({
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        status: "",
        type: "",
      } as Contact);

  const UserContactForm = useForm<z.infer<typeof UserContactFormScheme>>({
    resolver: zodResolver(UserContactFormScheme),
    mode: "onBlur",
    defaultValues: {
      ...contact,
    },
  });

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const handleContactDetailsUpdate = (
    data: z.infer<typeof UserContactFormScheme>,
  ) => {
    setIsProcessing(true);

    if (user) {
      setTimeout(() => {
        setUser({
          ...user,
          Contact: {
            ...contact,
            ...data,
          },
        });

        setIsProcessing(false);
        toast({
          title: "Success",
          description: (
            <>
              Contact details updated successfully!
              <br />
            </>
          ),
        });
      }, 1200);
    }
  };

  return (
    <div>
      <Form {...UserContactForm}>
        <p className="text-xs pb-4">All fields are required</p>
        <form
          onSubmit={UserContactForm.handleSubmit(handleContactDetailsUpdate)}
          className="grid grid-cols-2 grid-rows-3"
        >
          <FormField
            control={UserContactForm.control}
            name="firstname"
            render={(field) => (
              <FormItem className="w-full pr-2 mb-4">
                <FormLabel>FIRST NAME</FormLabel>
                <FormControl>
                  <Input
                    autoFocus
                    id="firstname"
                    placeholder="First Name"
                    data-cy="firstnameInput"
                    type="text"
                    aria-label="First Name"
                    {...field.field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={UserContactForm.control}
            name="lastname"
            render={(field) => (
              <FormItem className="w-full pr-2 mb-4">
                <FormLabel>LAST NAME</FormLabel>
                <FormControl>
                  <Input
                    id="lastname"
                    placeholder="Last Name"
                    data-cy="lastnameInput"
                    type="text"
                    aria-label="Last Name"
                    {...field.field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={UserContactForm.control}
            name="email"
            render={(field) => (
              <FormItem className="w-full pr-2 mb-4">
                <FormLabel>EMAIL</FormLabel>
                <FormControl>
                  <Input
                    id="email"
                    placeholder="Email Address"
                    data-cy="emailInput"
                    type="email"
                    aria-label="Email Address"
                    {...field.field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={UserContactForm.control}
            name="phone"
            render={(field) => (
              <FormItem className="w-full pr-2 mb-4">
                <FormLabel>Phone</FormLabel>
                <FormControl>
                  <Input
                    id="phone"
                    placeholder="Phone Number"
                    data-cy="phoneNumberInput"
                    type="tel"
                    aria-label="Phone Number"
                    {...field.field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={UserContactForm.control}
            name="status"
            render={(field) => (
              <FormItem className="w-full pr-2 mb-4">
                <FormLabel>STATUS</FormLabel>
                <Select
                  onValueChange={(value) => {
                    UserContactForm.setValue(field.field.name, value);
                    UserContactForm.trigger(field.field.name);
                  }}
                  defaultValue={contact?.status}
                >
                  <FormControl>
                    <SelectTrigger
                      aria-label="Contact status"
                      data-cy="contact-status-select"
                      onBlur={() => UserContactForm.trigger(field.field.name)}
                    >
                      <SelectValue />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {STATUS.map((stat) => (
                      <SelectItem
                        key={stat}
                        value={stat}
                        data-cy={`contact-status-${stat}`}
                      >
                        {stat}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={UserContactForm.control}
            name="type"
            render={(field) => (
              <FormItem className="w-full pr-2 mb-4">
                <FormLabel>TYPE</FormLabel>
                <Select
                  onValueChange={(value) => {
                    UserContactForm.setValue(field.field.name, value);
                    UserContactForm.trigger(field.field.name);
                  }}
                  defaultValue={contact?.type}
                >
                  <FormControl>
                    <SelectTrigger
                      aria-label="Contact type"
                      data-cy="contact-type-select"
                      onBlur={() => UserContactForm.trigger(field.field.name)}
                    >
                      <SelectValue />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {TYPES.map((stat) => (
                      <SelectItem
                        key={stat}
                        value={stat}
                        data-cy={`contact-type-${stat}`}
                      >
                        {stat}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>
      <EpButton
        styling={EpButtonStyles.PRIMARY}
        dataCy="updateContactDetailsBtn"
        aria-label="Update contact details"
        onClick={UserContactForm.handleSubmit(handleContactDetailsUpdate)}
        className="flex gap-1 mt-2"
        disabled={isProcessing}
      >
        {isProcessing && <LoadingAnimation />}
        {isProcessing ? "Saving" : "Save"}
      </EpButton>
    </div>
  );
}

import { SVGProps } from "react";

const DevicesIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const defaultFill = "#6D6D6D";
  const fill = props.fill || defaultFill;
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill={fill}
    >
      <path d="M12.6208 7.73863C13.0052 6.81093 13.6561 6.01804 14.4911 5.4602C15.3261 4.90236 16.3077 4.60462 17.3119 4.60462C18.3161 4.60462 19.2978 4.90236 20.1328 5.4602C20.9678 6.01804 21.6186 6.81093 22.003 7.73863C22.0844 7.93503 22.2404 8.09107 22.4368 8.17244C22.6332 8.2538 22.8539 8.25381 23.0503 8.17248C23.2467 8.09114 23.4027 7.93511 23.4841 7.73872C23.5654 7.54232 23.5655 7.32165 23.4841 7.12525C22.9786 5.90422 22.1223 4.86054 21.0235 4.12624C19.9248 3.39194 18.6329 3 17.3114 3C15.9898 3 14.698 3.39194 13.5992 4.12624C12.5005 4.86054 11.6442 5.90422 11.1386 7.12525C11.0573 7.32165 11.0573 7.54232 11.1387 7.73872C11.22 7.93511 11.3761 8.09114 11.5725 8.17248C11.7689 8.25381 11.9896 8.2538 12.1859 8.17244C12.3823 8.09107 12.5394 7.93503 12.6208 7.73863Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.44358 12.9767C1.81738 13.6029 1.81738 14.6095 1.81738 16.6249C1.81738 18.6403 1.81738 19.6469 2.44358 20.2731C3.06978 20.8993 4.07639 20.8993 6.09177 20.8993H18.9149C20.9303 20.8993 21.9369 20.8993 22.5631 20.2731C23.1893 19.6469 23.1893 18.6403 23.1893 16.6249C23.1893 14.6095 23.1893 13.6029 22.5631 12.9767C21.9369 12.3505 20.9303 12.3505 18.9149 12.3505H18.1135V9.14471C18.1135 8.93215 18.029 8.7283 17.8787 8.578C17.7284 8.4277 17.5246 8.34326 17.312 8.34326C17.0995 8.34326 16.8956 8.4277 16.7453 8.578C16.595 8.7283 16.5106 8.93215 16.5106 9.14471V12.3505H6.09177C4.07639 12.3505 3.06978 12.3505 2.44358 12.9767ZM6.09177 17.6935C6.37517 17.6935 6.64698 17.5809 6.84738 17.3805C7.04778 17.1801 7.16036 16.9083 7.16036 16.6249C7.16036 16.3415 7.04778 16.0697 6.84738 15.8693C6.64698 15.6689 6.37517 15.5563 6.09177 15.5563C5.80836 15.5563 5.53656 15.6689 5.33615 15.8693C5.13575 16.0697 5.02317 16.3415 5.02317 16.6249C5.02317 16.9083 5.13575 17.1801 5.33615 17.3805C5.53656 17.5809 5.80836 17.6935 6.09177 17.6935ZM9.29755 17.6935C9.58096 17.6935 9.85276 17.5809 10.0532 17.3805C10.2536 17.1801 10.3661 16.9083 10.3661 16.6249C10.3661 16.3415 10.2536 16.0697 10.0532 15.8693C9.85276 15.6689 9.58096 15.5563 9.29755 15.5563C9.01414 15.5563 8.74234 15.6689 8.54194 15.8693C8.34154 16.0697 8.22896 16.3415 8.22896 16.6249C8.22896 16.9083 8.34154 17.1801 8.54194 17.3805C8.74234 17.5809 9.01414 17.6935 9.29755 17.6935Z"
      />
      <path d="M15.0039 9.00364C15.1503 8.50482 15.4542 8.06682 15.8703 7.75524C16.2864 7.44365 16.7923 7.27525 17.3121 7.27525C17.8319 7.27525 18.3378 7.44365 18.7539 7.75524C19.17 8.06682 19.474 8.50482 19.6203 9.00364C19.6798 9.20769 19.8179 9.37975 20.0043 9.48195C20.0966 9.53256 20.1979 9.56449 20.3026 9.57593C20.4072 9.58737 20.513 9.57809 20.6141 9.54862C20.7151 9.51915 20.8094 9.47007 20.8914 9.40418C20.9735 9.33829 21.0418 9.25688 21.0924 9.1646C21.143 9.07231 21.1749 8.97097 21.1864 8.86634C21.1978 8.76172 21.1885 8.65587 21.1591 8.55483C20.9156 7.72307 20.4092 6.9926 19.7156 6.47293C19.0221 5.95325 18.1788 5.67236 17.3121 5.67236C16.4455 5.67236 15.6022 5.95325 14.9086 6.47293C14.215 6.9926 13.7086 7.72307 13.4652 8.55483C13.4056 8.75888 13.4296 8.97822 13.5318 9.1646C13.634 9.35097 13.8061 9.48911 14.0101 9.54862C14.2142 9.60814 14.4335 9.58416 14.6199 9.48195C14.8063 9.37975 14.9444 9.20769 15.0039 9.00364Z" />
    </svg>
  );
};

export default DevicesIcon;

import { ReportsTableRow } from "src/components/reports-table/ReportsTable";
import { WebhookHistory } from "src/interfaces";

type WebhookHistoryExpandedRowProps = {
  webhookHistory: WebhookHistory;
};

export default function WebhookHistoryExpandedRow({
  webhookHistory,
}: WebhookHistoryExpandedRowProps) {
  return (
    <ReportsTableRow
      style={{ display: "flex", gridColumn: "1 / -1", background: "#fff" }}
      className="text-sm"
      data-cy={`${webhookHistory.id}-expanded-row`}
    >
      <td className="text-sm text-[#434343] flex-1">
        <div className="px-4 pb-3">
          <div className="space-y-1 mt-3">
            <span className="font-primary font-semibold text-base">
              Request
            </span>
            <div className="bg-[#FBFFFE] border border-neutral-200 p-4 rounded-lg">
              <span
                data-cy={`${webhookHistory.id}-expanded-row-request`}
                className="font-sans italic text-xs"
              >
                {webhookHistory.request}
              </span>
            </div>
          </div>
        </div>
      </td>
      <td className="text-sm text-[#434343] flex-1">
        <div className="px-4 pb-3">
          <div className="space-y-1 mt-3">
            <span className="font-primary font-semibold text-base">
              Response
            </span>
            <ul className="space-y-1">
              <li
                data-cy={`${webhookHistory.id}-expanded-row-code`}
                className="text-xs font-primary font-base"
              >
                <span className="font-bold">Code:</span>{" "}
                {webhookHistory.response_code}
              </li>
              <li
                data-cy={`${webhookHistory.id}-expanded-row-type`}
                className="text-xs font-primary font-base"
              >
                <span className="font-bold">Type:</span>{" "}
                {webhookHistory.return_type}
              </li>
              <li className="text-xs font-primary font-base">
                <span className="font-bold">Body:</span>{" "}
                <div className="bg-[#FBFFFE] border border-neutral-200 p-4 mt-1 rounded-lg">
                  <pre
                    data-cy={`${webhookHistory.id}-expanded-row-response-body`}
                    className="font-primary text-xs"
                  >
                    {webhookHistory.response_body}
                  </pre>
                </div>
              </li>
              <li className="text-xs font-primary font-base">
                <span className="font-bold">Headers:</span>{" "}
                <div className="bg-[#FBFFFE] border border-neutral-200 p-4 mt-1 rounded-lg">
                  <pre
                    data-cy={`${webhookHistory.id}-expanded-row-headers`}
                    className="font-primary text-xs"
                  >
                    {webhookHistory.headers}
                  </pre>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </td>
      <td className="text-sm text-[#434343] flex-1">
        <div className="px-4 pb-3">
          <div className="space-y-1 mt-3">
            <span className="font-primary font-semibold text-base">
              Details
            </span>
            <ul className="space-y-1 [&>li]:grid [&>li]:max-w-56 [&>li]:grid-cols-2">
              <li
                data-cy={`${webhookHistory.id}-expanded-row-provider`}
                className="text-xs font-primary font-base"
              >
                <span className="font-bold">Provider:</span>{" "}
                {webhookHistory.provider_name}
              </li>
              <li
                data-cy={`${webhookHistory.id}-expanded-row-service`}
                className="text-xs font-primary font-base"
              >
                <span className="font-bold">Service:</span>{" "}
                {webhookHistory.service_name}
              </li>
              <li
                data-cy={`${webhookHistory.id}-expanded-row-plan`}
                className="text-xs font-primary font-base"
              >
                <span className="font-bold">Plan:</span>{" "}
                {webhookHistory.plan_name}
              </li>
              <li
                data-cy={`${webhookHistory.id}-expanded-row-transaction-id`}
                className="text-xs font-primary font-base"
              >
                <span className="font-bold">Transaction ID:</span>{" "}
                {webhookHistory.transaction_id}
              </li>
            </ul>
          </div>
        </div>
      </td>
    </ReportsTableRow>
  );
}

import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";

import {
  Account,
  Device,
  Flow,
  PortInterface,
  Subscription,
} from "src/interfaces";
import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/table/Table";
import { Dot } from "src/components/dot/Dot";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";

interface FlowRecord {
  flows: Flow[];
  timestamp: string;
}

type TestServiceNetworkDialogProps = {
  currentDevice: Device;
  currentPort: PortInterface | null;
  currentSubscription: Subscription | null;
};

export default function TestServiceNetworkDialog({
  currentDevice,
  currentPort,
}: TestServiceNetworkDialogProps) {
  const [flowHistory, setFlowHistory] = useState<FlowRecord[]>([]);
  const [vmStatus, _] = useState<string>("...");
  const [latency, __] = useState<string>("...");
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const accounts: { [key: string]: Account } = {};
  for (const account of currentDevice?.Accounts || []) {
    accounts[account.id] = account;
  }

  const updateVirtualSystemStatus = () => {};

  const clearFlowHistory = () => {
    setFlowHistory([]);
  };

  const getMoreTestResults = () => {};

  return (
    <Dialog
      open={isDialogOpened}
      onOpenChange={(value) => setIsDialogOpened(value)}
    >
      <DialogTrigger>
        <EpButton
          aria-label="test"
          dataCy={`testServiceNetworkDialogActionBtn-${currentPort?.display_name}`}
        >
          Test
        </EpButton>
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-4xl font-base"
        dataCy="testServiceNetworkDialogCnt"
      >
        <DialogHeader borderless dataCy="testServiceNetworkDialogHeader">
          <DialogTitle dataCy="testServiceNetworkDialogTitle">
            {currentPort?.display_name} Details
          </DialogTitle>
        </DialogHeader>
        <>
          <div className="gap-0 h-24">
            <div className="text-base font-medium">Test Service Network</div>
            <div className="text-1.5xs py-1 font-secondary">
              AVERAGE NETWORK OPERATOR LATENCY
            </div>
            <div className="text-1.5xs" data-cy="latency">
              {latency}
            </div>
          </div>

          <div className="flex justify-between items-end text-base font-medium">
            <div className="font-secondary uppercase text-xs">
              Virtual System
            </div>
            <div className="flex flex-row gap-2 items-end">
              <EpButton
                styling={EpButtonStyles.PRIMARY}
                onClick={updateVirtualSystemStatus}
                dataCy="refresh-vm"
                aria-label="Refresh VM"
              >
                Refresh
              </EpButton>
            </div>
          </div>

          <Table className="font-secondary">
            <TableHeader>
              <TableRow>
                <TableHead borderless>VM</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell>
                  {vmStatus !== "..." && (
                    <Dot
                      variant={
                        vmStatus === "NOT DEPLOYED" ? "inactive" : "active"
                      }
                    />
                  )}{" "}
                  {vmStatus}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <div className="flex justify-between items-end text-base font-medium pt-4 align-text-bottom">
            <div className="font-secondary uppercase text-xs">Network</div>
            <div className="flex flex-row gap-2 items-end">
              <EpButton
                styling={EpButtonStyles.SECONDARY}
                onClick={clearFlowHistory}
                dataCy="clear-flows"
                aria-label="Clear flows"
              >
                Clear Table
              </EpButton>
              <EpButton
                styling={EpButtonStyles.PRIMARY}
                onClick={getMoreTestResults}
                dataCy="refresh-flows"
                aria-label="Refresh flows"
              >
                Refresh
              </EpButton>
            </div>
          </div>

          <Table className="font-secondary">
            <TableHeader>
              <TableRow>
                <TableHead borderless>In</TableHead>
                <TableHead borderless>Out</TableHead>
                <TableHead borderless>Lifetime Data (MB)</TableHead>
                <TableHead borderless>Lifetime Packets</TableHead>
                <TableHead borderless>Time</TableHead>
              </TableRow>
            </TableHeader>
            {flowHistory.map((record, index) => {
              return (
                <TableBody key={record.timestamp}>
                  {record.flows.map((flow, idx) => (
                    <TableRow key={idx}>
                      <TableCell>{flow.in}</TableCell>
                      <TableCell>{flow.out}</TableCell>
                      <TableCell>
                        {(parseInt(flow.bytes) / 1000000).toFixed(4)}
                      </TableCell>
                      <TableCell>{flow.packets}</TableCell>
                      <TableCell data-cy={`timestamp-${index}-${idx}`}>
                        {record.timestamp}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              );
            })}
          </Table>
        </>
        <DialogFooter>
          <DialogClose asChild className="flex gap-2 ml-auto">
            <EpButton
              type="button"
              styling={EpButtonStyles.CANCEL}
              dataCy="cancelBtn"
              aria-label="Cancel"
            >
              Cancel
            </EpButton>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

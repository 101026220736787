import { useState } from "react";
import EpButton from "src/components/buttons/EpButton";
import Icon from "src/components/buttons/Icon";
import { EpButtonStyles } from "src/components/buttons/constants";
import ScrollArea from "src/components/scroll-area/ScrollArea";
import LoadingAnimation from "src/components/spinners/LoadingAnimation";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/table/Table";
import { toast } from "src/components/toast/useToast";

import { devicesMockData } from "../__mock__/devices";
import { deviceMockData } from "../__mock__/device";
import { Badge } from "src/components/badge/Badge";
import TabMenu, { TabConfig } from "src/components/tabMenu/TabMenu";
import InfoDiagram from "./InfoDiagram";
import InfoTable from "./InfoTable";

export default function DeviceInfo() {
  const currentDevice = deviceMockData;
  const ports = devicesMockData[0].Portinterfaces!;

  const [isRefreshing, setIsRefreshing] = useState(false);

  const subscriberDetailInfoTabs: TabConfig[] = [
    {
      key: "diagram",
      title: "Diagram",
      titleAttribute: "Device Info Diagram",
      component: <InfoDiagram ports={ports} currentDevice={currentDevice} />,
      dataCy: "deviceInfoDiagramTab",
    },
    {
      key: "table",
      title: "Table",
      titleAttribute: "Device Info Table",
      component: <InfoTable ports={ports} currentDevice={currentDevice} />,
      dataCy: "deviceInfoTableTab",
    },
  ];

  function refreshDevice() {
    setIsRefreshing(true);
    setTimeout(() => {
      toast({
        title: "Success",
        description: (
          <>
            Device data refreshed
            <br />
          </>
        ),
      });
    }, 1200);
    setIsRefreshing(false);
  }

  return (
    <ScrollArea
      className="max-h-[calc(100vh-theme(space.52))] w-full px-2"
      data-cy="deviceScrollArea"
    >
      <div className="">
        <div className="flex flex-row gap-2 items-end justify-between pb-4 my-4">
          <div className="flex items-center gap-2 text-base text-neutral-500 font-semibold">
            <span>Device Status: </span>
            <Badge className="bg-opacity-35 border-none" variant="paid">
              {currentDevice.status}
            </Badge>
          </div>
          <EpButton
            styling={EpButtonStyles.PRIMARY}
            dataCy="device-info-refresh"
            onClick={refreshDevice}
            disabled={isRefreshing}
            aria-label="Refresh device information"
          >
            {isRefreshing ? (
              <LoadingAnimation />
            ) : (
              <Icon size={16} name="change" />
            )}
            {isRefreshing ? "Refreshing" : "Refresh"}
          </EpButton>
        </div>

        <Table dataCy="deviceInfoTable">
          <TableHeader dataCy="deviceInfoHeader">
            <TableRow hoverable={false}>
              <TableHead borderless>Installed</TableHead>
              <TableHead borderless>Serial Number</TableHead>
              <TableHead borderless>Location</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody dataCy="deviceInfoBody">
            <TableRow dataCy="deviceInfoRow" borderless hoverable={false}>
              <TableCell dataCy="deviceInfoInstalledAt">
                {currentDevice?.installed_at}
              </TableCell>
              <TableCell dataCy="deviceInfoSerialNumber">
                {currentDevice?.serial_number}
              </TableCell>
              <TableCell dataCy="deviceInfoLocation">
                {currentDevice?.Physicallocation?.house_number}{" "}
                {currentDevice?.Physicallocation?.street}{" "}
                {currentDevice?.Physicallocation?.apartment_number
                  ? "Apt. " + currentDevice?.Physicallocation?.apartment_number
                  : ""}{" "}
                {currentDevice?.Physicallocation?.city},{" "}
                {currentDevice?.Physicallocation?.state}{" "}
                {currentDevice?.Physicallocation?.zip}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div className="bg-neutral-100/60 p-4 shadow-group-box rounded-lg my-6 mx-2">
          <TabMenu
            config={subscriberDetailInfoTabs}
            dataCy="subscriberDetailInfoTabMenu"
          ></TabMenu>
        </div>
      </div>
    </ScrollArea>
  );
}

import { useState } from "react";
import IconButton from "src/components/buttons/IconButton";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";
import TabMenu, { TabConfig } from "src/components/tabMenu/TabMenu";
import { Serviceplan } from "src/interfaces";
import ManageNetworkOperatorServicePlanDetails from "./ManageNetworkOperatorServicePlanForm";
import ManageNetworkOperatorServicePlanFields from "./ManageNetworkOperatorServiceFieldsForm";

type ManageNetworkOperatorPlansDialogProps = {
  servicePlan: Serviceplan;
};

export default function ManageNetworkOperatorPlansDialog({
  servicePlan,
}: ManageNetworkOperatorPlansDialogProps) {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const manageNetworkOperatorServicePlansTabsConfig: TabConfig[] = [
    {
      key: "details",
      title: "Details",
      titleAttribute: "Manage Plan Details",
      component: (
        <ManageNetworkOperatorServicePlanDetails servicePlan={servicePlan} />
      ),
      dataCy: "managePlanDetailsTab",
    },
    {
      key: "fields",
      title: "Fields",
      titleAttribute: "Manage Plan Fields",
      component: (
        <ManageNetworkOperatorServicePlanFields servicePlan={servicePlan} />
      ),
      dataCy: "managePlanFieldsTab",
    },
  ];

  return (
    <Dialog
      open={isDialogOpened}
      onOpenChange={(value) => setIsDialogOpened(value)}
    >
      <DialogTrigger asChild>
        <IconButton
          name="edit"
          dataCy="manageNetworkOperatorServicePlansActionBtn"
          tooltipText={`Manage Plan`}
          aria-label={`Manage Plan`}
        />
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-4xl font-base"
        dataCy="manageNetworkOperatorServicePlansDialogCnt"
      >
        <DialogHeader
          dataCy="manageNetworkOperatorServicePlansDialogHeader"
          borderless
        >
          <DialogTitle dataCy="manageNetworkOperatorServicePlansDialogTitle">
            {servicePlan.Service.name} - {servicePlan.name}
          </DialogTitle>
        </DialogHeader>
        <TabMenu
          config={manageNetworkOperatorServicePlansTabsConfig}
          dataCy="manageNetworkOperatorServicePlansTabMenu"
        ></TabMenu>
      </DialogContent>
    </Dialog>
  );
}

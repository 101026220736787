import IconButton from "src/components/buttons/IconButton";

import { NetworkOperator } from "../list/NetworkOperatorsList";
import ManageNetworkOperatorDialog from "./ManageNetworkOperatorDialog";

interface LoadNetworkOperatorActionsProps {
  operator: NetworkOperator;
  handleAddNetworkOperator: (operator: NetworkOperator) => void;
}

export default function LoadNetworkOperatorActions({
  operator,
  handleAddNetworkOperator,
}: LoadNetworkOperatorActionsProps) {
  return (
    <div className="flex justify-end gap-x-2">
      <ManageNetworkOperatorDialog networkOperator={operator} />
      <IconButton
        name="activate"
        dataCy="loadExistingNetworkOperatorBtn"
        tooltipText={`Load and existing network operator from ${operator.name}`}
        aria-label={`Load and existing network operator from ${operator.name}`}
        onClick={() => handleAddNetworkOperator(operator)}
      />
    </div>
  );
}

import { Navigate, Route, Routes } from "react-router-dom";
import { protectedPages, publicPages } from "./routes";
import InitialLoading from "./components/loading/InitialLoading";
import { useSession } from "./hooks/session/useSession";
import AnonymousLayout from "./layout/anonymous";
import AuthedLayout from "./layout/authed";

function App() {
  const { useUser } = useSession();
  const { isAuthenticated, isInitialLoading } = useUser;

  if (isInitialLoading) {
    return <InitialLoading />;
  }

  if (!isAuthenticated) {
    const pages = Object.entries(publicPages);
    return (
      <Routes>
        <Route path="/" element={<AnonymousLayout />}>
          {pages.map(([key, page]) => {
            const Component = page.component;
            return Component ? (
              <Route key={key} path={page.path} element={<Component />} />
            ) : null;
          })}
          <Route path="" element={<Navigate to="login" />} />
        </Route>
        <Route path="*" element={<Navigate to="login" />} />
      </Routes>
    );
  }

  const pages = Object.entries(protectedPages);
  return (
    <Routes>
      <Route path="/" element={<AuthedLayout />}>
        {pages.map(([key, page]) => {
          const Component = page.component;
          return Component ? (
            <Route key={key} path={page.path} element={<Component />} />
          ) : null;
        })}
        <Route path="" element={<Navigate to="dashboard" />} />
      </Route>
      <Route path="*" element={<Navigate to="/approval" />} />
    </Routes>
  );
}

export default App;

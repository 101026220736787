import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";

import {
  FormField,
  FormItem,
  FormControl,
  FormMessage,
  Form,
  FormLabel,
} from "src/components/form/Form";
import { Input } from "src/components/input/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/select/Select";
import LoadingAnimation from "src/components/spinners/LoadingAnimation";
import { toast } from "src/components/toast/useToast";
import { useSession } from "src/hooks/session/useSession";
import { Contact } from "src/interfaces/Contact";
import { Location } from "src/interfaces/Location";
import { z } from "zod";

const UserLocationFormScheme = z.object({
  address1: z.string().min(1, {
    message: "Please enter a valid address 1",
  }),
  address2: z.string().optional(),
  city: z.string().min(2, {
    message: "Please enter a valid city name",
  }),
  postalcode: z
    .string()
    .min(5, {
      message: "Please enter a valid postal code",
    })
    .max(9, {
      message: "Please enter a valid postal code",
    }),
  country: z.string().min(1, { message: "Required" }),
  state: z.string().min(1, { message: "Required" }),
});

const STATES = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const COUNTRIES = ["United States"];

export default function UserLocationForm() {
  const { useUser } = useSession();
  const { user, setUser } = useUser;

  const location = user?.Contact?.Location
    ? user?.Contact?.Location
    : ({
        address1: "",
        address2: "",
        city: "",
        country: "",
        state: "",
        zip: "",
      } as Location);

  const UserLocationForm = useForm<z.infer<typeof UserLocationFormScheme>>({
    resolver: zodResolver(UserLocationFormScheme),
    mode: "onBlur",
    defaultValues: {
      ...location,
    },
  });

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const handleUpdateLocationInformation = (
    data: z.infer<typeof UserLocationFormScheme>,
  ) => {
    setIsProcessing(true);

    const contact = user?.Contact
      ? user?.Contact
      : ({
          email: "",
          first_name: "",
          last_name: "",
          phone: "",
          status: "",
          type: "",
          Location: location,
        } as Contact);

    if (user) {
      setTimeout(() => {
        setUser({
          ...user,
          Contact: {
            ...contact,
            Location: {
              ...location,
              ...data,
            },
          },
        });

        setIsProcessing(false);
        toast({
          title: "Success",
          description: (
            <>
              Contact details updated successfully!
              <br />
            </>
          ),
        });
      }, 1200);
    }
  };

  return (
    <div>
      <Form {...UserLocationForm}>
        <form
          onSubmit={UserLocationForm.handleSubmit(
            handleUpdateLocationInformation,
          )}
          className="flex flex-col gap-4"
        >
          <div className="grid grid-cols-2 grid-rows-1">
            <FormField
              control={UserLocationForm.control}
              name="address1"
              render={(field) => (
                <FormItem className="w-full pr-2 mb-4">
                  <FormLabel>ADDRESS 1</FormLabel>
                  <FormControl>
                    <Input
                      autoFocus
                      id="address1"
                      placeholder="Address"
                      data-cy="address1Input"
                      type="text"
                      aria-label="Address 1"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={UserLocationForm.control}
              name="address2"
              render={(field) => (
                <FormItem className="w-full pr-2 mb-4">
                  <FormLabel>ADDRESS 2 (OPTIONAL)</FormLabel>
                  <FormControl>
                    <Input
                      id="address2"
                      placeholder="Address"
                      data-cy="address2Input"
                      type="text"
                      aria-label="Address Name"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="grid grid-cols-4 grid-rows-1">
            <FormField
              control={UserLocationForm.control}
              name="city"
              render={(field) => (
                <FormItem className="w-full pr-2 mb-4">
                  <FormLabel>CITY</FormLabel>
                  <FormControl>
                    <Input
                      id="city"
                      placeholder="City"
                      data-cy="cityInput"
                      type="text"
                      aria-label="City"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={UserLocationForm.control}
              name="state"
              render={(field) => (
                <FormItem className="w-full pr-2 mb-4">
                  <FormLabel>STATE</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      UserLocationForm.setValue(field.field.name, value);
                      UserLocationForm.trigger(field.field.name);
                    }}
                    defaultValue={location?.state}
                  >
                    <FormControl>
                      <SelectTrigger
                        aria-label="User state"
                        data-cy="user-location-state-select"
                        onBlur={() =>
                          UserLocationForm.trigger(field.field.name)
                        }
                      >
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {STATES.map((stat) => (
                        <SelectItem
                          key={stat}
                          value={stat}
                          data-cy={`user-location-state-${stat}`}
                        >
                          {stat}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={UserLocationForm.control}
              name="postalcode"
              render={(field) => (
                <FormItem className="w-full pr-2 mb-4">
                  <FormLabel>POSTAL CODE</FormLabel>
                  <FormControl>
                    <Input
                      id="postalcode"
                      placeholder="Postal Code"
                      data-cy="postalCodeInput"
                      type="text"
                      aria-label="Postal Code"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={UserLocationForm.control}
              name="country"
              render={(field) => (
                <FormItem className="w-full pr-2 mb-4">
                  <FormLabel>COUNTRY</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      UserLocationForm.setValue(field.field.name, value);
                      UserLocationForm.trigger(field.field.name);
                    }}
                    defaultValue={location?.country}
                  >
                    <FormControl>
                      <SelectTrigger
                        aria-label="User country"
                        data-cy="user-location-country-select"
                        onBlur={() =>
                          UserLocationForm.trigger(field.field.name)
                        }
                      >
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {COUNTRIES.map((country) => (
                        <SelectItem
                          key={country}
                          value={country}
                          data-cy={`user-location-country-select-${country}`}
                        >
                          {country}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </form>
      </Form>
      <EpButton
        styling={EpButtonStyles.PRIMARY}
        dataCy="updateUserLocationBtn"
        onClick={UserLocationForm.handleSubmit(handleUpdateLocationInformation)}
        aria-label="Update user location"
        className="flex gap-1 mt-2"
        disabled={isProcessing}
      >
        {isProcessing && <LoadingAnimation />}
        {isProcessing ? "Saving" : "Save"}
      </EpButton>
    </div>
  );
}

import { SVGProps } from "react";

const DevicesIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const defaultFill = "#434343";
  const fill = props.fill || defaultFill;
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill={fill}
    >
      <g>
        <path d="M16 27.52C12 27.52 8.464 25.472 6.4 22.4C6.448 19.2 12.8 17.44 16 17.44C19.2 17.44 25.552 19.2 25.6 22.4C24.5422 23.9751 23.1134 25.2658 21.4393 26.1587C19.7652 27.0515 17.8973 27.519 16 27.52ZM16 4.8C17.273 4.8 18.4939 5.30571 19.3941 6.20589C20.2943 7.10606 20.8 8.32696 20.8 9.6C20.8 10.873 20.2943 12.0939 19.3941 12.9941C18.4939 13.8943 17.273 14.4 16 14.4C14.727 14.4 13.5061 13.8943 12.6059 12.9941C11.7057 12.0939 11.2 10.873 11.2 9.6C11.2 8.32696 11.7057 7.10606 12.6059 6.20589C13.5061 5.30571 14.727 4.8 16 4.8ZM16 0C13.8989 0 11.8183 0.413852 9.87706 1.21793C7.93586 2.022 6.17203 3.20055 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C6.17203 28.7994 7.93586 29.978 9.87706 30.7821C11.8183 31.5861 13.8989 32 16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 7.152 24.8 0 16 0Z" />
      </g>
    </svg>
  );
};

export default DevicesIcon;

import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import EpButton from "src/components/buttons/EpButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import { DialogClose } from "src/components/dialog/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/form/Form";
import { Input } from "src/components/input/Input";

import { formatDate, formatDateToHumanReadableISOString } from "src/utils/date";
import { z } from "zod";
import { GlobalServicePlan } from "../manage-global-service/ManageDetailsPlans";
import PublishDetailsDialog from "../actions/PublishDetailsDialog";
import ScrollArea from "src/components/scroll-area/ScrollArea";

const ManageServicePlanFieldsFormSchema = z.object({
  linkSpeed: z.coerce.number(),
  down: z.coerce.number(),
  up: z.coerce.number(),
  price: z.coerce.number(),
});

type ManageServicePlanFieldsFormProps = {
  globalServicePlan: GlobalServicePlan;
  operationType: "create" | "update";
};

export default function ManageServicePlanFieldsForm({
  globalServicePlan,
  operationType,
}: ManageServicePlanFieldsFormProps) {
  const ManageServicePlanFieldsForm = useForm<
    z.infer<typeof ManageServicePlanFieldsFormSchema>
  >({
    resolver: zodResolver(ManageServicePlanFieldsFormSchema),
    mode: "onBlur",
    defaultValues: {
      ...globalServicePlan,
    },
  });

  function handleManageDetailsSave(
    data: z.infer<typeof ManageServicePlanFieldsFormSchema>,
  ) {
    if (operationType === "create") {
      //proceed with create API
    } else {
      // proceed with update API
    }
    console.log({ data });
  }

  return (
    <ScrollArea className="max-h-[calc(90vh-theme(space.52))]">
      <Form {...ManageServicePlanFieldsForm}>
        {operationType === "update" ? (
          <div className="grid grid-rows-1 grid-cols-2 text-neutral-500 font-secondary text-xs">
            <span>CREATED: {formatDate(globalServicePlan.createdAt)}</span>
            <span>UPDATED: {formatDate(globalServicePlan.updatedAt)}</span>
          </div>
        ) : null}
        <form
          onSubmit={ManageServicePlanFieldsForm.handleSubmit(
            handleManageDetailsSave,
          )}
        >
          <div className="grid grid-cols-2 grid-rows-1">
            <FormField
              control={ManageServicePlanFieldsForm.control}
              name="linkSpeed"
              render={(field) => (
                <FormItem className="w-full pr-2 mb-4">
                  <FormLabel>LINK SPEED</FormLabel>
                  <FormControl>
                    <Input
                      autoFocus
                      id="linkSpeed"
                      placeholder="0"
                      data-cy="linkSpeedInput"
                      type="number"
                      aria-label="Link Speed"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={ManageServicePlanFieldsForm.control}
              name="down"
              render={(field) => (
                <FormItem className="w-full pr-2 mb-4">
                  <FormLabel>DOWN</FormLabel>
                  <FormControl>
                    <Input
                      id="down"
                      placeholder="Down"
                      data-cy="downInput"
                      type="number"
                      aria-label="Down"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="grid grid-cols-2 grid-rows-1">
            <FormField
              control={ManageServicePlanFieldsForm.control}
              name="up"
              render={(field) => (
                <FormItem className="w-full pr-2 mb-4">
                  <FormLabel>UP</FormLabel>
                  <FormControl>
                    <Input
                      id="up"
                      placeholder="0"
                      data-cy="upInput"
                      type="number"
                      aria-label="Up"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={ManageServicePlanFieldsForm.control}
              name="price"
              render={(field) => (
                <FormItem className="w-full pr-2 mb-4">
                  <FormLabel>PRICE</FormLabel>
                  <FormControl>
                    <Input
                      id="price"
                      placeholder="Price"
                      data-cy="priceInput"
                      type="number"
                      aria-label="Price"
                      {...field.field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          {operationType === "update" ? (
            <div className="flex gap-4 items-center justify-start mt-6">
              <EpButton
                aria-label="Save updated changes"
                dataCy="saveUpdatedChangesBtn"
              >
                Save
              </EpButton>
              <span className="text-xs font-normal">
                <span className="font-medium mr-2">Last saved</span>{" "}
                {formatDateToHumanReadableISOString(
                  globalServicePlan.updatedAt,
                )}
              </span>
            </div>
          ) : null}
          <div className="flex items-center justify-between mt-8">
            {operationType === "update" ? (
              <div className="flex justify-start gap-4 items-center border border-neutral-200 rounded-md p-2">
                <PublishDetailsDialog
                  updatedAt={formatDateToHumanReadableISOString(
                    globalServicePlan.updatedAt,
                  )}
                />
                <span className="text-xs font-normal">
                  <span className="font-medium mr-2">Last published</span>{" "}
                  {formatDateToHumanReadableISOString(
                    globalServicePlan.updatedAt,
                  )}
                </span>
              </div>
            ) : (
              <EpButton
                aria-label="Create new service plan"
                dataCy="createNewServicePlanBtn"
              >
                Save
              </EpButton>
            )}
            <DialogClose asChild className="flex gap-2 ml-auto">
              <EpButton
                type="button"
                styling={EpButtonStyles.CANCEL}
                dataCy="cancelBtn"
                aria-label="cancel"
              >
                Cancel
              </EpButton>
            </DialogClose>
          </div>
        </form>
      </Form>
    </ScrollArea>
  );
}

export enum ArrowColors {
  RED = "red",
  GREEN = "green",
  ORANGE = "orange",
}

export enum ArrowDirections {
  LEFT = "left",
  RIGHT = "right",
}

export interface RowConfig {
  arrowDirection: ArrowDirections;
  arrowColor: ArrowColors;
  leftLabel: string;
  leftSublabel?: string;
  rightLabel: string;
  rightSublabel?: string;
  backgroundColor?: ArrowColors;
  lab: string;
  pri: number;
  bytes: number;
  packets: number;
  v: number;
  f: number;
}

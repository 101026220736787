import { useEffect, useState } from "react";
import IconButton from "src/components/buttons/IconButton";
import { Dot } from "src/components/dot/Dot";
import DeviceIllustration from "src/components/illustrations/DeviceIllustration";
import { AccountStatus, Device, PortInterface } from "src/interfaces";
import SubscriberLanDetailsDialog from "../actions/SubscriberLanDetailsDialog";
import TestServiceNetworkDialog from "../actions/TestServiceNetworkDialog";

type InfoDiagramProps = {
  ports: PortInterface[];
  currentDevice: Device;
};

export default function InfoDiagram({
  ports,
  currentDevice,
}: InfoDiagramProps) {
  const [lanItems, setLanItems] = useState<PortInterface[]>([]);
  const [currentPort, setCurrentPort] = useState<PortInterface | undefined>(
    undefined,
  );
  const [isPluggedInLan1, setIsPluggedInLan1] = useState(false);
  const [isPluggedInLan2, setIsPluggedInLan2] = useState(false);
  const [isPluggedInLan3, setIsPluggedInLan3] = useState(false);
  const [isPluggedInLan4, setIsPluggedInLan4] = useState(false);

  useEffect(() => {
    const aux: PortInterface[] = [];
    for (const port of ports) {
      if (port.type === "LAN") {
        aux.push(port);
      }
    }
    aux.sort((a, b) => a.display_name.localeCompare(b.display_name));
    setLanItems(aux);
  }, [ports]);

  useEffect(() => {
    const [lan1, lan2, lan3, lan4] = lanItems;

    setIsPluggedInLan1(lan1?.status === "ACTIVE");
    setIsPluggedInLan2(lan2?.status === "ACTIVE");
    setIsPluggedInLan3(lan3?.status === "ACTIVE");
    setIsPluggedInLan4(lan4?.status === "ACTIVE");
  }, [lanItems]);

  return (
    <div className="flex flex-col items-center gap-2 justify-center mt-6">
      <DeviceIllustration
        ports={lanItems}
        isPluggedInLan1={isPluggedInLan1}
        isPluggedInLan2={isPluggedInLan2}
        isPluggedInLan3={isPluggedInLan3}
        isPluggedInLan4={isPluggedInLan4}
        active={currentDevice?.status === AccountStatus.Active}
        currentPort={currentPort}
        setCurrentPort={setCurrentPort}
      />
      {currentDevice?.Portinterfaces && (
        <div className="flex flex-col px-2 space-y-5 justify-center w-full text-xs font-semibold text-[#6D6D6D] mt-4 mb-2">
          <span aria-label="Lan port status">LAN Port Status:</span>
          <div className="flex justify-between gap-5">
            <div className="flex flex-col gap-5 w-1/2">
              <div className="flex flex-row items-center justify-between">
                <div className="flex gap-1">
                  <div className="w-[43px]">LAN 1:</div>
                  {isPluggedInLan1 ? (
                    <>
                      <Dot className="h-3.5 w-3.5" variant={"active"} />
                      ACTIVE
                    </>
                  ) : (
                    <>
                      <Dot className="h-3.5 w-3.5" variant={"inactive"} />
                      INACTIVE
                    </>
                  )}
                </div>
                <div className="flex gap-2">
                  <IconButton
                    onClick={() => setCurrentPort(lanItems[0])}
                    aria-label="edit"
                    dataCy="editLan1"
                    name="edit"
                  />
                  <TestServiceNetworkDialog
                    currentDevice={currentDevice}
                    currentSubscription={lanItems[0]?.Subscriptions[0]}
                    currentPort={lanItems[0]}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center justify-between">
                <div className="flex gap-1">
                  <div className="w-[43px]">LAN 2:</div>
                  {isPluggedInLan2 ? (
                    <>
                      <Dot className="h-3.5 w-3.5" variant={"active"} />
                      ACTIVE
                    </>
                  ) : (
                    <>
                      <Dot className="h-3.5 w-3.5" variant={"inactive"} />
                      INACTIVE
                    </>
                  )}
                </div>
                <div className="flex gap-2">
                  <IconButton
                    onClick={() => setCurrentPort(lanItems[1])}
                    aria-label="edit"
                    dataCy="editLan2"
                    name="edit"
                  />
                  <TestServiceNetworkDialog
                    currentDevice={currentDevice}
                    currentSubscription={lanItems[1]?.Subscriptions[0]}
                    currentPort={lanItems[1]}
                  />
                </div>
              </div>
            </div>
            <div aria-hidden="true" className="w-[1px] bg-neutral-400 "></div>
            <div className="flex  flex-col gap-5 w-1/2">
              <div className="flex flex-row items-center justify-between">
                <div className="flex gap-1">
                  <div className="w-[43px]">LAN 3:</div>
                  {isPluggedInLan3 ? (
                    <>
                      <Dot className="h-3.5 w-3.5" variant={"active"} />
                      ACTIVE
                    </>
                  ) : (
                    <>
                      <Dot className="h-3.5 w-3.5" variant={"inactive"} />
                      INACTIVE
                    </>
                  )}
                </div>
                <div className="flex gap-2">
                  <IconButton
                    onClick={() => setCurrentPort(lanItems[2])}
                    aria-label="edit"
                    dataCy="editLan3"
                    name="edit"
                  />
                  <TestServiceNetworkDialog
                    currentDevice={currentDevice}
                    currentSubscription={lanItems[2]?.Subscriptions[0]}
                    currentPort={lanItems[2]}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center justify-between">
                <div className="flex gap-1">
                  <div className="w-[43px]">LAN 4:</div>
                  {isPluggedInLan4 ? (
                    <>
                      <Dot className="h-3.5 w-3.5" variant={"active"} />
                      ACTIVE
                    </>
                  ) : (
                    <>
                      <Dot className="h-3.5 w-3.5" variant={"inactive"} />
                      INACTIVE
                    </>
                  )}
                </div>
                <div className="flex gap-2">
                  <IconButton
                    onClick={() => setCurrentPort(lanItems[3])}
                    aria-label="edit"
                    dataCy="editLan4"
                    name="edit"
                  />
                  <TestServiceNetworkDialog
                    currentDevice={currentDevice}
                    currentSubscription={lanItems[3]?.Subscriptions[0]}
                    currentPort={lanItems[3]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {currentPort !== undefined ? (
        <SubscriberLanDetailsDialog
          currentDevice={currentDevice}
          currentPort={currentPort}
          setCurrentPort={setCurrentPort}
        />
      ) : null}
    </div>
  );
}

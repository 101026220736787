import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "../../utils/cn";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../tooltip/Tooltip";

export type VariantTypeValue =
  | "active"
  | "inactive"
  | "locked"
  | "paused"
  | "pending"
  | "suspended"
  | "provision"
  | "repaired"
  | "replaced"
  | "rma"
  | "standby"
  | "pendingActivation"
  | null
  | undefined;

export const VariantType: { [key: string]: VariantTypeValue } = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  LOCKED: "locked",
  PAUSED: "paused",
  PENDING: "pending",
  SUSPENDED: "suspended",
  PROVISION: "provision",
  REPAIRED: "repaired",
  REPLACED: "replaced",
  RMA: "rma",
  STANDBY: "standby",
  PENDING_ACTIVATION: "pendingActivation",
};

const dotVariants = cva(
  "w-2 h-2 inline-flex items-center justify-center text-center px-auto rounded-full border py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        active: "bg-[#488B5B]",
        inactive: "bg-[#EC0000]",
        locked: "bg-[#2E9BFF]",
        paused: "bg-[#F9CB28]",
        pending: "bg-[#33FFCC]",
        suspended: "bg-[#CC00CC]",
        provision: "bg-[#FF7A00]",
        repaired: "bg-[#1D19E0]",
        replaced: "bg-[#F366AA]",
        rma: "bg-[#B5531C]",
        standby: "bg-[#68BFBA]",
        pendingActivation: "bg-[#7ECEFB]",
      },
    },
    defaultVariants: {
      variant: "pending",
    },
  },
);

export interface DotProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof dotVariants> {}

function Dot({ className, variant, ...props }: DotProps) {
  return (
    <TooltipProvider delayDuration={500} skipDelayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className={cn(dotVariants({ variant }), className)} {...props} />
        </TooltipTrigger>
        <TooltipContent>
          <p className="capitalize">{variant}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export { Dot, dotVariants };

import { Label } from "@radix-ui/react-label";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@radix-ui/react-tooltip";
import { useState } from "react";
import EpButton from "src/components/buttons/EpButton";
import IconButton from "src/components/buttons/IconButton";
import { EpButtonStyles } from "src/components/buttons/constants";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";
import FlowRow from "src/components/flow-rows/FlowRow";
import WarningIcon from "src/components/icons/WarningIcon";
import ScrollArea from "src/components/scroll-area/ScrollArea";

import { FlowSection, DeviceFlow } from "src/interfaces";
import { flowSections } from "../__mock__/flows";
import {
  ArrowColors,
  ArrowDirections,
  RowConfig,
} from "src/components/flow-rows/constants";
import { toast } from "src/components/toast/useToast";

const filteredOutTypes = ["CORE"];

export default function DeviceFlows() {
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [expandedSections, setExpandedSections] = useState<Set<string>>(
    new Set(),
  );
  const [currentFlow, setCurrentFlow] = useState<DeviceFlow | undefined>();

  const buildFlowRow = (flow: DeviceFlow, idx: number) => {
    let lab = "";
    let stag = "";
    let ctag = "";

    // Extract label (Logic from old portal)
    for (const i in flow.notes) {
      const note = flow.notes[i];
      if (note.indexOf("Label: ") != -1) {
        lab = note.substring(7);

        if (lab.indexOf("STAG:") != -1) {
          stag = lab.substring(5);
          lab = "";
        } else if (lab.indexOf("CTAG:") != -1) {
          ctag = lab.substring(5);
          lab = "";
        }
      }
    }

    // Set arrow color
    let arrowColor = ArrowColors.RED;
    const include_virtual = true;
    if (
      flow.data?.length === 0 ||
      flow.physical?.length === 0 ||
      (include_virtual && flow.virtual?.length === 0)
    ) {
      arrowColor = ArrowColors.RED;
    }

    if (flow.physical?.length === 0) {
      arrowColor = ArrowColors.ORANGE;
    } else if (flow.idle_time < 5 && (flow.bytes as number) > 0) {
      arrowColor = ArrowColors.GREEN;
    }

    // Set labels and arrow direction
    let leftSublabel: string = "";
    let rightSublabel: string = "";
    let leftLabel: string = "";
    let rightLabel: string = "";
    let arrowDirection: ArrowDirections;

    const isUnknown = (portState: string) =>
      portState && portState !== "unknown";

    if (flow.source_port_name < flow.destination_port_name) {
      leftLabel = flow.destination_port_name.includes(",")
        ? "MULTIPLE"
        : flow.destination_port_name;
      rightLabel = flow.source_port_name;

      if (flow.physical && flow.physical.length) {
        const sourcePortState = flow.physical[0].source_port_state;
        const destPortState = flow.physical[0].destination_port_state;

        if (isUnknown(sourcePortState)) {
          rightSublabel = sourcePortState;
        }

        if (isUnknown(destPortState)) {
          leftSublabel = destPortState;
        }
      }

      arrowDirection = ArrowDirections.LEFT;
    } else {
      rightLabel = flow.destination_port_name.includes(",")
        ? "MULTIPLE"
        : flow.destination_port_name;
      leftLabel = flow.source_port_name;

      if (flow.physical && flow.physical.length) {
        const sourcePortState = flow.physical[0].source_port_state;
        const destPortState = flow.physical[0].destination_port_state;

        if (isUnknown(sourcePortState)) {
          leftSublabel = sourcePortState;
        }

        if (isUnknown(destPortState)) {
          rightSublabel = destPortState;
        }
      }

      arrowDirection = ArrowDirections.RIGHT;
    }

    // Create flow row config
    const flowRow: RowConfig = {
      arrowDirection,
      arrowColor,
      leftLabel,
      leftSublabel,
      rightLabel,
      rightSublabel,
      lab: "",
      pri: flow.priority,
      bytes: flow.bytes as number,
      packets: flow.packets as number,
      v: flow.physical?.length || 0,
      f: flow.data?.length || 0,
    };

    if (lab == "" && stag == "" && ctag == "") {
      flowRow.lab = "No Label";
    } else if (lab != "") {
      flowRow.lab = "Lab: " + lab;
    } else if (stag != "" && ctag != "") {
      flowRow.lab = "Lab: S-" + stag + "/C-" + ctag;
    } else if (stag != "") {
      flowRow.lab = "Lab: S-" + stag;
    } else if (ctag != "") {
      flowRow.lab = "Lab: C-" + ctag;
    }

    // Set classes
    const bgColor =
      arrowColor === ArrowColors.RED
        ? "bg-[#FFD2D2]"
        : arrowColor === ArrowColors.ORANGE
          ? "bg-[#FBE7D5]"
          : "bg-[#FBFFFE]";
    const className: string = `${bgColor} px-4${idx === 0 ? " pt-4" : ""}`;

    return { flow: flowRow, className };
  };

  const buildFlowRowTooltip = (flow: DeviceFlow) => {
    const device_communicate = true;
    const vbgFlow = `${
      flow.physical && flow.physical.length !== 0
        ? `PRESENT${
            flow.physical.length > 1 ? ` (${flow.physical.length})` : ""
          }`
        : `${device_communicate ? "NOT PRESENT" : "NO COMMUNICATION"}`
    }`;

    const flowOpsFlow = `${
      flow.data && flow.data.length !== 0
        ? `PRESENT${flow.data.length > 1 ? ` (${flow.data.length})` : ""}`
        : "NOT PRESENT"
    }`;

    return (
      <div className="flex z-50 flex-wrap p-2 gap-1 w-[550px] text-xs bg-white shadow-lg rounded-md">
        <Label className="w-1/4 inline-block font-semibold font-secondary text-[#434343]">
          Name:
        </Label>
        <Label className="w-2/4 inline-block font-medium">{flow.name}</Label>

        <Label className="w-1/4 inline-block font-semibold font-secondary text-[#434343]">
          VBG Flow:
        </Label>
        <Label
          className={`w-2/4 inline-block font-medium ${
            vbgFlow === "PRESENT" ? "text-[#488B5B]" : ""
          }`}
        >
          {vbgFlow}
        </Label>

        <Label className="w-1/4 inline-block font-semibold font-secondary text-[#434343]">
          FlowOps Flow:
        </Label>

        <Label
          className={`w-1-4 mr-2 inline-block font-medium ${
            flowOpsFlow === "PRESENT" ? "text-[#488B5B]" : ""
          }`}
        >
          {flowOpsFlow}
        </Label>

        {flow.data && flow.data.length && (
          <div className="w-2/4 flex gap-2 -mt-2">
            <DialogTrigger asChild>
              <EpButton
                dataCy="deleteFlowButton"
                styling={EpButtonStyles.DELETE}
                onClick={() => setCurrentFlow(flow)}
              >
                Delete Flow
              </EpButton>
            </DialogTrigger>
            <EpButton dataCy="repushFlowButton">Repush Flow</EpButton>
          </div>
        )}

        {flow.notes &&
          Object.values(flow.notes).map((note) => {
            const noteSections = (note as string).split(":");
            return (
              <>
                <Label className="w-1/4 inline-block font-semibold font-secondary text-[#434343]">{`${noteSections[0]}:`}</Label>
                <Label className="w-2/4 inline-block font-medium">{`${noteSections[1]}`}</Label>
              </>
            );
          })}

        <Label className="w-1/4 inline-block font-semibold font-secondary text-[#434343]">
          Destination Port(s):
        </Label>
        <Label className="w-2/4 inline-block font-medium">
          {flow.destination_port_name}
        </Label>
        <Label className="w-1/4 inline-block font-semibold font-secondary text-[#434343]">
          Source Port(s):
        </Label>
        <Label className="w-2/4 inline-block font-medium">
          {flow.source_port_name}
        </Label>
      </div>
    );
  };

  const handleDeleteFlow = () => {
    toast({
      title: "Success",
      description: (
        <>
          Flow Deleted
          <br />
        </>
      ),
    });
    setIsDialogOpened(false);
    console.log({ currentFlow });
  };

  return (
    <ScrollArea
      className="max-h-[calc(100vh-theme(space.52))] w-full"
      data-cy="deviceScrollArea"
    >
      <Dialog
        open={isDialogOpened}
        onOpenChange={(open) => setIsDialogOpened(open)}
      >
        <DialogContent
          className="sm:max-w-[425px]"
          dataCy="deleteFlowDialogCnt"
        >
          <DialogHeader dataCy="deleteFlowDialogHeader" borderless>
            <DialogTitle dataCy="deleteFlowDialogTitle">
              Delete Flow
            </DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-row items-center gap-x-3">
              <WarningIcon fill="red" width={35} height={35}></WarningIcon>
              <p className="text-sm font-medium" data-cy="deleteFlowText">
                Are you sure you want to delete this flow?
              </p>
            </div>
          </div>
          <DialogFooter className="flex flex-row">
            <EpButton
              styling={EpButtonStyles.DELETE}
              dataCy="removeBtn"
              aria-label="Remove Message"
              onClick={handleDeleteFlow}
            >
              Delete Flow
            </EpButton>
            <DialogClose asChild className="flex gap-2">
              <EpButton
                styling={EpButtonStyles.CANCEL}
                dataCy="cancelBtn"
                aria-label="Cancel"
                onClick={() => setIsDialogOpened(false)}
              >
                Cancel
              </EpButton>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
        <div className="flex flex-col gap-4 w-full">
          <div className="flex flex-col gap-4">
            {flowSections.length &&
            flowSections.some((section) => section.flows.length) ? (
              flowSections.map((section: FlowSection, idx: number) => {
                return (
                  !filteredOutTypes.includes(section.type) &&
                  section.flows.length > 0 && (
                    <div key={`flowSection${idx}`}>
                      <div
                        className="flex justify-between items-center bg-[#F0F0F0] rounded-t-xl px-4 py-2"
                        data-cy={`flowSection${idx}`}
                      >
                        <div className="flex flex-col gap-2 text-[#6D6D6D] font-semibold text-xs font-secondary">
                          <Label data-cy={`flowSectionType${idx}`}>
                            Type: {section.type}
                          </Label>
                          <Label data-cy={`flowSectionName${idx}`}>
                            {section.name}
                          </Label>
                        </div>
                        {expandedSections.has(section.id) ? (
                          <IconButton
                            name="collapse"
                            tooltipText="Collapse"
                            aria-label="Collapse"
                            dataCy="collapseButton"
                            onClick={() =>
                              setExpandedSections(
                                (prev) =>
                                  new Set(
                                    [...prev].filter((x) => x !== section.id),
                                  ),
                              )
                            }
                          />
                        ) : (
                          <IconButton
                            name="expand"
                            tooltipText="Expand"
                            aria-label="Expand"
                            dataCy="expandButton"
                            onClick={() =>
                              setExpandedSections(
                                (prev) => new Set([...prev, section.id]),
                              )
                            }
                          />
                        )}
                      </div>
                      {expandedSections.has(section.id) && (
                        <div className="w-full flex flex-col text-1.5xs text-[#434343] font-secondary">
                          {section.flows.length &&
                            section.flows.map(
                              (flow: DeviceFlow, idx: number) => {
                                const flowRow = buildFlowRow(flow, idx);
                                const flowRowTooltip =
                                  buildFlowRowTooltip(flow);

                                return (
                                  <TooltipProvider
                                    delayDuration={0}
                                    skipDelayDuration={0}
                                  >
                                    <Tooltip>
                                      <TooltipTrigger>
                                        <div className={flowRow.className}>
                                          <FlowRow
                                            config={flowRow.flow}
                                            key={flow.signature}
                                          />
                                        </div>
                                      </TooltipTrigger>
                                      <TooltipContent
                                        data-cy={`flowRow${idx}Tooltip`}
                                      >
                                        {flowRowTooltip}
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                );
                              },
                            )}
                        </div>
                      )}
                    </div>
                  )
                );
              })
            ) : (
              <Label>No records found.</Label>
            )}
          </div>
        </div>
      </Dialog>
    </ScrollArea>
  );
}

import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";
import TabMenu, { TabConfig } from "src/components/tabMenu/TabMenu";
import { GlobalServicePlan } from "../manage-global-service/ManageDetailsPlans";
import ManageGlobalServicePlanDetails from "../manage-global-service-plan/ManageServicePlanForm";
import ManageServicePlanFieldsForm from "../manage-global-service-plan/ManageServicePlanFieldsForm";
import EpButton from "src/components/buttons/EpButton";
import { Plus } from "lucide-react";

export default function AddNewGlobalServicePlan() {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const defaultServicePlanObject: GlobalServicePlan = {
    createdAt: new Date(),
    description: "",
    down: 0,
    expireTime: 0,
    id: "",
    linkSpeed: 0,
    name: "",
    price: 0,
    provider: "",
    status: "",
    accessCode: "",
    accountTypes: [],
    displayType: "",
    domain: "",
    ipRange: "",
    marketingURL: "",
    planName: "",
    standByEnabled: "",
    standByInitiallyOn: "",
    subscriptionRedirectURL: "",
    supportURL: "",
    type: "",
    up: 0,
    updatedAt: new Date(),
  };

  const addNewGlobalServicePlanTabsConfig: TabConfig[] = [
    {
      key: "details",
      title: "Details",
      titleAttribute: "Manage Plan Details",
      component: (
        <ManageGlobalServicePlanDetails
          operationType="create"
          globalServicePlan={defaultServicePlanObject}
        />
      ),
      dataCy: "managePlanDetailsTab",
    },
    {
      key: "fields",
      title: "Fields",
      titleAttribute: "Manage Plan Fields",
      component: (
        <ManageServicePlanFieldsForm
          operationType="create"
          globalServicePlan={defaultServicePlanObject}
        />
      ),
      dataCy: "managePlanFieldsTab",
    },
  ];

  return (
    <Dialog
      open={isDialogOpened}
      onOpenChange={(value) => setIsDialogOpened(value)}
    >
      <DialogTrigger asChild>
        <EpButton
          aria-label="Add plan"
          dataCy="addNewGlobalServicePlanActionBtn"
        >
          <Plus className="h-3 w-3" /> Add Plan
        </EpButton>
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-4xl font-base"
        dataCy="addNewGlobalServicePlanCnt"
      >
        <DialogHeader dataCy="addNewGlobalServicePlanHeader" borderless>
          <DialogTitle dataCy="addNewGlobalServicePlanTitle">
            Add Plan
          </DialogTitle>
        </DialogHeader>
        <TabMenu
          config={addNewGlobalServicePlanTabsConfig}
          dataCy="addNewGlobalServicePlanTabMenu"
        ></TabMenu>
      </DialogContent>
    </Dialog>
  );
}

import { SubscriptionHistory } from "src/interfaces";
import SubscriptionHistoryToolbar from "./SubscriptionHistoryToolbar";
import { useState } from "react";
import Loading from "src/components/loading/Loading";

import { usePagination } from "@table-library/react-table-library/pagination";

import Pagination from "src/components/pagination";
import {
  ReportsTable,
  ReportsTableHeader,
  ReportsTableRow,
  ReportsTableBody,
  ReportsTableCell,
  ReportsTableHeaderCell,
  ReportsTableHeaderRow,
  themeConfig,
} from "src/components/reports-table/ReportsTable";
import React from "react";

type SubscriptionHistoryTableProps = {
  filteredSubscriptionHistory: SubscriptionHistory[];
};

export const initialColumns = [
  {
    title: "Service",
    isHidden: false,
    width: "clamp(250px, 15%, 15%)",
    get: (item: SubscriptionHistory) => {
      return item.Service.name;
    },
  },
  {
    title: "Plan",
    get: (item: SubscriptionHistory) => {
      return item.Serviceplan.name;
    },
    width: "clamp(150px, 10%, 10%)",
    isHidden: false,
  },
  {
    title: "Username",
    get: (item: SubscriptionHistory) => {
      return item?.Provider?.Contacts?.at(0)?.email;
    },
    width: "clamp(300px, 20%, 20%)",
    isHidden: false,
  },
  {
    title: "Phone",
    get: (item: SubscriptionHistory) => {
      return item?.Provider?.Contacts?.at(0)?.phone;
    },
    width: "clamp(100px, 16%, 16%)",
    isHidden: false,
  },
  {
    title: "Created",
    get: (item: SubscriptionHistory) => {
      return item.created_at;
    },
    width: "clamp(250px, 20%, 20%)",
    isHidden: false,
  },
  {
    title: "Unsubscribed",
    get: (item: SubscriptionHistory) => {
      return item.canceled_at;
    },
    width: "clamp(250px, 18%, 18%)",
    isHidden: false,
  },
];

const subscriptionHistoryPageSize = 8;

export type SubscriptionHistoryCol = (typeof initialColumns)[number];

export default function SubscriptionHistoryTable({
  filteredSubscriptionHistory,
}: SubscriptionHistoryTableProps) {
  const hash = window.location.hash.replace("#", "");
  const [searchQuery, setSearchQuery] = useState(hash !== "" ? hash : "*");
  const [subscriptionHistorySearch, setSubscriptionHistorySearch] = useState<
    SubscriptionHistory[]
  >(filteredSubscriptionHistory);
  const [isLoading, setIsLoading] = useState(false);
  const [lastPage, setLastPage] = useState<number>(
    Math.ceil(filteredSubscriptionHistory.length / subscriptionHistoryPageSize),
  );

  const [columns, setColumns] = useState(initialColumns);

  const pagination = usePagination<SubscriptionHistory>(
    { nodes: subscriptionHistorySearch },
    {
      state: {
        page: 0,
        size: subscriptionHistoryPageSize,
      },
    },
  );

  const currentPage = pagination.state.page + 1;

  const clearSearchsubscriptionHistory = () => {
    setSearchQuery("*");
    setSubscriptionHistorySearch(filteredSubscriptionHistory);
  };

  const searchSubscriptionHistory = async (query: string, page: number = 1) => {
    if (!query) {
      setSubscriptionHistorySearch(filteredSubscriptionHistory);
      setLastPage(
        Math.ceil(
          filteredSubscriptionHistory.length / subscriptionHistoryPageSize,
        ),
      );
      return;
    }
    setIsLoading(true);
    const searchFilteredResults = filteredSubscriptionHistory.filter(
      (subscriptionHistory) => {
        if (
          subscriptionHistory.Provider.name
            .toLowerCase()
            .includes(query.toLowerCase())
        ) {
          return true;
        }
        return false;
      },
    );
    const maxNumberOfPages = Math.ceil(
      searchFilteredResults.length / subscriptionHistoryPageSize,
    );
    // perform client side pagination.
    const startIndex = (page - 1) * subscriptionHistoryPageSize;
    const endIndex = startIndex + subscriptionHistoryPageSize;
    const paginatedResults = searchFilteredResults.slice(startIndex, endIndex);

    setLastPage(maxNumberOfPages);
    setSubscriptionHistorySearch(paginatedResults);
    setIsLoading(false);
  };

  const handleSelectPage = (page: number) => {
    pagination.fns.onSetPage(page - 1);
  };

  function getTableColumnsWidths() {
    let widthTemplateString = "";

    columns.forEach((col) => {
      if (!col.isHidden) {
        widthTemplateString += col.width + " ";
      }
    });

    return widthTemplateString.trim();
  }

  function renderSubscriptionHistorysList() {
    if (isLoading) {
      return <Loading></Loading>;
    }

    if (subscriptionHistorySearch.length < 1 && searchQuery !== "*") {
      return (
        <div className="mt-10 p-10 text-center min-h-[calc(100vh-280px-2rem)]">
          <p className="font-bold mb-10">No matching search results</p>
          <p>Try again using another search term</p>
        </div>
      );
    }

    return (
      <div className="mt-4">
        <ReportsTable
          data={{ nodes: subscriptionHistorySearch }}
          layout={{ horizontalScroll: true, fixedHeader: true, custom: true }}
          theme={{
            Table: `
                --data-table-library_grid-template-columns: ${getTableColumnsWidths()} !important;
            `,
            BaseCell: `
                &:nth-of-type(1) {
                    left: 0px;
                }
            `,
            ...themeConfig,
          }}
          className="pb-4"
          pagination={pagination}
          data-cy="subscriptionHistoryTable"
        >
          {(tableList: SubscriptionHistory[]) => {
            return (
              <React.Fragment>
                <ReportsTableHeader
                  data-cy="subscriptionHistoryTableHeader"
                  className="sticky top-0"
                >
                  <ReportsTableHeaderRow data-cy="subscriptionHistoryTableHeaderRow">
                    {columns.map((col, index) => {
                      const pinLeft = index === 0;
                      return (
                        <ReportsTableHeaderCell
                          key={col.title}
                          pinLeft={pinLeft}
                          hide={col.isHidden}
                        >
                          {col.title}
                        </ReportsTableHeaderCell>
                      );
                    })}
                  </ReportsTableHeaderRow>
                </ReportsTableHeader>
                <ReportsTableBody data-cy="subscriptionHistoryTableBody">
                  {tableList.map((subscriptionHistory) => (
                    <ReportsTableRow
                      key={subscriptionHistory.id}
                      className="font-medium"
                      data-cy={`${subscriptionHistory.id}-Row`}
                    >
                      {columns.map((col, index) => {
                        const pinLeft = index === 0;
                        return (
                          <ReportsTableCell
                            key={index}
                            pinLeft={pinLeft}
                            hide={col.isHidden}
                            data-cy={`${subscriptionHistory.id}subscriptionHistory${col.title}Cell`}
                          >
                            {col.get(subscriptionHistory)}
                          </ReportsTableCell>
                        );
                      })}
                    </ReportsTableRow>
                  ))}
                </ReportsTableBody>
              </React.Fragment>
            );
          }}
        </ReportsTable>
        <div
          className={`flex flex-wrap items-center ${
            lastPage > 1 ? "justify-between" : "justify-start"
          } space-x-0 mt-4`}
        >
          {subscriptionHistorySearch.length >= 1 && (
            <div className="text-xs font-normal font-secondary">
              Showing{" "}
              {lastPage > 1
                ? `${(currentPage - 1) * subscriptionHistoryPageSize + 1} to ${
                    (currentPage - 1) * subscriptionHistoryPageSize +
                      subscriptionHistoryPageSize >
                    subscriptionHistorySearch.length
                      ? subscriptionHistorySearch.length
                      : (currentPage - 1) * subscriptionHistoryPageSize +
                        subscriptionHistoryPageSize
                  }`
                : `${subscriptionHistorySearch?.length}`}{" "}
              of {subscriptionHistorySearch?.length} entries
            </div>
          )}
          {lastPage > 1 && subscriptionHistorySearch.length >= 1 && (
            <div className="flex items-center">
              <Pagination
                pageCount={lastPage > 4 ? 4 : lastPage}
                currentPage={currentPage}
                lastPage={lastPage}
                onSelect={handleSelectPage}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="font-primary text-black w-full">
      <p className="font-medium my-6">Subscription History Search Results</p>
      <div className="bg-[#4485D140] mb-6 border border-[#2E9BFF] p-2 rounded-md font-semibold text-xs">
        {filteredSubscriptionHistory.length} subscription(s) matched the
        provided search.
      </div>
      <SubscriptionHistoryToolbar
        clearSearch={clearSearchsubscriptionHistory}
        setSearchFilter={(query: string) => {
          setSearchQuery(query);
          searchSubscriptionHistory(query, currentPage);
        }}
        setColumns={setColumns}
        columns={columns}
        data={filteredSubscriptionHistory}
      />
      {renderSubscriptionHistorysList()}
    </div>
  );
}

import { WebhookHistory } from "src/interfaces";

const webhookHistory: WebhookHistory = {
  id: "d00405fe-2c32-11ea-b0a9-4b595658324d",
  headers: `{
    “date”: “Wed, 01 Jan 2020 01:05:42 GMT”,
    “server”: “Apache\/2.4.18 (Ubuntu)”,
    “content-length”: “33”,
    “content-type”: “text\/html; charset=UTF-8”
}`,
  method: "POST",
  provider_id: "d00405fe-2c32-11ea-b0a9-4b595658334d",
  request: "Code",
  request_micro_time: "1234",
  response_body: `{
    “service_provider_label”: “1542”
}`,
  response_code: 500,
  response_micro_time: "1234",
  return_type: "json",
  service_id: "p00405fe-2c32-11ea-b0a9-4b595658334d",
  provider_name: "Fybercom",
  service_name: "None",
  plan_name: "None",
  serviceplan_id: "a00405fe-2c32-11ea-b0a9-4b595658334d",
  transaction_id: "d00405fe-2c32-11ea-b0a9-4b595658334d",
  type: "SUBSCRIBE",
  url: "https://ammon2.qwk.net/subscribe.php",
  created_at: "2024-04-30T11:13:15.938Z",
  updated_at: "2024-04-30T11:13:15.938Z",
};

export default webhookHistory;

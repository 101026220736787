import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialog/Dialog";

import { useState } from "react";
import { Service } from "src/interfaces";
import IconButton from "src/components/buttons/IconButton";
import ManageNetworkOperatorServiceForm from "./ManageNetworkOperatorServiceForm";
import ScrollArea from "src/components/scroll-area/ScrollArea";

type ManageNetworkOperatorServicesDialog = {
  service: Service;
};

export default function ManageNetworkOperatorServicesDialog({
  service,
}: ManageNetworkOperatorServicesDialog) {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  return (
    <Dialog
      open={isDialogOpened}
      onOpenChange={(value) => setIsDialogOpened(value)}
    >
      <DialogTrigger asChild>
        <IconButton
          dataCy={`manageNetworkOperatorServicesActionBtn-${service.id}`}
          aria-label={`Manage ${service.name}`}
          tooltipText={`Manage ${service.name}`}
          name="edit"
        />
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-4xl font-base"
        dataCy="manageNetworkOperatorServicesCnt"
      >
        <DialogHeader dataCy="manageNetworkOperatorServicesHeader" borderless>
          <DialogTitle dataCy="manageNetworkOperatorServicesTitle">
            Manage - {service.name}
          </DialogTitle>
          <ScrollArea className="max-h-[calc(90vh-theme(space.52))]">
            <ManageNetworkOperatorServiceForm serviceDetails={service} />
          </ScrollArea>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
